export const passConf = (password, passwordConf) => {
  if(password !== passwordConf)
{
  return 'Паролі не співпадають'
}
else{
    return '';
  }



}
