import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BlockDivider from '../BlockDivider';
import { albums } from '../../mockData/mockData';
import Album from '../gallery/album/Album';
import UserPageBlock from './UserPageBlock';
import { useResizeMode } from '../hooks/useResizeMode';
import AlbumsBlockMobile from './AlbumsBlockMobile';

const AlbumsBlockBody = styled.div`
  position: relative;
`;

const AlbumsWrapper = styled.div`
  > div {
    margin: 0;
    box-shadow: none;
  }
`;

const AlbumsBlockFooter = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  padding: 25px 20px;

  @media (max-width: 576px) {
    padding: 19px;
  }
`;

const ShowAllAlbums = styled.div`
  margin: 0 auto;
  width: 225px;
  padding: 0 10px;
  box-shadow: 0px 0px 2px #d4d4d4;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #1e87a9;
  cursor: pointer;

  @media (max-width: 576px) {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1em;
    box-shadow: none;
  }
`;

const DividerWrapper = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const AlbumsBlock = ({ userId }) => {
  const [albumsToShow, setAlbumsToShow] = useState(albums.slice(0, 4));
  const [showDivider, setShowDivider] = useState(true);
  const isMobile = useResizeMode();

  useEffect(() => {
    if (isMobile) {
      setAlbumsToShow(albums.slice(0, 2));
    }
  }, [isMobile]);

  const onShowAllAlbums = () => {
    setAlbumsToShow(albums);
    setShowDivider(false);
  };

  return (
    <UserPageBlock title="Альбоми" count={albums.length}>
      {isMobile ? (
        <AlbumsBlockMobile albums={albumsToShow} userId={userId} showDivider={showDivider} />
      ) : (
        <AlbumsBlockBody>
          <AlbumsWrapper>
            <Album
              albumLink={`/users-albums/all-users/${userId}/userGallery/`}
              albums={albumsToShow}
              showAddItem={false}
              withOption={false}
              isUserAlbum
            />
          </AlbumsWrapper>
          <DividerWrapper show={showDivider}>
            <BlockDivider />
          </DividerWrapper>
        </AlbumsBlockBody>
      )}

      <AlbumsBlockFooter show={showDivider}>
        <ShowAllAlbums onClick={onShowAllAlbums}>Показати усі альбоми</ShowAllAlbums>
      </AlbumsBlockFooter>
    </UserPageBlock>
  );
};

export default AlbumsBlock;
