import React from "react";
import AuthSelect from "../AuthSelect";
import AuthInputText from "../AuthInputText";
import AuthSelectClubs from "./AuthSelectClubs";
import AuthSelectTrainers from "./AuthSelectTrainers";

const ChooseTrainerWithAdd = ({ trainers, setTrainers, options, validate, trainersNoList, setTrainersNoList, mt,setId }) => {
  return (
    <>
      {
        <AuthSelectTrainers
          mt='44px'
          value={trainers}
          setId={setId}
          setValue={setTrainers}
          noInList
          filtered
          placeholder='Вибрати тренера'
          options={options}
          // validate={i === 0 ? validate : false}
          hint='Почніть вводити назву або виберіть зі списку' />
      }
      {
        trainers === "Немає в списку" ? (
          trainersNoList.map((item, i) => (
            <div key={i}>
              <AuthInputText
                mt='44px'
                hint='(якщо немає вашого тренера в списку)'
                value={trainersNoList[i]}
                setValue={(value) => setTrainersNoList(state => state.map((el, j) => j === i ? value : el))}
                placeholder='ПІБ та № тел. тренера'
              />
            </div>
          ))) : ""
      }

    </>
  );
};
export default ChooseTrainerWithAdd;