import React from 'react';
import styled from 'styled-components';
import HoverTitle from '../hovers/HoverTitle';

const CheckBoxStyled = styled.div`
  position: relative;
  input {
    display: none;
  }
  label {
    position: relative;
    display: block;
    width: 34px;
    height: 18px;
    background: ${({checked}) => checked ? '#1e87a9' : '#445566'};
    border-radius: 9px;
		cursor: ${({disabled}) => disabled ? 'unset' : 'pointer'};
    :after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: ${({checked}) => checked ? '1.5px' : 'unset'};
			left: ${({checked}) => checked ? 'unset' : '1.5px'};
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: #ffffff;
    }
  }
  :hover {
    .hover {
      display: block;
    }
  }

  @media(max-width: 576px) {
    :hover {
      .hover {
        display: none;
      }
    }
  }
`;

const SettingsCheckBox = ({ id, checked, onChange, disabled=false }) => {

  return (
    <CheckBoxStyled disabled={disabled} checked={checked}>
      <input disabled={disabled} checked={checked} onChange={onChange} type="checkbox" id={id} />
      <label htmlFor={id}></label>
      <HoverTitle title={checked ? 'Публічно' : 'Приховано'} />
    </CheckBoxStyled>
  );
};

export default SettingsCheckBox;
