import React from 'react';
import styled from 'styled-components';
import ItemAmountBlock from './ItemAmountBlock';

const DetailStyled = styled.div`
	display: ${({ show }) => (show ? 'block;' : 'none;')} 
	padding: 3px 25px 20px 205px;
	${({ paid, archive }) =>
    paid
      ? `
		padding-left: calc(27% + 25px);
	`
      : archive
      ? 'padding-left: calc(14.5% + 25px);'
      : 'padding-left: calc(19.5% + 25px);'}
	background: #FFF;

  @media(max-width: 1200px) {
    ${({ paid, archive }) =>
    paid
      ? `
		padding-left: 25px;
	`
      : archive
      ? 'padding-left: calc(17% + 40px);'
      : 'padding-left: calc(21% + 40px);'}
  }

  @media(max-width: 992px) {
    padding-left: 25px;
  }

  @media(max-width: 768px) {
    padding: ${({ accountPage }) => (accountPage ? '16px 64px' : '16px 20px')};
    padding-bottom: 60px;
  }
`;

const InfoBlock = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TitlesBlock = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #1e87a9;
  div {
    margin-top: 10px;
  }

  @media (max-width: 992px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 18px;
    div {
      margin-top: 0;
    }
  }
`;

const DataBlock = styled.div`
  padding-left: 10px;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #445566;
  div {
    margin-top: 10px;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 992px) {
    font-size: 15px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  button {
    margin-left: 25px;
    padding: 7px 30px;
    //background: linear-gradient(
    //    184.06deg,
    //    #f6f6f6 -84.07%,
    //    #f6f6f6 -84.05%,
    //    rgba(251, 251, 251, 0.407481) 18.48%,
    //    rgba(255, 255, 255, 0) 97.79%
    //  ),
    //  #1e87a9;
    background-color: #47A5BF;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    letter-spacing: 0.1em;
    :first-child {
      background: #fff;
      color: #47A5BF;
      border: 3px solid #47A5BF;
      margin-left: 0;
    }
  }

  @media (max-width: 992px) {
    button {
      padding: 7px 25px;
    }
  }

  @media (max-width: 768px) {
    margin: 0 auto;
    width: 265px;
    flex-direction: column;
    justify-content: space-between;
    height: 128px;
    padding: 16px 0;
    button {
      font-size: 16px;
      line-height: 20px;
      height: 40px;
      margin-left: 0;
      padding: 7px 10px;
    }
  }
`;

const DataBlockMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    color: #556677;
    font-size: 14px;
    margin-bottom: 16px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const ListItemHover = ({
  show,
  paid = false,
  archive = false,
  account,
  accountPage = false,
  buttonTitle = 'Повернути в рахунки',
  isMobile,
}) => {
  return (
    <DetailStyled show={show} paid={paid} archive={archive} accountPage={accountPage}>
      <InfoBlock>
        <TitlesBlock>
          <div>IBAN:</div>
          <DataBlockMobile>
            <div>{account.account}</div>
          </DataBlockMobile>
          <div>ЄДРПОУ:</div>
          <DataBlockMobile>
            <div>{account.indNumber}</div>
          </DataBlockMobile>
          <div>Призначення:</div>
          <DataBlockMobile>
            <div>{account.purpose}</div>
          </DataBlockMobile>
        </TitlesBlock>
        <DataBlock>
          <div>{account.account}</div>
          <div>{account.indNumber}</div>
          <div>{account.purpose}</div>
        </DataBlock>
        {isMobile ? <ItemAmountBlock account={account} /> : ''}
      </InfoBlock>
      {accountPage || archive ? (
        <ButtonBlock>
          <button>{buttonTitle}</button>
          <button>Сплатити</button>
        </ButtonBlock>
      ) : null}
    </DetailStyled>
  );
};

export default ListItemHover;
