import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BlockHeader from '../../BlockHeader';
import SettingsSection from '../../SettingsSection';
import InformationAboutBlock from './InformationAboutBlock';
import InformationAgeBlock from './InformationAgeBlock';
import ButtonBlock from '../../ButtonBlock';
import InformationContactBlock from './InformationContactBlock';
import {userData} from '../../../../mockData/mockData';
import { useResizeMode } from '../../../hooks/useResizeMode';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile, setUserProfile } from '../../../../actions/profileAction';
import { useValidate } from '../../../hooks/useValidate';
import { validateEmail } from '../../../../utils/validateEmail';
import { validateSocials } from '../../../../utils/validateSocials';

const ContentWrapper = styled.div`
  @media (max-width: 576px) {
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;


const InformationSection = () => {
  
  const userInfo = useSelector(state => state.userProfiles?.currentProfile?.user)
  const newUserInfo = useSelector(state => state.setUserProfiles?.currentProfile)
  const userVarify = useSelector(state => state.setUserProfiles?.varify)
  const userId = useSelector(state => state.login?.currentUser?.id)

  const dispatch = useDispatch();


  const [submitActive, setSubmitActive] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [ageChecked, setAgeChecked] = useState(true);
  const [birthdayChecked, setBirthdayChecked] = useState(true);
  const [inputValues, setInputValues] = useState(userData);
  // const [userId, setUserId] = (userInfo?.user?.id)
  const [inputEmail,setInputEmail] = useState('');
  const [inputInstagram,setInputInstagram] = useState('');
  const [inputFacebook,setInputFacebook] = useState('');
  const [inputTelegram,setInputTelegram] = useState('');
  const [inputTikTok,setInputTikTok] = useState('');
  const [inputAbout,setInputAbout] = useState('');

  const [validate, setValidate] = useState(false);

  useEffect(() => {
    setInputEmail(() => newUserInfo === undefined || newUserInfo === null ? userInfo?.email : newUserInfo?.email);
    setInputInstagram(() => newUserInfo === undefined || newUserInfo === null ? userInfo?.instagram : newUserInfo?.instagram);
    setInputFacebook(() => newUserInfo === undefined  || newUserInfo === null ? userInfo?.facebook : newUserInfo?.facebook);
    setInputTelegram(() => newUserInfo === undefined  || newUserInfo === null ? userInfo?.telegram : newUserInfo?.telegram);
    setInputTikTok(() => newUserInfo === undefined  || newUserInfo === null ? userInfo?.tiktok : newUserInfo?.tiktok);
    setInputAbout(() =>  newUserInfo === undefined  || newUserInfo === null ? userInfo?.about : newUserInfo?.about);
      },[userInfo,newUserInfo,userId,disabled])
  const isMobile = useResizeMode();

  // const inputEmailValidate = useValidate(inputEmail, validateEmail,validate)
  // const inputInstagramValidate = useValidate(inputInstagram, validateSocials,validate)
  // const inputFacebookValidate = useValidate(inputEmail, validateSocials,validate)
  // const inputTelegramValidate = useValidate(inputEmail, validateSocials,validate)
  // const inputTikTokValidate = useValidate(inputEmail, validateSocials,validate)

  // console.log(inputEmailValidate)

  const onChangeAgeChecked = (e) => {
    setAgeChecked(e.target.checked);
    setSubmitActive(true);
  };

  const onChangeBirthdayChecked = (e) => {
    setBirthdayChecked(e.target.checked);
    setSubmitActive(true);
  };

  const onEdit = () => {
    // setInputEmail(() => newUserInfo === undefined ? userInfo?.email : newUserInfo?.email);
    // setInputInstagram(() => newUserInfo === undefined ? userInfo?.instagram : newUserInfo?.instagram);
    // setInputFacebook(() => newUserInfo === undefined ? userInfo?.facebook : newUserInfo?.facebook);
    // setInputTelegram(() => newUserInfo === undefined  ? userInfo?.telegram : newUserInfo?.telegram);
    // setInputTikTok(() => newUserInfo === undefined  ? userInfo?.tiktok : newUserInfo?.tiktok);
    // setInputAbout(() =>  newUserInfo === undefined  ? userInfo?.about : newUserInfo?.about);
    setDisabled((state) => !state);
    setValidate(false)
    setSubmitActive(false)
  };


  const onEditAbout = (e) => {
    setSubmitActive(true)
    setInputAbout(e.target.value)
  }
  const onEditEmail = (e) => {
    setSubmitActive(true)
    setInputEmail(e.target.value)
  }
  const onEditInstagram = (e) => {
    setSubmitActive(true)
    setInputInstagram(e.target.value)
  }
  const onEditFacebook = (e) => {
    setSubmitActive(true)
    setInputFacebook(e.target.value)
  }
  const onEditTelegram = (e) => {
    setSubmitActive(true)
    setInputTelegram(e.target.value)
  }
  const onEditTikTok = (e) => {
    setSubmitActive(true)
    setInputTikTok(e.target.value)
  }

  const onReset = () => {
    setInputEmail(() => newUserInfo === undefined || newUserInfo === null ? userInfo?.email : newUserInfo?.email);
    setInputInstagram(() => newUserInfo === undefined || newUserInfo === null ? userInfo?.instagram : newUserInfo?.instagram);
    setInputFacebook(() => newUserInfo === undefined  || newUserInfo === null ? userInfo?.facebook : newUserInfo?.facebook);
    setInputTelegram(() => newUserInfo === undefined  || newUserInfo === null ? userInfo?.telegram : newUserInfo?.telegram);
    setInputTikTok(() => newUserInfo === undefined  || newUserInfo === null ? userInfo?.tiktok : newUserInfo?.tiktok);
    setInputAbout(() =>  newUserInfo === undefined  || newUserInfo === null ? userInfo?.about : newUserInfo?.about);
    setSubmitActive(false);
  }
  const onSubmit = (e) => {
    e.preventDefault();
    // setInputEmail(() => inputEmail === userInfo?.email ? userInfo?.email : inputEmail);
    // setInputInstagram(() => inputInstagram === userInfo?.instagram ? userInfo?.instagram : inputInstagram);
    // setInputFacebook(() => inputFacebook === userInfo?.facebook ? userInfo?.facebook : inputFacebook);
    // setInputTelegram(() => inputTelegram === userInfo?.telegram ? userInfo?.telegram : inputTelegram);
    // setInputTikTok(() => inputTikTok === userInfo?.tiktok  ? userInfo?.tiktok : inputTikTok);
    // setInputAbout(() => inputAbout === userInfo?.about ? userInfo?.about : inputAbout);
    dispatch(setUserProfile(
      userId,
      inputEmail,
      inputInstagram,
      inputFacebook,
      inputTelegram,
      inputTikTok,
      inputAbout,
    ))
    setDisabled((state) => !state)
    setSubmitActive(false)
  };

  useEffect(() => {
      if(userVarify){
    setValidate(true)
    }else {
      setValidate(false)
    }
  },[userVarify])
  return (
    <SettingsSection>
      <BlockHeader
        title="Особиста Інформація"
        subtitle="Введена нижче інформація буде відображатися на головній сторінці"
        divider={isMobile ? showContent ? true : false : true} 
        show={showContent}
        setShow={setShowContent}
      />
      <ContentWrapper show={showContent}>
        <form onSubmit={onSubmit} onReset={onReset}>
          <InformationAgeBlock
            checked={ageChecked}
            onChange={onChangeAgeChecked}
            id="age"
            iconUrl="/icons/user_inform/age.svg"
            title="Вік"
            disabled={disabled}
          />
          <InformationAgeBlock
            checked={birthdayChecked}
            onChange={onChangeBirthdayChecked}
            id="birthday"
            iconUrl="/icons/user_inform/cake.svg"
            title="Дата народження"
            disabled={disabled}
          />
          <InformationContactBlock
            disabled={disabled}
            validate={validate}
            isMobile={isMobile}
            valueEmail={inputEmail}
            valueInstagram={inputInstagram}
            valueFacebook={inputFacebook}
            valueTelegram={inputTelegram}
            valueTikTok={inputTikTok}
            setInputMail={onEditEmail}
            setInputInstagram={onEditInstagram}
            setInputFacebook={onEditFacebook}
            setInputTelegram={onEditTelegram}
            setInputTikTok={onEditTikTok}

          />

          <InformationAboutBlock
            disabled={disabled}
            // onChange={onChangeInputs}
            valueObj={inputValues}
            valueAbout={inputAbout}
            onChange={onEditAbout}
          />
          <ButtonBlock
            onEdit={onEdit}
            secondButton={disabled ? 'Редагувати' : 'Скасувати'}
            submitTitle={disabled ? '' : 'Зберегти зміни'}
            submitActive={submitActive}
            type='reset'
          />
        </form>
      </ContentWrapper>
    </SettingsSection>
  );
};

export default InformationSection;
