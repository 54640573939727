import React from 'react';
import styled from 'styled-components';

const HeaderStyled = styled.div`
  position: relative;
  padding: 21px;
  background: #f6f6f6;
`;

const BlockToBack = styled.div`
display: flex;
align-items: center;
justify-content: ${({ withClose }) => (withClose ? 'space-between' : 'flex-start')};
font-weight: 600;
font-size: 17px;
line-height: 20px;
letter-spacing: 0.07em;
color: #223344;
img {
	max-width: ${({withClose}) => withClose ? '16px' : '10px'};
	margin-right: 17px;
}
`;

const Divider = styled.div`
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  height: 2px;
  box-shadow: 0px -1px 2px #FFFFFF, 0px 1px 2px #E5E5E5;
`;



const MobileLayoutHeader = ({ title, withClose = false, onClose, divider=true }) => {
  return (
    <HeaderStyled>
      <BlockToBack withClose={withClose} onClick={onClose}>
        {withClose ? '' : <img src="/icons/slider_mobile/arrow_back.svg" alt="arrow_back" />}
        <div>{title}</div>
        {withClose ? <img src="/icons/close_icon.svg" alt="close" /> : ''}
      </BlockToBack>
      {divider ? <Divider></Divider> : ''}
    </HeaderStyled>
  );
};

export default MobileLayoutHeader;
