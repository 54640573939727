import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import BookmarksContent from './bookmarksContent/BookmarksContent';
import BookmarksTabs from './bookmarksTabs/BookmarksTabs';
import { dancersList, clubsList } from '../../mockData/mockData';
import BookmarksSearchMobile from './bookmarksContent/BookmarksSearchMobile';
import { useResizeMode } from '../hooks/useResizeMode';

const BookmarksStyled = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media(max-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: unset;
  }
`;

const FilterDescr = styled.div`
  display: none;

  @media(max-width: 768px) {
    margin: 2px 0;
    display: block;
    padding: 12px 20px;
    background: #F6F6F6;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #1E87A9;
  }
`;

const Bookmarks = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const isMobile = useResizeMode();
  

  return (
    <BookmarksStyled>
      <BookmarksSearchMobile value={searchTerm} setValue={setSearchTerm} />
      <BookmarksTabs 
        showInMobile
        title={isMobile ? 'Фільтр' : 'Закладки'}
        isBookmarks 
        tabsArr={[
          {title: 'Танцюристи', path: '/bookmarks/dancers'},
          {title: 'Тренери та судді', path: '/bookmarks/judges'},
          {title: 'Клуби', path: '/bookmarks/clubs'},
        ]}
      />
      <FilterDescr>Максимальна кількість закладок для вашого акаунту: 30</FilterDescr>
      <Switch>
        <Route path="/bookmarks/dancers">
          <BookmarksContent
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            content={dancersList.filter(
              (item) => item.staus.length === 1 && item.staus.includes('Танцюрист')
            )}
          />
          <BookmarksTabs 
            title={'Закладки'}
            isBookmarks 
            tabsArr={[
              {title: 'Танцюристи', path: '/bookmarks/dancers'},
              {title: 'Тренери та судді', path: '/bookmarks/judges'},
              {title: 'Клуби', path: '/bookmarks/clubs'},
            ]}
          />
        </Route>
        <Route path="/bookmarks/judges">
          <BookmarksContent
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            content={dancersList.filter(
              (item) => !(item.staus.length === 1 && item.staus.includes('Танцюрист'))
            )}
          />
          <BookmarksTabs 
            title='Закладки' 
            isBookmarks
            tabsArr={[
              {title: 'Танцюристи', path: '/bookmarks/dancers'},
              {title: 'Тренери та судді', path: '/bookmarks/judges'},
              {title: 'Клуби', path: '/bookmarks/clubs'},
            ]}
          />
        </Route>
				<Route path='/bookmarks/clubs'>
					<BookmarksContent
           content={clubsList} 
           searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
           isClub />
					<BookmarksTabs 
            title='Закладки' 
            isBookmarks
            tabsArr={[
              {title: 'Танцюристи', path: '/bookmarks/dancers'},
              {title: 'Тренери та судді', path: '/bookmarks/judges'},
              {title: 'Клуби', path: '/bookmarks/clubs'},
            ]}
          />
				</Route>
      </Switch>
    </BookmarksStyled>
  );
};

export default Bookmarks;
