import React from 'react';
import PaymentsListItem from '../../paymentsListItem/PaymentsListItem';
import ItemDateMobile from '../../paymentsListItem/listItemMedia/ItemDateMobile';
import ItemDateBlock from '../../paymentsListItem/ItemDateBlock';
import { useResizeMode } from '../../../hooks/useResizeMode';

const ArchiveItem = ({ account }) => {

  const isMobile = useResizeMode();

  return (
    <PaymentsListItem account={account.accountDescr} descrRatio={4} archive>
      {
        isMobile ? <ItemDateMobile dateTitle='Дата рахунку:' date={account.date} /> : <ItemDateBlock date={account.date} />
      }
    </PaymentsListItem>
  );
};

export default ArchiveItem;
