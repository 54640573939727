import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BlockDivider from '../../../../../BlockDivider';
import { albums } from '../../../../../../mockData/mockData';
import GalleryBlockItemActive from './GallaryBlockItemActive';
import { useResizeMode } from '../../../../../hooks/useResizeMode';
import { useDispatch, useSelector } from "react-redux";
import { getUserPhotos } from "../../../../../../actions/galleryAction";
import { rootURL } from "../../../../../../api/api";

const GalleryBlockStyled = styled.div`
  width: 25%;
  background: #f1f2f3;

  @media(max-width: 576px) {
    width: 100%;
    padding: 20px 0 20px 20px;
  }	
`;

const GalleryBlockHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 30px;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #445566;
  img {
    margin-right: 10px;
  }

  @media(max-width: 576px) {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    img {
      max-width: 18px;
    }
  }	
`;

const GalleryBlockItem = styled.div`
  width: 82%;
  margin: 0 auto;
  margin-top: 15px;
  cursor: pointer;
  img {
    max-width: 100%;
    border-radius: 5px;
  }

  @media(max-width: 576px) {
    width: 137px;
    height: 75px;
    margin: 0 2px;
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0;
    }
  }	
`;

const GalleryBody = styled.div`
max-height: calc(100% - 85px);
overflow-y: auto;
scrollbar-width: thin;
scrollbar-color: #f1f2f3 #fff;
::-webkit-scrollbar {
	width: 10px;
	background-color: #f1f2f3;
}
::-webkit-scrollbar-thumb {
	background: #f0f0f0;
	box-shadow: -1px -1px 2px #ffffff, 1px 1px 2px rgba(174, 174, 192, 0.5);
	border-radius: 10px;
}

@media(max-width: 576px) {
  overflow-x: auto;
  width: 100%;
  margin-top: 12px;
  ::-webkit-scrollbar {
    display: none;
  }
}	
`;

const OverflowGallery = styled.div`

@media(max-width: 576px) {
  display: flex;
  width: ${({width}) => width};
}	
`;

const DividerWrapper = styled.div`
@media(max-width: 576px) {
  display: none;
}	
`;

const GalleryBlock = ({ setImgSrc, curentSrc }) => {

  const [idxActivePhoto, setIdxActivePhoto] = useState(null);
  const [containerWidth, setContainerWidth] = useState('100%');
  const isMobile = useResizeMode();

  const galleryImg = useSelector(state => state.userGallery?.dataPhoto?.results?.photos)
  const userId = useSelector(state => state.userProfiles?.currentProfile?.user?.id);

  const dispatch = useDispatch()

  useEffect(() => {
    if (userId !== undefined){
      dispatch(getUserPhotos(userId,0,200))
    }
  },[userId])

  // useEffect(()=>{
  //   const count = albums[0].photos.length;
  //   setContainerWidth(`${count*141-4}px`)
  // }, []);

  const onClick = (idx, src) => {
    if (isMobile) {
      setImgSrc(src);
    } else {
      setIdxActivePhoto(idx);
    }
    
  };
  return (
    <GalleryBlockStyled>
      <GalleryBlockHeader>
        <img src="/icons/active_links/galery.svg" alt="gallery" />
        Галерея
        <DividerWrapper><BlockDivider height="2px" br="1.5px" bg="#1E87A9" /></DividerWrapper>
      </GalleryBlockHeader>
      
      <GalleryBody>
      <OverflowGallery width={containerWidth}>
      {galleryImg?.map((item, i) => {
          if (idxActivePhoto === i) {
            return (
              <GalleryBlockItemActive key={i} src={rootURL + item.image} setImgSrc={setImgSrc} curentSrc={curentSrc} />
            );
          } else {
            return (
              <GalleryBlockItem onClick={() => onClick(i, item)} key={i}>
                <img src={rootURL + item.image} alt="фото" />
              </GalleryBlockItem>
            );
          }
        })}
      </OverflowGallery>
      </GalleryBody>
    </GalleryBlockStyled>
  );
};

export default GalleryBlock;
