import React from "react";
import styled from "styled-components";

const ContactsBlockStyled = styled.ul`
  li {
    display: flex;
    align-items: center;
    height: 40px;
  }
  img {
    margin-right: 5px;
  }
  div {
    font-weight: 500;
    font-size: 16px;
    color: #445566; 
    line-height: 18px;
  }

  @media(max-width: 1200px) {
    span {
      display: none;
    }
  }

  @media(max-width: 576px) {
    div {
      font-size: 13.5px;
      padding-top: 2px;
    }
    img {
      max-width: 22px;
    }
    li {
      height: 30px;
      align-items: flex-start;
    }
  }
`;

const ContactsClubBlock = ({ value }) => {
  return (
    <ContactsBlockStyled>
      <li>
        <img src="/icons/clubs/phone.svg" alt="phone" />
        <div>{value.number}</div>
      </li>
      <li>
        <img src="/icons/clubs/sphere.svg" alt="website" />
        <div>{value.website}</div>
      </li>
      <li>
        <img src="/icons/user_inform/mail.svg" alt="email" />
        <div>{value.email}</div>
      </li>
      <li>
        <img src="/icons/user_inform/instagram.png" alt="instagram" />
        <div>{value.instagram}</div>
      </li>
      <li>
        <img src="/icons/user_inform/facebook.svg" alt="facebook" />
        <div>{value.facebook}</div>
      </li>
      <li>
        <img src="/icons/user_inform/telegram.svg" alt="telegram" />
        <div>{value.telegram}</div>
      </li>
      <li>
        <img src="/icons/user_inform/tiktok.svg" alt="tiktok" />
        <div>{value.tiktok}</div>
      </li>
    </ContactsBlockStyled>
  );
};

export default ContactsClubBlock;
