import React from 'react';
import styled from 'styled-components';
import FooterItem from './FooterItem';
import { showPopupAddress } from '../../actions/popupsAction';
import { useDispatch } from 'react-redux';

const FooterStyled = styled.footer`
  margin-top: 100px;
  padding: 40px 10px 30px 10px;
  background: rgba(160, 172, 183, 0.75);

  @media (max-width: 768px) {
    margin-top: 60px;
    padding: 0;
  }
`;

const FooterWrapper = styled.div`
  max-width: 1185px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const AddressTitle = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #1e87a9;
  margin-top: 8px;
  cursor: pointer;
  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.05em;
  }
`;

const Address = styled.div`
  display: none;
  span {
    max-width: 375px;
    margin-top: 10px;
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #223344;
    
  }

  @media (max-width: 768px) {
    display: block;
    span {
      max-width: 100%;
      margin-top: 8px;
    }
  }
`;

const Footer = () => {

  const dispatch = useDispatch();

  const openAddressPopup = () => {
    dispatch(showPopupAddress())
  }

  return (
    <FooterStyled>
      <FooterWrapper>
        <FooterItem title="Про компанію">
          <div>ГС "Спілка громадських організацій спортивного танцю України"</div>
          <AddressTitle onClick={openAddressPopup}>Адреса</AddressTitle>
          <Address>
            <span>Юридична адреса: Україна, 02099, Київ, вул.Ялтинська, будинок 5 - б</span>
            <span>Фактична адреса: Україна, 01103, Київ, Залізничне шосе, 3</span>
            <span>Код організації: 30386538</span>
          </Address>
        </FooterItem>
        <FooterItem title="Контакти">
          <div>
            <a href="mailto:sgostu.finance@gmail.com">sgostu.finance@gmail.com</a>
          </div>
          <div>
            <a href="tel:+380931077770">+38(093)107-77-70</a>
          </div>
        </FooterItem>
        <FooterItem title="Підтримка">
          <div>
            <a href="mailto:support@e-phan.com">support@e-phan.com</a>
          </div>
          <div>
            <a href="tel:+380000000000">+38(000)000-00-00</a>
          </div>
        </FooterItem>
      </FooterWrapper>
    </FooterStyled>
  );
};

export default Footer;
