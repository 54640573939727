import React, {
  useState,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import styled
  from 'styled-components';
import RegisterContainer
  from '../../components/auth/register/RegisterContainer';
import RegisterNav
  from '../../components/auth/register/RegisterNav';
import AuthTitle
  from '../../components/auth/AuthTitle';
import AuthSubTitle
  from '../../components/auth/AuthSubTitle';
import AuthDivider
  from '../../components/auth/AuthDivider';
import RadioButton
  from '../../components/auth/register/RadioButton';
import AuthTextArea
  from '../../components/auth/AuthTextArea';
import InpupHint
  from '../../components/auth/InputHint';
import AddInputLink
  from '../../components/auth/register/AddInputLink';
import AuthInputText
  from '../../components/auth/AuthInputText';
import InputTitle
  from '../../components/auth/register/InputTitle';
import ChooseClubWithAdd
  from '../../components/auth/register/ChooseClubWithAdd';
import RegisterFileInputBlock
  from '../../components/auth/register/RegisterFileInputBlock';
import ButtonsContainer
  from '../../components/auth/ButtonsContainer';
import ButtonSubmit
  from '../../components/auth/buttons/ButtonSubmit';
import ValidMessage
  from '../../components/auth/ValidMessage';
import { useSelector } from 'react-redux';
import AuthSelectForId
  from '../../components/auth/AuthSelectForId';
import { useDispatch } from 'react-redux';
import {
  registUserJudge,
  registUserTrainer,
} from '../../actions/registerAction';
import { getClubsList, getEventPosition, getTrainersList } from "../../actions/positionsAction";
import { dancerClassOptions } from '../../mockData/mockData'

const EducationBlock = styled.div`
  position: relative;
  padding-bottom: 32px;
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  @media (max-width: 1000px) {
    max-width: ${({ mw1000 }) => mw1000};
    margin: ${({ m1000 }) => m1000};
  }
  @media (max-width: 480px) {
    max-width: ${({ mw480 }) => mw480};
  }@media (max-width: 360px) {
    max-width: 296px;
  }

`;

const SpecialEducBlock = styled(EducationBlock)`
  padding-bottom: 40px;
  @media (max-width: 1000px) {
    max-width: ${({ mw1000 }) => mw1000};
    margin: ${({ m1000 }) => m1000};
    padding: ${({ pd1000 }) => pd1000};
  }
  @media (max-width: 480px) {
    max-width: ${({ mw480 }) => mw480};
  }@media (max-width: 360px) {
    max-width: 296px;
  }
`;

const InputContainer = styled.div`
  width: 432px;
  @media (max-width: 480px) {
    margin-top: ${({ mt480 }) => mt480};
    max-width: ${({ mw480 }) => mw480};
  }@media (max-width: 360px) {
    max-width: 296px;
  }
`;

const SectionContainer = styled.div`
  padding: ${p => p.padding || '40px 0'};
  position: relative;
  @media (max-width: 1000px) {
    max-width: ${({ mw1000 }) => mw1000};
    margin: ${({ m1000 }) => m1000};
    display: ${({ df }) => df};
    flex-direction: ${({ fd }) => fd};
  }
  @media (max-width: 480px) {
    max-width: ${({ mw480 }) => mw480};
  }@media (max-width: 360px) {
    max-width:296px;
  }
`;

const ContainerTwoColumns = styled.div`
  margin-top: 44px;
  display: grid;
  grid-template-columns: repeat(2, minmax(200px, 432px));
  justify-content: space-between;
  row-gap: 44px;
  @media (max-width: 1000px) {
    display: ${({ df }) => df};
    flex-direction: ${({ fd }) => fd};
  }
`;

const ContainerOneColumn = styled.div`
  margin-top: ${p => p.mt || '32px'};
  width: 432px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  @media (max-width: 480px) {
    width: 312px;
  }@media (max-width: 480px) {
    width: 296px;
  }
`;

const QuestionaryTrainerPage = () => {

  useEffect(() => {
    dispatch(getClubsList())

  },[])

  const clubsList = useSelector(state => state.positionsReducer?.clubsList?.results)


  const [education, setEducation] = useState([
    {
      id: 2,
      education: 'Вища',
      checked: false,
    },
    {
      id: 20,
      education: 'Середня спеціальна',
      checked: false,
    },
    {
      id: 21,
      education: 'Середня',
      checked: false,
    },
  ]);
  const [specialEducation, setSpecialEducation] = useState([
    {
      id: 1,
      education: 'Маю',
      checked: false,
      value_true: true,
    },
    {
      id: 2,
      education: 'Не маю',
      checked: false,
      value_false: false,
    },
  ]);
  const [congress, setCongress] = useState([
    {
      value: 'Так',
      checked: false,
    },
    {
      value: 'Ні',
      checked: false,
    },
  ]);
  const [congressJudges, setCongressJudges] = useState([
    {
      value: 'Так',
      checked: false,
    },
    {
      value: 'Ні',
      checked: false,
    },
  ]);
  const [judgesTest, setJudgesTest] = useState([
    {
      value: 'Так',
      flag: 1,
      checked: false,
    },
    {
      value: 'Ні',
      flag: 0,
      checked: false,
    },
  ]);
  const [congressDescr, setCongressDescr] = useState('');
  const [congressJudgesDescr, setCongressJudgesDescr] = useState('');
  const [schools, setSchools] = useState(['']);
  const [dancerClass, setDancerClass] = useState('');
  const [dancerClassId, setDancerClassId] = useState(0);
  const [yearEndCompetition, setYearEndCompetition] = useState('');
  const [yearTrainer, setYearTrainer] = useState('');
  const [competitionResult, setCompetitionResult] = useState('');
  const [awards, setAwards] = useState('');
  const [levelOfTraining, setLevelOfTraining] = useState('');
  const [clubs, setClubs] = useState('');
  const [clubs2, setClubs2] = useState('');
  const [clubsId,setClubsId] = useState(0);
  const [newClub, setNewClub] = useState(false)
  const [judgeTestFlag, setJudgeTestFlag] = useState(0);
  const [clubsNoList, setClubsNoList] = useState([{
    id: 1,
    clubName: '',
    manager: '',
  }, {
    id: 2,
    clubName: '',
    manager: '',
  }]);
  const [judgeYear, setJudgeYear] = useState('');
  const [positions, setPositions] = useState([
    {
      id: 5,
      title: 'Суддя',
      checked: false,
    },
    {
      id: 11,
      title: 'Головний суддя',
      checked: false,
    },
    {
      id: 12,
      title: 'Спортивний інспектор',
      checked: false,
    },
  ]);
  const [judgeCategory, setJudgeCategory] = useState('');
  const [judgeCategoryId, setJudgeCategoryId] = useState(0);
  const [dateJudgeCategory, setDateJudgeCategory] = useState('');
  const [judgesTestDescr, setJudgesTestDescr] = useState('');
  const [judgeBookFiles, setJudjeBookFiles] = useState([]);

  const [validate, setValidate] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const userCurrent = useSelector(state => state.registerUsers?.registersUser.data?.pk);
  const usersRole = useSelector(state => state.users?.curentUser?.role);
  const history = useHistory();

  //-------------API
  const [specialEducationName, setSpecialEducationName] = useState('');
  const [educationName, setEducationName] = useState([]);
  const [positionName, setPositionName] = useState('');
  const [positionId, setPositionId] = useState(0);
  // const [categoryId, setCategoryId] = useState(0);
  const [educationId, setEducationId] = useState([]);
  const [specialEducationId, setSpecialEducationId] = useState(0);
  const [specialEducationBool] = useState(false);
  const [congressBoolTrainer, setCongressBoolTrainer] = useState(false);
  const [congressBoolJudge, setCongressBoolJudge] = useState(false);
  const dispatch = useDispatch();

  //-------------

  useEffect(() => {
    if (validate) {
      if (
        dancerClass
      ) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    }
    {
      education.map((item) => {
        if (item.checked === true) {
          setEducationId([item.id]);
        }
      });
    }
    {
      specialEducation.map((item) => {
        if (item.checked === true) {
          setSpecialEducationId(item.id);
        }
      });
    }
    {
      congress.map((item) => {
        if (item.checked === true) {
          setCongressBoolTrainer(false);
        } else {
          setCongressBoolTrainer(true);
        }
      });
    }
    {
      congressJudges.map((item) => {
        if (item.checked === true) {
          setCongressBoolJudge(false);
        } else {
          setCongressBoolJudge(true);
        }
      });
    }
    {
      positions.map(item => {
        if (item.checked === true) {
          setPositionId(item.id);
        }
      });
    }
    {

      judgesTest.map(item => {
          if (item.checked && item.value === 'Так') {
            setJudgeTestFlag(1)
          } else if (item.checked && item.value === 'Ні') {
            setJudgeTestFlag(0)
          }
        }
      )

    }
  }, [judgesTest,judgeTestFlag,validate, education, dancerClass, specialEducation, congress, congressJudges, positions, positionId, specialEducationName, positionName, yearTrainer, judgeYear, judgeCategoryId, specialEducationBool, congressBoolTrainer, congressBoolJudge, formValid]);

console.log(educationId)

  const judgeCategoryOptions = [
    {
      title: 'Національна',
      id: 4,
    },
    {
      title: 'Перша категорія',
      id: 8,
    },
    {
      title: 'Друга категорія',
      id: 9,
    },
    {
      title: 'Третя категорія',
      id: 10,
    },
  ];


  const onAddField = (setValue) => {
    setValue(state => [...state, '']);
  };

  const onAddClub = () => {
    setNewClub(true)
  }

  const onChangeFile = (e, setValue) => {
    const fileList = Object.values(e.target.files).filter((item) => isNaN(item));
    setValue(fileList);
  };

  const onRemoveFile = (idx, setValue) => {
    setValue((state) => {
      return [...state.slice(0, idx), ...state.slice(idx + 1)];
    });
  };


  const onSubmit = (e) => {
    e.preventDefault();
    setValidate(true);
    if (!formValid) {
      return;
    }

    if (!usersRole.length) {
      history.push('/auth/reg_data');
    } else {
      const idx = usersRole.findIndex(item => item === 'trainer');
      if (usersRole[idx + 1]) {
        history.push(`/auth/questionary_${usersRole[idx + 1]}`);
      } else {
        history.push('/auth/reg_add_doc');
      }
    }
    if (judgeYear !== '' && yearTrainer !== '') {
      dispatch(registUserTrainer({
        is_dance_educated: specialEducationBool,
        dance_education_place: specialEducationName,
        dancing_career_start: yearEndCompetition,
        dancing_awards: awards,
        dancing_career_results: competitionResult,
        start_work_year: yearTrainer,
        is_visited_congresses: congressBoolTrainer,
        congresses_list: congressDescr,
        dancing_classes: dancerClassId,
        education: [2],
        dancing_career_stop: yearEndCompetition,
        user: userCurrent,
      }));
      dispatch(registUserJudge({
          is_dance_educated: specialEducationBool,
          dance_education_place: specialEducationName,
          dancing_career_start: yearEndCompetition,
          dancing_awards: awards,
          dancing_career_results: competitionResult,
          start_work_year: judgeYear,
          is_visited_congresses: congressBoolJudge,
          congresses_list: congressJudgesDescr,
          dancing_classes: dancerClassId,
          education: [2],
          position: positionId,
          category: judgeCategoryId,
        dancing_career_stop:yearEndCompetition,
        judge_book: judgeBookFiles,
        is_tested: judgeTestFlag,
          user: userCurrent,
        },
      ));

    } else {
      if (judgeYear && congressBoolJudge && congressJudgesDescr && positionId !== undefined && yearTrainer === '') {
        dispatch(registUserJudge({
          is_dance_educated: specialEducationBool,
          dance_education_place: specialEducationName,
          dancing_career_start: yearEndCompetition,
          dancing_awards: awards,
          dancing_career_results: competitionResult,
          start_work_year: judgeYear,
          is_visited_congresses: congressBoolJudge,
          congresses_list: congressJudgesDescr,
          dancing_classes: dancerClassId,
          education: [2],
          position: positionId,
          category: judgeCategoryId,
          dancing_career_stop: yearEndCompetition,
          judge_book: judgeBookFiles,
          is_tested: judgeTestFlag,
          user: userCurrent,
        }));
      } else if (yearTrainer && congressBoolTrainer && congressDescr !== '' && judgeYear === '') {
        dispatch(registUserTrainer({
          is_dance_educated: specialEducationBool,
          dance_education_place: specialEducationName,
          dancing_career_start: yearEndCompetition,
          dancing_awards: awards,
          dancing_career_results: competitionResult,
          start_work_year: yearTrainer,
          is_visited_congresses: congressBoolTrainer,
          congresses_list: congressDescr,
          dancing_classes: dancerClassId,
          // education:
          education: [2],
          dancing_career_stop: yearEndCompetition,
          // is_tested: ,
          user: userCurrent,
        }));
      }
    }
    if (judgeYear === '' && yearTrainer === '') {
      setFormValid(false);
      return null;
    }
  };
  return (
    <RegisterContainer>
      <RegisterNav
        backLink='/auth/questionary_dancer'
        currentStep={2} />
      <AuthTitle
        w480='312px'
        mw1000='432px'
        m1000='0 auto'
        fs480='24px'
        title='Анкета тренера чи судді' />
      <form
        onSubmit={onSubmit}>
        <EducationBlock
          mw1000='432px'
          mw480='312px'
          m1000='0 auto'
          df='flex'
          fd='column'>
          <AuthTitle
            mt1000='24px'
            fs480='20px'
            w480='312px'
            small
            title='Освіта' />
          {
            education.map((item, i) => (
              <RadioButton
                idx={i}
                name='education'
                key={i}
                checked={item.checked}
                setChecked={setEducation}
                title={item.education}
              />))
          }
          <ValidMessage
            mt='0'
            message={validate && !education.filter(item => item.checked).length ? 'Потрібно вибрати варіант' : ''} />
          <AuthDivider w480='312px' />
        </EducationBlock>
        <SpecialEducBlock
          mw1000='432px'
          mw480='312px'
          m1000='0 auto'
          df='flex'
          fd='column'
          pd1000='24px 0'
        >
          <div>
            <AuthTitle
              fs480='20px'
              w480='312px'
              small
              title='Освіта за спеціальністю' />
            <AuthSubTitle
              w480='312px'
              fs480='15px'
              fullWidth
              w360='296px'
              title='Яка має відношення до викладання спортивного танцю' />
          </div>
          {
            specialEducation.map((item, i) => (
              <RadioButton
                idx={i}
                name='specEducation'
                key={i}
                checked={item.checked}
                setChecked={setSpecialEducation}
                value={specialEducation}
                title={item.education}
              />))
          }

          <ValidMessage
            mt='0'
            message={validate && !specialEducation.filter(item => item.checked).length ? 'Потрібно вибрати варіант' : ''} />
          {/*{*/}

          {/* schools.map((item, i) => (*/}
          <InputContainer mw480='312px'>
            {/*key={i}*/}
            <AuthTextArea
              placeholder='Введіть текст'
              w480='312px'
              value={specialEducationName}
              // setValue={(value)=>setSchools(state=>state.map((el, j)=> i === j ? value : el))}
              setValue={setSpecialEducationName}

            />
            <InpupHint
              title='Назва навчального закладу, спеціальність, рік закінчення'
              w480='300px'
            />
          </InputContainer>

          {/*}*/}
          <AddInputLink
            mt='0'
            mw480='312px'
            show={schools.length <= 4}
            onClick={() => onAddField(setSchools)}
            title='Додати навчальний заклад' />
          <AuthDivider w480='312px' />
        </SpecialEducBlock>
        <SectionContainer
          mw1000='432px'
          mw480='312px'
          m1000='0 auto'
          df='flex'
          fd='column'>
          <AuthTitle
            fs480='20px'
            w480='312px'
            small
            title='Танцювальна кар’єра' />
          <ContainerTwoColumns
            mw1000='432px'
            w480='312px'
            m1000='0 auto'
            df='flex'
            fd='column'>
            <AuthSelectForId
              mt='0'
              placeholder='Вибрати клас'
              value={dancerClass}
              setId={setDancerClassId}
              setValue={setDancerClass}
              options={dancerClassOptions}
              hint='В якому танцювальному класі закінчили змагатися'
              validate={validate}
            />
            <AuthInputText
              lang={'UA_Num'}
              hint='Рік закінчення виступів на змаганнях зі спортивного танцю'
              value={yearEndCompetition}
              setValue={setYearEndCompetition}
              placeholder='Введіть рік'
            />
            <div>
              <AuthTextArea
                mt='0'
                w480='312px'
                value={competitionResult}
                setValue={setCompetitionResult}
                placeholder='Найвищі результати в змаганнях'
              />
              <InpupHint
                title='Назва змагань, рік, вікова категорія, клас, зайняте місце' w480='300px' />
            </div>
            <div>
              <AuthTextArea
                mt='0'
                w480='312px'
                value={awards}
                setValue={setAwards}
                placeholder='Найвищі результати в змаганнях'
              />
              <InpupHint
                title='Державні нагороди, звання, спортивні розряди' w480='300px' />
            </div>
          </ContainerTwoColumns>
          <AuthDivider w480='312px' />
        </SectionContainer>
        <SectionContainer
          mw1000='432px'
          mw480='312px'
          m1000='0 auto'
          df='flex'
          fd='column'>
          <AuthTitle
            small
            w480='312px'
            fs480='20px'
            title='Дані тренера' />
          <AuthSubTitle
            fullWidth
            w480='312px'
            fs480='15px'
            title='Заповнюється у випадку, коли ви є тренером' />
          <ContainerTwoColumns
            mw1000='432px'
            m1000='0 auto'
            df='flex'
            fd='column'>
            <AuthInputText
              hint='Рік початку роботи в якості тренера'
              value={yearTrainer}
              lang={'UA_Num'}
              setValue={setYearTrainer}
              placeholder='Введіть рік'
            />
            <div>
              <AuthTextArea
                mt='0'
                w480='312px'
                value={levelOfTraining}
                setValue={setLevelOfTraining}
                placeholder='Рівень підготовлених пар'
              />
              <InpupHint
                title='Прізвища, клас, їх найвищі досягнення' />
            </div>
          </ContainerTwoColumns>
          <AuthDivider w480='312px' />
        </SectionContainer>
        <SectionContainer
          padding='0 0 40px 0'
          mw1000='432px'
          mw480='312px'
          m1000='0 auto'
          df='flex'
          fd='column'>
          <InputTitle
            title='Відвідували конгреси тренерів?' />
          <ContainerOneColumn>
            {
              congress.map((item, i) => (
                <RadioButton
                  idx={i}
                  name='congress'
                  key={i}
                  checked={item.checked}
                  setChecked={setCongress}
                  title={item.value}
                />))
            }
            <div>
              <AuthTextArea
                mt='0'
                w480='312px'
                value={congressDescr}
                setValue={setCongressDescr}
                placeholder='Введіть текст'
              />
              <InpupHint
                title='Якщо «так» – то коли і де?' />
            </div>
          </ContainerOneColumn>
          <AuthDivider w480='312px' />
        </SectionContainer>
        <SectionContainer
          padding='0 0 40px 0'
          mw1000='432px'
          mw480='312px'
          m1000='0 auto'
          df='flex'
          fd='column'>
          <InputContainer mt480='36px' mw480='312px'>
            <ChooseClubWithAdd
              setId={setClubsId}
              clubs={clubs}
              setClubs={setClubs}
              clubsNoList={clubsNoList}
              setClubsNoList={setClubsNoList}
              options={clubsList}
              validate={clubs.length !== 0 ? validate : false }
            />
            {
              newClub? <ChooseClubWithAdd
                setId={setClubsId}
                clubs={clubs2}
                setClubs={setClubs2}
                clubsNoList={clubsNoList}
                setClubsNoList={setClubsNoList}
                options={clubsList}
                validate={clubs.length !== 0 ? validate : false }
              /> : <></>
            }
            <AddInputLink
              mw480='312px'
              show={!newClub}
              onClick={() => onAddClub(setClubs)}
              title='Додати ще один клуб' />
          </InputContainer>
          <AuthDivider w480='312px' />
        </SectionContainer>
        <SectionContainer
          mw1000='432px'
          mw480='312px'
          m1000='0 auto'
          df='flex'
          fd='column'>
          <AuthTitle
            small
            w480='312px'
            fs480='20px'
            title='Дані судді' />
          <AuthSubTitle
            fs480='15px'
            w480='312px'
            fullWidth
            title='Заповнюється у випадку, коли ви є суддею' />
          <ContainerOneColumn
            mt='44px'>
            <AuthInputText
              placeholder='Введіть рік'
              hint='Рік початку роботи в якості судді'
              lang={'UA_Num'}
              value={judgeYear}
              setValue={setJudgeYear}
            />
          </ContainerOneColumn>
          <AuthDivider w480='31px' />
        </SectionContainer>
        <SectionContainer
          padding='32px 0'
          mw1000='432px'
          mw480='312px'
          m1000='0 auto'
          df='flex'
          fd='column'>
          <AuthTitle
            fs480='16px'
            w480='312px'
            small
            title='Посада' />
          <AuthSubTitle
            w480='312px'
            fs480='15px'
            fullWidth
            title='Виберіть один варіант:' />
          <ContainerOneColumn>
            {
              positions.map((item, i) => (
                <RadioButton
                  idx={i}
                  name='education'
                  key={i}
                  checked={item.checked}
                  setChecked={setPositions}
                  title={item.title}
                />))
            }
          </ContainerOneColumn>
          <ContainerTwoColumns
            mw1000='432px'
            m1000='0 auto'
            df='flex'
            fd='column'>
            <AuthSelectForId
              mt='0'
              options={judgeCategoryOptions}
              placeholder='Вибрати суддівську категорію'
              setId={setJudgeCategoryId}
              value={judgeCategory}
              setValue={setJudgeCategory}
            />
            <AuthInputText
              value={dateJudgeCategory}
              lang={'UA_Num'}
              setValue={setDateJudgeCategory}
              placeholder='Введіть дату'
              hint='(дату присвоєння діючої суддівської категорії)'
            />
          </ContainerTwoColumns>
          <AuthDivider w480='31px' />
        </SectionContainer>
        <SectionContainer
          mw1000='432px'
          mw480='312px'
          m1000='0 auto'
          df='flex'
          fd='column'>
          <AuthTitle
            fs480='16px'
            w480='312px'
            small
            title='Відвідували конгреси суддів?' />
          <ContainerOneColumn>
            {
              congressJudges.map((item, i) => (
                <RadioButton
                  idx={i}
                  name='congressJudges'
                  key={i}
                  checked={item.checked}
                  setChecked={setCongressJudges}
                  title={item.value}
                />))
            }
            <div>
              <AuthTextArea
                mt='0'
                w480='312px'
                value={congressJudgesDescr}
                setValue={setCongressJudgesDescr}
                placeholder='Введіть текст'
              />
              <InpupHint
                title='Якщо «так» – то коли і де?' />
            </div>
          </ContainerOneColumn>
          <AuthDivider w480='312px' />
        </SectionContainer>
        <SectionContainer
          mw1000='432px'
          mw480='312px'
          m1000='0 auto'
          df='flex'
          fd='column'>
          <AuthTitle
            fs480='16px'
            w480='312px'
            small
            title='Здавали професійні суддівські іспити / тести?' />
          <ContainerOneColumn>
            {
              judgesTest.map((item, i) => (
                <RadioButton
                  idx={i}
                  name='judgesTest'
                  key={i}
                  checked={item.checked}
                  setChecked={setJudgesTest}
                  title={item.value}
                />))
            }
            <div>
              <AuthTextArea
                w480='312px'
                mt='0'
                value={judgesTestDescr}
                setValue={setJudgesTestDescr}
                placeholder='Введіть текст'
              />
              <InpupHint
                title='Якщо «так» – то коли, де саме, прізвища екзаменаторів' w480='300px' />
            </div>
          </ContainerOneColumn>
          <AuthDivider />
        </SectionContainer>
        <SectionContainer
          mw1000='432px'
          mw480='312px'
          m1000='0 auto'
          df='flex'
          fd='column'>
          <AuthTitle
            fs480='16px'
            w480='312px'
            small
            title='Додати копії суддівської книжки' />
          <AuthSubTitle
            fs480='15px'
            w480='312px'
            fullWidth
            title='Сторінки з прізвищем та ім’ям, суддівською категорією, присутністю на конгресах та відмітками про оплату річних внесків' />
          <RegisterFileInputBlock
            multiple
            validate={validate}
            files={judgeBookFiles}
            onChange={(e) => onChangeFile(e, setJudjeBookFiles)}
            onRemoveFile={(idx) => onRemoveFile(idx, setJudjeBookFiles)}
          />
          <AuthDivider w480='312px' />
        </SectionContainer>
        <ButtonsContainer
          h480='60px'
          mw1000='432px'
          mw480='312px'
          m1000='0 auto'
          jc1000='center'>
          <ButtonSubmit
            isActive={formValid}
            title='Продовжити'
            width='210px'
            width480='312px'
            w360='296px'
          />
        </ButtonsContainer>
      </form>
    </RegisterContainer>
  );
};

export default QuestionaryTrainerPage;