import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const PhotoBlock = styled.div`
	position: ${({fullScreen}) => fullScreen ? 'fixed' : 'relative'};
  ${({fullScreen}) => fullScreen ? `
  padding: 90px 0;
  top: 0;
  left: 0;
  background: #0F171F;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  ` : ''}
`;

const PhotoImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0 auto;
`;

const PrevSlide = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
`;

const NextSlide = styled(PrevSlide)`
  left: unset;
  right: 10px;
  transform: translateY(-50%) rotate(180deg);
`;

const IconClose = styled.img`
  display: ${({show}) => show ? 'block' : 'none'};
  position: absolute;
  top: 20px;
  right: 20px;
`;

const SliderPhotoBlock = ({ photos, curentSlide, setCurentSlide }) => {
  const [posStart, setPosStart] = useState(false);
  const [swipeLeft, setSwipeLeft] = useState(false);
  const [swipeRight, setSwipeRight] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    if (swipeLeft) {
      setCurentSlide((state) => {
        if (state === 0) {
          return photos.length - 1;
        }
        return state - 1;
      });

      setSwipeLeft(false);
    }
    if (swipeRight) {
      setCurentSlide((state) => {
        if (state === photos.length - 1) {
          return 0;
        }
        return state + 1;
      });

      setSwipeRight(false);
    }
  }, [swipeLeft, swipeRight, posStart, photos.length, setCurentSlide]);

  const onPrevSlide = () => {
    setCurentSlide((state) => {
      if (state === 0) {
        return photos.length - 1;
      }
      return state - 1;
    });
  };

  const onNextSlide = (e) => {
    setCurentSlide((state) => {
      if (state === photos.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const onTouchStart = (e) => {
    setPosStart(e.touches[0].clientX);
  };

  const onTouchEnd = (e) => {
    if (posStart - e.changedTouches[0].clientX > 40) {
      setSwipeRight(true);
    }
    if (posStart - e.changedTouches[0].clientX < -40) {
      setSwipeLeft(true);
    }
  };

  const onCloseFullScreen = (e) => {
    e.stopPropagation();
    setFullScreen(false)
  }

  return (
    <PhotoBlock onClick={()=>setFullScreen(true)} fullScreen={fullScreen} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
      <PrevSlide
        onClick={onPrevSlide}
        src="/icons/slider_mobile/prev_slide.svg"
        alt="prev slide"
      />
      <PhotoImg src={photos[curentSlide]} alt="gallery" />
      <NextSlide
        onClick={onNextSlide}
        src="/icons/slider_mobile/prev_slide.svg"
        alt="next slide"
      />
      <IconClose show={fullScreen} onClick={onCloseFullScreen} src='/icons/close_white.svg' alt='close' />
    </PhotoBlock>
  );
};

export default SliderPhotoBlock;
