import React from 'react';
import styled from 'styled-components';
import { useResizeMode } from '../../hooks/useResizeMode';
import UserTableDesktop from './UserTableDesktop';
import UserTableMobile from './UserTableMobile';

const UserTableStyled = styled.section`
  padding-top: 25px;

  @media (max-width: 768px) {
    padding-top: 20px;
  }
`;

const TableTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #445566;
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #1e87a9;
    letter-spacing: 0.1em;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
    font-size: 16px;
    line-height: 24px;
    span {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const Table = styled.table`
  display: block;
  width: 100%;
  border-spacing: 0px;
  thead {
    display: block;
    background: #f1f2f3;
    box-shadow: -1px -1px 2px #ffffff, 1px 1px 2px #e5e5e5;
    border-radius: 5px 5px 0px 0px;
    th {
      padding: 20px 25px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1em;
      color: #556677;
      text-align: left;
      :first-child {
        position: relative;
        width: 150px;
        border-right: none;
        :before {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          top: 4px;
          width: 2px;
          height: 55px;
          box-shadow: -1px -1px 2px #ffffff, 1px 1px 2px #e5e5e5;
          border-radius: 5px;
        }
      }
    }
  }

  tbody {
    display: block;
    max-height: 250px;
    box-shadow: 0px 0px 2px #d4d4d4;
    border-radius: 0px 0px 5px 5px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #f1f2f3 #fff;
    ::-webkit-scrollbar {
      width: 10px;
      background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: #f1f2f3;
      border-radius: 10px;
    }
    tr {
      background: #ffffff;
      height: 80px;
      td {
        position: relative;
        :after {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          background: linear-gradient(
              88.74deg,
              rgba(255, 255, 255, 0.25) 0%,
              rgba(255, 255, 255, 0.125) 100%
            ),
            #1e87a9;
          border-radius: 0px 2px 2px 0px;
          position: absolute;
          bottom: 0px;
          left: 0;
        }
        :first-child {
          padding: 0 20px;
          font-weight: 500;
          font-size: 16px;
          letter-spacing: 0.1em;
          color: #556677;
        }
        :last-child {
          width: 100%;
          padding: 0 30px;
          font-weight: 500;
          font-size: 16px;
          color: #445566;
          :after {
            background: #F1F2F3;
          }
          div {
            display: flex;
            align-items: center;
            img {
              margin-right: 30px;
            }
          }
        }
      }
      :last-child {
        td {
          :after {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    thead {
      th {
        padding: 14px;
        font-size: 14px;
        line-height: 24px;
        img {
          max-width: 14px;
        }
        :first-child {
          img {
            display: block;
            margin: 0 auto;
          }
          vertical-align: middle;
          width: ${({ isReward }) => (isReward ? '60px' : '115px')};
          :before {
            height: 44px;
            top: 5px;
          }
        }
      }
    }
    tbody {
      tr {
        height: 60px;
        td {
          :first-child {
            padding: ${({ isReward }) => (isReward ? '0 20px' : '0 14px')};
            font-size: 13px;
            line-height: 22px;
            img {
              max-width: 18px;
            }
          }
          :last-child {
            padding: 12px 14px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
  
`;

const UserTable = ({ title, count, isReward = false, mockData }) => {
  const isMobile = useResizeMode();

  const staticImg = [
    '/icons/reward/reward1.svg',
    '/icons/reward/reward2.svg',
    '/icons/reward/reward3.svg',
  ];

  return (
    <UserTableStyled>
      <TableTitle>
        <div>{title}</div>
        <span>{count}</span>
      </TableTitle>
      <Table isReward={isReward}>
        {isMobile && isReward ? (
          <UserTableMobile iconsList={staticImg} data={mockData} />
        ) : (
          <UserTableDesktop iconsList={staticImg} data={mockData} isReward={isReward} />
        )}
      </Table>
    </UserTableStyled>
  );
};

export default UserTable;
