import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const ItemDescr = styled.div``;

const ItemTitle = styled.div`
  font-weight: 600;
  font-size: 19px;
  line-height: 30px;
  color: #223344;
  cursor: pointer;

  @media(max-width: 576px) {
    font-size: 17px;
    line-height: 24px;
  }
`;

const ItemStatus = styled.div`
  margin-top: 5px;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #1e87a9;

  @media(max-width: 576px) {
    margin-top: 8px;
    font-size: 13px;
    line-height: 20px;
  }
`;

const ItemPlace = styled.div`
  margin-top: 5px;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: #445566;

  @media(max-width: 576px) {
    margin-top: 8px;
    font-size: 15px;
    line-height: 20px;
  }
`;

const BookmarksItemDetail = ({ userName, status, place, id }) => {

  const history = useHistory();

  return (
    <ItemDescr>
      <ItemTitle onClick={()=>history.push(`/all-users/${id}/information/user`)}>
        {userName}
      </ItemTitle>
      <ItemStatus>{status}</ItemStatus>
      <ItemPlace>{place}</ItemPlace>
    </ItemDescr>
  );
};

export default BookmarksItemDetail;
