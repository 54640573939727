import React, { useEffect } from 'react';
import UserPromoBlock from '../components/myPage/userPromo/UserPromoBlock';
import UserInformationBlock from '../components/myPage/userInformation/UserInformationBlock';
import UserTable from '../components/myPage/userTable/UserTable';
import { rewards, chronology } from '../mockData/mockData';
import { useSelector } from 'react-redux';

const MyPage = () => {
  const userInfo = useSelector(state => state.userProfiles?.currentProfile);
  useEffect(()=>{},[userInfo])
  return (
    <>
      <UserPromoBlock />
      <UserInformationBlock tabsArr={[
        userInfo?.user !== null && userInfo?.user? { title: 'Користувач', path: '/home/information/user' } : null,
        userInfo?.dancer?.id !== null && userInfo?.dancer?.id ? { title: 'Танцюрист', path: '/home/information/dancer' } : null,
        userInfo?.trainer?.id !== null && userInfo?.trainer?.id ? { title: 'Тренер', path: '/home/information/trainer' } : null,
        userInfo?.judge !== null && userInfo?.judge?.id ? { title: 'Суддя', path: '/home/information/judge' } : null,
        userInfo?.manager !== null && userInfo?.manager?.id ? { title: 'Керівник клубу', path: '/home/information/manager' } : null,
      ]} />
      <UserTable title='Нагороди' count={3} mockData={rewards} isReward />
      <UserTable title='Хронологія' count={3} mockData={chronology} />
    </>
  );
};

export default MyPage;
