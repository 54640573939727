import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from 'styled-components';
import { useResizeMode } from '../hooks/useResizeMode';
import { useDispatch, useSelector } from 'react-redux';
import { addPhotosToAlbum, addPhotoToAlbum, addUserAlbum, addUserPhoto, changeItsAlbum, getUserPhotos } from "../../actions/galleryAction";

const GalleryAddItemStyled = styled.div`
  display: ${({show}) => show ? 'flex' : 'none'};
  margin: 5px;
  width: 208px;
  height: 208px;
  justify-content: center;
  align-items: center;
  background: #f1f2f3;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.1em;
  color: ${({ishover}) => ishover ? '#1E87A9' : '#778899'};
  label{
    display: flex;
    height: 100%;  
    width: 100%;
    cursor:pointer;
    align-items: center;
    justify-content: center;
    &:hover{
      height: 100%;
      width: 100%;
      cursor:pointer;
      align-items: center;
      justify-content: center;
      &:hover{
    }
  }
  input{
    display: none;
  }
  img {
    display: flex;
    width: ${({isAlbum}) => isAlbum ? '30px' : '14px'};
    margin-right: 10px;
  }

  @media(max-width: 576px) {
    width: 100%;
    margin: 0;
    background: #fff;
    height: 100%;
    color: #1E87A9;
    font-weight: 600;
    font-size: 14px;
    grid-column: ${({isAlbum}) => isAlbum ? '1 / 3' : '1 / 4'};
    img {
      width: 13px;
    }
  }
`;

const GalleryAddItem = ({isAlbum=true, show=true, userId,currentOffset,userPhotos}) => {


const location = useLocation();


  const isMobile = useResizeMode();
  const [ishover, setIsHover] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();

  const galleryId = useSelector(state => state.userGallery?.dataPhoto?.results?.gallery?.id)
  const itsAlbum = useSelector(state => state.userGallery?.itsAlbum)

useEffect(() => {
  if (location.pathname.includes('/gallery/album')){
    dispatch(changeItsAlbum(true))
  }
  else {
    dispatch(changeItsAlbum(false))
  }

},[itsAlbum])

  console.log(itsAlbum)

  const onCreateAlbum = (e) => {
    if (isAlbum) {
      e.preventDefault()
      history.push('/gallery/album/new/create');
    }
  }
  const onChangeFiles = (e) => {
    if (userPhotos !== undefined && itsAlbum === false){
    dispatch(addUserPhoto(e.target.files[0],[galleryId],lastPosition(userPhotos)))
    }
    else if (itsAlbum){
      dispatch(addPhotoToAlbum(e.target.files[0]))
    }
  }
  const lastPosition = (arr) => {
    return arr[arr?.length - 1]?.position + 1;
  }
  return (
    <GalleryAddItemStyled 
      onMouseEnter={()=>setIsHover(true)} 
      onMouseLeave={()=>setIsHover(false)} 
      isAlbum={isAlbum} 
      ishover={ishover}
      onClick={onCreateAlbum}
      show={show}
    >
      <label>
      <img src={ishover || isMobile ? "/icons/gallery/photo/add_photo_active.svg" : "/icons/gallery/photo/add_photo.svg"} alt="plus" />
      {
        isAlbum ? isMobile ? 'Альбом' : '' : 'Фото'
      }
      <input type='file' onChange={onChangeFiles}/>
      </label>
    </GalleryAddItemStyled>
  );
};

export default GalleryAddItem;
