import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SearchUsers from '../components/search/seaechUsers/SearchUsers';
import SearchTrainers from '../components/search/seaechUsers/searchTrainers/SearchTrainers';
import SearchDancers from '../components/search/seaechUsers/searchDancers/SearchDancers';
import SearchJudges from '../components/search/seaechUsers/searchJudges/SearchJudges';
import SearchClubs from '../components/search/searchClubs/SearchClubs';
import SearchOrganizations from '../components/search/searchOrganizations/SearchOrganizations';

const SearchPage = () => {
  return (
    <Switch>
      <Route exact path="/search/users">
        <SearchUsers />
      </Route>
      <Route path="/search/users/searchDancers">
        <SearchDancers />
      </Route>
      <Route path="/search/users/searchTrainers">
        <SearchTrainers />
      </Route>
			<Route path="/search/users/searchJudges">
        <SearchJudges />
      </Route>
      <Route exact path="/search/clubs">
        <SearchClubs />
      </Route>
      <Route exact path="/search/organizations">
        <SearchOrganizations />
      </Route>
    </Switch>
  );
};

export default SearchPage;
