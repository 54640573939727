import React, { useState } from 'react';
import styled from 'styled-components';
import AlertMessage from '../../AlertMessage';
import IconWrapper from '../../IconWrapper';
import EyeBlock from '../EyeBlock';

const InputBlockStyled = styled.div`
  width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  :first-child {
    margin-top: 0;
  }

  @media (max-width: 576px) {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const InputTitle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;
  font-weight: 600;
  font-size: 17px;
  line-height: 19px;
  color: #445566;

  @media (max-width: 576px) {
    font-size: 15px;
    margin-bottom: 8px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 360px;
  input {
    padding: 5px 50px 5px 10px;
    display: block;
    width: 100%;
    height: 40px;
    border: 1px solid ${({isActive}) => isActive ? '#1E87A9' : '#778899'};
    border-radius: 5px;
    font-weight: normal;
    outline: none;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    color: #445566;
    ::placeholder {
      font-size: 15px;
      line-height: 30px;
      color: #778899;
    }
  }

  @media (max-width: 576px) {
    width: 100%;
    input {
      font-size: 14px;
      line-height: 20px;
      height: 36px;
      ::placeholder {
        font-size: 14px;
      } 
    }
  }
`;

const InputBlock = ({ iconUrl = false, title, value, onChange, alertMessage, successMessage }) => {
  const [visible, setVisible] = useState(false);
  return (
    <InputBlockStyled>
      <InputTitle>
        <IconWrapper iconUrl={iconUrl} alt='password'/>
        <div>{title}</div>
      </InputTitle>
      <InputWrapper isActive={!!value}>
        <div>
          <input
            onChange={onChange}
            value={value}
            type={visible ? 'text' : 'password'}
            placeholder="Введіть"
          />
          <EyeBlock visible={visible} setVisible={setVisible} />
        </div>
        <AlertMessage message={alertMessage} show={!!alertMessage} showSuccess={!!successMessage} successMessage={successMessage} />
      </InputWrapper>
    </InputBlockStyled>
  );
};

export default InputBlock;
