import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AuthInputStyled, InputStyled } from "../../auth/AuthInputText";
import InpupHint from "../../auth/InputHint";
import InputLabel from "../../auth/InputLabel";
import ValidMessage from "../../auth/ValidMessage";
const AuthSelectStyled = styled(AuthInputStyled)`
  display: ${({df}) => df ? df : 'block'};
  border-radius: ${(p) => (p.focus ? "10px 10px 0 0" : "10px")};
  @media (max-width: 1000px) {
    margin-top: ${({ mt1000 }) => mt1000 || "0"};
  }

  img {
    margin-right: 15px;
  }
`;

const InputSelectStyled = styled(InputStyled)`
  padding-right: 25px;
  ${(p) => (!p.filtered ? "caret-color: transparent;" : "")}
  background: url('/icons/auth/arrow-down.svg') right center no-repeat;

  :focus {
    background: url('/icons/auth/arrow-up.svg') right center no-repeat;
  }
`;

const OptionsBlock = styled.div`
  display: ${(p) => (p.show ? "block" : "none")};
  padding: 16px 12px;
  position: absolute;
  top: 100%;
  left: 0;
  width: calc(100% + 1px);
  background: #fafafa;
  border-radius: 0px 0px 10px 10px;
  max-height: 200px;
  box-shadow: 1px 1px 1px #ffffff, -1px -1px 1px #e7eaed;
  border: 1px solid transparent;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #f1f2f3 #fff;
  z-index: 10;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #f1f2f3;
    border-radius: 10px;
  }
`;

const OptionsItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #223344;
  cursor: pointer;

  :first-child {
    margin-top: 0;
  }

  img {
    margin-right: 15px;
    width: 18px;
    height: 18px;
    /* :last-child {
      margin-right: 20px;
    } */
  }
`;

const AuthSelectTrainers = ({
                           df,
                           mt,
                           noInList,
                           mt1000,
                           mtMd,
                           placeholder,
                           value,
                           setValue,
                           options,
                           validate,
                           setId,
                           iconsArr = [],
                           hint
                         }) => {
  const [focus, setFocus] = useState(false);
  const [iconSrc, setIconSrc] = useState('');
  const [validMessage, setvalidMessage] = useState("");


  useEffect(() => {},[options])
  useEffect(() => {
    if (validate && !value) {
      setvalidMessage('Обов’язкове поле');
    } else {
      setvalidMessage('');
    }}, [validate, value]);
  const onChooseItem = (item, i, id) => {
    setValue(item);
    setId(id);
    setFocus(false)
  };
  return (
    <>
      <AuthSelectStyled df={df} mt1000={mt1000} mt={mt} mtMd={mtMd} isValid={!validMessage} focus={focus} onClick={() => setFocus(true)}>
        {iconSrc ? <img src={iconSrc} alt={value}></img> : ''}
        <InputSelectStyled
          // filtered={filtered}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={value}
          onChange={(e) => (setValue(e.target.value))}
          type="text"
          placeholder={focus ? '' : placeholder}
        />
        <InputLabel text valid={!validMessage} focus={focus} title={placeholder} show={value} />
        <OptionsBlock show={focus}>
          {noInList ? (
            <OptionsItem onMouseDown={() => onChooseItem('Немає в списку')}>
              Немає в списку
            </OptionsItem>
          ) : (
            ''
          )}
          {options?.map((item, i, id) => {
            return (
              <OptionsItem onMouseDown={() => onChooseItem(item.full_name, i,item.id)} key={i}>
                {iconsArr.length ? (
                  <>
                    {' '}
                    <img src={iconsArr[i]} alt={item.full_name}></img> {item.full_name}{' '}
                  </>
                ) : (
                  item.full_name
                )}
              </OptionsItem>
            );
          })}
        </OptionsBlock>
      </AuthSelectStyled>
      <ValidMessage message={validMessage} ml0rd='10px' />
      <InpupHint title={hint} show={hint} p0cd='4px 24px 0 14px' w480='300px' />
    </>
  )
};
export default AuthSelectTrainers;
