import React from 'react';
import Photo from '../../photo/Photo';
import AlbumBodyTitle from './AlbumBodyTitle';

const AlbumItemEditBody = ({
  album,
  checkedAll,
  onCheckedAll,
  checkedItems,
  onCheckedItems,
  onUnCheckedItems,
  onAddALLtoChecked,
  onRemoveAllFromChecked
}) => {
  return (
    <>
      <AlbumBodyTitle
        album={album}
        countPhoto={checkedItems.length}
        onCheckedAll={onCheckedAll}
        checkedAll={checkedAll}
        onAddALLtoChecked={onAddALLtoChecked}
        onRemoveAllFromChecked={onRemoveAllFromChecked}
        checkedItems={checkedItems}
      />
      <Photo
        album={album}
        withOption={false}
        withCheck
        checkedAll={checkedAll}
        onCheckedAll={onCheckedAll}
        checkedItems={checkedItems}
        onCheckedItems={onCheckedItems}
        onUnCheckedItems={onUnCheckedItems}
      />
    </>
  );
};

export default AlbumItemEditBody;
