import React from "react";
import AccountItem from "./AccountItem";

const AccountsBody = ({checkedAll, onCheckedAll, onCheckedItems, checkedItems, onUnCheckedItems, filteredArr}) => {

  return (
    <ul>
      {filteredArr.map((item) => (
        <AccountItem 
          key={item.id}
          onCheckedAll={onCheckedAll}
          checkedAll={checkedAll}
          checkedItems={checkedItems}
          onCheckedItems={onCheckedItems}
					onUnCheckedItems={onUnCheckedItems}
          account={item}
        />
      ))}
    </ul>
  );
};

export default AccountsBody;
