import React from "react";
import UserInformationItem from "./UserInformationItem";

const InformationListJudge = ({ mockData }) => {
  return (
    <ul>
      <UserInformationItem
        image="/icons/dancer_inform/status.svg"
        title="Статус"
        value={mockData.status}
      />
      <UserInformationItem
        image="/icons/dancer_inform/organization.svg"
        title="Організація"
        value={mockData.organization}
      />
      <UserInformationItem
        image="/icons/user_inform/judge_category.svg"
        title="Суддівська категорія"
        value={mockData.judgeCategory}
      />
      <UserInformationItem
        image="/icons/user_inform/position.svg"
        title="Посада"
        value={mockData.position}
      />
    </ul>
  );
};

export default InformationListJudge;
