import React from 'react';
import styled from 'styled-components';

const ContactListItemStyled = styled.li`
  margin-top: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  :first-child {
    margin-top: 0;
  }

  @media(max-width: 576px) {
    display: ${({disabled}) => disabled ? 'flex' : 'block'};
    height: ${({disabled}) => disabled ? '24px' : 'auto'};
    margin-top: 16px;
    :first-child {
      margin-top: 22px;
    }
  }
`;

const ItemTitle = styled.div`
  width: 120px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #1e87a9;
  img {
    margin-right: 5px;
  }

  @media(max-width: 576px) {
    font-size: 14px;
    line-height: 24px;
    width: 115px;
    margin-right: 12px;
    img {
      max-width: 24px;
    }
  }
`;

export const ItemInput = styled.input`
  padding: 9px 14px;
  display: block;
  width: 400px;
  border: 1px solid ${({ isActive }) => (isActive ? '#1E87A9' : '#778899')};
  border-radius: 5px;
  line-height: 20px;
  font-weight: 500;
  font-size: 17px;
  color: #445566;
  outline: none;
  ::placeholder {
    font-weight: normal;
    font-size: 15px;
    color: #778899;
  }

  @media(max-width: 576px) {
    width: 100%;
    margin-top: ${({mt}) => mt ? '0' : '8px'};
    font-size: 14px;
    ::placeholder {
      font-size: 14px;
    }
  }
`;

export const ItemData = styled.div`
  padding: 9px 14px;
  width: 400px;
  line-height: 20px;
  font-weight: 500;
  font-size: 16px;
  color: #445566;

  @media(max-width: 576px) {
    width: unset;
    font-size: 14px;
    line-height: 24px;
    padding: 0;
  }
`;

const ContactListItem = ({ iconUrl, title, onChange, valueObj, name, disabled , mail, defaultValue}) => {
  return (
    <ContactListItemStyled disabled={disabled}>
      <ItemTitle>
        <img src={iconUrl} alt={title} />
        {title}
      </ItemTitle>
      {disabled ? (
        // <ItemData>{mail ? mail : "Нема"}</ItemData>
        <ItemData>{valueObj}</ItemData>
      ) : (
        <ItemInput
          // value={mail ? mail : valueObj}
          value={valueObj}
          // defaultValue={defaultValue}
          onChange={onChange}
          name={name}
          type="text"
          placeholder="Введіть назву"
          isActive={!!valueObj}
          mt={disabled}
        />
      )}
    </ContactListItemStyled>
  );
};

export default ContactListItem;
