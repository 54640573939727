import React from "react";
import styled from "styled-components";
import { showPopupAddress, showPopupPrivacy } from '../actions/popupsAction';
import { useDispatch } from 'react-redux';


const PrivacyPolicyStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px;
  max-width: 1158px;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  color: #445566;
  letter-spacing: 0.1em;
  margin: 0 auto;
  div:nth-child(2){
    padding-left: 250px;
  }
  div, a {
    color: #445566;
    cursor: pointer;
  }
  
  @media(max-width: 992px) {
    padding: 15px 25px;
    max-width: 100%;
  }

  @media(max-width: 768px) {
    margin-bottom: 122px;
    padding: 24px 20px;
    height: 104px;
    flex-direction: column;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    div:nth-child(2){
      margin: 25px 0;
      padding-left: 0;
    }
  }
  @media(max-width: 480px) {
    padding: 24px 20px;
    height: 104px;
    flex-direction: column;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    div:nth-child(2){
       margin: 15px 0;
     }
  }
`;

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const openPrivacyPopup = () => {
    dispatch(showPopupPrivacy())
  }
  return (
    <PrivacyPolicyStyled>
      <div>© 2021 e-phan</div>
      <div onClick={openPrivacyPopup}>
        Політика конфіденційності
      </div>
      <div>
        Умови користування
      </div>
    </PrivacyPolicyStyled>
  );
};

export default PrivacyPolicy;
