import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AuthenticateBlock from './auth/AuthenticateBlock';
import LanguageBlock from './language/LanguageBlock';
import SearchBlock from './search/SearchBlock';



const HeaderStyled = styled.div`
  //padding: 15px 8% 15px 13%;
  ////position: relative;
  //display: flex;
  width: 100%;
  //align-items: center;
  background: linear-gradient(88.74deg,
  rgba(255, 255, 255, 0.25) 0%,
  rgba(255, 255, 255, 0.125) 100%),
  #fff;
  //justify-content: flex-end;


  @media (max-width: 768px) {
    background: #f6f6f6;
    padding: 15px 20px 0 20px;
  }
`;

const HeaderContainer = styled.div `
max-width: 1212px;
  padding: 15px 15px;
  //padding: 15px 8% 15px 13%;
  //position: relative;
  display: flex;
  //width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  @media (max-width: 768px){
    padding: 15px 0;
  }
  
`

const Logo = styled.div`
  width: 112px;
  height: 44px;
  cursor: pointer;
  margin: 0 0 0 70px;
  @media (max-width: 768px){
    margin: 0 10px 0 0;
  }
`;
const LogoImg = styled.img`

  @media (max-width: 768px) {
  }
`;

const HeaderActionsBlock = styled.div`
  position: relative;
  width: 174px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    width: unset;
  }
`;

const Header = ({ auth }) => {
  const history = useHistory();

  return (
    <HeaderStyled>
    <HeaderContainer>


      <Logo onClick={() => history.push(auth ? '/home/information/user' : '/auth/login')}>
        <LogoImg src='/icons/logo_active.svg' alt='Logo' />
      </Logo>
      <SearchBlock />
      <HeaderActionsBlock>
        <LanguageBlock />
        <AuthenticateBlock />
      </HeaderActionsBlock>

    </HeaderContainer>
    </HeaderStyled>
  );
};

export default Header;
