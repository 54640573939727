import axios from "axios";
import instanceLogin from "./instanceLogin";

export const rootURL = "https://phn.pmapplications.com";


export const api = {
  users: {

    // -------------- Users --------------

    login: (phone_number, password) =>
      axios.post(`${rootURL}/api/users/login/`, { phone_number, password }),

    logOut: (data) => instanceLogin.post(`${rootURL}/api/users/logout/`, data),

    changePass: (old_password, password,password2) =>
      instanceLogin.post(`${rootURL}/api/users/change/password/`, {old_password, password, password2}),

    loginVarify: (code) =>
      axios.post(`${rootURL}/api/users/verify/`, { code }),

    userProfile: (id) =>
      instanceLogin.get(`${rootURL}/api/users/${id}/`),

    userProfileUpdate: (id, data, config) =>
      instanceLogin.patch(`${rootURL}/api/users/${id}/`, data, config),

    // ------------- Register roles --------------

    registerUser: (data) =>
      axios.post(`${rootURL}/api/users/register/`, data),

    registerUserDancer: (data, config) =>
      axios.post(`${rootURL}/api/users/register/role/dancer/`, data, config),

    registerUserParent: (data, config) =>
      axios.post(`${rootURL}/api/users/register/role/parent/`, data, config),

    registerUserJudge: (data) =>
      axios.post(`${rootURL}/api/users/register/role/judge/`, data),

    registerUserTrainer: (data) =>
      axios.post(`${rootURL}/api/users/register/role/trainer/`, data),

    registerUserClubManager: (data, config) =>
      axios.post(`${rootURL}/api/users/register/role/club_head/`, data, config),

    registerUserPersonal: (data) =>
      axios.post(`${rootURL}/api/users/register/role/event_staff/`, data),

    registerUserSponsor: (data) =>
      axios.post(`${rootURL}/api/users/register/role/organizer/`, data),

    refresh: () =>
      instanceLogin.post(`${rootURL}/api/token/refresh/`, {
        refresh: localStorage.getItem("refresh"),
      }),


  },

  //----------- Galery ---------------

  gallery: {
    getUserAlbums: (id, count) =>
      axios.get(`${rootURL}/api/img/user/${id}/albums/`, {
        params: {
          limit: 7,
          offset: count,
        },
      }),

    addUserAlbums: (data, config) =>
      instanceLogin.post(`${rootURL}/api/img/add/album/`, data, config),

    getUserPhotos: (id, count, limit) =>
      axios.get(`${rootURL}/api/img/user/${id}/photos/`, {
        params: {
          limit: limit ? limit : 7,
          offset: count ? count : 0,
        },
      }),

    delUserPhoto: (id) =>
      instanceLogin.delete(`${rootURL}/api/img/photo/${id}/`),

    addUserPhoto: (data, config) =>
      instanceLogin.post(`${rootURL}/api/img/add/photo/`, data, config),

  },


  // ------ Locations ------------

  geolocation: {
    getCountry: () =>
      axios.get(`${rootURL}/api/locations/countries/`),

    getRegion: () =>
      axios.get(`${rootURL}/api/locations/regions/`),
  },

//----- Positions Registration Lists --------------

  regposition : {
    getEventPosition: () =>
      axios.get(`${rootURL}/api/users/types/event_positions/`),
    getClubsList: () =>
      axios.get(`${rootURL}/api/orgs/clubs/`),
    getTrainersList: () =>
      axios.get(`${rootURL}/api/users/trainers`)
  }

};