import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AuthDivider from '../AuthDivider';
import ValidMessage from '../ValidMessage';
import RegisterCheckBox from './RegisterCheckBox';

const UsersGroupeBlockStyled = styled.div`
  position: relative;
  padding-bottom: 40px;
  
`;

const UserGroupeItem = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #223344;
  
  label {
    margin-right: 16px;
  }
`;

const UsersGroupeBlock = ({ usersGroupe, setUsersGroupe, validate }) => {
  const [message, setMessaage] = useState('');

  useEffect(() => {
    if (validate) {
      usersGroupe.filter((item) => item.checked).length > 0
        ? setMessaage('')
        : setMessaage('Потрібно вибрати варіант');
    }
  }, [validate, usersGroupe]);

  const onCheckedGroupe = (checked, id) => {
    setUsersGroupe((state) => {
      return state.map((item) => (item.id === id ? { ...item, checked } : item));
    });
  };

  return (
    <UsersGroupeBlockStyled>
      {usersGroupe.map((item) => (
        <UserGroupeItem key={item.id}>
          <RegisterCheckBox
            id={item.id}
            checked={usersGroupe.find((el) => el.id === item.id).checked}
            setChecked={onCheckedGroupe}
          />
          {item.title}
        </UserGroupeItem>
      ))}
      <ValidMessage mt="32px" ml="4px" message={message} ml0rd="4px" />
      <AuthDivider mlcd="0" />
    </UsersGroupeBlockStyled>
  );
};

export default UsersGroupeBlock;
