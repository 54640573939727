import React from 'react';
import styled from 'styled-components';



const PreloaderImg = styled.img`
  transform: translate(-50%,-50%);
  z-index: 1000;
  position: fixed;
  //background: rgb(0,0,0,0.1);
  top: 50%;
  left: 50%;

`





const Preloader = () => {
  return(
  // <PreloaderStyled>
    <PreloaderImg src='/icons/preloader/Circle-Loading.svg' alt='Загрузка'/>
// </PreloaderStyled>
  )
}

export default Preloader