import React from 'react';
import styled from 'styled-components';

const SearchBlockStyled = styled.div`
  position: relative;
  width: 567px;
  height: 36px;
  input {
    height: 100%;
    padding: ${({isActive}) => isActive ? '7px 35px 7px 12px' : '7px 35px'};
    width: 100%;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    color: #556677;
    outline: none;
    background: #f6f6f6;
    border: none;
    box-shadow: 1px 1px 2px #ffffff, -1px -1px 2px rgba(174, 174, 192, 0.3);
    border-radius: 5px;
    ::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #778899;
    }
  }
  :after {
    content: '';
    display: ${({isActive}) => isActive ? 'none' : 'block'};
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    background: url('/icons/account_search.svg') center center/cover no-repeat;
    width: 15px;
    height: 15px;
  }


  @media(max-width: 992px) {
    width: 100%;
    height: 40px;
  }

  @media(max-width: 576px) {
    input {
      font-size: 14px;
      line-height: 24px;
      color: #556677;
      ::placeholder {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
`;

const IconClose = styled.img`
  display: ${({isActive}) => isActive ? 'block' : 'none'};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 13.5px;
  width: 13px;
`;

const SearchBlock = ({ onSearch, searchTerm }) => {
  return (
    <SearchBlockStyled isActive={!!searchTerm}>
      <input value={searchTerm} onChange={(e)=>onSearch(e.target.value)} type="text" placeholder='Пошук по назві' />
      <IconClose onClick={()=>onSearch('')} isActive={!!searchTerm} src='/icons/settings_page/close.svg' />
    </SearchBlockStyled>
  );
};

export default SearchBlock;
