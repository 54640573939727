import React from 'react';
import styled from 'styled-components';

const DividerStyled = styled.div`
	position: absolute;
	bottom: 0;
	${({posTop}) => posTop ? `
		bottom: unset;
		top: 0;
	` : ''}
	left: 0;
	width: 100%;
	height: ${({height}) => height};
	background: ${({bg}) => bg};
	border-radius: ${({br}) => br};
`;

const BlockDivider = ({height='3px', br='1.5px', bg='#F1F2F3', posTop=false}) => {
	return <DividerStyled className='divider' posTop={posTop} height={height} br={br} bg={bg}></DividerStyled>
}

export default BlockDivider;