import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import AuthenticatedHover from "./AuthenticatedHover";

import { accounts } from '../../../mockData/mockData';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const AuthenticatedStyled = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  //overflow: hidden;
  border-radius: 100%;

  img {
    max-width: 100%;
    border-radius: 100%;
    height: 100%;
  }
  

  @media(max-width: 768px) {
    display: none;
  }
`;

const AuthenticateBlock = () => {

  const [showHover, setShowHover] = useState(false);
  const userInfo = useSelector(state => state.userProfiles?.currentProfile)
  const history = useHistory()

  useEffect(() => {
    if(localStorage.getItem('access') === null){
      history.push('/auth/login/')
  }},[localStorage]);


  return (
    <AuthenticatedStyled onMouseEnter={()=>setShowHover(true)} onMouseLeave={()=>setShowHover(false)}>
      <img src={`${userInfo?.user?.photo}`} alt="account" />
      <AuthenticatedHover accounts={accounts} curentAccount={accounts[0]} show={showHover} setShow={setShowHover}/>
    </AuthenticatedStyled>
  );
};

export default AuthenticateBlock;
