import React from 'react';
import styled from 'styled-components';

const ItemDate = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: #556677;
`;

const ItemName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #445566;
`;

const UserTableMobile = ({data, iconsList}) => {
  return (
    <>
      <thead>
        <tr>
          <th>
            <img src="/icons/reward/rewards_title.svg" alt="reward" />
          </th>
          <th>Дата та назва</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => {
          return (
            <tr key={i}>
              <td><img src={iconsList[i]} alt='reward' /></td>
              <td>	
								<ItemDate>{item.date}</ItemDate>
                <ItemName>{item.name}</ItemName>
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

export default UserTableMobile;
