import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const GalleryBlockItemActiveStyled = styled.div`
  width: 89%;
  padding: 10px;
  margin: 0 auto;
  margin-top: 15px;
  background: #fff;
  border-radius: 5px;
  img {
    display: block;
    max-width: 100%;
  }
  div {
    margin-top: 10px;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
    color: ${({active})=> active ? '#1E87A9' : '#445566'};
    letter-spacing: 0.1em;
    cursor: pointer;
    :hover {
      background: #f1f2f3;
      border-radius: 2px;
    }
  }
`;

const GalleryBlockItemActive = ({setImgSrc, src, curentSrc}) => {

	const [active, setActive] = useState(false);

	useEffect(() => {
		if (src === curentSrc) {
			setActive(true)
		} else {
			setActive(false)
		}
	}, [curentSrc, src])

	const onEditImg = (src) => {
    setImgSrc(src);
  };

	return (
		<GalleryBlockItemActiveStyled active={active}>
			<img src={src} alt="фото" />
			<div onClick={() => onEditImg(src)}>Застосувати</div>
		</GalleryBlockItemActiveStyled>
	)
}

export default GalleryBlockItemActive;