let initialState = {
  country: {},
  region: {},
  error: ''
}

export const userCountry = (state = initialState, action) => {
  switch (action.type) {
    case 'GET-COUNTRY':
      return {
        ...state,
        country: action.payload
      }
    case "GET-COUNTRY-ERROR":
      return {
        ...state,
        error: action.err
      }
    default:
      return {
        ...state
      }
  }
}

export const userRegion = (state = initialState , action) => {
  switch (action.type){
    case 'GET-REGION':
      return {
        ...state,
        region: action.payload
      }
    case 'GET-REGION-ERROR':
      return {
        ...state,
        error: action.err

      }
    default:
      return {
        ...state
      }
  }


}

