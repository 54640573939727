import React from 'react';
import styled from 'styled-components';
import { statusesEnum } from '../../../mockData/mockData';
import StatusMenuHover from './albumItemEdit/StatusMenuHover';
import HoverTitle from '../../hovers/HoverTitle';

export const StatusAlbum = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 2px #f6f6f6, -1px -1px 2px #f6f6f6;
  border-radius: 5px;
  :hover {
    .hover, .menu_hover {
      display: block;
    }
  }
`;

const AlbumStatusIcon = ({ album, withHover = true }) => {
  return (
    <StatusAlbum>
      <img src='/icons/gallery/album/unlock.svg' alt='unlock' />
			{
				withHover ? <HoverTitle pos='center' title='unlock' /> : <StatusMenuHover activeStatus='unlock'/>
			}
    </StatusAlbum>
  );
};

export default AlbumStatusIcon;
