import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AlbumStatusIcon from '../AlbumStatusIcon';
import HoverTitle from '../../../hovers/HoverTitle';
import BlockDivider from '../../../BlockDivider';
import { useDispatch, useSelector } from 'react-redux';
import { addUserAlbum } from '../../../../actions/galleryAction';
import { useHistory } from 'react-router-dom';

const HeaderStyled = styled.form`
  position: relative;
  padding: 25px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #778899;
`;

const InputBlock = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  input {
    margin-right: 10px;
    display: block;
    padding: 5px 20px 5px 10px;
    width: 770px;
    border: 2px solid #1e87a9;
    border-radius: 12px;
    outline: none;
    font-weight: 600;
    font-size: 19px;
    line-height: 30px;
    color: #223344;
    ::placeholder {
      font-weight: 500;
      font-size: 17px;
      line-height: 30px;
      color: #778899;
    }
  }
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const ButtonSubmit = styled.button`
  padding: 10px 30px;
  background: #47A5BF;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  :hover {
  background: #0A87AA;
  }
  }
`;

const OptionGroupe = styled.div`
  width: 220px;
  display: flex;
  justify-content: space-between;
`;

const OptionItem = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  box-shadow: 1px 1px 2px #f6f6f6, -1px -1px 2px #f6f6f6;
  border-radius: 5px;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'unset')};
  :hover {
    background: ${({ isActive }) => (isActive ? '#F1F2F3' : '#FFF')};
    .hover {
      display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    }
  }
`;

const AlbumItemEdidHeader = ({
                               itsAlbum, setItsAlbum,
  album,
  albumTitle,
  setAlbumTitle,
  create,
  checkedItems,
  setShowPopupRemove,
  setShowPopupMove,
}) => {


  const dispatch = useDispatch();

  const galleryId = useSelector(state => state.userGallery?.dataAlbum?.results?.gallery?.id)
  const albumPhotos = useSelector(state => state.userGallery?.albumPhotos)

  const history = useHistory()

  console.log(albumTitle)

  useEffect(() => {
  }, [galleryId,albumPhotos]);

  const onChangeTitle = (e) => {
    setAlbumTitle(e.target.value);
  };

  const onShowRemovePopup = () => {
    if (checkedItems.length) {
      setShowPopupRemove(true);
    }
  };
// checkedItems.forEach(item => console.log(typeof item))
  const onShowMovePopup = () => {
    if (checkedItems.length) {
      setShowPopupMove(true);
    }
  };
const addAlbum = (e) => {
  e.preventDefault();
  dispatch(addUserAlbum(albumTitle,galleryId,...albumPhotos));
  history.push('/gallery/album');
}
console.log(albumTitle, galleryId,...albumPhotos)

  return (
    <HeaderStyled>
      <Title>Назва альбому</Title>
      <InputBlock>
        <input
          required
          value={albumTitle}
          onChange={onChangeTitle}
          type="text"
          placeholder="Введіть назву альбому..."
        />
        <AlbumStatusIcon album={album} withHover={false} />
      </InputBlock>
      <BlockDivider />
      <ButtonBlock>
        <ButtonSubmit type="submit" onClick={addAlbum}>
          Зберегти
        </ButtonSubmit>
        <OptionGroupe>
          <OptionItem isActive={!!checkedItems.length}>
            <img
              src={
                checkedItems.length
                  ? '/icons/gallery/album/options/rotate.svg'
                  : '/icons/gallery/album/options/inactive/rotate_inactive.svg'
              }
              alt="rotate"
            />
            <HoverTitle pos="center" title="Повернути ліворуч" />
          </OptionItem>
          <OptionItem isActive={!!checkedItems.length}>
            <img
              src={
                checkedItems.length
                  ? '/icons/gallery/album/options/download.svg'
                  : '/icons/gallery/album/options/inactive/download_inactive.svg'
              }
              alt="download"
            />
            <HoverTitle pos="center" title="Завантажити" />
          </OptionItem>
          <OptionItem onClick={onShowMovePopup} isActive={!!checkedItems.length}>
            <img
              src={
                checkedItems.length
                  ? '/icons/gallery/album/options/move.svg'
                  : '/icons/gallery/album/options/inactive/move_inactive.svg'
              }
              alt="move"
            />
            <HoverTitle pos="center" title="Перенести в альбом" />
          </OptionItem>
          <OptionItem onClick={onShowRemovePopup} isActive={!!checkedItems.length}>
            <img
              src={
                checkedItems.length
                  ? '/icons/gallery/album/options/delete.svg'
                  : '/icons/gallery/album/options/inactive/delete_inactive.svg'
              }
              alt="delete"
            />
            <HoverTitle pos="center" title="Видалити альбом" />
          </OptionItem>
        </OptionGroupe>
      </ButtonBlock>
    </HeaderStyled>
  );
};

export default AlbumItemEdidHeader;
