import React, { useEffect } from 'react';
import ContactList from './ContactList';
import SettingsBlock from '../../SettingsBlock';
import SettingsBlockTitle from '../../SettingsBlockTitle';
import styled from 'styled-components';



const InformationContactBlock = ({ onChange, valueObj, disabled, isMobile,valueEmail,valueFacebook,valueInstagram,valueTelegram,valueTikTok, setInputMail,setInputFacebook,setInputInstagram,setInputTikTok,setInputTelegram, validate }) => {

  useEffect(() => {},[validate])
  return (
    <SettingsBlock isFlex={isMobile ? false : true} validate={validate}>
      <SettingsBlockTitle title="Контактні дані" iconUrl="/icons/user_inform/contacts.svg" />
      <ContactList onChange={onChange}
                   valueObj={valueObj}
                   disabled={disabled}
                   validate={validate}
                   valueEmail={valueEmail}
                   valueInstagram={valueInstagram}
                   valueFacebook={valueFacebook}
                   valueTelegram={valueTelegram}
                   valueTikTok={valueTikTok}
                   setInputMail={setInputMail}
                   setInputInstagram={setInputInstagram}
                   setInputFacebook={setInputFacebook}
                   setInputTelegram={setInputTelegram}
                   setInputTikTok={setInputTikTok}/>

    </SettingsBlock>
  );
};

export default InformationContactBlock;
