import React from 'react';
import styled from 'styled-components';
import AuthDivider from '../AuthDivider';
import AuthTitle from '../AuthTitle';
import ButtonSubmit from '../buttons/ButtonSubmit';
import ButtonStroke from '../buttons/ButtonStroke';

const PopupStyled = styled.div`
  display: ${(p) => (p.show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fdfdfd;
`;

const PopupHeader = styled.div`
  padding: 0 27px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #f6f6f6;
  box-shadow: 0px 1px 0px #e7eaed;
  height: 70px;
`;

const IconClose = styled.img`
  cursor: pointer;
`;

const PopupBody = styled.div`
  position: relative;
  padding-bottom: 40px;
  margin: 0 auto;
  margin-top: 40px;
  width: 432px;
`;

const PopupSubTitle = styled.div`
  margin-top: 8px;
  letter-spacing: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #223344;
`;

const AgreementText = styled(PopupSubTitle)`
  margin-top: 15px;
  letter-spacing: 0.05em;
`;

const ButtonsContainer = styled.div`
  width: 432px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
`;

const AgreementBlockPopup = ({ show, setShow, title, subtitle, text, setChecked }) => {
  const onSubmit = () => {
    setChecked(true);
    setShow(false);
  };

  const onClose = () => {
    setShow(false);
  };

  return (
    <PopupStyled show={show}>
      <PopupHeader>
        <IconClose onClick={() => setShow(false)} src="/icons/close_grey.svg" alt="close" />
      </PopupHeader>
      <PopupBody>
        <AuthTitle small title={title} />
        <PopupSubTitle>{subtitle}</PopupSubTitle>
        <AgreementText>{text}</AgreementText>
        <AuthDivider />
      </PopupBody>
      <ButtonsContainer>
        <ButtonSubmit
          onClick={onSubmit}
          width="210px"
          widthSd="312px"
          widthMd="210px"
          width320="296px"
          title="Прийняти"
          isActive
          type="button"
        />
        <ButtonStroke
          onClick={onClose}
          width="210px"
          widthSd="312px"
          widthMd="210px"
          width320="296px"
          title="Закрити"
        />
      </ButtonsContainer>
    </PopupStyled>
  );
};

export default AgreementBlockPopup;
