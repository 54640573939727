import React from 'react';
import styled from 'styled-components';

const SliderPhotoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 87%;
  height: 100%;
  background: #223344;
  img {
    max-width: 100%;
    max-height: 100%;
    // object-fit: cover;
  }
`;
const SliderPhoto = ({ photos, curentSlide }) => {
  return (
    <SliderPhotoStyled>
      <img src={photos !== undefined ?photos[curentSlide] : ''} alt="dancer" />
    </SliderPhotoStyled>
  );
}

export default SliderPhoto;
