import React, {useState} from 'react';
import styled from 'styled-components';
import PhotoOptionsHover from './photo/PhotoOptionsHover';

const OptionIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background: rgba(241, 242, 243, 0.75);
  border-radius: 5px;
  cursor: pointer;

  @media(max-width: 576px) {
    display: none;
  }
`;

const OptionIconHover = ({isAlbum=false, id, setShowPopupRemove, onShowRemoveAlbum, setShowPopupMove, userId,currentOffset}) => {
  
	const [showOptions, setShowOptions] = useState(false);
  const [optionsLeft, setOptionsLeft] = useState(false);

  const onShowOptions = (e) => {
    if (e.clientX > document.documentElement.clientWidth / 2) {
      setOptionsLeft(false);
    } else {
      setOptionsLeft(true);
    }
    setShowOptions((state) => !state);
  };

	return (
		<OptionIcon
			draggable={false}
			onClick={onShowOptions}
			onMouseLeave={() => setShowOptions(false)}
		>
			<img draggable={false} src={isAlbum ? '/icons/gallery/album/options_icon.svg' : '/icons/gallery/photo/edit.svg'} alt="edit" />
			<PhotoOptionsHover userId={userId} currentOffset={currentOffset} setShowPopupMove={setShowPopupMove} onShowRemoveAlbum={onShowRemoveAlbum} setShowPopupRemove={setShowPopupRemove} show={showOptions} setShow={setShowOptions} left={optionsLeft} isAlbum={isAlbum} id={id}/>
		</OptionIcon>
	)
}

export default OptionIconHover;