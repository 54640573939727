import React, {
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import InpupHint from './InputHint';
import InputLabel from './InputLabel';
// import ContactDataBlock from './register/ContactDataBlock';
import ValidMessage
  from './ValidMessage';
import { userLoginStatusReset } from '../../actions/loginAction';
import { useDispatch } from 'react-redux';

export const AuthInputStyled = styled.div`
  position: relative;
  margin-top: ${(p) => p.mtLog || p.mtReg};
  min-height: 52px;
  padding: 16px 12px;
  box-shadow: ${(p) =>
          p.focus
                  ? '1px 1px 1px #FFFFFF, -1px -1px 1px #E7EAED'
                  : '-1px -1px 1px #FFFFFF, 1px 1px 1px #E7EAED'};
  border: ${(p) => (!p.isValid && !p.focus ? '1px solid #C70000' : '1px solid transparent')};
  border-radius: 10px;
  display: flex;
  align-items: center;
  @media (max-width: 1000px) {
    max-width: 432px;
    //margin: 36px auto 4px;
  }
  @media (max-width: 480px) {
    width: ${({ w480 }) => w480};
    margin: ${({m480}) => m480 || '36px auto 4px'};
  }
}

@media (max-width: 360px) {
  width: ${({w360}) => w360 || '296px'};
  margin: 36px auto 4px;
}

@media (max-width: 320px) {
  width: 296px;
  margin: 36px auto 4px;
}
`;

const PhoneCodeBlock = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-right: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #223344;
  }

  @media (max-width: 360px) {
    span {
      font-size: 13px;
      /* padding: 4 px 0 0 24px; */
    }
  }
`;

export const InputStyled = styled.input`
  width: 100%;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  background: #f6f6f6;
  outline: none;
  border: none;
  color: #223344;

  ::placeholder {
    font-weight: normal;
    color: #778899;
  }
`;

const AuthInputPhone = ({
  ml360,
  validPhone,
  setValidPhone,
                          mtLog,
                          mtReg,
                          value,
                          setValue,
                          message,
                          hint,
                          checkComponent,
                          maxLength,
                          invalidLogin,
                          setInvalidLogin,
  w480,
  m480,
  w360
                        }) => {

  const [focus, setFocus] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
  }, [invalidLogin]);

  const handleValue = (e) => {
    setValue(e.target.value);
    console.log(e)
    // setInvalidLogin(false);
    setValidPhone('')
    dispatch(userLoginStatusReset());
  };

  return (
    <>
      <AuthInputStyled focus={focus}
                       isValid={!message}
                       mtLog={mtLog}
                       mtReg={mtReg}
      w480={w480}
                       m480={m480}
                       w360={w360}
      >
        <PhoneCodeBlock>
          <span>+38</span>
          <img
            src='/icons/auth/phone-arrow.svg'
            alt='arrow' />
        </PhoneCodeBlock>
        <InputStyled
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={value}
          maxLength={maxLength}
          onChange={handleValue}
          type='tel'
          placeholder={focus ? '' : 'Номер телефону'}
        />
        <InputLabel valid={!message}
                    focus={focus}
                    title='Номер телефону'
                    show={value} />
      </AuthInputStyled>
      <ValidMessage

        message={message}
        // ml0rd={checkComponent ? '10px' : '23px'}
        ml360={ml360}/>
      <InpupHint title={hint}
                 show={hint}
                 p0cd='4px 24px 0 14px' />
    </>
  );
};

export default AuthInputPhone;
