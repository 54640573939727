import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import EyeBlock from '../settings/settingsPassword/EyeBlock';
import { AuthInputStyled, InputStyled } from './AuthInputPhone';
import InpupHint from './InputHint';
import InputLabel from './InputLabel';
import ValidMessage from './ValidMessage';
import { userLoginStatusReset } from '../../actions/loginAction';
import { useDispatch } from 'react-redux';

const InputBlock = styled(AuthInputStyled)`
  padding: ${(p) => (p.withIcon ? '16px 40px 16px 21px' : '16px 40px 16px 12px')};
  @media (max-width: 1000px) {
    max-width: 432px;
  }@media (max-width: 480px) {
    width: ${({w480}) => w480};
  }}@media (max-width: 360px) {
  width: ${({w360}) => w360 || '296px'}
  }
  @media (max-width: 320px) {
    width: 296px;
  }
`;

const InputLockImg = styled.img`
  display: ${(p) => (p.show ? 'block' : 'none')};
  margin-right: 37px;
`;

const AuthInputPassword = ({w360, m480, w480, mtLog, value, setValue, message, placeholder, withIcon, hint,  invalidLogin,setInvalidLogin }) => {

  const [visible, setVisible] = useState(false);
  const [focus, setFocus] = useState(false);

  const dispatch = useDispatch()

  useEffect(() => {},[invalidLogin])

  const handleValue = (e) => {
    setValue(e.target.value);
    // setInvalidLogin(false)
    dispatch(userLoginStatusReset())
  }

  return (
    <>
      <InputBlock
        withIcon={withIcon}
        focus={focus}
        isValid={!message}
        isActive={value}
        mtLog={mtLog}
        w480={w480}
        m480={m480}
        w360={w360}
      >
        <InputLockImg show={withIcon} src="/icons/auth/locked.svg" alt="lock"/>
        <InputStyled
          value={value}
          placeholder={focus ? '' : placeholder}
          onChange={handleValue}
          type={visible ? 'text' : 'password'}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        <EyeBlock top="calc(50% - 20px)" right="21px" visible={visible} setVisible={setVisible} />
        <InputLabel valid={!message} title={placeholder} focus={focus} show={value} />
      </InputBlock>
      <ValidMessage message={message} ml0rd="10px"/>
      <InpupHint title={hint} show={hint} p0cd="4px 24px 0 14px" w480='312px'/>
    </>
  );
};

export default AuthInputPassword;
