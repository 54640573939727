import {api} from '../api/api';


export const getUserCountry = () => {
  return(dispatch) => {
    api.geolocation
      .getCountry()
      .then(res => {
        dispatch(getUserCountrySuccess(res.data))
      })
      .catch(err =>  {
        dispatch(getUserCountryError('something-wrong'))
        console.log(err)})


  }

}

export const getUserRegion = () => {
  return(dispatch) => {
    api.geolocation
      .getRegion()
      .then(res => {
        dispatch(getUserRegionSuccess(res.data))
      })
      .catch(err => {
        dispatch(getUserRegionError('something-wrong'))
        console.log(err)
      })


  }
}

export const getUserCountrySuccess = (payload) => {
  return {
    type: 'GET-COUNTRY',
    payload
  }
}
export const getUserCountryError = (err) => {
  return {
    type: 'GET-COUNTRY-ERROR',
    err
  }
}

export const getUserRegionSuccess = (payload) => {
  return {
    type: "GET-REGION",
    payload
  }
}
export const getUserRegionError = (err) => {
  return {
    type: 'GET-REGION-ERROR',
    err
  }

}