import React from 'react';
import styled from 'styled-components';

const HeaderTitleStyled = styled.h1`
  letter-spacing: 0.05em;
  color: #223344;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 10px;
`


const HeaderClubTitle = ({children}) => {
return(
  <HeaderTitleStyled>
  {children}
</HeaderTitleStyled>
);
}

export default HeaderClubTitle