import React from 'react';
import styled from 'styled-components';
import BlockDivider from '../../BlockDivider';
import PhoneNumberInput from '../../settings/settingsPassword/phoneNumberSection/PhoneNumberInput';
import PopupLayout from '../PopupLayout';

const PopupBody = styled.div`
	position: relative;
	padding: 15px 25px 40px 25px;
`;

const TextBlock = styled.div`
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #223344;

	@media(max-width: 576px) {
		font-size: 15px;
  	line-height: 20px;
	}
`;

const PopupCodeNone = ({ show, setShow, phoneNumber, setPhoneNumber, alertMessage }) => {

  const onEdit = () => {
    setShow(false);
  };
	  
  return (
    <PopupLayout
      submitActive={phoneNumber && !alertMessage}
      submitTitle="Продовжити"
      onEdit={onEdit}
      show={show}
      setShow={setShow}
      title="Введіть новий номер телефону"
    >
			<PopupBody>
				<TextBlock>Ми надішлемо код підтвердження в SMS.</TextBlock>
				<PhoneNumberInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} alertMessage={alertMessage} plNone/>
				<BlockDivider height='2px' />
			</PopupBody>
		</PopupLayout>
  );
};

export default PopupCodeNone;
