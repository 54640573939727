import React from "react";
import styled from "styled-components";

const ChooseLanguageStyled = styled.div`
	display: ${({show})=>show ? 'block' : 'none'};
	padding: 15px 0;
	position: absolute;
	bottom: -110px;
	right: 0;
	width: 150px;
	height: 110px;
	box-shadow: 0px 1.5px 3px rgba(174, 174, 192, 0.2);
	border-radius: 0px 0px 10px 10px;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 15px, rgb(255, 255, 255) 80px);
	z-index: 1;
	div {
		margin-top: 15px;
		text-align: center;
		font-weight: 400;
		font-size: 14px;
		line-height: 25px;
		:hover {
			color: #1E87A9;
			letter-spacing: 0.04em;
			font-weight: 500;
		}
	}
}
`;

const LanguageHover = ({show, setVisible}) => {
  return (
    <ChooseLanguageStyled show={show} className="drop-content">
      <div onClick={()=>setVisible(false)}>Русский RU</div>
      <div onClick={()=>setVisible(false)}>English EN</div>
    </ChooseLanguageStyled>
  );
};

export default LanguageHover;
