import React from 'react';
import styled from 'styled-components';
import LikeBlock from '../bookmarks/bookmarksContent/bookmarksItem/LikeBlock';

const Header = styled.div`
  position: relative;
  padding: 5px 10px 5px 20px;
  display: flex;
  justify-content: space-between;
  background: #f6f6f6;
  box-shadow: -1.5px -1.5px 3px #ffffff, 1px 1px 2px #e1e1e1;
  border-radius: 5px;

  @media(max-width: 576px) { 
    padding: 10px 16px;
  }
`;

const NameBlock = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarBlock = styled.div`
  margin-right: 20px;
  width: 36px;
  height: 36px;

  img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media(max-width: 576px) { 
    margin-right: 16px;
    width: 44px;
    height: 44px;
  }
`;

const UserName = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 40px;
  letter-spacing: 0.1em;
  color: #445566;

  @media(max-width: 576px) { 
    font-size: 15px;
    line-height: 36px;
    letter-spacing: 0.07em;
  }
`;

const UserPageHeader = ({userName, liked}) => {
	return (
		<Header>
			<NameBlock>
				<AvatarBlock>
					<img src="/icons/avatar_my_info.png" alt="avatar" />
				</AvatarBlock>
				<UserName>{userName}</UserName>
			</NameBlock>
			<LikeBlock isGallery topCenter isSearch liked={liked} top="calc(50% - 18px)" right="20px" />
		</Header>
	)
}

export default UserPageHeader;