import React, {
  useEffect,
  useState,
} from "react";
import styled
  from "styled-components";
import AuthTitle
  from "../../components/auth/AuthTitle";
import RegisterContainer
  from "../../components/auth/register/RegisterContainer";
import RegisterNav
  from "../../components/auth/register/RegisterNav";
import AuthTextArea
  from "../../components/auth/AuthTextArea";
import AuthInputText
  from "../../components/auth/AuthInputText";
import AuthSelect
  from "../../components/auth/AuthSelect";
import AddInputLink
  from "../../components/auth/register/AddInputLink";
import AuthDivider
  from "../../components/auth/AuthDivider";
import AuthSubTitle
  from "../../components/auth/AuthSubTitle";
import RegisterFileInputBlock
  from "../../components/auth/register/RegisterFileInputBlock";
import ButtonsContainer
  from "../../components/auth/ButtonsContainer";
import ButtonSubmit
  from "../../components/auth/buttons/ButtonSubmit";
import ValidMessage
  from "../../components/auth/ValidMessage";
import { validateEmail } from "../../utils/validateEmail";
import { useValidate } from "../../components/hooks/useValidate";
import { useHistory } from "react-router-dom";
import {
  useSelector,
  useDispatch,
} from "react-redux";
import { registUserClubManager } from "../../actions/registerAction";
import AuthInputDate
  from "../../components/auth/AuthInputDate";
import AuthInputEmail
  from "../../components/auth/AuthInputEmail";
import AuthSelectLocations from "../../components/auth/register/AuthSelectLocations";
import { getClubsList, getTrainersList } from "../../actions/positionsAction";
import { getUserCountry, getUserRegion } from "../../actions/countryAction";

const MainContainer = styled.div`
  position: relative;
  padding-bottom: 40px;
  margin-top: 44px;
  display: grid;
  grid-template-columns: repeat(2, 432px);
  justify-content: space-between;
  align-items: start;
  grid-template-rows: minmax(114px, auto);
  grid-auto-rows: minmax(74px, auto);
  row-gap: 44px;
  @media (max-width: 1000px) {
    grid-template-columns: ${({ gtc1000 }) => gtc1000};
    justify-content: ${({ jc1000 }) => jc1000};
    max-width: 432px;
    margin: ${({ m1000 }) => m1000};
  }
  @media (max-width: 480px) {
    max-width: 312px;
    grid-template-columns: repeat(1, 312px);
    grid-template-rows: minmax(60px, auto);
  }
  @media (max-width: 360px) {
    max-width: 296px;
    grid-template-columns: repeat(1, 296px);
    grid-template-rows: minmax(60px, auto);
  }
`;

const ChapterWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 44px;
`;

const PageSection = styled.div`
  padding: 40px 0;
  position: relative;
  @media (max-width: 1000px) {
    max-width: ${({ mw1000 }) => mw1000};
    margin: ${({ m1000 }) => m1000};
  }
  @media (max-width: 480px) {
    max-width: ${({ mw480 }) => mw480};
    margin: ${({ m480 }) => m480};
  }
`;

const InputContainer = styled.div`
  max-width: 432px;
`;

const QuestionaryManagerPage = () => {


  const countryList = useSelector((state) => state.userCountry?.country?.results);
  const regionList = useSelector((state) => state.userRegion?.region?.results);

  useEffect(() => {
    dispatch(getUserCountry())
    dispatch(getUserRegion())
  },[]);

  const [clubName, setClubName] = useState("");
  const [found, setFound] = useState("");
  const [country, setCountry] = useState("");
  const [countryId, setCountryId] = useState(0);
  const [region, setRegion] = useState("");
  const [regionId, setRegionId] = useState("");
  const [city, setCity] = useState("");
  const [adress, setAdress] = useState("");
  const [chapters, setChapters] = useState([""]);
  const [email, setEmail] = useState("");
  const [siteUrl, setSiteUrl] = useState("");
  const [countTrainers, setCountTrainers] = useState("");
  const [trainerNames, setTraintersNames] = useState([""]);
  const [aboutClub, setAboutClub] = useState("");
  const [docCopies, setDocCopies] = useState([]);
  const [validMail,setValidMail] = useState('')

  const [validate, setValidate] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const usersRole = useSelector((state) => state.users.curentUser.role);
  const userCurrent = useSelector(state => state.registerUsers.registersUser.data?.pk);

  const splitted = found.includes(".") && found !== "" ? found.split(".") : "";
  const swapped = splitted ? [splitted[2], splitted[1], splitted[0]] : "";
  const birthdayForm = swapped !== "" ? swapped.join("-") : ""; // заменяет точку в дате на тире для отправки на запроса

  const emailValidMessage = useValidate(email, validateEmail, validate);



  useEffect(() => {

    if (
      clubName &&
      found &&
      country &&
      region &&
      city &&
      adress &&
      !emailValidMessage &&
      countTrainers &&
      docCopies.length
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }

  }, [
    clubName,
    found,
    country,
    region,
    city,
    adress,
    emailValidMessage,
    countTrainers,
    docCopies,
    validate,
    formValid,
  ]);

  const onChangeFile = (e, setValue) => {
    // const fileList = Object.values(e.target.files).filter((item) => isNaN(item));
    setValue(oldCopies => [...oldCopies,e.target.files[0]]);
  };


  const onRemoveFile = (idx, setValue) => {
    setValue((state) => {
      return [...state.slice(0, idx), ...state.slice(idx + 1)];
    });
  };

  const onAddField = (setValue) => {
    setValue((state) => [...state, ""]);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setValidate(true);

    if (!formValid) {
      return;
    }
    dispatch(registUserClubManager(
      1,
      // trainerNames,
      // docCopies, пока не сделают на бэке
      clubName,
      adress,
      email,
      siteUrl,
      aboutClub,
      countryId,
      regionId,
      1,
      userCurrent,
    ));



    if (!usersRole.length) {
      history.push("/auth/reg_data");
    } else {
      const idx = usersRole.findIndex((item) => item === "manager");
      if (usersRole[idx + 1]) {
        history.push(`/auth/questionary_${usersRole[idx + 1]}`);
      } else {
        history.push("/auth/reg_add_doc");
      }
    }
  };
  // console.log(trainerNames,
  //   clubName,
  //   adress,
  //   email,
  //   siteUrl,
  //   aboutClub,
  //   countryId,
  //   regionId,
  //   1,
  //   userCurrent,)
  return (
    <RegisterContainer>
      <RegisterNav
        backLink='/auth/questionary_dancer'
        currentStep={2} />

      <form
        onSubmit={onSubmit}>
        <MainContainer
          gtc1000='repeat(1, 432px)'
          jc1000='center'
          m1000='44px auto 0'
        >
          <AuthTitle
            mw1000='432px'
            fs480='24px'
            m1000='0 auto'
            title='Анкета керівника клубу' />
          <div>
            <AuthTextArea
              mt='0'
              w480='312px'
              placeholder='Назва клубу'
              value={clubName}
              setValue={setClubName}
            />
            <ValidMessage
              message={!clubName && validate ? "Обов’язкове поле" : ""} />
          </div>
          <AuthInputDate
            validate={validate}
            hint='(дд.мм.рррр)'
            value={found}
            setValue={setFound}
            placeholder='Дата народження'
          />
          <InputContainer>
            <AuthSelectLocations
              validate={validate}
              df='flex'
              filtered
              mt='0'
              mtMd='22px'
              placeholder='Вибрати країну'
              options={countryList}
              setId={setCountryId}
              value={country}
              setValue={setCountry}
              m1000='0 auto'
            />
          </InputContainer>
          <InputContainer>
            <AuthSelectLocations
              validate={validate}
              df='flex'
              filtered
              mt='0'
              mtMd='22px'
              placeholder='Вибрати Область'
              options={regionList}
              setId={setRegionId}
              value={region}
              setValue={setRegion}
              m1000='0 auto'
            />
          </InputContainer>
          <AuthInputText

            value={city}
            lang={"UA"}
            setValue={setCity}
            placeholder='Місто'
            hint='Вкажіть назву населеного пункту де знаходиться клуб'
            validate={validate}
          />
          <AuthInputText
            value={adress}
            lang={"UA"}
            setValue={setAdress}
            placeholder='Вкажіть адресу'
            hint='Вкажіть точну адресу розташування для мапи'
            validate={validate}
          />
          <div>
            <ChapterWrapper>
              {chapters.map((item, i) => (
                <AuthInputText
                  key={i}
                  value={item}
                  lang={"UA"}
                  setValue={(value) =>
                    setChapters((state) => state.map((el, j) => (i === j ? value : el)))
                  }
                  placeholder={`Вкажіть адресу філії клубу №${i + 2}`}
                  hint='Вкажіть точну адресу розташування для мапи'
                />
              ))}
            </ChapterWrapper>
            <AddInputLink
              show={chapters.length < 9}
              onClick={() => onAddField(setChapters)}
              title='Додати філію клубу'
            />
          </div>
          <AuthInputEmail
            validMail={validMail}
            setValidMail={setValidMail}
            message={emailValidMessage}
            validate={validate}
            hint='Вкажіть офіційний e-mail клубу'
            value={email}
            lang={"ENG_NUM"}
            setValue={(e) => setEmail(e)}
            placeholder='E-mail'
          />
          <AuthInputText
            placeholder='Введіть веб-адресу'
            hint='Вкажіть URL адреси веб-сторінки чи сайту клубу (якщо існує)'
            lang={"ENG_NUM"}
            value={siteUrl}
            setValue={setSiteUrl}
          />
          <AuthDivider w480='312px' />
        </MainContainer>
        <PageSection
          mw1000='432px'
          mw480='312px'
          m480='0 auto'
          m1000='0 auto'>
          <AuthTitle
            mw1000='432px'
            m1000='0 auto'
            fs480='20px'
            small
            title='Тренери клубу' />
          <AuthSubTitle
            fs480='15px'
            w480='312px'
            mw1000='432px'
            m1000='0 auto'
            fullWidth
            title='Вкажіть загальну кількість тренерів вашого клубу та їх прізвища (включаючи себе, якщо ви тренер)'
          />
          <InputContainer>
            <AuthInputText
              mt='60px'
              mt1000='36px'
              value={countTrainers}
              setValue={setCountTrainers}
              placeholder='Введіть число'
              lang={"NUM"}
              hint='Кількість тренерів у вашому клубі'
              validate={validate}
            />
            {trainerNames.map((item, i) => (
              <AuthInputText
                mt='44px'
                mt1000='36px'
                key={i}
                placeholder=' Ім’я та прізвище'
                lang={"UA"}
                hint='Напишіть ім’я та прізвище тренера клубу'
                value={item}
                setValue={(value) =>
                  setTraintersNames((state) => state.map((el, j) => (j === i ? value : el)))
                }
              />
            ))}
            <AddInputLink
              mw480='312px'
              show
              onClick={() => onAddField(setTraintersNames)}
              title='Додати тренера клубу'
            />
          </InputContainer>
          <AuthDivider w480='312px' />
        </PageSection>
        <PageSection
          mw1000='432px'
          mw480='312px'
          m1000='0 auto'>
          <AuthTitle
            mw1000='432px'
            m1000='0 auto'
            fs480='20px'
            small
            title='Додаткові відомості' />
          <AuthSubTitle
            fs480='15px'
            fullWidth
            title='Про клуб' />
          <InputContainer>
            <AuthTextArea
              w480='312px'
              mt='36px'
              placeholder='Введіть текст'
              value={aboutClub}
              setValue={setAboutClub}
            />
          </InputContainer>
          <AuthDivider w480='312px' />
        </PageSection>
        <PageSection
          mw1000='432px'
          mw480='312px'
          m1000='0 auto'>
          <AuthTitle
            mw1000='432px'
            m1000='0 auto'
            fs480='20px'
            small
            title='Додати копії документів' />
          <AuthSubTitle
            fs480='15px'
            mw1000='432px'
            m1000='0 auto'
            fullWidth
            title='(статут громадської організації, свідоцтво про реєстрацію, свідоцтво платника податків, реєстрація ФОП тощо)'
          />
          <RegisterFileInputBlock
            files={docCopies}
            multiple
            onChange={(e) => onChangeFile(e, setDocCopies)}
            onRemoveFile={(idx) => onRemoveFile(idx, setDocCopies)}
          />
          <ValidMessage
            ml480='0'
            message={validate && !docCopies.length ? "Файл не вибрано" : ""} />
          <AuthDivider />
        </PageSection>
        <ButtonsContainer jc1000='center' h480='60px'>
          <ButtonSubmit
            isActive={formValid}
            title='Продовжити'
            width='250px'
            width480='312px'
            w360='296px'

          />

        </ButtonsContainer>
      </form>
    </RegisterContainer>
  );
};

export default QuestionaryManagerPage;
