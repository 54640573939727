import React from 'react';
import styled from 'styled-components';
import { ButtonSubmit } from '../../../styles/StyledComponents';

const ButtonBlockEditStyled = styled.div`
  padding: 25px;
  display: flex;
  justify-content: flex-end;
`;

const ButtonEdit = styled.button`
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 1.22222px 1.22222px 2.44444px #f6f6f6, -1.22222px -1.22222px 2.44444px #f6f6f6;
  border-radius: 10px;
  border: ${({ isActive }) => (isActive ? '2px solid #1E87A9' : 'none')};
  outline: none;
  margin-right: 25px;
`;

const ButtonBlockEdit = ({ editActive, onEdit, submitActive }) => {
  return (
    <ButtonBlockEditStyled>
      <ButtonEdit onClick={onEdit} type="button" isActive={editActive}>
        <img src="/icons/slider_mobile/edit.svg" alt="edit" />
      </ButtonEdit>
      <ButtonSubmit isActive={submitActive} type="submit">Зберегти зміни</ButtonSubmit>
    </ButtonBlockEditStyled>
  );
};

export default ButtonBlockEdit;
