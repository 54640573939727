import React from 'react';
import styled from 'styled-components';

const PopupText = styled.p`
font-weight: 500;
  margin-bottom: 15px;
  @media (max-width: 530px){
    font-size: 14px;
  }
`


const PopupAddressText = ({children}) => {
  return(
    <PopupText>
      {children}
    </PopupText>

  )
}

export default PopupAddressText;