import React from 'react';
import MainMobileLayout from '../../mobileLayout/MainMobileLayout';
import TrainerDancersListPopup from './TrainerDancersListPopup';

const MobileListPopup = ({ show, setShow }) => {
  const onClose = () => {
    setShow(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <MainMobileLayout
      onClose={onClose}
      show={show}
      title="Список Танцюристів"
      withClose
      overflow="hidden"
    >
      <TrainerDancersListPopup show />
    </MainMobileLayout>
  );
};

export default MobileListPopup;
