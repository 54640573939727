import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Paid from '../payments/paid/Paid';
import Archive from '../payments/archive/Archive';
import ClubInformation from './mainClub/ClubInformation';

const ClubCategories = () => {
  return (
    <Switch>
      <Route path='/my_club/main'>
        <ClubInformation />
      </Route>
      <Route path='/my_club/trainers'>
        <Paid />
      </Route>
      <Route path='/my_club/participants'>
        <Archive />
      </Route>
    </Switch>
  );

}

export default ClubCategories