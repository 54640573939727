import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputLabel from './InputLabel';
import ValidMessage from './ValidMessage';
import InputHint from './InputHint';

export const AuthInputStyled = styled.div`
  position: relative;
  margin-top: ${(p) => p.mt || 0};
  min-height: 52px;
  padding: 16px 12px;
  box-shadow: ${(p) =>
    p.focus
      ? '1px 1px 1px #FFFFFF, -1px -1px 1px #E7EAED'
      : '-1px -1px 1px #FFFFFF, 1px 1px 1px #E7EAED'};
  border: ${(p) => (!p.isValid && !p.focus ? '1px solid #C70000' : '1px solid transparent')};
  border-radius: 10px;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    margin-top: ${({mt1000}) => mt1000 || "0"};
    max-width: 432px;
  }@media (max-width: 480px) {
    max-width: 312px;
  }@media (max-width: 360px) {
    max-width: 296px;
  }
`;

export const InputStyled = styled.input`
  width: 100%;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  background: #f6f6f6;
  outline: none;
  border: none;
  color: #223344;
  ::placeholder {
    font-weight: normal;
    color: #778899;
  }
  @media (max-width: 1000px){
    max-width: 432px;
  }
`;
const AuthInputDate = ({
  mt,
  mt1000,
  hint,
  value,
  placeholder,
  validate,
  message,
  setValue,
  realizeDateField,
  backSpaceButton,
}) => {
  const [focus, setFocus] = useState(false);
  const [validMessage, setvalidMessage] = useState('');

  useEffect(() => {
    if (validate && !value) {
      setvalidMessage('Обов’язкове поле');
    } else {
      setvalidMessage('');
    }
  }, [validate, value]);

  return (
    <div>
      <AuthInputStyled mt1000={mt1000} mt={mt} focus={focus} isValid={message ? !message : !validMessage}>
        <InputStyled
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={value}
          type="text"
          maxLength={10}
          placeholder={focus ? '' : placeholder}
          onChange={(e) => setValue(e.target.value.match(/^[0-9.]*$/) ? e.target.value : value)}
          //   e.target.value.match(/^[0-9.]*$/) ? realizeDateField(e.target.value) : <></>
          // }
          // onKeyDown={(e) => {
          //   e.code === 'Backspace' ? realizeDateField(e.code) : <></>;
          // }}
          // onKeyUp={(e) => {
          //   // e.code === 'Backspace' ?
          //   if (e.code === 'Backspace'){return realizeDateField(e.code)}else{return false}
          //
          //     // : <></>
          // }}
          // onChnge={(e =>)}
        />
        <InputLabel
          text
          valid={message ? !message : !validMessage}
          focus={focus}
          title={placeholder}
          show={value}
        />
      </AuthInputStyled>
      <ValidMessage message={message ? message : validMessage} ml0rd='10px'/>
      {hint ? <InputHint title={hint} p0cd="4px 24px 0 14px" /> : ''}
    </div>
  );
};
export default AuthInputDate;
