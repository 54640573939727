import { api } from "../api/api";
import { UPDATE_USER_COVER_ERROR, UPDATE_USER_COVER_SUCCESS, UPDATE_USER_PHOTO_ERROR, UPDATE_USER_PHOTO_SUCCESS } from "../reducers/profile.reducer";


export const getUserProfile =  (id) => {
    return(dispatch) => {
        // dispatch(getUserProfileRequest());
api.users
          .userProfile(id)
          .then(res =>  {
              dispatch(getUserProfileSuccess(res.data))
          })
          .catch(err => {
              console.log(err)
              dispatch(getUserProfileError('something-wrong'))
          })
    }
}

//------------------------UserUpdate



export const setUserProfile = (id, email, instagram, facebook, telegram, tiktok, about) => {
    return(dispatch) => {
        let formData = new FormData()

        formData.append('email', email)
        formData.append('instagram', instagram)
        formData.append('facebook', facebook)
        formData.append('telegram', telegram)
        formData.append('tiktok', tiktok)
        formData.append('about', about)

        const config = {
            headers: { "content-type": "multipart/form-data" },
        }

        dispatch(setUserProfileRequest());
        api.users
          .userProfileUpdate(id,formData,config)
          .then(res => {
              dispatch(setUserProfileSuccess(res.data))
          })
          .catch(err => {
              console.log(err)
              dispatch(setUserProfileError('something-wrong'))
          })
    }
}

export const updateUserPhoto = (id,photo) => {
    return(dispatch) => {
        let formData = new FormData()

        formData.append('photo', photo)
        const config = {
            headers: { "content-type": "multipart/form-data" },
        }
        api.users
          .userProfileUpdate(id,formData,config)

          .then(res => {
              dispatch(updateUserPhotoSuccess(res.data))
          })
          .catch(err => {
              console.log(err)
              dispatch(updateUserPhotoError('something-wrong'))
          })
    }
}
export const updateUserCover = (id,cover) => {
    return(dispatch) => {
        let formData = new FormData()

        formData.append('cover', cover)
        const config = {
            headers: { "content-type": "multipart/form-data" },
        }
        api.users
          .userProfileUpdate(id,formData,config)
          .then(res => {
              dispatch(updateUserCoverSuccess(res.data))
          })
          .catch(err => {
              console.log(err)
              dispatch(updateUserCoverError('something-wrong'))
          })
    }
}

export const getUserProfileRequest = () => {
    return{
        type: 'GET-USER-PROFILE-REQUEST'
    }
}

export const getUserProfileSuccess = (data) => {
    return {
        type: 'GET-USER-PROFILE-SUCCESS',
        payload : data
    }
}

export const getUserProfileError = (err) => {
    return {
        type: 'GET-USER-PROFILE-ERROR',
        err
    }
}

export const setUserProfileRequest = () => {
    return{
        type: 'SET-USER-PROFILE-REQUEST'
    }
}

export const setUserProfileSuccess = (data) =>{
    return{
        type: 'SET-USER-PROFILE-SUCCESS',
        payload: data,
    }
}

export const setUserProfileError = (err) => {
    return{
        type: 'SET-USER-PROFILE-ERROR',
        err
    }
}

export const updateUserPhotoSuccess = (data) => {
    return{
        type: UPDATE_USER_PHOTO_SUCCESS,
        payload:data,
    }

}
export const updateUserPhotoError = (err) => {
    return{
        type: UPDATE_USER_PHOTO_ERROR,
        err
    }

}
export const updateUserCoverSuccess = (data) => {
    return{
        type: UPDATE_USER_COVER_SUCCESS,
        payload:data,
    }

}
export const updateUserCoverError = (err) => {
    return{
        type: UPDATE_USER_COVER_ERROR,
        err
    }

}
