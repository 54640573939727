import React from 'react';
import Search from '../../Search';
import { dancersList } from '../../../../mockData/mockData';

const SearchJudges = () => {
  const searchList = dancersList.filter((item) => item.staus.includes('Суддя'));

  return <Search searchList={searchList} />;
};

export default SearchJudges;
