import React from 'react';
import styled from 'styled-components';
import BlockDivider from '../BlockDivider';

const HeaderStyled = styled.div`
	position: relative;
	padding: 20px;
	display: flex;
	background: #FFF;
`;

const HeaderPhoto = styled.div`
	min-width: 115px;
	max-width: 115px;
	height: 115px;
	margin-right: 12px;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

const AlbumTitle = styled.div`
	font-weight: 600;
	font-size: 15px;
	line-height: 19px;
	color: #223344;
	span {
		display: block;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: #1E87A9;
	}
`;

const AlbumItemHeaderMobile = ({album}) => {
	return (
		<HeaderStyled>
			<HeaderPhoto><img src={album.photos[0]} alt='album' /></HeaderPhoto>
			<AlbumTitle>{album.name}<span>{album.photos.length} фото</span></AlbumTitle>
			<BlockDivider />
		</HeaderStyled>
	)
}

export default AlbumItemHeaderMobile;