import React from 'react';
import styled from 'styled-components';
import { albums } from '../../../mockData/mockData';
import BlockDivider from '../../BlockDivider';
import AlertMessage from '../../settings/AlertMessage';

const PopupContentStyled = styled.div`
	position: relative;
	padding: 20px 20px 40px 20px;
`;

const ContentDescr = styled.div`
	font-weight: 500;
	font-size: 17px;
	line-height: 22px;
	color: #223344;

	@media(max-width: 576px) {
		font-size: 15px;
		line-height: 20px;
  }
`;

const AlbumNameBlock = styled.div`
	margin-top: 16px;
	background: #F1F2F3;
	border-radius: 10px;
	padding: 10px 8px;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	color: #778899;
	text-align: center;
`;

const InputStyled = styled.input`
margin-top: 16px;
height: 40px;
display: block;
padding: 10px 12px;
width: 100%;
border: 1px solid ${({value}) => value ? '#1E87A9' : '#778899'};
border-radius: 10px;
outline: none;
font-weight: 600;
font-size: 15px;
line-height: 20px;
color: #223344;
::placeholder {
	font-weight: 500;
	color: #778899;
}
`;

const PopupContent = ({albumId, value, setValue, alertMessage, setAlertMessage}) => {

	const onChange = (e) => {
		setValue(e.target.value);
		if (!e.target.value || e.target.value === albums.find(item=>item.id === albumId).name ) {
			setAlertMessage('');
		}
	}


	return (
		<PopupContentStyled>
			<ContentDescr>
				Ви впевнені, що хочете видалити даний альбом? <br></br>
				Всі фото, які містяться у цьому альбомі теж будуть видалені. Ця дія незворотна. 
				Будь ласка, підтвердьте, ввівши назву альбому.
			</ContentDescr>
			<AlbumNameBlock>{albumId ? albums.find(item=>item.id === albumId).name : ''}</AlbumNameBlock>
			<InputStyled value={value} onChange={onChange}  placeholder='Введіть назву альбому...' />
			<AlertMessage message={alertMessage} show={alertMessage} />
			<BlockDivider height='2px' />
		</PopupContentStyled>
	)
}

export default PopupContent;