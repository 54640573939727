import React from 'react';
import ChangePasswordSection from './changePasswordSection/ChangePasswordSection';
import PhoneNumberSection from './phoneNumberSection/PhoneNumberSection';
import AccessAcountSection from './accessAcountSection/AccessAcountSection';

const SettingsPassword = () => {
  return (
    <>
      <ChangePasswordSection />
      <PhoneNumberSection />
      <AccessAcountSection />
    </>
  );
};

export default SettingsPassword;
