import React from 'react';
import styled from 'styled-components';
import OptionIconHover from '../OptionIconHover';
import AlbumStatusIcon, { StatusAlbum } from './AlbumStatusIcon';
import BlockDivider from '../../BlockDivider';
import { useSelector } from "react-redux";

const AlbumItemHeaderStyled = styled.div`
  position: relative;
  padding: 25px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AlbumItemTitle = styled.div`
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  color: #223344;
`;

const OptionsWrapper = styled.div`
  display: flex;
  width: 170px;
  align-items: center;
  justify-content: space-between;
`;

const CountPhoto = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #1e87a9;
`;

const OptionsMenu = styled(StatusAlbum)`
  position: relative;
  margin-right: 0;
  top: 0;
  right: 0;
  background: #fff;
  > div {
    background: #fff;
    position: unset;
    width: 100%;
    height: 100%;
    :hover {
      background: #f1f2f3;
    }
  }
`;

// const userAlbums = useSelector(state => state.userGallery?.dataAlbum?.results?.albums);
// const countAlbums = useSelector(state => state.userGallery?.dataAlbum?.count)


const AlbumItemHeader = ({ album, onShowRemoveAlbum }) => {

  return (
    <AlbumItemHeaderStyled>
      <AlbumItemTitle>
        {album?.title?.length > 55 ? `${album?.title?.slice(0, 55)}...` : album?.title}
      </AlbumItemTitle>
      <OptionsWrapper>
        <CountPhoto>{album?.photos?.length} Фото</CountPhoto>
        <AlbumStatusIcon album={album} />
		{
			album?.title === 'Фото' ? '' : (
				<OptionsMenu>
					<OptionIconHover onShowRemoveAlbum={onShowRemoveAlbum} isAlbum id={album?.id} />
				</OptionsMenu>
			)
		}
      <BlockDivider />
      </OptionsWrapper>
    </AlbumItemHeaderStyled>
  );
};

export default AlbumItemHeader;
