const initialState = {
  showAddressPopup : false,
  showPrivacyPopup: false,
}

const popup = (state = initialState, action) => {

  switch(action.type){
    case "OPEN-ADDRESS-POPUP":
      return {
        ...state,
        showAddressPopup: true
      }
    case "CLOSE-ADDRESS-POPUP":
      return {
        ...state,
        showAddressPopup: false
      }
    case "OPEN-PRIVACY-POPUP":
      return {
        ...state,
        showPrivacyPopup: true
      }
    case "CLOSE-PRIVACY-POPUP":
      return {
        ...state,
        showPrivacyPopup: false
      }
    default:
      return {
        ...state
      }
  }

}

export default popup;