import React from 'react';
import styled from 'styled-components';
import HoverTitle from '../../../hovers/HoverTitle';

export const ItemIconStyled = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${({top}) => top};
  right: ${({right}) => right};
  width: ${({ itemHover }) => (itemHover ? 'unset' : '36px')};
  height: 36px;
  box-shadow: 1px 1px 2px #f6f6f6, -1px -1px 2px #f6f6f6;
  background: #FFF;
  border-radius: 5px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  img {
    max-width: 100%;
  }
  :hover {
    background: #F1F2F3;
  }

  @media (max-width: 576px) {
    top: ${({topCenter}) => topCenter ? 'calc(50% - 18px)' : '12px'};
  }
`;

const ItemIcon = ({top='20px', right='20px', topCenter = false}) => {

  return (
    <ItemIconStyled top={top} right={right} topCenter={topCenter}>
      <img src="/icons/bookmarks/delete.svg" alt="delete" />
      <HoverTitle title={'Видалити з закладок'} />
    </ItemIconStyled>
  )
};

export default ItemIcon;
