import React, { useState } from "react";
import ArchiveBody from "./archiveBody/ArchiveBody";
import ArchiveHeader from "./archiveHeader/ArchiveHeader";
import Pagination from "../../pagination/Pagination";
import PaymentsSubheader from '../paymentsSubheader/PaymentsSubheader';
import { paymentsData } from "../../../mockData/mockData";

const Archive = () => {

  const [searchTerm, setSearchTerm] = useState('');

  const filteredArr = paymentsData.filter(
    (item) => item.accountDescr.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
  );

  return (
    <>
      <PaymentsSubheader searchTerm={searchTerm} onSearch={setSearchTerm} />
      <ArchiveHeader />
      <ArchiveBody filteredArr={filteredArr}/>
      <Pagination />
    </>
  );
};

export default Archive;
