import { api } from "../api/api";
import {
  GET_EVENT_POSITION_SUCCESS,
  GET_EVENT_POSITION_ERROR,
  GET_CLUBS_LIST_SUCCESS,
  GET_CLUBS_LIST_ERROR,
  GET_TRAINERS_LIST_SUCCESS,
  GET_TRAINERS_LIST_ERROR,
} from "../reducers/positions.reducer";


export const getEventPosition = () => {
  return (dispatch) => {
    api.regposition.getEventPosition()
      .then(res => {
        dispatch(getEventPositionSuccess(res.data));
      })
      .catch(err => {
        console.log(err);
        dispatch(getEventPositionError("something-wrong"));
      });
  };
};
export const getClubsList = () => {
  return (dispatch) => {
    api.regposition.getClubsList()
      .then(res => {
        dispatch(getClubsListSuccess(res.data));
      })
      .catch(err => {
        console.log(err);
        dispatch(getClubsListError("something-wrong"));
      });
  };
};
export const getTrainersList = () => {
  return (dispatch) => {
    api.regposition.getTrainersList()
      .then(res => {
        dispatch(getTrainersListSuccess(res.data));
      })
      .catch(err => {
        console.log(err);
        dispatch(getTrainersListError("something-wrong"));
      });
  };
};


const getEventPositionSuccess = (payload) => {
  return {
    type: GET_EVENT_POSITION_SUCCESS,
    payload,
  };
};
const getEventPositionError = (err) => {
  return {
    type: GET_EVENT_POSITION_ERROR,
    err,
  };
};
const getClubsListSuccess = (payload) => {
  return {
    type: GET_CLUBS_LIST_SUCCESS,
    payload,
  };
};
const getClubsListError = (err) => {
  return {
    type: GET_CLUBS_LIST_ERROR,
    err,
  };
};
const getTrainersListSuccess = (payload) => {
  return {
    type: GET_TRAINERS_LIST_SUCCESS,
    payload,
  };
};
const getTrainersListError = (err) => {
  return {
    type: GET_TRAINERS_LIST_ERROR,
    err,
  };
};











