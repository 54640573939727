import React from 'react';
import styled from 'styled-components';
import { useResizeMode } from '../hooks/useResizeMode';

const BlockItemTitleStyled = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  color: #445566;
  ${({partner}) => partner ? 'padding-left: 35px' : '0'};
  span {
    margin-right: 25px;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #778899;
  }
  img {
    margin-right: 10px;
  }

  @media(max-width: 576px) {
    font-size: 15px;
    line-height: 24px;
    img {
      max-width: 22px;
    }
  }
`;

const SettingsBlockTitle = ({ iconUrl, title, partner=false }) => {

  const isMobile = useResizeMode()

  return (
    <BlockItemTitleStyled partner={partner}>
      {partner && !isMobile ? <span>Статус</span> : <img src={iconUrl} alt={title} />} {title}
    </BlockItemTitleStyled>
  );
};

export default SettingsBlockTitle;
