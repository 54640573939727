import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import BlockDivider from '../../../../../BlockDivider';
import { useResizeMode } from '../../../../../hooks/useResizeMode';

const Canvas = styled.canvas``;

const CanvasRect = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;

  @media(max-width: 576px) {
    top: 0;
  }	
`;

const CanvasWrapper = styled.div`
  position: relative;
  height: 500px;
  padding: 0;

  @media(max-width: 576px) {
    height: ${({isWrapper}) => isWrapper ? '260px' : '500px'};
    padding: 0;
  }	
`;

const HintBlock = styled.div`
display: ${({show, isWrapper}) => show && isWrapper ? 'block' : 'none'};
position: absolute;
top: calc(50% + 43px);
left: 50%;
transform: translate(-50%, -50%);
padding: 12px 11px;
background: rgba(255, 255, 255, 0.7);
border-radius: 5px;
font-weight: 500;
font-size: 13px;
line-height: 16px;
white-space: nowrap;
z-index: 1;
color: #445566;
:after {
  content: '';
  display: block;
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 6px;
  background: url('/icons/checkbox_arrow.svg') center center/cover no-repeat;
  opacity: 0.7;
  z-index: 5;
}

@media(max-width: 576px) {
  display: none;
}	
  
`;

const EditImgBlock = ({ file, imgSrc, isWrapper = false }) => {
  const [canvasPhoto, setCanvasPhoto] = useState(null);
  const [canvasRect, setCanvasRect] = useState(null);
  const [canvasWrapperWidth, setCanvasWrapperWidth] = useState(null);
  const [canvasWrapperHeight, setCanvasWrapperHeight] = useState(null);
  const [mouseDown, setMouseDown] = useState(false);
  const [showHint, setShowHint] = useState(true);
  const isMobile = useResizeMode();
  

  useEffect(() => {
    setCanvasPhoto(document.querySelector('#canvasPhoto'));
    setCanvasRect(document.querySelector('#canvasRect'));
    setCanvasWrapperWidth(
      parseInt(window.getComputedStyle(document.querySelector('#canvasWrapper')).width)
    );
    setCanvasWrapperHeight(
      parseInt(window.getComputedStyle(document.querySelector('#canvasWrapper')).height)
    );
  }, [canvasPhoto]);

  const drawForWrapper = useCallback(drawRectForWrapper, [
    canvasPhoto,
    canvasRect,
    canvasWrapperWidth,
    isMobile
  ]);
  const drawForAccount = useCallback(drawRectForAccount, [
    canvasRect,
    canvasWrapperWidth,
    canvasPhoto,
  ]);

  useMemo(() => {
    if (canvasPhoto && (file || imgSrc) && canvasRect) {
      const ctx = canvasPhoto.getContext('2d');
      const image = new Image();

      image.onload = () => {
        setShowHint(true);
        const wrapperHeight = canvasWrapperHeight;   // 500-30;
        const wrapperWidth = canvasWrapperWidth;
        canvasPhoto.height = wrapperHeight;

        ctx.clearRect(0, 0, wrapperWidth, wrapperHeight);
        const ratio = image.width / image.height;
        const albumOrintation = ratio >= 1;
        const bigger = image.width > wrapperWidth || image.height > wrapperHeight;
        if (bigger) {
          if (albumOrintation) {

            //розмір під ширину блоку

            // const height = wrapperWidth / ratio;
            // canvasPhoto.height = height < wrapperHeight ? wrapperHeight : height;
            // const y = image.height < wrapperHeight ? (wrapperHeight - height) / 2 : 0;
            // ctx.drawImage(image, 0, y, wrapperWidth, height);

            // з фіксованою висотою
  
            const width = image.height > wrapperHeight ? wrapperHeight * ratio : image.width;
            const height = image.height > wrapperHeight ? wrapperHeight : image.height
            const x = (wrapperWidth - width) / 2;
            const y = (wrapperHeight - height) / 2;
            ctx.drawImage(image, x, y, width, height);
          } else {

            // під ширину блоку, повний по висоті

            // const width = image.width > wrapperWidth ? wrapperWidth : image.width;
            // const height = image.width > wrapperWidth ? wrapperWidth / ratio : image.height;
            // canvasPhoto.height = height;
            // const x = (canvasWrapperWidth - width) / 2;
            // ctx.drawImage(image, x, 0, width, height);

             // з фіксованою висотою

             const width = wrapperHeight * ratio > wrapperWidth ? wrapperWidth : wrapperHeight * ratio;
             const height = wrapperHeight * ratio > wrapperWidth ? wrapperWidth / ratio : wrapperHeight;
             const x = (wrapperWidth - width) / 2;
             const y = (wrapperHeight - height) / 2;
             ctx.drawImage(image, x, y, width, height);
          }
        } else {
          ctx.drawImage(
            image,
            (canvasWrapperWidth - image.width) / 2,
            (wrapperHeight - image.height) / 2,
            image.width,
            image.height
          );
        }
        if (isWrapper) {
          drawForWrapper();
        } else {
          drawForAccount();
        }
      };
      imgSrc ? (image.src = imgSrc) : (image.src = URL.createObjectURL(file));
    }
  }, [
    canvasPhoto,
    canvasRect,
    canvasWrapperWidth,
    drawForWrapper,
    file,
    imgSrc,
    isWrapper,
    drawForAccount,
    canvasWrapperHeight
  ]);

  const onMouseDown = () => {
    setMouseDown(true);
  };

  const onMouseUp = () => {
    setMouseDown(false);
  };

  const onMouseMove = (e) => {
    if (mouseDown) {
      if (isWrapper) {
        moveRectForWrapper(e);
        setShowHint(false);
      } else {
        moveRectForAccount(e);
      }
    }
  };

  const onMouseOut = () => {
    setMouseDown(false);
  };

  function drawRectForAccount() {
    const canvasHeight = canvasPhoto.getBoundingClientRect().height;
    canvasRect.height = canvasHeight;
    canvasRect.width = canvasPhoto.getBoundingClientRect().width;
    const ctxRect = canvasRect.getContext('2d');
    ctxRect.clearRect(0, 0, canvasWrapperWidth, canvasHeight);
    ctxRect.fillStyle = 'rgba(246, 246, 246, 0.5)';
    ctxRect.fillRect(0, 0, canvasWrapperWidth, canvasHeight);
    ctxRect.clearRect((canvasWrapperWidth - 300) / 2, (canvasHeight - 300) / 2, 300, 300);
    ctxRect.strokeStyle = 'rgba(255, 255, 255, 0.5)';
    ctxRect.lineWidth = 2;
    ctxRect.strokeRect((canvasWrapperWidth - 300) / 2, (canvasHeight - 300) / 2, 300, 300);

    ctxRect.strokeStyle = '#223344';
    ctxRect.fillStyle = '#FFF';
    ctxRect.lineWidth = 1;
    ctxRect.strokeRect((canvasWrapperWidth - 300) / 2 - 5, (canvasHeight - 300) / 2 - 5, 10, 10);
    ctxRect.fillRect((canvasWrapperWidth - 300) / 2 - 5, (canvasHeight - 300) / 2 - 5, 10, 10);
    ctxRect.strokeRect(
      (canvasWrapperWidth - 300) / 2 - 5 + 300,
      (canvasHeight - 300) / 2 - 5,
      10,
      10
    );
    ctxRect.fillRect(
      (canvasWrapperWidth - 300) / 2 - 5 + 300,
      (canvasHeight - 300) / 2 - 5,
      10,
      10
    );
    ctxRect.strokeRect(
      (canvasWrapperWidth - 300) / 2 - 5,
      (canvasHeight - 300) / 2 - 5 + 300,
      10,
      10
    );
    ctxRect.fillRect(
      (canvasWrapperWidth - 300) / 2 - 5,
      (canvasHeight - 300) / 2 - 5 + 300,
      10,
      10
    );
    ctxRect.strokeRect(
      (canvasWrapperWidth - 300) / 2 - 5 + 300,
      (canvasHeight - 300) / 2 - 5 + 300,
      10,
      10
    );
    ctxRect.fillRect(
      (canvasWrapperWidth - 300) / 2 - 5 + 300,
      (canvasHeight - 300) / 2 - 5 + 300,
      10,
      10
    );
  }

  function drawRectForWrapper() {
    const rectHeight = isMobile ? 150 : 347;
    const canvasHeight = canvasPhoto.getBoundingClientRect().height;
    canvasRect.height = canvasHeight;
    canvasRect.width = canvasWrapperWidth;
    const ctxRect = canvasRect.getContext('2d');
    ctxRect.clearRect(0, 0, canvasWrapperWidth, canvasHeight);
    ctxRect.fillStyle = 'rgba(246, 246, 246, 0.5)';
    ctxRect.fillRect(0, 0, canvasWrapperWidth, canvasHeight);
    ctxRect.clearRect(0, (canvasHeight - rectHeight) / 2, canvasWrapperWidth, rectHeight);
    ctxRect.strokeStyle = 'rgba(255, 255, 255, 0.5)';
    ctxRect.lineWidth = 2;
    ctxRect.strokeRect(0, (canvasHeight - rectHeight) / 2, canvasWrapperWidth, rectHeight);
    drawHint(ctxRect, (canvasWrapperWidth / 2) -20, (canvasHeight / 2) -20);
  }

  function moveRectForWrapper(e) {
    const canvasHeight = canvasPhoto.getBoundingClientRect().height;
    canvasRect.height = canvasHeight;
    canvasRect.width = canvasPhoto.getBoundingClientRect().width;
    const ctxRect = canvasRect.getContext('2d');
    const offsetY = canvasPhoto.getBoundingClientRect().y;
    const x = 0;
    let y = e.clientY - offsetY - 173;
    if (y < 0) {
      y = 0;
    }
    if (y > canvasHeight - 347) {
      y = canvasHeight - 347;
    }
    ctxRect.clearRect(0, 0, canvasWrapperWidth, canvasHeight);
    ctxRect.fillStyle = 'rgba(246, 246, 246, 0.5)';
    ctxRect.fillRect(0, 0, canvasWrapperWidth, canvasHeight);
    ctxRect.clearRect(x, y, canvasWrapperWidth, 347);
    ctxRect.strokeStyle = 'rgba(255, 255, 255, 0.5)';
    ctxRect.lineWidth = 2;
    ctxRect.strokeRect(x, y, canvasWrapperWidth, 347);
    // drawHint(ctxRect, (canvasWrapperWidth / 2) - 20, y+153)
  }

  function moveRectForAccount(e) {
    const canvasHeight = canvasPhoto.getBoundingClientRect().height;
    canvasRect.height = canvasHeight;
    canvasRect.width = canvasPhoto.getBoundingClientRect().width;
    const offsetX = canvasPhoto.getBoundingClientRect().x;
    const offsetY = canvasPhoto.getBoundingClientRect().y;
    const x = e.clientX - offsetX - 150;
    const y = e.clientY - offsetY - 150;
    const ctxRect = canvasRect.getContext('2d');
    ctxRect.clearRect(0, 0, canvasWrapperWidth, canvasHeight);
    ctxRect.fillStyle = 'rgba(246, 246, 246, 0.5)';
    ctxRect.fillRect(0, 0, canvasWrapperWidth, canvasHeight);
    ctxRect.clearRect(x, y, 300, 300);
    ctxRect.strokeStyle = 'rgba(255, 255, 255, 0.5)';
    ctxRect.lineWidth = 2;
    ctxRect.strokeRect(x, y, 300, 300);
    ctxRect.strokeStyle = '#223344';
    ctxRect.fillStyle = '#FFF';
    ctxRect.lineWidth = 1;
    ctxRect.strokeRect(x - 5, y - 5, 10, 10);
    ctxRect.fillRect(x - 5, y - 5, 10, 10);
    ctxRect.strokeRect(x - 5 + 300, y - 5, 10, 10);
    ctxRect.fillRect(x - 5 + 300, y - 5, 10, 10);
    ctxRect.strokeRect(x - 5, y - 5 + 300, 10, 10);
    ctxRect.fillRect(x - 5, y - 5 + 300, 10, 10);
    ctxRect.strokeRect(x - 5 + 300, y - 5 + 300, 10, 10);
    ctxRect.fillRect(x - 5 + 300, y - 5 + 300, 10, 10);
  }

  function drawHint(ctxRect, x, y) {
    const startX = x;
    const startY = y;
    ctxRect.beginPath();
    ctxRect.moveTo(startX+5, startY);
    ctxRect.lineTo(startX + 35, startY);
    ctxRect.arcTo(startX + 40, startY, startX+40, startY+5, 5);
    ctxRect.lineTo(startX + 40, startY + 35);
    ctxRect.arcTo(startX + 40, startY + 40, startX+35, startY+40, 5);
    ctxRect.lineTo(startX+5, startY + 40);
    ctxRect.arcTo(startX, startY + 40, startX, startY+35, 5);
    ctxRect.lineTo(startX, startY + 5);
    ctxRect.arcTo(startX, startY, startX+5, startY, 5);
    ctxRect.strokeStyle = 'rgba(255, 255, 255, 0.7)';
    ctxRect.fill();
    const image = new Image();
    image.onload = () => {
      ctxRect.drawImage(image, startX+15, startY+7)
    }
    image.src = '/icons/settings_page/arrow_up_down.svg'
  }

  return (
    <CanvasWrapper id="canvasWrapper" isWrapper={isWrapper}>
      <Canvas width={canvasWrapperWidth} height={canvasWrapperHeight} id="canvasPhoto"></Canvas>
      <CanvasRect
        onMouseOut={onMouseOut}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        width={canvasWrapperWidth}
        height={canvasWrapperHeight}
        id="canvasRect"
      ></CanvasRect>
      <BlockDivider />
      <HintBlock show={showHint} isWrapper={isWrapper}>Перетягніть для зміни положення</HintBlock>
    </CanvasWrapper>
  );
};

export default EditImgBlock;
