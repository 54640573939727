import React from 'react';
import styled from 'styled-components';
import {useResizeMode} from '../../../hooks/useResizeMode';

const FileInputStyled = styled.div`
  margin-top: ${({mt}) => mt};
  display: flex;
  align-items: flex-start;

  @media(max-width: 576px) {
    margin-top: 16px;
    flex-direction: column;
    align-items: flex-end;
  }
`;

const BlockTitle = styled.div`
  margin-left: 15px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #445566;

  @media(max-width: 576px) {
    font-size: 14px;
  }
`;

const InputFile = styled.div`
  margin-left: 20px;
  label {
    display: block;
    padding: 8px 20px;
    border: 1.5px solid #1e87a9;
    border-radius: 5px;
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    color: #1e87a9;
    cursor: ${({disabled}) => disabled ? 'unset' : 'pointer'};
    &:hover{
      background: #F1F2F3;

    }
  }
  input {
    display: none;
  }

  @media(max-width: 576px) {
    label {
      font-size: 15px;
      padding: 8px 16px;
    }
  }
`;

const CheckedFiles = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: column;

  @media(max-width: 576px) {
    margin-top: 16px;
  }
`;

const CheckedFileItem = styled.div`
  position: relative;
  margin-top: 15px;
  padding: 10px 35px 10px 10px;
  min-width: 205px;
  max-width: 320px;
  box-shadow: 0px 0px 2px #d4d4d4;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.07em;
  color: #445566;
  word-break: break-word;
  :first-child {
    margin-top: 0;
  }
  img {
    position: absolute;
    top: 10px;
    right: 12px;
    cursor: pointer;
  }
`;

const ChooseBlock = styled.div`
  display: flex;
  align-items: center;

  @media(max-width: 576px) {
    justify-content: space-between;
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
display: flex;
align-items: center;
`;

const FileInputBlock = ({ onChangeFiles, files, setFiles, disabled, mt='25px'}) => {

  const isMobile = useResizeMode();

  const removeFile = (idx) => {
    setFiles((state) => {
      return [...state.slice(0, idx), ...state.slice(idx + 1)];
    });
  };

  return (
    <FileInputStyled mt={mt}>
      <ChooseBlock>
        <TitleWrapper>
          <img src="/icons/settings_page/file.svg" alt="file" />
          <BlockTitle>Вкласти файли</BlockTitle>
        </TitleWrapper>
        <InputFile disabled={disabled}>
          <label>
            {isMobile ? 'Вибрати' : 'Вибрати з комп’ютера'}
            <input disabled={disabled} type="file" onChange={onChangeFiles} multiple />
          </label>
        </InputFile>
      </ChooseBlock>
      <CheckedFiles>
        {files.map((item, i) => (
          <CheckedFileItem key={i}>
            {item.name}
            <img onClick={() => removeFile(i)} src="/icons/close_icon.svg" alt="close" />
          </CheckedFileItem>
        ))}
      </CheckedFiles>
    </FileInputStyled>
  );
};

export default FileInputBlock;
