import React from 'react';
import styled from 'styled-components';
import BookmarksClubsDetail from './BookmarksClubsDetail';
import BookmarksItemDetail from './BookmarksItemDetail';
import ItemIcon from './ItemIcon';
import LikeBlock from './LikeBlock';

const BookmarksItemStyled = styled.li`
  position: relative;
  margin-top: 10px;
  display: flex;
  padding: 25px;
  background: #ffffff;
  box-shadow: 0px 0px 2px #d4d4d4;
  border-radius: 5px;

  @media (max-width: 576px) {
    width: 100%;
    margin-top: 12px;
    padding: 20px;
    border-radius: 0;
    :first-child {
      margin-top: 0;
    }
  }
`;

const AvatarIcon = styled.div`
  margin-right: 15px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 576px) {
    width: 50px;
    height: 50px;
  }
`;

const BookmarksItem = ({
  avatarUrl,
  userName,
  status,
  place,
  clubName,
  country,
  id,
  isClub,
  isSearch = false,
  liked,
  managerType,
}) => {
  return (
    <BookmarksItemStyled>
      <AvatarIcon>
        <img src={avatarUrl} alt="avatar" />
      </AvatarIcon>
      {isClub ? (
        <BookmarksClubsDetail
          clubName={clubName}
          userName={userName}
          country={country}
          managerType={managerType}
        />
      ) : (
        <BookmarksItemDetail userName={userName} status={status} place={place} id={id} />
      )}
      {isSearch ? <LikeBlock liked={liked} />  : <ItemIcon />}
    </BookmarksItemStyled>
  );
};

export default BookmarksItem;
