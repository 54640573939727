let initialState = {
  list: [],
	curentUser: {role: []}
}

const usersProfiles = (state=initialState, action) => {
	switch(action.type) {
		case 'SET-USER-ROLE':
		return {
			...state,
			curentUser: {...state.curentUser, role: action.payload}
		}
		default: 
			return {
				...state
			}
	}
}

export default usersProfiles;




