import React from 'react';
import styled from 'styled-components';
import PopupSubTitle from './PopupSubtitle';
import PopupAddressText from './PopupText';
import { closePopupAddress } from '../../../actions/popupsAction';
import { useDispatch, useSelector } from 'react-redux';


const PopupBlur = styled.div`
height: ${p=>p.show ? '100%' : '0'};
  width: ${p=>p.show ? '100%' : '0'};
  top: 0;
  left: 0;
  position:fixed;
  z-index: 100;
`
const PopupOverlay = styled.div`
  width: ${p=>p.show ? '100vw' : '0'};
  top:0;
  left:0;
  height: ${p=>p.show ? '100vh' : '0'};
  position: absolute;
  z-index: 101;
  background: rgba(0, 0, 0, .4);

`
const PopupAddressStyled = styled.div`
max-width: 612px;
  width: 100%;
  background: #fff;
  min-height: 260px;
  display: ${p => p.show ? 'flex' : 'none'};
position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 1001;
  flex-direction: column;
  border: 1px solid #E7EAED;
  border-radius: 5px;
  @media (max-width: 768px){
    width: 95%;
    
  }
`


const PopupHeader = styled.div`
height: 74px;
  border-bottom:2px solid #E7EAED;;
  display: flex;
  justify-content: space-between;
  padding: 25px;
`
const PopupTitle = styled.h2`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #223344;
`
const PopupClose = styled.img`
  cursor:pointer;
//background: url("cons/popups/popup-close.svg") center center/cover;
`
const PopupContainer = styled.div`
padding: 25px;
`





const PopupAddress = () => {
  const showPopup = useSelector(state => state.popup.showAddressPopup)
  const dispatch = useDispatch()
  const onClosePopup = () => {
    dispatch(closePopupAddress())
  }
  return(
    <PopupBlur show={showPopup}>
      <PopupOverlay show={showPopup}>
    <PopupAddressStyled show={showPopup}>
      <PopupHeader>
        <PopupTitle>
          {'Адреса'}
        </PopupTitle>
        <PopupClose onClick={onClosePopup} src='/icons/popups/popup-close.svg' alt='close-popup'/>
      </PopupHeader>
<PopupContainer>
<PopupSubTitle children={'Юридична адреса:'}/>
<PopupAddressText children={'Україна, 02099, Київ,вул.Ялтинська, будинок 5 - б'}/>
  <PopupSubTitle children={'Фактична адреса:'}/>
<PopupAddressText children={'Україна, 01103, Київ, Залізничне шосе, 3'}/>
<PopupAddressText children={'Код організації: 30386538'}/>
</PopupContainer>
    </PopupAddressStyled>
       </PopupOverlay>
     </PopupBlur>
  )



}
export default PopupAddress;