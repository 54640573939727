import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PopupLayout from "../PopupLayout";
import PopupChoosePhoto from "../PopupChoosePhoto";
import PhotoToAccountHeader from "./PhotoToAccountHeader";
import PopupInputFileBlock from "../PopupInputFileBlock";
import ButtonBlock from "../../../../ButtonBlock";
// import EditImgBlock from './EditImgBlock';
import EditImgBlockTest from "./EditImgBlockTest";
import { useResizeMode } from "../../../../../hooks/useResizeMode";
import PhotoToAccountMobile from "./photoToAccountMobile/PhotoToAccountMobile";
import { listAlertsForAvatar } from "../../../../../../mockData/mockData";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, updateUserPhoto } from "../../../../../../actions/profileAction";

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ withPhoto }) => withPhoto ? "auto" : "100%"};
`;

const FormOverflow = styled.div`
  height: calc(100% - ${({ file }) => file ? "65px" : "205px"});
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #f1f2f3 #fff;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #f1f2f3;
    border-radius: 10px;
  }
`;

const PhotoToAccount = ({ show, setShow }) => {
  const [file, setFile] = useState(null);
  const isMobile = useResizeMode();

  const dispatch = useDispatch();
  const userId = useSelector(state => state.userProfiles?.currentProfile?.user?.id);

  useEffect(() => {
  }, [userId]);

  const onEdit = () => {
    setFile(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setShow(false);
    setFile(null);
    dispatch(updateUserPhoto(
      userId,
      file,
    ));
    setTimeout((
    ) => {dispatch(getUserProfile(userId))},200)

  };

  if (isMobile) {
    return <PhotoToAccountMobile onEdit={onEdit} onSubmit={onSubmit} show={show} setShow={setShow} setFile={setFile} file={file} />;
  }

  return (
    <PopupLayout show={show}>
      <PopupChoosePhoto setFile={setFile} setShow={setShow} file={file}>
        <PhotoToAccountHeader title='Увага! Завантажте лише своє особисте фото.' listItem={listAlertsForAvatar} showDescr={!!!file} />
        <FormOverflow file={!!file}>
          <FormStyled withPhoto={!!file} onSubmit={onSubmit}>
            {file ? <EditImgBlockTest file={file} /> : <PopupInputFileBlock setFile={setFile} file={file} />}
            <ButtonBlock
              submitActive={!!file}
              onEdit={onEdit}
              submitTitle='Зберегти зміни'
              secondButton='Скасувати'
            />
          </FormStyled>
        </FormOverflow>
      </PopupChoosePhoto>
    </PopupLayout>
  );
};

export default PhotoToAccount;
