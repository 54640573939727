import React from 'react';
import styled from 'styled-components';


const HeaderSubTitleStyled = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #445566;
`


const HeaderClubSubTitle = ({children}) => {
  return (
    <HeaderSubTitleStyled>
      {children}
    </HeaderSubTitleStyled>

  )



}

export default HeaderClubSubTitle