import React from 'react';
import AuthContainer from '../../components/auth/AuthContainer';
import AuthSubTitle from '../../components/auth/AuthSubTitle';
import AuthTitle from '../../components/auth/AuthTitle';
import AttentionBlock from '../../components/auth/register/AttentionBlock';
import ButtonsContainer from '../../components/auth/ButtonsContainer';
import ButtonSubmit from '../../components/auth/buttons/ButtonSubmit';
import ButtonStroke from '../../components/auth/buttons/ButtonStroke';
import { useHistory } from 'react-router-dom';
import AlreadyRegistered from '../../components/auth/register/AlreadyRegistered';

const RegisterPage = ({ pd }) => {
  const history = useHistory();

  const onSubmit = (e) => {
    e.preventDefault();

    history.push('/auth/reg_data');
  };

  const onReset = () => {
    history.goBack();
  };

  return (
    <AuthContainer pd320="80px 12px 60px" pd360="80px 0 60px">
      <AuthTitle title="Реєстрація" />
      <AuthSubTitle title="Вас вітає платформа e-phan!" />
      <AttentionBlock />
      <form onSubmit={onSubmit} onReset={onReset}>
        <ButtonsContainer fd480='column'>
          <ButtonSubmit
            isActive
            title="Продовжити"
            width="210px"
            widthSd="312px"
            widthMd="210px"
            width320="296px"
          />
          <ButtonStroke
            title="Скасувати"
            width="210px"
            type="reset"
            widthSd="312px"
            widthMd="210px"
            width320="296px"
            mt="16px"

          />
        </ButtonsContainer>
      </form>
      <AlreadyRegistered />
    </AuthContainer>
  );
};

export default RegisterPage;
