import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ButtonStroke from '../buttons/ButtonStroke';

const LogInFooterStyled = styled.div`
  margin-top: 40px;
  @media (max-width: 480px) {
    margin: ${({m480}) => m480};
    max-width: ${({w480}) => w480};
  }@media (max-width: 360px) {
    //padding: 0 24px;
  max-width: ${({w360}) => w360};
  }
  @media (max-width: 320px) {
    padding: 0 12px;
  }
`;

const FooterTitle = styled.div`
  margin-bottom: 12px;
  padding-left: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #778899;
`;

const LoginFooter = ({m480, w480,w360}) => {
  const history = useHistory();

  return (
    <LogInFooterStyled m480={m480} w480={w480} w360={w360}>
      <FooterTitle>Ще немає акаунта?</FooterTitle>
      <ButtonStroke
        onClick={() => history.push('/auth/register')}
        title="Зареєструватись"
        width="250px"
        w480="312px"
        widthMd="210px"
        w360="296px"
        m480='0 auto'
      />
    </LogInFooterStyled>
  );
};

export default LoginFooter;
