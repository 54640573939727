import { api } from "../api/api";

// export const registUser = (
//   pass1,
//   pass2,
//   phoneNum,
//   email,
//   firstName,
//   lastName,
//   firstNameCyr,
//   lastNameCyr,
//   birthdate,
//   sex,
//   country,
//   region,
//   photo
// ) => {
//   return (dispatch) => {
//     let formData = new FormData();
//
//     formData.append("password", pass1);
//     formData.append("password2", pass2);
//     formData.append("phone_number", phoneNum);
//     formData.append("email", email);
//     formData.append("first_name", firstName);
//     formData.append("last_name", lastName);
//     formData.append("first_name_cyr", firstNameCyr);
//     formData.append("last_name_cyr", lastNameCyr);
//     formData.append("birthdate", birthdate);
//     formData.append("sex", sex);
//     formData.append("country", country);
//     formData.append("region", region);
//     formData.append("photo", photo);
//
//     const config = {
//       headers: { "content-type": "multipart/form-data" },
//     };
//
//     api.users
//       .registerUser(formData, config)
//       .then((res) => {
//         dispatch(registerUserAction(res))
//         }
//       )
//       .catch((err) => {
//         console.warn(err.response.data);
//         dispatch(errorRegisterUser(err));
//       });
//   };
// };


// export const registerUserId = (data ) => {
//   return(dispatch) => {
//     dispatch(setUserId())
//   }
//
// }

export const registUser = (data) => {
  return(dispatch) => {
    dispatch(registerUserAction(data))
  }
}

//---------------------------------------------------DANCER

export const registUserDancer = (
  sportCategory,
  sportCategoryIssued,
  sportCategoryDoc,
  clubs,
  trainers,
  dancingClasses,
  partners,
  user
) => {
  return (dispatch) => {
    let formData = new FormData();

    formData.append("sport_category", sportCategory);
    formData.append("sport_category_issued", sportCategoryIssued);
    formData.append("sport_category_document", sportCategoryDoc);
    formData.append("clubs", clubs);
    formData.append("trainers", trainers);
    formData.append("dancing_classes", dancingClasses);
    formData.append("partners", partners);
    formData.append("events", []);
    formData.append("awards", []);
    formData.append("user", user);
    

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    api.users
      .registerUserDancer(formData, config)
      .then((res) => dispatch(registerUserDancerAction(res)))
      .catch((err) => {
        console.log(err);
        dispatch(errorRegisterDancer(err));
      });
  };
};

//-----------------------------------------------------PARENT

export const registUserParent = (statusPar,kids, user, UserInteger) => {
  return (dispatch) => {
    let data ={
      status:statusPar,
      kids: [{
        user : {
          password: localStorage.getItem('password'),
          password2: localStorage.getItem('password2'),
          phone_number: user.phone_number,
          email: user.email,
          first_name_cyr: user.first_name_cyr,
          last_name_cyr: user.last_name_cyr,
          first_name: user.first_name,
          last_name: user.last_name,
          birthdate: user.birthdate,
          sex: user.sex,
          country: user.country,
          region: user.region
    },
        full_name: kids.name,
        sport_category_issued: kids.sport_category_issued,
        sport_category_document: kids.sport_category_document,
        sport_category: kids.sport_category,
        clubs: [
          kids.clubs,
        ],
        trainers: [
          kids.trainers,
        ],
        dancing_classes: [
          kids.dancing_classes,
        ],
      },],
      user: UserInteger,
    }

api.users
      // .registerUserParent(formData, config)
      .registerUserParent(data)
      .then((res) => dispatch(registerUserParentAction(res)))
      .catch((err) => {
        console.dir(err);
        dispatch(errorRegisterParent(err));
      });
  };
};

//---------------------------------------------------JUDGE

export const registUserJudge = (data) => {
  return (dispatch) => {
    api.users
      .registerUserJudge(data)
      .then((res) => {
        dispatch(registerUserJudgeAction(res));
      })
      .catch((err) => {
        dispatch(errorRegisterJudge(err));
        console.log(err);
      });
  };
};

//---------------------------------------------------TRAINER
export const registUserTrainer = (data) => {
    return(dispatch) => {
        api.users   
            .registerUserTrainer(data)
            .then((res) => {
                dispatch(registerUserTrainerAction(res))
            })
            .catch((err) => {
                dispatch(errorRegisterTrainer(err));
                console.log(err)
            })
    }
}
//---------------------------------------------------CLUB MANAGER

export const registUserClubManager = (
  trainers,
  // clubDocs,
  // clubDocsClub,
  clubName,
  clubAddress,
  clubEmail,
  clubWebsite,
  clubDescription,
  clubCountry,
  clubRegion,
  clubOrganisation,
  user
) => {
  return(dispatch) => {
    const formData = new FormData()
      formData.append("trainers", trainers);
      // formData.append("club.docs", clubDocs);
      formData.append("club.name", clubName);
      formData.append("club.address", clubAddress);
      formData.append("club.email", clubEmail);
      formData.append("club.website", clubWebsite);
      formData.append("club.description", clubDescription);
      formData.append("club.country", clubCountry);
      formData.append("club.region", clubRegion);
      formData.append("club.organisation", clubOrganisation);
      formData.append("user", user);


      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      api.users
          .registerUserClubManager(formData, config)
          .then(res =>
            dispatch(registerUserClubManagerAction(res))
            )
          .catch(err => {
              console.dir(err);
              dispatch(errorRegisterClubMan(err));
          }
            )
  }
}

//---------------------------------------------------EVENT STUFF

export const registUserEventStuff = (data) => {
  return(dispatch) => {
    api.users
          .registerUserPersonal(data)
          .then(res =>
              dispatch(registerUserPersonalAction(res))
            )
          .catch(err => {
            console.log(err)
            dispatch(errorRegisterPersonal(err))
          })

  }
}

//------------------------------------------------- SPONSOR

export const registUserSponsor = (currentUser,
                                  experianceFlag,
                                  dateFirst,
                                  countCompetitions,
                                  compDescr) => {
  return(dispatch) => {
    const formData = new FormData()
    formData.append("user", currentUser);
    formData.append("experience", experianceFlag);
    formData.append("first_event_year", dateFirst);
    formData.append("event_count", countCompetitions);
    formData.append("about_events", compDescr);


    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    api.users
          .registerUserSponsor(formData,config)
          .then(res =>
              dispatch(registerUserSponsorAction(res))
            )
          .catch(err => {
            console.log(err)
            dispatch(errorRegisterSponsor(err))
          })

  }
}

//---------------------------------------------------ACTIONS
const registerUserTrainerAction = (data) => {
    return{
        type: "REGISTER_USER_TRAINER_ACTION",
        payload: data
    }
}
const registerUserJudgeAction = (data) => {
  return {
    type: "REGISTER_USER_JUDGE_ACTION",
    payload: data,
  };
};
const registerUserDancerAction = (data) => {
  return {
    type: "REGISTER_USER_DANCER_ACTION",
    payload: data,
  };
};

const registerUserParentAction = (data) => {
  return {
    type: "REGISTER_USER_PARENT_ACTION",
    payload: data,
  };
};

const registerUserAction = (data) => {
  return {
    type: "REGISTER_USER_ACTION",
    payload: data,
  };
};

const registerUserClubManagerAction = (data) => {
  return {
    type: "REGISTER_CLUB_MANAGER_ACTION",
    payload: data
  }
}

const registerUserPersonalAction = (data) => {
  return {
    type: 'REGISTER_PERSONAL_ACTION',
    payload: data

  }
}

const registerUserSponsorAction = (data) => {
  return {
    type: 'REGISTER_SPONSOR_ACTION',
    payload: data

  }
}
//----------------------------------------ERRORS

const errorRegisterUser = (err) => {
  return {
    type: "ERROR_USER",
    err,
  };
};
const errorRegisterDancer = (err) => {
  return {
    type: "ERROR_DANCER",
    err,
  };
};
const errorRegisterParent = (err) => {
  return {
    type: "ERROR_PARENT",
    err,
  };
};

const errorRegisterJudge = (err) => {
  return {
    type: "ERROR_JUDGE",
    err,
  };
};

const errorRegisterTrainer = (err) => {
    return {
        type: "ERROR_TRAINER",
        err
    }
}

const errorRegisterClubMan = (err) => {
  return {
    type: "ERROR_CLUBMAN",
    err
  }
}

const errorRegisterPersonal = (err) => {
  return {
    type: "ERROR_PERSONAL",
    err
  }
}
const errorRegisterSponsor = (err) => {
  return {
    type: "ERROR_SPONSOR",
    err
  }
}