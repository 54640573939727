import React, { useState } from 'react';
import styled from 'styled-components';
import GalleryMobilePage from '../../galleryMedia/GalleryMobilePage';
import SliderMobileHeader from '../../sliderMobile/SliderMobileHeader';
import EditAlbumBlock from './EditAlbumBlock';
import Photo from '../../photo/Photo';
import CheckBoxBlock from '../../../checkBox/CheckBoxBlock';
import ActionItem from '../../sliderMobile/ActionItem';
import PopupRemovePhoto from '../../../popups/removePhoto/PopupRemovePhoto';
import PopupMoveToAlbum from '../../../popups/moveToAlbum/PopupMoveToAlbum';
import { useHistory } from 'react-router-dom';

const PhotoWrapper = styled.div`
  background: #fff;
`;

const CheckedBlock = styled.div`
display: ${({show}) => show ? 'flex' : 'none'};
	justify-content: space-between;
	padding: 12px 20px;
	background: #FFF;
`;

const CheckedCount = styled.div`
display: flex;
align-items: center;
font-weight: 500;
font-size: 14px;
line-height: 24px;
color: #445566;
img {
	max-width: 13px;
	margin-left: 13px;
}
`;

const CheckedAll = styled.div`
font-weight: 500;
font-size: 14px;
line-height: 24px;
color: #1E87A9;
> div {
	label {
		width: unset;
		height: unset;
		background: unset;
		:after {
			content: unset;
		}
	}
  :hover {
    label {
      background: none;
    }
  }
}
`;

const OptionsBlock = styled.div`
	display: ${({show}) => show ? 'block' : 'none'};
	background: #FFF;
	padding: 4px 20px 20px 20px;
`;

const AlbumEditMobilePage = ({ album, isCreate, show, showPopupRemove, setShowPopupRemove, showPopupMove, setShowPopupMove }) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedPhotos, setCheckedPhotos] = useState([]);

  const history = useHistory();

  const onCheckedAll = (checked) => {
    setCheckedAll(checked);
  };

  const onCheckedPhotos = (id) => {
    if (!checkedPhotos.includes(id)) {
      if (album.photos.length - 1 === checkedPhotos.length) {
        setCheckedAll(true);
      }
      setCheckedPhotos((state) => [...state, id]);
    }
  };

  const onUnCheckedPhotos = (id) => {
    if (checkedPhotos.length) {
      setCheckedAll(false);
      setCheckedPhotos((state) => state.filter((item) => item !== id));
    }
  };

  const onAddALLtoChecked = () => {
    setCheckedPhotos(album.photos.map((item, i) => i));
  };

  const onRemoveAllFromChecked = () => {
    setCheckedPhotos([]);
  };

  const unCheckAll = () => {
    onRemoveAllFromChecked();
    setCheckedAll(false);
  }

  return (
    <GalleryMobilePage show={show}>
      <SliderMobileHeader withClose title={isCreate ? 'Створити Альбом' : 'Редагувати Альбом'} onClose={()=>history.goBack()} />
      <EditAlbumBlock album={album} />
			<CheckedBlock show={checkedPhotos.length}>
				<CheckedCount>
					Вибрано {checkedPhotos.length} фото 
					<img onClick={unCheckAll} src='/icons/close_icon.svg' alt='uncheck all' />
					</CheckedCount>
				<CheckedAll>
				<CheckBoxBlock
            label="Вибрати всі"
            id="advanced"
            value="advanced"
            onCheckedAll={onCheckedAll}
            onAddALLtoChecked={onAddALLtoChecked}
            onRemoveAllFromChecked={()=>{}}
            checkedAll={checkedAll}
            checkedItems={checkedPhotos}
          />
				</CheckedAll>
			</CheckedBlock>
      <PhotoWrapper>
        <Photo
          withCheck
          onCheckedAll={onCheckedAll}
          onCheckedItems={onCheckedPhotos}
					onUnCheckedItems={onUnCheckedPhotos}
          checkedAll={checkedAll}
          checkedItems={checkedPhotos}
          album={album}
        />
				<OptionsBlock show={checkedPhotos.length}>
					<ActionItem onClick={()=>setShowPopupRemove(true)} title='Видалити фото' src='/icons/slider_mobile/cart.svg' />
					<ActionItem title='Обернути ліворуч' src='/icons/slider_mobile/rotate.svg' />
					<ActionItem title='Завантажити фото' src='/icons/slider_mobile/download.svg' />
					<ActionItem onClick={()=>setShowPopupMove(true)} title='Перенести в альбом' src='/icons/slider_mobile/move.svg' />
				</OptionsBlock>
      </PhotoWrapper>
      <PopupRemovePhoto show={showPopupRemove} setShow={setShowPopupRemove} />
      <PopupMoveToAlbum show={showPopupMove} setShow={setShowPopupMove} />
    </GalleryMobilePage>
  );
};

export default AlbumEditMobilePage;
