import React from 'react';
import styled from 'styled-components';
import TabsClubList from './TabsClubList';
import TabsClubLike from '../TabsIcon/TabsClubLike';


const TabsClubStyled = styled.div`
  position: relative;
  padding: 0 15px;
background: #fff;
  height: 70px;
  box-shadow: 0px 0px 2px #D4D4D4;
  border-radius: 0 0 5px 5px;
`




const TabsClub = ( {tabsArr=[] }) => {
  return (
<TabsClubStyled show={tabsArr.length}>
<TabsClubList tabsArr={tabsArr}/>
  <TabsClubLike />
</TabsClubStyled>
  )


}

export default TabsClub