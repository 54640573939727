import React, { useState } from 'react';
import styled from 'styled-components';
import BookmarksContent from '../../bookmarks/bookmarksContent/BookmarksContent';
import BookmarksTabs from '../../bookmarks/bookmarksTabs/BookmarksTabs';
import { organizationsList } from '../../../mockData/mockData';
import SearchFilterOrg from './SearchFilterOrg';

const SearchStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media(max-width: 576px) {
    display: block;
  }
`;

const SearchSidePanel = styled.div`
  width: 29%;
  > div {
    width: 100%;
  }
`;

const SearchOrganizations = () => {

  const [searchTerm, setSearchTerm] = useState('');

  return (
    <SearchStyled>
      <BookmarksContent searchTerm={searchTerm} setSearchTerm={setSearchTerm} content={organizationsList} isSearch isClub managerType='організації' />
      <SearchSidePanel>
        <BookmarksTabs
          title="Пошук"
          tabsArr={[
            { title: 'Користувачі', path: '/search/users' },
            { title: 'Клуби', path: '/search/clubs' },
            { title: 'Організації', path: '/search/organizations' },
          ]}
        />
        <SearchFilterOrg />
      </SearchSidePanel>
    </SearchStyled>
  );
};

export default SearchOrganizations;
