import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import AuthTitle from '../../components/auth/AuthTitle';
import AuthSubTitle from '../../components/auth/AuthSubTitle';
import RegisterContainer from '../../components/auth/register/RegisterContainer';
import RegisterNav from '../../components/auth/register/RegisterNav';
import RegisterFileInputBlock from '../../components/auth/register/RegisterFileInputBlock';
import AuthDivider from '../../components/auth/AuthDivider';
import ButtonsContainer from '../../components/auth/ButtonsContainer';
import ButtonSubmit from '../../components/auth/buttons/ButtonSubmit';
import ValidMessage from '../../components/auth/ValidMessage';

const PageSection = styled.div`
  position: relative;
  padding-bottom: 40px;
`;

const AddDocumentsPage = () => {

  const [files, setFiles] = useState([]);
  const [validate, setValidate] = useState(false);

  const history = useHistory();

  const onChangeFile = (e, setValue) => {
    const fileList = Object.values(e.target.files).filter((item) => isNaN(item));
    setValue(fileList)
  }

  const onRemoveFile = (idx, setValue) => {
    setValue((state) => {
      return [...state.slice(0, idx), ...state.slice(idx + 1)];
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();

    setValidate(true)

    if(!files.length) {
      return
    }

    history.push('/auth/reg_success')
  }


  return (
    <RegisterContainer>
      <RegisterNav backLink='/' currentStep={3} />
      <form onSubmit={onSubmit}>
        <PageSection>
          <AuthTitle fs480='20px' title='Додати документи' />
          <AuthSubTitle fs480='15px' fullWidth title='Будь ласка, завантажте фото свідоцтва про народження або паспорта' />
          <RegisterFileInputBlock 
            files={files}
            multiple
            onChange={(e)=>onChangeFile(e, setFiles)}
            onRemoveFile={(idx)=>onRemoveFile(idx, setFiles)}
          />
          <ValidMessage message={validate && !files.length ? 'Файл не вибрано' : ''} />
          <AuthDivider />
        </PageSection>
        <ButtonsContainer jc1000='center'>
          <ButtonSubmit
            width480='312px'
            width='250px'
            title='Надіслати'
            isActive={files.length}

          />
      </ButtonsContainer>
      </form>
    </RegisterContainer>
  )
}

export default AddDocumentsPage;