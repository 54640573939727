import React from 'react';
import styled from 'styled-components';

const ItemDateStyled = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #556677;
	width: ${({paid}) => paid ? '110px' : '100%'};
`;

const ItemDateMobile = ({ date, dateTitle, paid=false }) => {
  return (
    <ItemDateStyled paid={paid}>
      {dateTitle} {date}
    </ItemDateStyled>
  );
};

export default ItemDateMobile;
