import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import BookmarksTabsItem from '../bookmarks/bookmarksTabs/BookmarksTabsItem';

const TabsMobileStyled = styled.div`
  padding: 8px 20px 28px 0;
  margin-left: 20px;
  background: #f6f6f6;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const OverflowTabs = styled.div`
  width: 480px;
  display: flex;
`;

const SearchTabsMobile = ({ tabsArr }) => {
  const [orderedTabsArray, setOrderedTabsArray] = useState(tabsArr);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    setActiveIndex(orderedTabsArray.findIndex((item) => item.path === location.pathname));
  }, [location.pathname, orderedTabsArray]);

  useEffect(() => {
    if (activeIndex > -1) {
      setOrderedTabsArray((state) => [
        state[activeIndex],
        ...state.slice(0, activeIndex),
        ...state.slice(activeIndex + 1),
      ]);
    }
  }, [activeIndex]);

  return (
    <TabsMobileStyled id="searchTabs">
      <OverflowTabs>
        {orderedTabsArray.map((item, i) => (
          <BookmarksTabsItem
            key={i}
            id={i}
            title={item.title}
            path={item.path}
            orderedTabsArray={orderedTabsArray}
          />
        ))}
      </OverflowTabs>
    </TabsMobileStyled>
  );
};

export default SearchTabsMobile;
