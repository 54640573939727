import { useState, useEffect } from 'react';

export const useValidate = (value, validator, validate, confirmValue) => {
  const [message, setMessage] = useState('');

  useEffect(()=>{
    if(validate) {
      setMessage(validator(value, confirmValue))
    } 
  }, [value, validate, validator, confirmValue]);

  return message
}