import React from 'react';

const UserTableDesktop = ({ data, iconsList, isReward }) => {
  return (
    <>
      <thead>
        <tr>
          <th>Дата</th>
          <th>Назва</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => {
          return (
            <tr key={i}>
              <td>{item.date}</td>
              <td>
                {isReward ? (
                  <div>
                    <img src={iconsList[i] ? iconsList[i] : iconsList[0]} alt={'reward'} />
                    <div>{item.name}</div>
                  </div>
                ) : (
                  `${item.name}`
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

export default UserTableDesktop;
