import React from 'react';
import styled from 'styled-components';
import MobileLayoutHeader from './MobileLayoutHeader';

const LayoutStyled = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #e5e5e5;
  z-index: 10;
`;

const Content = styled.div`
  height: calc(100% - 62px);
  overflow-y: ${({overflow}) => overflow};
  background: #F6F6F6;
`;

const MainMobileLayout = ({ children, show, overflow='auto', ...props }) => {
  return (
    <LayoutStyled show={show}>
      <MobileLayoutHeader {...props} />
      <Content overflow={overflow}>{children}</Content>
    </LayoutStyled>
  );
};

export default MainMobileLayout;
