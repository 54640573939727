import React from 'react';
import styled from 'styled-components';
import BlockDivider from '../BlockDivider';
import ButtonBlock from '../settings/ButtonBlock';

const PopupLayoutStyled = styled.div`
  position: fixed;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  padding-top: 10px;
  align-items: ${({ai}) => ai};
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
  overflow-y: auto;

  @media (max-width: 576px) {
    align-items: ${({ai}) => ai === 'center' ? 'flex-end' : ai};
  }
`;

const Popup = styled.div`
  width: ${({ width }) => width};
  max-width: 930px;
  background: #ffffff;
  box-shadow: 0px 0px 2px #d4d4d4;
  border-radius: 10px;

  @media (max-width: 576px) {
    width: 100%;
    border-radius: 0;
    padding-bottom: 40px;
  }
`;

const PopupHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #445566;
  img {
    max-width: 15px;
    cursor: pointer;
  }

  @media (max-width: 576px) {
    padding: 20px;
    font-size: 18px;
    line-height: 20px;
  }
`;

const Wrapper = styled.div`
  max-height: calc(100vh - 80px);
  overflow: auto;
`;

const ButtonWrapper = styled.div`
  display: ${({ button }) => (button ? 'block' : 'none')};
`;

const PopupLayout = ({
  children,
  show,
  title,
  onAccess,
  onEdit,
  width = '500px',
  button = true,
  ai='center',
  submitTitle='Видалити',
  submitActive=true
}) => {
  return (
    <PopupLayoutStyled show={show} ai={ai}>
      <Popup width={width}>
        <PopupHeader>
          {title}
          <img onClick={onEdit} src="/icons/bookmarks/close.svg" alt="close" />
          <BlockDivider bg="#1E87A9" height="2px" />
        </PopupHeader>
        <Wrapper>
          {children}
          <ButtonWrapper button={button}>
            <ButtonBlock
              submitTitle={submitTitle}
              submitActive={submitActive}
              secondButton="Скасувати"
              onAccess={onAccess}
              onEdit={onEdit}
            />
          </ButtonWrapper>
        </Wrapper>
      </Popup>
    </PopupLayoutStyled>
  );
};

export default PopupLayout;
