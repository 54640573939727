import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SearchResult = styled.div`
  display: ${({ show }) => (show ? 'block;' : 'none;')};
  padding: 10px;
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  max-height: 140px;
  box-shadow: 0px 1px 2px #d4d4d4;
  border-radius: 0 0 10px 10px;
  background: #fff;
`;

const SearchItem = styled.div`
  height: 35px;
  line-height: 35px;
  display: flex;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    color: #445566;
    img {
      margin-right: 15px;
    }
  }
`;

const SearchResultHover = ({ isActive, searchResult, setSearchTerm }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isActive) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [isActive]);

  return (
    <SearchResult show={show}>
      {searchResult.map((item, i) => (
        <SearchItem onClick={() => setSearchTerm('')} key={i}>
          <Link to={item.url}>
            <img src="/icons/search_icon.svg" alt="search" />
            {item.name}
          </Link>
        </SearchItem>
      ))}
    </SearchResult>
  );
};

export default SearchResultHover;
