import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import BlockDivider from '../BlockDivider';
import { useResizeMode } from '../hooks/useResizeMode';
import HoverTitle from '../hovers/HoverTitle';
import MainMobileLayout from '../mobileLayout/MainMobileLayout';
import AlbumItemHeaderMobile from './AlbumItemHeaderMobile';

const PageBlockStyled = styled.div`
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 2px #d4d4d4;
  border-radius: 5px;

  @media(max-width: 576px) {
    margin-top: 28px;
  }
`;

const PageBlockHeader = styled.div`
  position: relative;
  padding: 25px;
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  color: #223344;
  span {
    margin-left: 15px;
    font-weight: 500;
    font-size: 18px;
    color: #1e87a9;
  }

  @media(max-width: 576px) {
    padding: 14px 20px;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.07em;
    span {
      margin-left: 8px;
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

const IconClose = styled.div`
  display: ${({show}) => show ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  width: 36px;
  height: 36px;
  box-shadow: 1px 1px 2px #F6F6F6, -1px -1px 2px #F6F6F6;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background: #F1F2F3;
    .hover {
      display: block;
    }
  }
`;

const UserPageBlock = ({children, title, count, withClose=false, userName, album}) => {

  const history = useHistory();
  const isMobile = useResizeMode();

  if (isMobile && withClose) {
    return (
      <MainMobileLayout show title={`Альбоми ${userName}`} onClose={()=>history.goBack()} >
        <AlbumItemHeaderMobile album={album} />
        {children}
      </MainMobileLayout>
    )
  }

	return (
		<PageBlockStyled>
			<PageBlockHeader>
				{title}<span>{count}</span>
        <IconClose onClick={()=>history.goBack()} show={withClose}><img src='/icons/settings_page/close.svg' alt='close' />
          <HoverTitle title='Закрити' />
        </IconClose>
        <BlockDivider />
			</PageBlockHeader>
			{children}
		</PageBlockStyled>
	)
}

export default UserPageBlock;