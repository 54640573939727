import React from 'react';
import styled from 'styled-components';

const RadioButtonStyled = styled.div`
  display: flex;
  align-items: center;
  @media(max-width: 480px){
    max-width: 312px;
  }
`;

const RadioButtonLabel = styled.label`
  display: block;
  margin-right: 16px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 7px solid #D6DBE0;
  background: ${p => p.checked ? '#0A87AA' : 'transparent'};
  cursor: pointer;
  input {
    display: none;
  }
`;

const RadioButtonTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #223344;
`;

const RadioButton = ({name, checked, setChecked, idx, title}) => {

  const onChange = (checked) => {
    setChecked(state=>{
      return state.map((item, i)=> i === idx ? {...item, checked} : {...item, checked: false})
    })
  }

  return (
    <RadioButtonStyled>
      <RadioButtonLabel checked={checked}>
        <input checked={checked} onChange={(e)=>onChange(e.target.checked)} type='radio' name={name} />
      </RadioButtonLabel>
      <RadioButtonTitle>{title}</RadioButtonTitle>
    </RadioButtonStyled>
  )
}

export default RadioButton;