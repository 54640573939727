import { useState, useEffect } from 'react';

export function useResizeMode() {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function  getInnerWidth() {
      if (window.innerWidth <= 992) {
          setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    getInnerWidth()
    window.addEventListener('resize', getInnerWidth);
    return () => {
        window.removeEventListener('resize', getInnerWidth);
    }
  }, [window.innerWidth]);

  return isMobile;
}