import React from 'react';
import styled from 'styled-components';
import ActionItem from '../../sliderMobile/ActionItem';

const AlbumActionBlockStyled = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  grid-column: 1 / 3;
  padding-top: 24px;
`;

const AlbumStatusesBlock = ({ show }) => {
  return (
    <AlbumActionBlockStyled show={show}>
      <ActionItem title="Відкритий всім" src="/icons/gallery/album/black_statuses/open.svg" />
      <ActionItem title="Для мого клубу" src="/icons/gallery/album/black_statuses/club.svg" />
      <ActionItem title="Для шанувальників" src="/icons/gallery/album/black_statuses/heart.svg" />
      <ActionItem title="Секретний" src="/icons/gallery/album/black_statuses/locked.svg" />
    </AlbumActionBlockStyled>
  );
};

export default AlbumStatusesBlock;
