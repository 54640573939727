import React, { useEffect } from 'react';
import styled from 'styled-components';


const InvalidLoginContainer = styled.div`
display: ${({show,validatePhone,validatePass}) => show && !validatePhone && !validatePass  ? 'flex' : 'none'};
  margin-top: 7px;
  margin-left: 12px;
`
const InvalidLoginImg = styled.img`
margin-right: 8px;
`
const InvalidLoginText = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #C70000;
`

const InvalidLoginOrPass = ({show, validatePhone, validatePass}) => {
  useEffect(() => {},[show,validatePhone,validatePass])
  return(
    <InvalidLoginContainer show={show} validatePhone={validatePhone} validatePass={validatePass}>
      <InvalidLoginImg src="/icons/auth/danger.svg" alt="Невірний логін або пароль"/>
      <InvalidLoginText>
        {'Невірний логін або пароль'}
      </InvalidLoginText>
    </InvalidLoginContainer>
  )
}

export default InvalidLoginOrPass