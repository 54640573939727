import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SearchHover from './SearchHover';

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
`;
const SearchBlockStyled = styled.div`
  max-width: 757px;
  width:100%;
  position: relative;
  height: 22px;
  cursor: pointer;


@media (max-width: 1200px) {
  max-width: 677px;
}

@media (max-width: 1144px) {
  max-width:577px;
}

@media (max-width: 990px) {
  max-width:477px;
}

@media (max-width: 843px) {
  max-width:427px;
}

@media (max-width: 768px) {
  max-width: 464px;
  width: 100%;
  height: 22px;
}
`

const SearchBlockIcon = styled.img`
  box-shadow: -1px -1px 2px #FFFFFF, 1px 1px 2px #E5E5E5;
  -moz-box-shadow: -1px -1px 2px #FFFFFF, 1px 1px 2px #E5E5E5;
  -webkit-box-shadow: -1px -1px 2px #FFFFFF, 1px 1px 2px #E5E5E5;
  width: 22px;
  height: 22px;
  float:right;
`

const SearchBlock = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [show, setShow] = useState(false);
  const searchRef = useRef();
  useEffect(() => {
    document.body.addEventListener('click', hideOutsideOnClickSearch);
    window.addEventListener('scroll', hideOnScrollSearch);

    return () => {
      document.body.removeEventListener('click', hideOutsideOnClickSearch);
      window.removeEventListener('scroll', hideOnScrollSearch);
    };
  }, []);

  const hideOutsideOnClickSearch = (e) => {
    return !e.composedPath().includes(searchRef.current) ? setShow(false) : null;
  };
  const hideOnScrollSearch = useCallback(() => setShow(false), []);


  return (
    <SearchContainer>
      <SearchBlockStyled onClick={() => setShow(true)} show={show} ref={searchRef}>
        {show ?
          <SearchHover show={show} setSearchTerm={setSearchTerm} searchTerm={searchTerm} />
          :
          <SearchBlockIcon src="/icons/icon-search.svg" alt='Поиск'/>}
      </SearchBlockStyled>
    </SearchContainer>
  );
};

export default SearchBlock;
