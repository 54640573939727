import React from 'react';
import styled from 'styled-components';
import HeaderClubLogo from './HeaderClubLogo';
import HeaderClubTitle from './HeaderClubTitle';
import HeaderClubSubTitle from './HeaderClubSubTitle';

const HeaderClubStyled = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 2px #D4D4D4;
  border-radius: 5px;
  
`

const HeaderCLubInfoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 20px 25px;
`
const HeaderClubDevide = styled.div`
  display: block;
  width: 100%;
  height: 3px;
  background: #f2f2f3;
  border-radius: 5px;
`

const HeaderClubTitlesContainer = styled.div`
margin-left: 25px;
`


const HeaderClub = () => {
  return (
<HeaderClubStyled>
  <HeaderCLubInfoContainer>
  <HeaderClubLogo  logo={'/icons/clubs/Club_Logo.svg'}/>

<HeaderClubTitlesContainer>
  <HeaderClubTitle children={'Дуже довга назва клубу (Місто)'}/>
<HeaderClubSubTitle children={'Танцювальний клуб'}/>
</HeaderClubTitlesContainer>
  </HeaderCLubInfoContainer>
  <HeaderClubDevide/>

</HeaderClubStyled>
  )


}

export default HeaderClub