import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const TabsItemStyled = styled.li`
  margin-top: 20px;
  display: ${({item}) => (item === null ? 'none' : 'block')};
  height: 35px;
  :first-child {
    margin-top: 0;
  };
	border: ${({ isActive }) => (isActive ? '2px solid #1e87a9;' : '2px solid transparent;')}
	border-radius: 15px;
  -webkit-tap-highlight-color: transparent;
  ${({ isActive }) =>
    !isActive
      ? `
  :hover {
    border: 2.5px solid rgba(253, 253, 253, 0.75);
    filter: drop-shadow(0px 1px 2px rgba(174, 174, 192, 0.2));
		border: 2px solid #F6F6F6;
		border-radius: 15px;
	}`
      : null}

  @media(max-width: 992px) {
    margin-top: 0;
    position: relative;
    width: 140px;
    margin-right: 20px;
    ${({ isActive }) =>
      !isActive
        ? `
    border: 1px solid #FDFDFD;
    filter: drop-shadow(0px 1px 2px rgba(174, 174, 192, 0.2)) drop-shadow(-1px -1px 1px #FFFFFF) drop-shadow(1px 1px 1px #E5E5E5);
    border-radius: 14px;
    ` : ''}
    a {
      font-weight: ${({ isActive }) => (isActive ? '600' : '500')};
      color: ${({ isActive }) => (isActive ? '#223344' : '#778899')};
    }
    :hover {
      ${({ isActive }) =>
      !isActive
        ? `
    border: 1px solid #FDFDFD;
    filter: drop-shadow(0px 1px 2px rgba(174, 174, 192, 0.2)) drop-shadow(-1px -1px 1px #FFFFFF) drop-shadow(1px 1px 1px #E5E5E5);
    border-radius: 14px;
    ` : ''}
    }
  }
`;

const LinkStyled = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 25px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: #223344;

  @media (max-width: 992px) {
    justify-content: center;
    padding: 0 21px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.09em;
    text-align: center;
    
  }
`;

const TabsItem = ({title, path, isActive, item}) => {
	return (
    <TabsItemStyled isActive={isActive} item={item}>
      <LinkStyled to={item !== undefined ? path : ''}>{title}</LinkStyled>
    </TabsItemStyled>
	)
}

export default TabsItem;