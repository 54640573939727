import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AuthContainer from '../../components/auth/AuthContainer';
import AuthDivider from '../../components/auth/AuthDivider';
import AuthInputPhone from '../../components/auth/AuthInputPhone';
import AuthSubTitle from '../../components/auth/AuthSubTitle';
import AuthTitle from '../../components/auth/AuthTitle';
import ButtonStroke from '../../components/auth/buttons/ButtonStroke';
import ButtonSubmit from '../../components/auth/buttons/ButtonSubmit';
import { useValidate } from '../../components/hooks/useValidate';
import { validatePhone } from '../../utils/validatePhone';
import InputHint from '../../components/auth/InputHint';

const FormStyled = styled.form``;

// const InpupHint = styled.div`
//   padding: 4px 0 0 10px;
//   font-weight: 500;
//   font-size: 13px;
//   line-height: 18px;
//   color: #556677;
// `;

const InputWrapper = styled.div`
  position: relative;
  padding-bottom: 40px;
  @media (max-width: 480px) {
    padding-bottom: ${({pb480}) => pb480};
  }
`;

const ButtonsContainer = styled.div`
  margin-top: 60px;
  display: flex;
  
  //justify-content: space-between;
  @media (max-width: 480px) {
    flex-direction: column;
    min-width: 312px;
    margin: ${({m480}) => m480};
  }
  @media (max-width: 360px) {
    flex-direction: column;
    min-width: 312px;
    margin: ${({m360}) => m360};
    margin-top: ${(p) => p.mt};
  }
  @media (max-width: 320px) {
    flex-direction: column;
    width: 296px;
    margin-top: ${(p) => p.mt};
  }
`;

const ForgotPasswordPage = ({ phoneCode = '+38' }) => {
  const [phone, setPhone] = useState('');
  const [validate, setValidate] = useState(false);

  const history = useHistory();
  const phoneValidMessage = useValidate(phoneCode + phone, validatePhone, validate);

  const onSubmit = (e) => {
    e.preventDefault();
    setValidate(true);

    if (validatePhone(phoneCode + phone)) {
      return;
    }

    history.push('/auth/enter_code');
  };

  const onReset = () => {
    history.push('/auth/login');
  };

  return (
    <AuthContainer fpw600='100%' fpw800='65%' fpw720='80%' fpw560='100%' m480='0 auto' w480='312px' pd360='84px 0px 60px 0px'>
      <AuthTitle w360='296px' small title="Скинути пароль" />
      <AuthSubTitle w360='296px' title="Введіть номер телефону, який ви вказували при реєстрації. На нього прийде код для скидання паролю. " />
      <FormStyled onSubmit={onSubmit} onReset={onReset}>
        <InputWrapper pb480='60px'>
          <AuthInputPhone
            ml360='8px'
            message={phoneValidMessage}
            value={phone}
            setValue={setPhone}
            mtLog="36px"
            w480='312px'
            w360='296px'
          />
          <InputHint title="(у форматі 0981234567)" />
          <AuthDivider />
        </InputWrapper>
        <ButtonsContainer m480='0 auto' m360='0 auto'>
          <ButtonSubmit
            isActive={phone}
            title="Продовжити"
            width="210px"
            widthSd="312px"
            widthMd="210px"
            width320="296px"
            mr12='12px'
            width480='312px'
            w360='296px'
            // m480='0 auto'
          />
          <ButtonStroke
            type="reset"
            title="Скасувати"
            width="210px"
            mt="16px"
            w480="312px"
            // m480='16px auto 0'
            widthMd="210px"
            width320="296px"
            mt480='16px'
            w360='296px'
          />
        </ButtonsContainer>
      </FormStyled>
    </AuthContainer>
  );
};

export default ForgotPasswordPage;
