import React, { useState, useEffect, useCallback } from "react";
import styled from 'styled-components';
import SettingsSection from '../../SettingsSection';
import BlockHeader from '../../BlockHeader';
import InputBlock from './InputBlock';
import BlockDivider from '../../../BlockDivider';
import ButtonBlock from '../../ButtonBlock';
import { useResizeMode } from '../../../hooks/useResizeMode';
import { useDispatch, useSelector } from "react-redux";
import { changeUserPass, clearAlertMessage, clearMessage } from "../../../../actions/loginAction";

const InputGroupe = styled.div`
  position: relative;
  padding: 25px 20px 40px 20px;

  @media (max-width: 576px) {
    padding: 20px;
  }
`;

const FormStyled = styled.form`
@media (max-width: 576px) {
  display: ${({ show }) => (show ? 'block' : 'none')};
}
`;

const ChangePasswordSection = ({ oldPassword = '123456' }) => {
  const [curentPassword, setCurentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitActive, setSubmitActive] = useState(false);
  const [alertCurentMessage, setAlertCurentMessage] = useState('');
  const [alertNewMessage, setAlertNewMessage] = useState('');
  const [alertConfirmMessage, setAlertConfirmeMessage] = useState('');
  const [validate, setValidate] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showContent, setShowContent] = useState(false);

  const isMobile = useResizeMode();

  const dispatch = useDispatch();

  const succesChanged = useSelector(state => state.login?.changePass)
  const errorOldPass = useSelector(state => state.login?.errorPass?.status)


  useEffect(() => {
    if (validate) {

      if (errorOldPass === 400) {
        setAlertCurentMessage(
          <span>
            Невірний поточний пароль.<br></br> Введіть дійсний пароль і повторіть спробу.
          </span>
        );
      } else {
        setAlertCurentMessage('');
      }

      if (
        newPassword.match(/[a-z]+/) &&
        newPassword.match(/[A-Z]+/) &&
        newPassword.match(/[0-9]+/)
      ) {
        if (newPassword.length < 8) {
          setAlertNewMessage('Пароль повинен містити не менше 10 символів.');
        } else {
          setAlertNewMessage('');
        }
      } else if (newPassword.length < 8) {
        setAlertNewMessage(
          'Пароль повинен містити: цифри, великі і малі літери. Пароль повинен містити не менше 10 символів.'
        );
      } else {
        setAlertNewMessage('Пароль повинен містити: цифри, великі і малі літери. ');
      }

      if (newPassword !== confirmPassword) {
        setAlertConfirmeMessage('Паролі не співпадають!');
        setSuccessMessage('');
      } else {
        setAlertConfirmeMessage('');
        setSuccessMessage('Пароль співпадає')
      }
    }

    if (errorOldPass,curentPassword && newPassword && confirmPassword) {
      setSubmitActive(true);
    } else {
      setSubmitActive(false);
    }
  }, [errorOldPass,curentPassword, newPassword, confirmPassword, validate, oldPassword]);

  const onChangeCurentPassword = (e) => {
    setCurentPassword(e.target.value);
    dispatch(clearAlertMessage())

  };

  const onChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const onReset = () => {
    setCurentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setValidate(false);
    setAlertCurentMessage('');
    setAlertNewMessage('');
    setAlertConfirmeMessage('');
    setSuccessMessage('');
  };

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setValidate(true);
    dispatch(changeUserPass(
      curentPassword,
      newPassword,
      confirmPassword
    ))
    setTimeout(() => {
      dispatch(clearMessage())
      },4000)

  },[newPassword,confirmPassword,succesChanged,successMessage]);
useEffect(() => {
  if (succesChanged === 200){
    setSuccessMessage('Пароль успішно змінено')
  }
},[succesChanged,successMessage])
  return (
    <SettingsSection>
      <BlockHeader
        title="Змінити пароль"
        subtitle="Придумайте надійний пароль, який ви не використовуєте в інших місцях."
        divider={isMobile ? (showContent ? true : false) : true}
        show={showContent}
        setShow={setShowContent}
      />
      <FormStyled show={showContent} onReset={onReset} onSubmit={onSubmit}>
        <InputGroupe>
          <InputBlock
            onChange={onChangeCurentPassword}
            value={curentPassword}
            iconUrl="/icons/settings_page/password.svg"
            title="Поточний пароль"
            alertMessage={alertCurentMessage}
          />
          <InputBlock
            onChange={onChangeNewPassword}
            value={newPassword}
            iconUrl="/icons/settings_page/password.svg"
            title="Новий"
            alertMessage={alertNewMessage}
          />
          <InputBlock
            onChange={onChangeConfirmPassword}
            value={confirmPassword}
            iconUrl="/icons/settings_page/password.svg"
            title="Повторіть новий"
            alertMessage={alertConfirmMessage}
            successMessage={successMessage}
          />
          <BlockDivider height="2px" br="2px" />
        </InputGroupe>
        <ButtonBlock
          submitActive={submitActive && !!!alertNewMessage && !!!alertCurentMessage && !!!alertConfirmMessage}
          submitTitle="Зберегти"
          secondButton="Скасувати"
          type="reset"
        />
      </FormStyled>
    </SettingsSection>
  );
};

export default ChangePasswordSection;
