import React, {
  useEffect,
  useState,
} from 'react';
import styled
  from 'styled-components';
import AuthTitle
  from '../../components/auth/AuthTitle';
import AuthSubTitle
  from '../../components/auth/AuthSubTitle';
import RegisterContainer
  from '../../components/auth/register/RegisterContainer';
import RegisterNav
  from '../../components/auth/register/RegisterNav';
import AddInputLink
  from '../../components/auth/register/AddInputLink';
import AuthInputText
  from '../../components/auth/AuthInputText';
import AuthDivider
  from '../../components/auth/AuthDivider';
import InputTitle
  from '../../components/auth/register/InputTitle';
import AuthTextArea
  from '../../components/auth/AuthTextArea';
import RegisterFileInputBlock
  from '../../components/auth/register/RegisterFileInputBlock';
import ButtonSubmit
  from '../../components/auth/buttons/ButtonSubmit';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { registUserDancer } from '../../actions/registerAction';
import AuthSelectForId
  from '../../components/auth/AuthSelectForId';
import { getClubsList, getTrainersList } from "../../actions/positionsAction";
import ChooseClubWithAdd from "../../components/auth/register/ChooseClubWithAdd";
import ChooseTrainerWithAdd from "../../components/auth/register/ChooseTrainerWithAdd";
import { sportCategoryOptions,dancerClassOptions } from "../../mockData/mockData";
import AuthSelectTrainers from "../../components/auth/register/AuthSelectTrainers";

const MainBlock = styled.div`
  position: relative;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    max-width: 432px;
    margin: 0 auto;
  }@media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    max-width: 312px;
    margin: 0 auto;
  }
`;

const AdditionalBlock = styled(MainBlock)`
  padding: 40px 0;
  @media (max-width: 1000px) {
  }
`;

const InputsContainer = styled.div`
  max-width: 432px;
  @media (max-width: 480px){
    max-width: 312px;
  }
`;

const FileBlockTitle = styled.div`
  margin-top: 92px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #223344;
`;

const ButtonContainer = styled.div`
  padding: 60px 0;
  @media (max-width: 1000px) {
    display: ${({ df1000 }) => df1000};
    justify-content: ${({ jc1000 }) => jc1000};
  }
`;


const QuestionaryDancerPage = () => {

  const clubsList = useSelector(state => state.positionsReducer?.clubsList?.results)
  const trainersList = useSelector(state => state.positionsReducer?.trainersList?.results)



  const usersRole = useSelector(state => state.users.curentUser?.role);
  const currentUser = useSelector(state => state.registerUsers.registersUser.data?.pk);
  const [clubs, setClubs] = useState('');
  const [clubs2, setClubs2] = useState('');
  const [clubsId,setClubsId] = useState(0);
  const [clubsNoList, setClubsNoList] = useState([{
    id: 1,
    clubName: '',
    manager: '',
  }, {
    id: 2,
    clubName: '',
    manager: '',
  }]);
  const [dancerClass, setDancerClass] = useState('');
  const [dancerClassId, setDancerClassId] = useState(0);
  const [trainers, setTrainers] = useState('');
  const [trainers2, setTrainers2] = useState('');
 const [newTrainers, setNewTrainers] = useState(false)
 const [newClub, setNewClub] = useState(false)
  const [trainersNoList, setTrainersNoList] = useState(['', '', '']);
  const [trainersId,setTrainersId] = useState(0);
  const [partners, setPartners] = useState(['']);
  const [sportCategory, setSportCategory] = useState('');
  const [sportCategoryId, setSportCategoryId] = useState(0);
  const [organization, setOrganization] = useState('');
  const [document, setDocument] = useState('');
  const [files, setFiles] = useState([]);
  const [validate, setValidate] = useState();

  const history = useHistory();
  const dispatch = useDispatch();

  console.log(trainersId)


  useEffect(() => {
    dispatch(getClubsList())
    dispatch(getTrainersList())
  },[])

  useEffect(() => {
  }, [files, validate]);


  const onAddField = (setValue) => {
    setValue(state => [...state, '']);
  };

  // const onAddSelect = (setValue) => {
  //   setValue([...state, '']);
  // };

  const onAddClub = () => {
    setNewClub(true)
  }
  const onAddTrainers = () => {
    setNewTrainers(true)
  }

  const onAddSelect = (setValue) => {
    setValue(state => [...state,''])
  }

  const onChangeFile = (e) => {
    const fileList = Object.values(e.target.files).filter((item) => isNaN(item));
    setFiles(fileList);
  };

  const onRemoveFile = (idx) => {
    setFiles((state) => {
      return [...state.slice(0, idx), ...state.slice(idx + 1)];
    });
  };


  const onSubmit = (e) => {
    e.preventDefault();

    setValidate(true);
    if (!clubs[0] || !trainers[0] || dancerClass === '') {
      return !validate;
    }

    dispatch(registUserDancer(
      sportCategoryId,
      organization,
      document,
      clubsId,
      trainersId,
      dancerClassId,
      partners,
      currentUser,
    ));

    if (!usersRole.length) {
      history.push('/auth/reg_data');
    } else {
      const idx = usersRole.findIndex(item => item === 'dancer');
      if (usersRole[idx + 1]) {
        history.push(`/auth/questionary_${usersRole[idx + 1]}`);
      } else {
        history.push('/auth/reg_add_doc');
      }
    }
  };


  return (
    <RegisterContainer>
      <RegisterNav
        backLink='/auth/reg_congratulation'
        currentStep={2} />
      <AuthTitle
        mw1000='432px'
        m1000='0 auto'
        fs480='24px'
        title='Анкета танцюриста' />
      <form
        onSubmit={onSubmit}>
        <MainBlock
          mw1000='432px'
          m1000='0 auto'
          df='flex'
          fd='column'>
          <InputsContainer>
            <ChooseClubWithAdd
              setId={setClubsId}
              clubs={clubs}
              setClubs={setClubs}
              clubsNoList={clubsNoList}
              setClubsNoList={setClubsNoList}
              options={clubsList}
              validate={clubs.length !== 0 ? validate : false }
            />
            {
              newClub? <ChooseClubWithAdd
                setId={setClubsId}
                clubs={clubs2}
                setClubs={setClubs2}
                clubsNoList={clubsNoList}
                setClubsNoList={setClubsNoList}
                options={clubsList}
                validate={clubs.length !== 0 ? validate : false }
              /> : <></>
            }
            <AddInputLink
              mw480='312px'
              // show={clubs.length <= 20}
              show={!newClub}
              onClick={() => onAddClub(setClubs)}
              title='Додати ще один клуб' />
            {/*<AddInputLink*/}
            {/*  show={clubs.length <= 1}*/}
            {/*  onClick={() => onAddClub(setClubs)}*/}
            {/*  title='Додати ще один клуб' />*/}
            <AuthSelectForId
              placeholder='Вибрати клас'
              value={dancerClass}
              setId={setDancerClassId}
              setValue={setDancerClass}
              options={dancerClassOptions}
              validate={dancerClass === '' ? validate : false}
              hint='В якому танцювальному класі закінчили змагатися'
            />
          </InputsContainer>
          <InputsContainer>
            <ChooseTrainerWithAdd
              setId={setTrainersId}
              trainers={trainers}
              setTrainers={setTrainers}
              trainersNoList={trainersNoList}
              setTrainersNoList={setTrainersNoList}
              options={trainersList}
              validate={validate}
              // setValue={(value) => setTrainers(state => state.map((partner, j) => i === j ? value : partner)}
            />
            {
              newTrainers ? <ChooseTrainerWithAdd
                setId={setTrainersId}
                trainers={trainers2}
                setTrainers={setTrainers2}
                trainersNoList={trainersNoList}
                setTrainersNoList={setTrainersNoList}
                options={trainersList}
                validate={validate}
                // setValue={(value) => setTrainers(state => state.map((partner, j) => i === j ? value : partner)}
              /> : <></>
            }
            <AddInputLink
              // show={trainers.length <= 2}
              show={!newTrainers}
              onClick={() => onAddTrainers(setTrainers2)}
              title='Додати ще одного тренера' />
            {
              partners.map((item, i) => (
                <AuthInputText
                  key={i}
                  mt='44px'
                  mt1000='36px'
                  value={item}
                  lang={'UA'}
                  setValue={(value) => setPartners(state => state.map((partner, j) => i === j ? value : partner))}
                  hint='Напишіть ім’я та прізвище партнера/ки'
                  placeholder='Партнер/ка'
                />
              ))
            }
            <AddInputLink
              show={partners.length <= 1}
              onClick={() => onAddSelect(setPartners)}
              title='Додати ще партнера/ку' />
          </InputsContainer>
          <AuthDivider />
        </MainBlock>
        <AdditionalBlock>
          <InputsContainer>
            <AuthTitle
              small
              fs480='20px'
              title='Додаткові дані' />
            <AuthSubTitle
              fullWidth
              fs='15px'
              title='Заповнюється лише при наявності' />
            <AuthSelectForId
              mt1000='36px'
              placeholder='Спортивний розряд / звання'
              value={sportCategory}
              setValue={setSportCategory}
              options={sportCategoryOptions}
              setId={setSportCategoryId}
              hint='Виберіть актуальні дані'
            />
            <InputTitle
              title='Ким присвоїно:' />
            <AuthTextArea
              value={organization}
              setValue={setOrganization}
              placeholder='Введіть назву державного органу' />
            <InputTitle
              title='Документ:' />
            <AuthInputText
              mt='12px'
              mt1000='36px'
              hint='Наприклад: наказ №1234 від 01.01.2000р.'
              value={document}
              lang={'UA_Num'}
              setValue={setDocument}
              placeholder='Введіть номер та дату наказу' />
          </InputsContainer>
          <InputsContainer>
            <FileBlockTitle>Для
              підтвердження
              інформації
              необхідно
              вкласти
              сканкопію
              підтверджуючого
              документу:</FileBlockTitle>
            <RegisterFileInputBlock
              validate={validate}
              multiple
              files={files}
              onChange={onChangeFile}
              onRemoveFile={onRemoveFile} />
          </InputsContainer>
          <AuthDivider />
        </AdditionalBlock>
        <ButtonContainer
          jc1000='center'
          df1000='flex'>
          <ButtonSubmit
          isActive={clubs[0] && trainers[0] && dancerClass}
          title='Продовжити'
          width='210px'
          width480='312px'
          />
        </ButtonContainer>
      </form>
    </RegisterContainer>
  );
};

export default QuestionaryDancerPage;