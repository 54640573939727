import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AuthContainer from '../../components/auth/AuthContainer';
import AuthDivider from '../../components/auth/AuthDivider';
import AuthInputPassword from '../../components/auth/AuthInputPassword';
import AuthInputPhone from '../../components/auth/AuthInputPhone';
import AuthSubTitle from '../../components/auth/AuthSubTitle';
import AuthTitle from '../../components/auth/AuthTitle';
import ButtonSubmit from '../../components/auth/buttons/ButtonSubmit';
import LoginFooter from '../../components/auth/logIn/LoginFooter';
import { useValidate } from '../../components/hooks/useValidate';
import { validatePhone } from '../../utils/validatePhone';
import { useSelector, useDispatch } from 'react-redux';
import { validatePassword } from '../../utils/validatePassword';
import { useEffect } from 'react';
// import axios from "axios";
// import jwt_decode from "jwt-decode";
import { userLogin } from '../../actions/loginAction';
import InvalidLoginOrPass from '../../components/auth/logIn/InvalidLoginOrPass';

const FormStyled = styled.form`
  position: relative;
  padding-bottom: 40px;
  @media (max-width: 360px) {
    //padding-bottom: 24px;
    //padding: 0 24px;
  }
  @media (max-width: 320px) {
    //padding-bottom: 24px;
    padding: 0 12px;
  }
`;

const ButtonsBlock = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: start;
  }
`;

const ForgotPasswordLink = styled(Link)`
  margin-left: 28px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0a87aa;
  @media (max-width: 480px) {
    margin-top: ${({mt480}) => mt480};
    margin-left: 16px;
    margin-bottom: ${(p) => p.mb};
  }@media (max-width: 360px) {
    margin-top: 36px;
    margin-left: 16px;
    margin-bottom: ${(p) => p.mb};
  }
`;


const LogInPage = ({ phoneCode = '+38', auth, setAuth }) => {
  const originData = useSelector(state => state.login?.loginSuccess);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [validate, setValidate] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [validPhone, setValidPhone] = useState("");

  const loginSuccess = useSelector(state => state.login?.statusText);

  const phoneValidMessage = useValidate(
    phoneCode + phone,
    validatePhone,
    validate,
  );
  const passwordValidMessage = useValidate(
    password,
    validatePassword,
    validate,
  );
  useEffect(() => {
    if (originData === true) {
      return history.push('/home/information/user');
    }
  }, [originData]);



  const onSubmit = (e) => {
    e.preventDefault();
    setValidate(true);

    if (validatePhone(phoneCode + phone) && validatePassword(password)) {
      return;
    }
    dispatch(userLogin(phoneCode + phone, password));
  };

  useEffect(() => {
    if (loginSuccess === 401) {
      setInvalidLogin(true);
    } else {
      setInvalidLogin(false);
    }
    ;
  }, [loginSuccess]);
  return (
    <AuthContainer w480='312px' m480='0 auto'>
      <AuthTitle fs480='24px' title='Вхід' />
      <AuthSubTitle fs480='15px' title='Вас вітає платформа e-phan!' />
      <FormStyled onSubmit={onSubmit}>
        <AuthInputPhone
          w360='296px'
          ml360='8px'
          validPhone={validPhone}
          setValidPhone={setValidPhone}
          phoneCode={phoneCode}
          message={phoneValidMessage}
          value={phone}
          setValue={setPhone}
          mtLog='36px'
          maxLength='10'
          invalidLogin={invalidLogin}
          setInvalidLogin={setInvalidLogin}
          w480='312px'
        />
        <AuthInputPassword
          withIcon
          w360='296px'
          value={password}
          message={passwordValidMessage}
          setValue={setPassword}
          placeholder='Пароль'
          mtLog='36px'
          w480='312px'
          invalidLogin={invalidLogin}
          setInvalidLogin={setInvalidLogin}
        />

        <InvalidLoginOrPass show={invalidLogin} validatePhone={phoneValidMessage} validatePass={passwordValidMessage}/>

        <ButtonsBlock fd480='column'>
          <ButtonSubmit
            isActive={phone && password}
            title='Увійти'
            width='250px'
            width480='312px'
            w360='296px'
            m480='0 auto'
          />
          <ForgotPasswordLink to='/auth/forgot_password' mb='24px' mt480='36px'>
            Забули пароль?
          </ForgotPasswordLink>
        </ButtonsBlock>
        <AuthDivider ml='24px' w360='296px'/>
      </FormStyled>
      <LoginFooter m480='44px auto 0' w480='312px' w360='296px'/>
    </AuthContainer>
  );
};

export default LogInPage;
