import React from "react";
import styled from "styled-components";
import PaymentsHeader from '../../header/PaymentsHeader';
import CheckBoxBlock from "../../../checkBox/CheckBoxBlock";

const TitleItemStyled = styled.div`
  flex: 1;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #556677;

  @media (max-width: 1200px) {
    flex: 1.2;
  }

  @media (max-width: 992px) {
    margin-left: 5px;
  }
`;

const TitleItemAmmount = styled(TitleItemStyled)`
  flex: 1;
  text-align: right;

  @media(max-width: 1200px) {
    font-size: 15px;
  }

  @media (max-width: 992px) {
    margin-left: 0;
    flex: 1;
  }
`;

const TitleBlockName = styled(TitleItemStyled)`
  flex: 4;
  @media(max-width: 1200px) {
    padding-left: 15px;
  }

  @media (max-width: 992px) {
    margin-left: 0;
    flex: 4;
  }
`;

const CheckBoxWrapper = styled.div`
  flex: 0.45;
`;

const AccountsHeader = ({onCheckedAll, checkedAll, checkedItems, onAddALLtoChecked, onRemoveAllFromChecked}) => {
  return (
    <PaymentsHeader>
      <CheckBoxWrapper>
      <CheckBoxBlock
        checkedItems={checkedItems} 
        checkedAll={checkedAll} 
        onCheckedAll={onCheckedAll}
        onAddALLtoChecked={onAddALLtoChecked}
        onRemoveAllFromChecked={onRemoveAllFromChecked}
        grey={false} id={'advanced'} 
        value={'advanced'}
      />
      </CheckBoxWrapper>
      <TitleItemStyled>Дата</TitleItemStyled>
      <TitleBlockName>Найменування</TitleBlockName>
      <TitleItemAmmount>Сума, грн</TitleItemAmmount>
    </PaymentsHeader>
  );
};

export default AccountsHeader;
