import React from 'react';
import styled from 'styled-components';

const SidePanelStyled = styled.div`
  width: 6.5%;
  height: 100%;
  background: #223344;
  ${({ isRight }) => (isRight ? 'transform: rotate(180deg);' : '')}
  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(241, 242, 243, 0.05);
		img {
			cursor: pointer;
		}
  }
`;

const SliderSidePanel = ({ isRight = false, setCurentSlide, countSlide }) => {

  const onChangeSlide = () => {
    if (isRight) {
      setCurentSlide((slide) => {
        if (slide === countSlide - 1) {
          return 0;
        } else {
          return slide + 1;
        }
      });
    } else {
      setCurentSlide((slide) => {
        if (slide === 0) {
          return countSlide - 1;
        } else {
          return slide - 1;
        }
      });
    }
  };

  return (
    <SidePanelStyled isRight={isRight}>
      <div>
        <img onClick={onChangeSlide} src="/icons/gallery/slider/slider_left.svg" alt="prev slide" />
      </div>
    </SidePanelStyled>
  );
};

export default SliderSidePanel;
