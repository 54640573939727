import React, { useState } from 'react';
import styled from 'styled-components';
import SearchClubInput from './SearchBlock';

const SearchClubStyled = styled.div`
width: 260px;
  height: 164px;
  border: 2px solid #F6F6F6;
  filter: drop-shadow(-1px -1px 1px #FFFFFF) drop-shadow(1px 1px 1px #E5E5E5);
  border-radius: 5px;
`
const SearchClubTitle = styled.h3`
  font-weight: bold;
  font-size: 19px;
  line-height: 25px;
  color: #445566;
padding: 20px 15px;
`
const SearchClubDevide = styled.div`
  max-width: 240px;
  height: 2px;
  background: #F6F6F6;
  box-shadow: -1px -1px 2px #FFFFFF, 1px 1px 2px #E5E5E5;
  border-radius: 5px;
  margin: 0px auto 25px;
  ;
`

const SearchClub = () => {
  const [searchTerm, setSearchTerm] = useState('')
  return(
    <SearchClubStyled>
      <SearchClubTitle>
        {'Пошук по клубу'}
      </SearchClubTitle>
      <SearchClubDevide/>
      <SearchClubInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
    </SearchClubStyled>
  )
};
export default SearchClub;