let initialState = {
  error: '',
  loading: false,
  albumPhotos: [],
  itsAlbum: false,
  dataPhoto: {
    id: '',
    title: '',
    gallery: 0,
    photos: [],
  },
  dataAlbum: {
    id: '',
    title: '',
    gallery: 0,
    photos: [],
  },

};

const userGallery = (state = initialState, action) => {
  switch (action.type) {
    case 'GET-USER-ALBUM-REQUEST':
      return {
        ...state,
        loading: true,
        error: '',
      }

    case  'GET-USER-ALBUM-SUCCESS':
      return {
        ...state,
        loading: false,
        dataAlbum: action.payload,
        error: '',

      };
    case 'GET-USER-ALBUM-ERROR':
      return {
        ...state,
        loading: false,
        error: action.err,
      };
      case 'ADD-USER-ALBUM-REQUEST':
      return {
        ...state,
        loading: true,
        error: '',
      }

    case  'ADD-USER-ALBUM-SUCCESS':
      return {
        ...state,
        loading: false,
        // dataAlbum: action.payload,
        dataAlbum:{
          ...state.dataAlbum,
          results: { ...state.dataAlbum.results, photos:  [...state.dataPhoto.results.photos, action.payload]}
        } ,
        error: '',

      };
    case 'ADD-USER-ALBUM-ERROR':
      return {
        ...state,
        loading: false,
        error: action.err,
      };
    case 'GET-USER-PHOTOS-REQUEST':
      return {
        ...state,
        loading: true,
        error: '',
      }

    case 'GET-USER-PHOTOS-SUCCESS':
      return {
        ...state,
        loading: false,
        dataPhoto: action.payload,
        error: '',
      };
    case 'GET-USER-PHOTOS-ERROR':
      return {
        ...state,
        loading: false,
        error: action.err,
      };
    case 'DELETE-USER-PHOTO-REQUEST':
      return {
        ...state,
        loading: true,
        error: '',
      };
      case 'DELETE-USER-PHOTO-SUCCESS':
      return {
        ...state,
        loading: false,
        dataPhoto:{
          ...state.dataPhoto,
          results: { ...state.dataPhoto.results, photos:  [...state.dataPhoto.results.photos, action.payload]}
        } ,
        error: '',
      }
      case 'DELETE-USER-PHOTO-ERROR':
      return {
        ...state,
        error: action.err,
        loading: false,
      }

    case 'ADD-USER-PHOTO-REQUEST':
      return {
        ...state,
        loading: true,
        error: '',
      };
    case 'ADD-USER-PHOTO-SUCCESS':
      return {
        ...state,
        loading: false,
        dataPhoto:{
          ...state.dataPhoto,
          results: { ...state.dataPhoto.results, photos:  [...state.dataPhoto.results.photos, action.payload]}
        } ,
        error: '',
      }
    case 'ADD-USER-PHOTO-ERROR':
      return {
        ...state,
        error: action.err,
        loading: false,
      }
    case 'ADD-PHOTOS-TO-ALBUM':
      return {
        ...state,
        albumPhotos: [...state.albumPhotos, action.payload],
        loading: false
      }
    case 'CHANGE-ITS-ALBUM':
      return {
        ...state,
        itsAlbum: action.payload
      }


    default:
      return {
        ...state,
      };
  }

};

export default userGallery;

