import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { sportCategory } from '../../../mockData/mockData';
import { useSelector } from 'react-redux';

const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  :after {
    content: '';
    display: block;
    margin-left: 20px;
    width: 16px;
    height: 8px;
    background: url('/icons/dancer_inform/arrow_black.svg') center center/cover no-repeat;
    ${({ show }) => (show ? 'transform: rotate(180deg);' : null)}
  }
`;

const HoverBlock = styled.div`
  display: ${({ show }) => (show ? 'block;' : 'none;')};
`;

const SubTitle = styled.div`
  margin-top: 5px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #1e87a9;
  @media(max-width: 576px) {
    font-size: 13px;
    line-height: 18px;
  }
`;

const Descr = styled.div`
  margin-top: 5px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #445566;

  @media(max-width: 576px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const SportCategory = () => {
  const [show, setShow] = useState(false);
  const dancerInfo =useSelector(state => state.userProfiles?.currentProfile?.dancer)

  const [sportCategory,setSportCategory] = useState('');
  const [sportCategoryIssued,setSportCategoryIssued] = useState('');
  const [sportCategoryDocument,setSportCategoryDocument] = useState('');


useEffect(() => {
  setSportCategory(() => dancerInfo?.sport_category);
  setSportCategoryIssued(() => dancerInfo?.sport_category_issued);
  setSportCategoryDocument(() => dancerInfo?.sport_category_document);

},[dancerInfo])
  return (
    <>
      <Title show={show} onClick={() => setShow((state) => !state)}>
        {sportCategory}
      </Title>
      <HoverBlock show={show}>
        <SubTitle>Ким присвоїно</SubTitle>
        <Descr>{sportCategoryIssued}</Descr>
        <SubTitle>Документ</SubTitle>
        <Descr>{sportCategoryDocument}</Descr>
      </HoverBlock>
    </>
  );
};

export default SportCategory;
