import React from 'react';
import Search from '../Search';
import { searchList } from '../../../mockData/mockData';


const SearchUsers = () => {
	return (
		<Search searchList={searchList}/>
	)
}

export default SearchUsers;
