import React from "react";
import styled from "styled-components";

import Menu from "../menu/Menu";
import AdvancedSearchButton from "./AdvancedSearchButton";

const SidePanelStyled = styled.div`
  width: 22%;
  padding-bottom: 20px;
  margin-top: 70px;

  @media(max-width: 1200px) {
    width: 26%;
  }

  @media(max-width: 768px) {
    display: none;
  }

`;

const SidePanel = () => {
  return (
    <SidePanelStyled>
      <Menu />
      <AdvancedSearchButton  />
    </SidePanelStyled>
  );
};

export default SidePanel;
