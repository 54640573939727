import React from 'react';
import styled from 'styled-components';

const ItemTitle = styled.div`
  font-weight: 600;
  font-size: 19px;
  line-height: 30px;
  color: #223344;

  @media(max-width: 576px) {
    font-size: 17px;
    line-height: 24px;
  }
`;

const DetailBlock = styled.div`
  display: flex;
  margin-top: 5px;
  div {
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #445566;
    :first-child {
      width: ${({isOrganization}) => isOrganization ? '180px' : '135px'};
      margin-right: 15px;
      font-weight: 500;
      font-size: 15px;
      color: #1e87a9;
    }
  }

  @media(max-width: 576px) {
    margin-top: 8px;
    div{
      font-size: 15px;
      line-height: 20px;
      :first-child {
        width: ${({isOrganization}) => isOrganization ? '100px' : '117px'};
        margin-right: 12px;
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
`;

const BookmarksClubsDetail = ({ clubName, userName, country, managerType='клубу' }) => {
  return (
    <div>
      <ItemTitle>{clubName}</ItemTitle>
      <DetailBlock isOrganization={managerType === 'організації'}>
        <div>Керівник {managerType}</div>
        <div>{userName}</div>
      </DetailBlock>
      <DetailBlock isOrganization={managerType === 'організації'}>
        <div>Країна</div>
        <div>{country}</div>
      </DetailBlock>
    </div>
  );
};

export default BookmarksClubsDetail;
