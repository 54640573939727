import { api } from '../api/api';
import jwt_decode from 'jwt-decode';
import { getUserProfile, setUserProfile } from './profileAction';
import { CHANGE_USER_PASS_ERROR, CHANGE_USER_PASS_SUCCESS, CLEAR_ALERT_MESSAGE, CLEAR_MESSAGE } from "../reducers/login.reducer";

export const userLogin = (phone_number, password) => {
  return (dispatch) => {
    dispatch(loginRequest());

    api.users
      .login(phone_number, password)
      .then(res => {
        localStorage.setItem('access', res.data.access);
        localStorage.setItem('refresh', res.data.refresh);
        const tokenDecode = jwt_decode(res.data.access);
        console.log(tokenDecode);
        dispatch(loginSuccess({
          id: tokenDecode.user_id,
          phone_number: tokenDecode.phone_number,
          phone: tokenDecode.phone_number,
        }));
        dispatch(getUserProfile(tokenDecode.user_id));
      })
      .catch(err => {
        console.dir(err);
        dispatch(loginError(err));
        dispatch(loginStatus(err.response?.status));
      });
  };
};
//----------------------------------------CheckAuth

export const checkAuth = () => {
  return (dispatch) => {
    if (localStorage.getItem('refresh')) {
      api.users
        .refresh()
        .then((res) => {
          const tokenDecode = jwt_decode(res.data.access);
          dispatch(
            loginSuccess({
              id: tokenDecode.user_id,
              phone_number: tokenDecode.full_name,
              phone: tokenDecode.phone,
            }),
          );
          dispatch(getUserProfile(tokenDecode.user_id));

        })
        .catch((err) => {
          console.log(err);
          localStorage.removeItem('access');
          localStorage.removeItem('refresh');
        });
    }
  };
};


//----------------------------------------Logout

export const userLogOut = (refresh) => {
  return (dispatch) => {
    dispatch(logOutRequest());

    api.users
      .logOut(refresh)
      .then(res => {
        console.log(res.data);
        dispatch(logOutSuccess(res));
        // localStorage.removeItem('access');
        // localStorage.removeItem('refresh');
        // localStorage.removeItem('password');
        // localStorage.removeItem('password2');
        // localStorage.removeItem('email');
        localStorage.clear();
      })
      .catch(err => {
        console.dir(err.response);
        dispatch(logOutError(err));
      });
  };
};

export const userLoginStatusReset = () => {
  return (dispatch) => {
    dispatch(loginStatusReset());
  };
};


//-----------------------------------------Varify

export const userVarification = (code) => {
  return (dispatch) => {
    api.users
      .loginVarify(code)
      .then(res => {
        dispatch(userVarifyAction(res.status));
      })
      .catch(err => {
        console.dir(err);
        dispatch(userVarifyAction(err.response.status));
      });
  };
};

//-------------------------------------ChangePass

export const changeUserPass = (old_password,password,password2) => {
  return(dispatch) => {
    api.users.changePass(old_password, password,password2)
      .then(res => {
        dispatch(changeUserPassSuccess(res.status))
        console.log(res.status)
      })
      .catch(err => {
        console.log(err.response.data)
        dispatch(changeUserPassError(err.response))
      })

  }
}

export const clearMessage = () => {
 return(dispatch) => {
   dispatch(clearMessageSuccess())
 }
}
export const clearAlertMessage = () => {
 return(dispatch) => {
   dispatch(clearAlertMessageSuccess())
 }
}




const loginRequest = () => {
  return {
    type: 'LOGIN-REQUEST',
  };
};

const loginSuccess = (user) => {
  return {
    type: 'LOGIN-SUCCESS',
    payload: user,
  };
};
const loginStatus = (data) => {
  return {
    type: 'LOGIN-STATUS',
    payload: data,
  };
};

const loginStatusReset = () => {
  return {
    type: 'LOGIN-STATUS-RESET',
  };
};

const loginError = (err) => {
  return {
    type: 'LOGIN-ERROR',
    payload: err,
  };
};

const userVarifyAction = (data) => {
  return {
    type: 'VARIFICATION',
    payload: data,
  };
};

const logOutRequest = () => {
  return {
    type: 'LOGOUT-REQUEST',
  };
};

const logOutSuccess = () => {
  return {
    type: 'LOGOUT-SUCCESS',
  };
};

const logOutError = (err) => {
  return {
    type: 'LOGOUT-ERROR',
    payload: err,
  };
};

export const changeUserPassSuccess = (data) =>{
  return{
    type: CHANGE_USER_PASS_SUCCESS,
    payload: data,
  }
}

export const changeUserPassError = (err) => {
  return{
    type: CHANGE_USER_PASS_ERROR,
    err
  }
}

export const clearMessageSuccess = () => {
  return{
    type: CLEAR_MESSAGE
  }
}

export const clearAlertMessageSuccess = () => {
  return{
    type: CLEAR_ALERT_MESSAGE
  }
}