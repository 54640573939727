import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AuthDivider from "../AuthDivider";
import AuthInputPhone from "../AuthInputPhone";
import AuthInputText from "../AuthInputText";
import AuthSelect from "../AuthSelect";
import AuthTitle from "../AuthTitle";
import InputTitle from "./InputTitle";
import AddInputLink from "./AddInputLink";
import ChoosePartnerWithAdd from "./ChoosePartnerWithAdd";
import DownloadPhotoBlock from "./DownloadPhotoBlock";
import AuthSubTitle from "../AuthSubTitle";
import RegisterFileInputBlock from "./RegisterFileInputBlock";
import AuthTextArea from "../AuthTextArea";
import AuthInputDate from "../AuthInputDate";
import AuthSelectForId from "../AuthSelectForId";
import { useValidate } from "../../hooks/useValidate";
import { validateName } from "../../../utils/validateName";
import { validateLastName } from "../../../utils/validateLastName";
import { validateNameLatin } from "../../../utils/validateNameLatin";
import { validateLastNameLatin } from "../../../utils/validateLastNameLatin";
import { validateDate } from "../../../utils/validateDate";
import AuthSelectLocations from "./AuthSelectLocations";
import { useDispatch, useSelector } from "react-redux";
import ChooseClubWithAdd from "./ChooseClubWithAdd";
import ChooseTrainerWithAdd from "./ChooseTrainerWithAdd";
import { getClubsList, getTrainersList } from "../../../actions/positionsAction";
import { sportCategoryOptions,dancerClassOptions } from "../../../mockData/mockData";



const WhoYouAreBlock = styled.div`
  position: relative;
  padding-bottom: 40px;
  @media (max-width: 1000px) {
    max-width: 432px;
    margin: 0 auto;
  }
  @media (max-width: 480px) {
    max-width: 312px;
  }
  @media (max-width: 360px) {
    max-width: 296px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1000px) {
    flex-direction: ${({ fd }) => fd};
  }
`;

const InputContainer = styled.div`
  max-width: 432px;
  @media (max-width: 480px) {
    max-width: 312px;
    margin: 0 auto;
  }
  @media (max-width: 480px) {
    max-width: 296px;
  }


`;

const SectionContainer = styled.div`
  padding: 40px 0;
  position: relative;
  @media (max-width: 1000px) {
    max-width: ${({ mw1000 }) => mw1000};
    margin: ${({ m1000 }) => m1000};
    display: ${({ df }) => df};
    flex-direction: ${({ fd }) => fd};
  }
  @media (max-width: 480px) {
    max-width: 312px;
    margin: 0 auto;
  }
  @media (max-width: 360px) {
    max-width: 296px;
  }
`;

const FileBlockTitle = styled.div`
  margin-top: 36px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #223344;
`;

const AddChildBlock = styled.div`
  padding-bottom: 16px;
  position: relative;
  @media (max-width: 480px) {
    max-width: 312px;
    margin: 0 auto;
  }
  @media (max-width: 360px) {
    max-width: 296px;
  }
`;

const Wrapper = styled.div`
  margin-top: 40px;
  @media (max-width: 480px) {
    max-width: 312px;
    margin: 0 auto;
  }
  @media (max-width: 360px) {
    max-width: 296px;
  }
`;

const DancerDataBlock = styled.div`
  margin-top: 44px;
  display: grid;
  grid-template-columns: repeat(2, 432px);
  justify-content: space-between;
  row-gap: 44px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 432px);
    justify-content: center;
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 312px);
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 296px);
  }
`;

const QuestionaryParentBlock = ({
                                  setChilds,
                                  validate,
                                  onSubmitCheck,
                                  setOnSubmitCheck,
                                  setValidate,
                                  idx,
                                  setKids,
                                  setUser,
                                  setParents,
                                  formValid,
                                  setFormValid,
                                  countryList,
                                  regionList,
                                }) => {
  const [parent, setParent] = useState("");
  const [name, setName] = useState("");
  const [email] = useState("");
  const [nameLatin, setNameLatin] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameLatin, setLastNameLatin] = useState("");
  const [birthday, setBirthday] = useState("");
  const [country, setCountry] = useState("");
  const [countryId, setCountryId] = useState(0);
  const [region, setRegion] = useState("");
  const [regionId, setRegionId] = useState("");
  const [phone, setPhone] = useState("");
  const [clubs, setClubs] = useState("");
  const [newClub, setNewClub] = useState(false)
  const [clubs2, setClubs2] = useState('');
  const [clubsId, setClubsId] = useState(0);
  const [clubsNoList, setClubsNoList] = useState([{
    id: 1,
    clubName: '',
    manager: '',
  }, {
    id: 2,
    clubName: '',
    manager: '',
  }]);

  const [trainers, setTrainers] = useState("");
  const [trainers2, setTrainers2] = useState('');
  const [newTrainers, setNewTrainers] = useState(false)
  const [trainersId, setTrainersId] = useState(0);
  const [trainersNoList, setTrainersNoList] = useState(['', '', '']);
  const [dancerClass, setDancerClass] = useState("");
  const [dancerClassId, setDancerClassId] = useState(0);
  const [sex, setSex] = useState("");
  const [partners, setPartners] = useState([""]);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [docFiles, setDocFiles] = useState([]);
  const [sportCategory, setSportCategory] = useState("");
  const [sportCategoryId, setSportCategoryId] = useState(0);
  const [organization, setOrganization] = useState("");
  const [doc, setDoc] = useState("");
  const [confirmDocFiles, setConfirmDocFiles] = useState([]);
  const [phoneCode] = useState("+38");

  const clubsList = useSelector(state => state.positionsReducer?.clubsList?.results)
  const trainersList = useSelector(state => state.positionsReducer?.trainersList?.results)


  const splitted = birthday.includes(".") && birthday !== "" ? birthday.split(".") : "";
  const swapped = splitted ? [splitted[2], splitted[1], splitted[0]] : "";
  const birthdayForm = swapped !== "" ? swapped.join("-") : ""; // заменяет точку в дате на тире для отправки на запроса

  const inputValidName = useValidate(name, validateName, validate);
  const inputValidLastName = useValidate(lastName, validateLastName, validate);
  const inputValidNameLatin = useValidate(nameLatin, validateNameLatin, validate);
  const inputValidLastNameLatin = useValidate(lastNameLatin, validateLastNameLatin, validate);
  const inputValidDate = useValidate(birthday, validateDate, validate);

  const validFiles = (file) => {
    return !!file.match(/.(png|jpeg|PNG|JPEG)$/i);
  };

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getClubsList())
    dispatch(getTrainersList())
  },[])

  useEffect(() => {
    if (
      name &&
      parent &&
      lastName &&
      nameLatin &&
      lastNameLatin &&
      birthday &&
      country &&
      region &&
      photoFiles.length !== 0 &&
      !validateName(name) &&
      !validateLastName(lastName) &&
      !validateNameLatin(nameLatin) &&
      !validateLastNameLatin(lastNameLatin) &&
      validFiles(photoFiles[0]?.name)
      && (docFiles.length === 0 ||
      docFiles[0]?.name?.match(/.(png|jpeg|pdf|PNG|JPEG|PDF)$/i))
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [
    name,
    parent,
    onSubmitCheck,
    lastName,
    nameLatin,
    lastNameLatin,
    email,
    birthday,
    country,
    region,
    formValid,
    validate,
    docFiles,
    photoFiles,
  ]);


  setParents(parent);
  const sexId = () => {
    if (sex === "Чоловіча") {
      return 1;
    } else if (sex === "Жіноча") {
      return 2;
    } else {
      return null;
    }
  };
  const mathRandomMail = (min, max) => {
    return Math.random() * (max - min) + min;
  };
  const mathRandomPhone = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  };

  const onAddClub = () => {
    setNewClub(true)
  }
  const onAddTrainers = () => {
    setNewTrainers(true)
  }

  useEffect(() => {
    setKids({
      full_name: name + lastName,
      clubs: clubsId,
      trainers: trainersId,
      dancing_classes: dancerClassId,
      partners: partners,
      document: docFiles,
      sport_category: sportCategoryId,
      sport_category_issued: organization,
      sport_category_document: doc === "" ? "Hello World" : doc,
    });
    setUser({
      email: `dasdak${mathRandomMail(1, 1000)}@gmail.com`,
      phone_number: phoneCode + (phone === "" ? `067992${mathRandomPhone(1000, 9999)}` : phone),
      first_name: name,
      last_name: lastName,
      first_name_cyr: nameLatin,
      last_name_cyr: lastNameLatin,
      birthdate: birthdayForm,
      sex: sexId(),
      photo: photoFiles,
      country: countryId,
      region: regionId,
    });
  }, [
    name,
    lastName,
    email,
    dancerClassId,
    nameLatin,
    lastNameLatin,
    birthdayForm,
    sex,
    country,
    region,
    phone,
    clubsId,
    trainersId,
    dancerClass,
    partners,
    photoFiles,
    docFiles,
    sportCategory,
    organization,
    doc,
    confirmDocFiles,
    parent,
  ]);


  useEffect(() => {
    if (
      parent &&
      name &&
      nameLatin &&
      lastName &&
      lastNameLatin &&
      birthday &&
      country &&
      clubs &&
      trainers &&
      photoFiles.length &&
      docFiles.length &&
      sportCategory &&
      organization &&
      confirmDocFiles.length
    ) {
      setChilds((state) =>
        state.map((item, i) => (i === idx ? { ...item, valid: true } : item)),
      );
    } else {
      setChilds((state) =>
        state.map((item, i) => (i === idx ? { ...item, valid: false } : item)),
      );
    }
  }, [
    parent,
    name,
    nameLatin,
    lastName,
    lastNameLatin,
    birthday,
    country,
    clubs,
    trainers,
    photoFiles,
    docFiles,
    idx,
    setChilds,

  ]);


  const onAddComponent = (setValue) => {
    setValue((state) => [...state, ""]);
  };

  const onChangeFile = (e, setValue) => {
    const fileList = Object.values(e.target.files).filter((item) =>
      isNaN(item),
    );
    setValue(fileList);
  };

  const onRemoveFile = (idx, setValue) => {
    setValue((state) => {
      return [...state.slice(0, idx), ...state.slice(idx + 1)];
    });
  };
  return (
    <Wrapper>
      <AuthTitle fs480='24px' mw1000='432px' m1000='0 auto' title='Анкета одного з батьків танцюриста' />
      <WhoYouAreBlock>
        <InputContainer>
          <InputTitle title='Хто ви для танцюриста?' />
          <AuthSelect
            mt='16px'
            mt1000='36px'
            placeholder='Хто ви для танцюриста?'
            value={parent}
            setValue={setParent}
            options={["Батько", "Мати", "Інше (опікун, дідусь, бабуся тощо)"]}
            validate={validate}
          />
        </InputContainer>
        <AuthDivider />
      </WhoYouAreBlock>
      <SectionContainer>
        <AuthTitle mw1000='432px' m1000='0 auto' small title='Введіть дані танцюриста:' />
        <DancerDataBlock>
          <AuthInputText
            hint='(як у свідоцтві про народження)'
            value={name}
            lang={"UA"}
            setValue={setName}
            message={inputValidName}
            placeholder='Ім’я дитини укр. мовою'
            validate={validate}
          />
          <AuthInputText
            hint='(як у свідоцтві про народження)'
            value={lastName}
            message={inputValidLastName}
            lang={"UA"}
            setValue={setLastName}
            placeholder='Прізвище дитини укр. мовою'
            validate={validate}
          />
          <AuthInputText
            hint='(як у закордонному паспорті)'
            value={nameLatin}
            message={inputValidNameLatin}
            lang={"ENG"}
            setValue={setNameLatin}
            placeholder='Ім’я дитини латиницею'
            validate={validate}
          />
          <AuthInputText
            hint='(як у закордонному паспорті)'
            value={lastNameLatin}
            message={inputValidLastNameLatin}
            lang={"ENG"}
            setValue={setLastNameLatin}
            placeholder='Прізвище дитини латиницею'
            validate={validate}
          />
          <AuthInputDate
            hint='(дд.мм.рррр)'
            message={inputValidDate}
            value={birthday}
            setValue={setBirthday}
            placeholder='Дата народження дитини'
            validate={validate}
          />
          <AuthSelect
            placeholder='Вибрати стать дитини'
            value={sex}
            setValue={setSex}
            options={["Чоловіча", "Жіноча"]}
            id={[1, 2]}
            iconsArr={["/icons/auth/male.svg", "/icons/auth/female.svg"]}
            validate={validate}
          />
          <InputContainer>
            <AuthSelectLocations
              validate={validate}
              df='flex'
              filtered
              mt='0'
              mtMd='22px'
              placeholder='Вибрати країну'
              options={countryList}
              setId={setCountryId}
              value={country}
              setValue={setCountry}
              m1000='0 auto'
            />
          </InputContainer>
          <InputContainer>
            <AuthSelectLocations
              validate={validate}
              df='flex'
              filtered
              mt='0'
              mtMd='22px'
              placeholder='Вибрати Область'
              options={regionList}
              setId={setRegionId}
              value={region}
              setValue={setRegion}
              m1000='0 auto'
            />
          </InputContainer>
          <div>
            <AuthInputPhone
              mtLog='0'
              value={phone}
              setValue={setPhone}
              hint='За бажанням, для можливості надання окремого входу в акаунт танцюриста'
              maxLength='10'
            />
          </div>
          <div>
            <ChooseClubWithAdd
              mtp='0'
              setId={setClubsId}
              clubs={clubs}
              setClubs={setClubs}
              clubsNoList={clubsNoList}
              setClubsNoList={setClubsNoList}
              options={clubsList}
              validate={clubs.length !== 0 ? validate : false }
            />
            {
              newClub? <ChooseClubWithAdd
                setId={setClubsId}
                clubs={clubs2}
                setClubs={setClubs2}
                clubsNoList={clubsNoList}
                setClubsNoList={setClubsNoList}
                options={clubsList}
                validate={clubs.length !== 0 ? validate : false }
              /> : <></>
            }

            <AddInputLink
              // show={clubs.length <= 20}
              show={!newClub}
              onClick={() => onAddClub(setClubs)}
              title='Додати ще один клуб'
            />
          </div>
          <div>
            <ChooseTrainerWithAdd
              setId={setTrainersId}
              trainers={trainers}
              setTrainers={setTrainers}
              trainersNoList={trainersNoList}
              setTrainersNoList={setTrainersNoList}
              options={trainersList}
              validate={validate}
            />
            {
              newTrainers ? <ChooseTrainerWithAdd
                setId={setTrainersId}
                trainers={trainers2}
                setTrainers={setTrainers2}
                trainersNoList={trainersNoList}
                setTrainersNoList={setTrainersNoList}
                options={trainersList}
                validate={validate}
                // setValue={(value) => setTrainers(state => state.map((partner, j) => i === j ? value : partner)}
              /> : <></>
            }
            <AddInputLink
              // show={trainers.length <= 20}
              show={!newTrainers}
              onClick={() => onAddTrainers(setTrainers)}
              title='Додати ще одного тренера'
            />
          </div>
          <AuthSelectForId
            placeholder='Вибрати клас'
            value={dancerClass}
            setId={setDancerClassId}
            setValue={setDancerClass}
            options={dancerClassOptions}
            validate={dancerClass === '' ? validate : false}
            hint='В якому танцювальному класі закінчили змагатися'
          />
          <div>
            <ChoosePartnerWithAdd
              mt='0'
              partners={partners}
              setPartners={setPartners}
              lang={"UA"}
            />
            <AddInputLink
              show={partners.length <= 1}
              onClick={() => onAddComponent(setPartners)}
              title='Додати ще партнера/ку'
            />
          </div>
        </DancerDataBlock>
        <AuthDivider />
      </SectionContainer>
      <DownloadPhotoBlock
        title='Завантажте фото дитини'
        files={photoFiles}
        onChange={(e) => onChangeFile(e, setPhotoFiles)}
        onRemoveFile={(idx) => onRemoveFile(idx, setPhotoFiles)}
        validate={validate}
        mw1000='432px'
        m1000='0 auto'
      />
      <SectionContainer mw1000='432px' m1000='0 auto'>
        <AuthTitle small title='Додати документи' />
        <AuthSubTitle
          fullWidth
          title='Будь ласка, завантажте фото свідоцтва про народження дитини'
        />
        <RegisterFileInputBlock
          files={docFiles}
          validate={validate}
          onChange={(e) => onChangeFile(e, setDocFiles)}
          onRemoveFile={(idx) => onRemoveFile(idx, setDocFiles)}
          multiple
        />
        <AuthDivider />
      </SectionContainer>
      <SectionContainer mw1000='432px' m1000='0 auto' fd='column' df='flex'>
        <AuthTitle small title='Додаткові дані' />
        <AuthSubTitle fullWidth title='Заповнюється лише при наявності' />
        <InputWrapper fd='column'>
          <InputContainer>
            <AuthSelectForId
              mt1000='36px'
              placeholder='Спортивний розряд / звання'
              options={sportCategoryOptions}
              setId={setSportCategoryId}
              value={sportCategory}
              setValue={setSportCategory}
              hint='Виберіть актуальні дані'
            />
            <InputTitle title='Ким присвоїно:' />
            <AuthTextArea
              placeholder='Введіть назву державного органу'
              value={organization}
              setValue={setOrganization}
            />
            <InputTitle title='Документ:' />
            <AuthInputText
              mt='12px'
              mt1000='36px'
              hint='Наприклад: наказ №1234 від 01.01.2000р.'
              placeholder='Введіть номер та дату наказу'
              lang={"UA_Num"}
              value={doc}
              setValue={setDoc}
              // validate={validate}
            />
          </InputContainer>
          <InputContainer>
            <FileBlockTitle>
              Для підтвердження інформації необхідно вкласти сканкопію
              підтверджуючого документу:
            </FileBlockTitle>
            <RegisterFileInputBlock
              multiple
              files={confirmDocFiles}
              onChange={(e) => onChangeFile(e, setConfirmDocFiles)}
              onRemoveFile={(idx) => onRemoveFile(idx, setConfirmDocFiles)}
            />
          </InputContainer>
        </InputWrapper>
        <AuthDivider />
      </SectionContainer>
      <AddChildBlock>
        <AddInputLink
          onClick={() => {
            setChilds((state) => [...state, { valid: false }]);
            setValidate(false);
          }}
          mt='16px'
          show
          title='Додати ще одну дитину'
          mw1000='432px'
          m1000='16px auto 0'
        />
        <AuthDivider mw1000='432px' l1000='50%' t1000='translateX(-50%)' />
      </AddChildBlock>
    </Wrapper>
  );
};

export default QuestionaryParentBlock;
