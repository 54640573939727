import React from 'react';
import styled from 'styled-components';
import BlockDivider from '../../BlockDivider';
import PopupLayout from '../PopupLayout';

const PopupContent = styled.div`
  position: relative;
  padding: 25px;
  height: 80px;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #223344;

  @media (max-width: 576px) {
    padding: 20px;
    font-size: 15px;
    line-height: 20px;
  }
`;

const PopupRemovePhoto = ({ show, setShow }) => {

	const onAccess = () => {
		setShow(false)
	}

	const onEdit = () => {
		setShow(false)
	}

  return (
    <PopupLayout show={show} setShow={setShow} title="Видалити фото" onAccess={onAccess} onEdit={onEdit}>
      <PopupContent>
        Ви впевнені, що хочете видалити це фото?
        <BlockDivider height="2px" />
      </PopupContent>
    </PopupLayout>
  );
};

export default PopupRemovePhoto;
