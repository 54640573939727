import React from 'react';
import Settings from '../components/settings/Settings';
import Tabs from '../components/tabs/Tabs';

const SettingsPage = () => {
  return (
    <>
      <Tabs
        title="Налаштування"
        tabsArr={[
          { title: 'Загальні', path: '/settings/common' },
          { title: 'Паролі та доступ', path: '/settings/passwords' },
          { title: 'Розширені', path: '/settings/extended' },
        ]}
      />
      <Settings />
    </>
  );
};

export default SettingsPage;
