import React from 'react';
import styled from 'styled-components';

import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { albums, dancersList } from '../mockData/mockData';

const BreadCrumbsStyled = styled.div`
  display: flex;
  padding: 30px 0 20px 0;
  background-color: #f6f6f6;
  overflow-x: auto;
  @media (max-width: 768px) {
    padding: 25px 20px;
  }
  @media(max-width: 768px) {
    
  }
`;

const LinkStyled = styled(Link)`
	display: flex;
	align-items: center;
	margin-right: 15px;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #556677;
	:after {
		content: '';
		display: block;
		background: url('/icons/breadcrumb_arrow.svg') center center/cover no-repeat;
		width: 5px;
		height: 10px;
		margin-left: 15px;
	}
	:last-child {
		:after {
			display: none;
		}
	}

  @media(max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    margin-right: 9px;
    :after {
      margin-left: 9px;
    }

  }
`;

const BreadCrumbs = () => {
  const location = useLocation();
  const matchAlbumId = useRouteMatch('/gallery/album/:id');
  const matchAlbumIdUser = useRouteMatch('/users-albums/all-users/:userId/userGallery/:id');
  const matchUserId = useRouteMatch('/all-users/:id');
  const matchUserIdAlbums = useRouteMatch('/users-albums/all-users/:id');
  const albumId = matchAlbumId?.params.id || matchAlbumIdUser?.params.id;
  const userId = matchUserId?.params.id  || matchUserIdAlbums?.params.id;
  const parsedRoute = location.pathname.split('/').slice(1);

  const routesEnum = {
    home: { name: 'Моя сторінка', url: '/home/information/user' },
    information: { name: 'Інформація', url: '#dancer' },
    user: { name: 'Загальна', url: '/home/information/user' },
    dancer: { name: 'Танцюрист', url: '/home/information/dancer' },
    trainer: { name: 'Тренер', url: '/home/information/trainer' },
    judge: { name: 'Суддя', url: '/home/information/judge' },
    payments: { name: 'Платежі', url: '/payments/accounts' },
    accounts: { name: 'Рахунки', url: '/payments/accounts' },
    paid: { name: 'Сплачені', url: '/payments/paid' },
    archive: { name: 'Архів', url: '/payments/archive' },
    my_club: {name : 'Мій клуб', url: '/my_club/main'},
    main: {name: 'Головна', url: '/my_club/main'},
    trainers: {name: 'Тренери', url: '/my_club/trainers'},
    participants: {name: 'Учасники', url: '/my_club/participants'},
    my_card: { name: 'Електронна картка', url: '/my_card' },
    gallery: { name: 'Фотогалерея', url: '/gallery/photo' },
    userGallery: { name: 'Фотогалерея', url: `/users-albums/all-users/${userId}/userGallery` },
    photo: { name: 'Фото', url: '/gallery/photo' },
    album: { name: 'Альбоми', url: '/gallery/album' },
    edit: { name: 'Редагування', url: '#' },
    create: { name: 'Створити Альбом', url: '#' },
    bookmarks: { name: 'Закладки', url: '/bookmarks/dancers' },
    dancers: { name: 'Танцюристи', url: '/bookmarks/dancers' },
    judges: { name: 'Тренери та судді', url: '/bookmarks/judges' },
    clubs: { name: 'Клуби', url: '#' },
    search: { name: 'Пошук', url: '/search/users' },
    users: { name: 'Користувачі', url: '/search/users' },
    searchDancers: { name: 'Танцюристи', url: '/search/users/searchDancers' },
    searchTrainers: { name: 'Тренери', url: '/search/users/searchTrainers' },
    searchJudges: { name: 'Судді', url: '/search/users/searchJudges' },
    organizations: { name: 'Організації', url: '/search/organizations' },
    settings: { name: 'Налаштування', url: '/settings/common' },
    common: { name: 'Загальні', url: '/settings/common' },
    passwords: { name: 'Паролі та доступ', url: '/settings/passwords' },
    extended: { name: 'Розширені', url: '/settings/extended' },
  };

  const createLink = (route, idx) => {
    const isAlbumId =  route  === albumId;
    const isUserId = parsedRoute[idx - 1] === 'all-users' && route === userId;
    const arr = isAlbumId ? albums : isUserId ? dancersList : null;
    if (arr === true && !undefined) {
      const name = isAlbumId ?
        arr.find((el) => +el.id === +route).name :
      `${arr.find((el) => +el.id === +route).userName}`;
      const link = isAlbumId ? `#` : `/all-users/${userId}/information/user`
      return (
        <LinkStyled key={idx} to={link}>
          {name}
        </LinkStyled>
      );
    } else if (routesEnum[route]) {
      return (
        <LinkStyled key={idx} to={routesEnum[route].url}>
          {routesEnum[route].name}
        </LinkStyled>
      );
    } else {
      return '';
    }
  };

  return <BreadCrumbsStyled>{parsedRoute.map(createLink)}</BreadCrumbsStyled>;
};

export default BreadCrumbs;
