import React from "react";
import styled from "styled-components";
import { useSelector } from 'react-redux';

const ItemIcon = styled.div`
	position: absolute;
	top: 0;
	left: 0;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background: #778899;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  overflow: hidden;
  
  img {
    max-width: 100%;
    height: 100%
  }
`;

const ChooseItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
	margin: 0 auto 10px;
  padding: 15px 12px 15px 45px;
  width: 236px;
  height: 35px;
  background: #ffffff;
  cursor: pointer;
	:last-child {
		margin-bottom: 0;
	}
	:hover {
		background: #F1F2F3;
		border-radius: 25px;
	}
  div {
    font-weight: 600;
    font-size: 15px;
  }
`;

const ChooseAccount = styled.div`
  display: ${({ show }) => (show ? "block;" : "none;")};
	background: #fff;
	padding-bottom: 10px;
`;

const ChooseAccountHover = ({ onChooseAccount, show, accounts, account }) => {
  const userInfo = useSelector(state => state.userProfiles?.currentProfile)
  return (
    <ChooseAccount show={show}>
      {accounts.filter((item) => item.id !== account.id)
        .map((item) => (
          <ChooseItem onClick={onChooseAccount} key={item.id} data-id={item.id}>
            <ItemIcon>
              <img src={`${userInfo?.user?.photo}`} alt="user icon" />
            </ItemIcon>
            <div>{`${userInfo?.user?.first_name} ${userInfo?.user?.last_name}`}</div>
          </ChooseItem>
        ))}
      <ChooseItem>
        <ItemIcon>
          <img src="/icons/add_user.png" alt="user icon" />
        </ItemIcon>
        <div>Додати акаунт</div>
      </ChooseItem>
    </ChooseAccount>
  );
};

export default ChooseAccountHover;
