import React from 'react';
import styled from 'styled-components';

const DividerStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  box-shadow: -1px -1px 2px #ffffff, 1px 1px 2px #e5e5e5;
  border-radius: 5px;
  @media (max-width: 1000px) {
    max-width: ${({mw1000}) => mw1000};
    left: ${({l1000}) => l1000};
    transform: ${({t1000}) => t1000};
    //transform: translateX(-50%);
  }
  @media (max-width: 480px){
    max-width: ${({w480}) => w480};
  }
  @media (max-width: 360px) {
    max-width: ${({w360}) => w360};
    //margin-left: 24px;
    //margin-left: ${(p) => p.mlcd};
  }
  @media (max-width: 320px) {
    width: 296px;
    /* margin-left: ${(p) => p.ml}; */
    margin-left: 12px;
  }
`;

const AuthDivider = ({ ml, mlcd , mw1000, l1000, t1000,w480, w360}) => {
  return <DividerStyled w360={w360} ml={ml} mlcd={mlcd} mw1000={mw1000} w480={w480} l1000={l1000} t1000={t1000}><></></DividerStyled>;
};

export default AuthDivider;
