import React from 'react';
import styled from 'styled-components';
import PaginationItem from './PaginationItem';

const PaginationStyled = styled.div`
	display: ${({show}) => show ? 'block' : 'none'};
  margin-top: 40px;

  @media(max-width: 576px) {
    display: none;
  }
`;

const PaginationList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: flex-end;
`;

const Pagination = ({show=true}) => {
  return (
    <PaginationStyled show={show}>
      <PaginationList>
        <PaginationItem count={1} isActive />
        <PaginationItem count={2} />
        <PaginationItem count={3} />
        <PaginationItem count={<img src="/icons/pagination_arrow.svg" alt="next" />} />
      </PaginationList>
    </PaginationStyled>
  );
};

export default Pagination;
