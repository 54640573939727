import React from 'react';
import styled from 'styled-components';
import BlockDivider from '../BlockDivider';

const SettingsBlockStyled = styled.div`
  position: relative;
  padding: 20px;
  display: ${({isFlex}) => isFlex ? 'flex' : 'block'};
  align-items: flex-start;
  justify-content: space-between;

  @media(max-width: 576px) {
    display: ${({isFlex}) => isFlex ? 'flex' : 'block' };
  }
`;


const SettingsBlock = ({ children, isFlex=true, validate}) => {
  return (
    <SettingsBlockStyled isFlex={isFlex}>
      {children}

      <BlockDivider height="2px" br="2px" />

    </SettingsBlockStyled>
  );
};

export default SettingsBlock;
