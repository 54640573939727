import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

const RegisterNavStyled = styled.div`
  padding: 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1000px){
    max-width: 100%;
    //padding: 32px 40px;
  }
  //@media (max-width: 480px){
  //  padding: 32px 0px;
  //}
`;

const BackLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a0acb7;
  display: flex;
  img {
    margin-right: 11px;
  }

  @media (max-width: 1000px) {
    max-width: 100%;
    img {
      max-width: 8px;
    }
  }
`;

const CirclesBlock = styled.div`
  width: 152px;
  display: ${(p) => (p.show ? 'flex' : 'none')};
  justify-content: space-between;
`;

const CircleItem = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  border: 1.5px solid #bbc3cc;
  border-radius: 100%;
  background: ${(p) => (p.stepChecked ? '#47A5BF' : p.stepCurrentChecked ? '#0A87AA' : 'transparent')};
  cursor: ${(p) => (p.stepChecked ? 'pointer' : 'default')};
  :after {
    content: '';
    width: 16px;
    height: 1.5px;
    background: #bbc3cc;
    position: absolute;
    top: calc(50% - 0.75px);
    left: calc(100% + 8px);
  }
  :last-child {
    :after {
      display: none;
    }
  }
`;

const RegisterNav = ({ backLink, currentStep, onlyBack }) => {
  const history = useHistory();

  const navLinksArr = ['/auth/reg_data', '/auth/reg_code', '#', '#'];

  const onCircleNav = (link, idx) => {
    if (idx <= currentStep) {
      history.push(link);
    }
  };

  return (
    <RegisterNavStyled>
      <BackLink to={backLink}>
        <img src="/icons/auth/arrow-back.svg" alt="arrow"/>
        Назад
      </BackLink>
      <CirclesBlock show={!onlyBack}>
        {navLinksArr.map((item, i) => (
          <CircleItem
            stepCurrentChecked={i === currentStep}
            stepChecked={i < currentStep}
            onClick={() => onCircleNav(item, i)}
            key={i}
          ><></></CircleItem>
        ))}
      </CirclesBlock>
    </RegisterNavStyled>
  );
};

export default RegisterNav;
