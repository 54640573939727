import React, { useState } from "react";
import styled from 'styled-components';

const CheckBoxLabel = styled.label`
  display: block;
  min-width: 24px;
  min-height: 24px;
  border-radius: 100%;
  background: #D6DBE0 ${p => p.checked ? 'url(/icons/auth/checked.svg)' : 'url(/icons/auth/check.svg)'} center top 7px no-repeat;
  cursor: pointer;
  input {
    display: none;
  }
`;



const RegisterCheckBox = ({onClick}) => {

  const [checked,setChecked] = useState(false)

  return (
    <CheckBoxLabel checked={checked}>
      <input checked={checked} onChange={(e) => setChecked(e.target.checked)} type='checkbox' onClick={onClick}/>
    </CheckBoxLabel>
  )
}

export default RegisterCheckBox;