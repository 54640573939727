import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HoverTitle from '../hovers/HoverTitle';

const CheckboxGroupe = styled.div`
	position: relative;
  ${({isAlbumEdit}) => isAlbumEdit ? `
    position: absolute;
    top: 10px;
    left: 10px;
  ` : ''}
	input {
		display: none;
	}
	label {
		position: relative;
		display: block;
		width: 24px; 
		height: 24px;
		border-radius: 100%;
		background: ${({ grey }) => (grey ? 'url("/icons/checkBox/check.svg") center center/cover' : 'url("/icons/checkBox/check_main.svg") center center/cover')};
		cursor: pointer;
    //background-size: contain;
    ${({isAlbumEdit, checked, grey}) => isAlbumEdit ? `
      width: 20px;
      height: 20px;
      border: ${checked ? 'none' : '2px solid #F1F2F3'};
      background: ${checked ? 'url("/icons/gallery/album/checkPhoto_active.svg")  center center/cover no-repeat' : 'transparent'};
    ` : `
    background: ${!checked ? '' : grey ? 'url("/icons/checkBox/check_active.svg") center center/cover;' : 'url("/icons/checkBox/check_main_active.svg") center center/cover;'} 
    `}
    
	}
	:hover {
		label {
      ${({isAlbumEdit, checked, grey}) => isAlbumEdit ? `
        ${checked ? '' : 'background: url("/icons/gallery/album/checkPhoto.svg") center center/cover no-repeat;'}
        border: none;
      ` : `
      ${checked ? '' : grey ? 'background: url("/icons/checkBox/check_hover.svg") center center/cover;' : 'background: url("/icons/checkBox/check_main_hover.svg") center center/cover;'} 
      `}
		}
		.hover {
			display: block;
		}
	}

  

  @media(max-width: 576px) {
    -webkit-tap-highlight-color: transparent;
    :hover {
      label {
        ${({isAlbumEdit, checked, grey}) => isAlbumEdit ? `
      border: ${checked ? 'none' : '2px solid #F1F2F3'};
      background: ${checked ? 'url("/icons/gallery/album/checkPhoto_active.svg") center center/cover no-repeat' : 'transparent'};
    ` : `
    ${checked ? '' : grey ? 'background: url("/icons/checkBox/check.svg");' : 'background: url("/icons/checkBox/check_main.svg");'} 
    `}
      }
      .hover {
        display: none;
      }
    }
  }
`;

const CheckBoxBlock = ({
  value,
  id,
  grey = true,
  checkedAll,
  onCheckedAll,
  onCheckedItems,
  onUnCheckedItems,
  onAddALLtoChecked,
  onRemoveAllFromChecked,
  isAlbumEdit = false,
  checkedItems,
  label=''
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    
    if (checkedAll) {
      setChecked(true);
    } else {
      if (id === 'advanced') {
        setChecked(false);
      }
      if (!checkedItems.length) {
        setChecked(false);
      }
    }
  }, [checkedAll, id, checkedItems]);


  const onChangeChecked = (e) => {
    setChecked(e.target.checked);

    if (e.target.value === 'advanced') {
      onCheckedAll(e.target.checked);

      if (e.target.checked) {
        onAddALLtoChecked();
      } else {
        onRemoveAllFromChecked();
      }
    } else if (e.target.checked) {
      onCheckedItems(id);
    } else {
      onUnCheckedItems(id);
    }
  };

  let hoverTitle = 'Вибрати усі';

  if (!checked) {
    if (value !== 'advanced') {
      hoverTitle = 'Вибрати';
    }
  } else {
    hoverTitle = 'Зняти вибір';
  }

  const onClick = (e) => {
    e.stopPropagation();
  } 

  return (
    <CheckboxGroupe onClick={onClick} checked={checked} grey={grey} isAlbumEdit={isAlbumEdit}>
      <input
        checked={checked}
        value={value}
        onChange={onChangeChecked}
        type="checkbox"
        id={id}
      ></input>
      <label htmlFor={id}>{label}</label>
      <HoverTitle title={hoverTitle} pos={isAlbumEdit ? 'center' : 'right'}/>
    </CheckboxGroupe>
  );
};

export default CheckBoxBlock;
