import React from 'react';
import styled from 'styled-components';

const ItemDateWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const ItemDateBlockMobile = ({children}) => {
	return (
		<ItemDateWrapper>
			{children}
		</ItemDateWrapper>
	)
}

export default ItemDateBlockMobile;