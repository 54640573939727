import React from 'react';
import Gallery from '../components/gallery/Gallery';
import Tabs from '../components/tabs/Tabs';

const GalleryPage = () => {
  return (
    <>
      <Tabs
        title="Фотогалерея"
        tabsArr={[
          { title: 'Фото', path: '/gallery/photo' },
          { title: 'Альбоми', path: '/gallery/album' },
        ]}
      />
      <Gallery />
    </>
  );
};

export default GalleryPage;
