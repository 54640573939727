import React from 'react'
import styled from 'styled-components';

const HeaderLogoStyled = styled.img``

const HeaderClubLogo = ({logo}) => {
return (
  <HeaderLogoStyled src={logo} alt='Аватар клуба' />

)


}
export default HeaderClubLogo

