import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SubMenuStyled = styled.div`
	padding: 12px 22px;
`;

const SubMenuTitle = styled.div`
	font-weight: 500;
	font-size: 13.5px;
	line-height: 16px;
	letter-spacing: 0.07em;
	color: #1E87A9;
`;

const IconWrapper = styled.div`
	margin-right: 12px;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	box-shadow: -1px -1px 3px #FFFFFF, 1px 1px 3px #E5E5E5;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		max-width: 100%;
	}
`;

const MenuList = styled.div``;

const MenuItemLink = styled(Link)`
	margin-top: 12px;
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.07em;
	color: #445566;
`;

const SubMenuBlock = () => {
  return (
    <SubMenuStyled>
      <SubMenuTitle>Обрані аккаунти:</SubMenuTitle>
      <MenuList>
				<MenuItemLink to='/my-club'>
					<IconWrapper><img src='/icons/links/my_club.svg' alt='my club' /></IconWrapper>
					Мій клуб
				</MenuItemLink>
				<MenuItemLink to='/my-organization'>
					<IconWrapper><img src='/icons/links//my_organization.svg' alt='my organization' /></IconWrapper>
					Мій клуб
				</MenuItemLink>
      </MenuList>
    </SubMenuStyled>
  );
};

export default SubMenuBlock;
