import React from 'react';
import styled from 'styled-components';

const EyeBlockStyled = styled.div`
  position: absolute;
  top: ${p => p.top || 0 };
  right: ${p => p.right || '5px' };
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 40px;
  cursor: pointer;
  img {
    max-width: 100%;
  }

  @media (max-width: 576px) {
    width: 15px;
    right: 12px;
    top: 3px;
    height: 36px;
  }
`;

const EyeBlock = ({visible, setVisible, top, right}) => {

	return (
		<EyeBlockStyled
			onMouseDown={() => setVisible(true)}
			onMouseUp={() => setVisible(false)}
			onMouseLeave={() => setVisible(false)}
			onTouchStart={() => {setVisible(true)}}
			onTouchEnd={() => setVisible(false)}
      top={top}
      right={right}
		>
			<img
				draggable={false}
				src={
					visible ? '/icons/settings_page/eye_open.svg' : '/icons/settings_page/eye_close.svg'
				}
				alt="eye"
			/>
		</EyeBlockStyled>
	)
}

export default EyeBlock;