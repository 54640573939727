import React from 'react';
import styled from 'styled-components';

const BookmarksSearchStyled = styled.div`
	display: flex;
	padding: 20px;
	background: #FFFFFF;
	box-shadow: 0px 1.5px 3px rgba(174, 174, 192, 0.2);
	border-radius: 2px;
	input {
		width: 100%;
		padding-left: 12px;
		outline: none;
		border: none;
		font-weight: 600;
		font-size: 17px;
		line-height: 25px;
		color: #445566
		::placeholder {
			font-weight: 500;
			font-size: 17px;
			line-height: 25px;
			color: #778899;
		}
	}

  @media(max-width: 576px) {
    display: none;
  }
`;

const SearchIcon = styled.img`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const CloseIcon = styled.img`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  cursor: pointer;
`;

const BookmarksSearch = ({ value, setValue, show }) => {

  const onChangeValue = (e) => {
    setValue(e.target.value);
  };

  return (
    <BookmarksSearchStyled show={show} > 
      <SearchIcon visible={!!!value} src="/icons/bookmarks/search.svg" alt="search" />
      <input
        value={value}
        onChange={onChangeValue}
        type="text"
        placeholder="Введіть слово для пошуку"
      />
      <CloseIcon
        onClick={() => {
          setValue('');
        }}
        visible={!!value}
        src="/icons/bookmarks/close.svg"
        alt="search"
      />
    </BookmarksSearchStyled>
  );
};

export default BookmarksSearch;
