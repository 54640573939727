import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ChooseAccountHover from '../../header/auth/ChooseAccountHover';
import { accounts } from '../../../mockData/mockData';
import MenuMainBlock from './MenuMainBlock';
import SubMenuBlock from './SubMenuBlock';
import { useResizeMode } from '../../hooks/useResizeMode';
import Swipe from 'react-easy-swipe';
import { userLogOut } from '../../../actions/loginAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const MenuMobileStyled = styled.div`
  position: fixed;
  top: 0;
  left: ${({ show }) => (show ? '0' : '-100%')};
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 2px #d4d4d4;
  transition: 0.3s all;
`;

const MenuContent = styled.div`
  width: 84%;
  height: 100%;
  background: #f6f6f6;
  overflow: auto;
`;

const IconClose = styled.img`
  position: absolute;
  width: 20px;
  top: 30px;
  right: 20px;
`;

const MenuHeader = styled.div`
  width: 100%;
  padding: 20px;
  background: linear-gradient(88.74deg,
  rgba(255, 255, 255, 0.25) 0%,
  rgba(255, 255, 255, 0.125) 100%),
  #1e87a9;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #ffffff;
`;

const AvatarIcon = styled.div`
  margin-right: 12px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  // border: 2px solid #FFFFFF;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ChooseAccountBlock = styled.div`
  position: relative;

  > div {
    background: #f6f6f6;
    padding: 12px 24px;

    > div {
      background: #f6f6f6;
      margin: 0;
      margin-bottom: 12px;
    }
  }

  :after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -1px;
    left: 0;
    background: #fdfdfd;
    box-shadow: 0px -1px 2px #ffffff, 0px 1px 2px #e5e5e5;
  }
`;

const ExitBlock = styled.div`
  background: #f1f2f3;
  padding: 12px 34px;
  margin-top: 24px;
  height: 52px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.07em;
  color: #445566;

  img {
    margin-right: 24px;
  }
`;

const IconOpen = styled.img`
  ${({ show }) => (show ? 'transform: rotate(180deg);' : '')}
`;

const MenuMobile = ({ show, setShow, onSaveSearchPrevRoute }) => {
  const [account, setAccount] = useState(accounts[0]);
  const [showChooseAccount, setShowChooseAccount] = useState(false);

  const isMobile = useResizeMode();

  const swipeRef = useRef();

  const dispatch = useDispatch();

  const history = useHistory();

  const onChooseAccount = (e) => {
    let target = e.target;

    while (!target.hasAttribute('data-id')) {
      target = target.parentElement;
    }

    const id = target.getAttribute('data-id');
    setAccount(accounts.find((item) => +item.id === +id));
    setShowChooseAccount(false);
  };


  const onSwipeMove = (position,event) => {
    let pos = Math.ceil(position?.x)
    if (pos && show && isMobile && (pos <= -100)){
      setShow(false)
    }
  }

  const userInfo = useSelector(state => state.userProfiles?.currentProfile)

  const [userName,setUserName] = useState('');
  const [userLastName,setUserLastName] = useState('');
  const [userAvatar,setUserAvatar] = useState('');


  useEffect(() => {
    setUserName(() => userInfo?.user?.first_name);
    setUserLastName(() => userInfo?.user?.last_name);
    setUserAvatar(() => userInfo?.user?.photo);
  },[userInfo])

  const onLogOut = (e) => {
    dispatch(userLogOut()); // временно пока бэк не настроят (не работает)
    e.preventDefault();
    // localStorage.removeItem('access') //временно пока бэк не починят
    // localStorage.removeItem('refresh') //временно пока бэк не починят;
    // localStorage.removeItem('email') //временно пока бэк не починят;
    // localStorage.removeItem('password2') //временно пока бэк не починят;
    // localStorage.removeItem('password') //временно пока бэк не починят;
    localStorage.clear();
    // history.push('/');
    setTimeout(() => {history.push('/auth/login')},200)


  };

  return (
    <Swipe onSwipeMove={onSwipeMove}>
    <MenuMobileStyled show={show}>
      <IconClose onClick={() => setShow(false)} src='/icons/close_icon.svg' alt='close' />
      <MenuContent ref={swipeRef}>
        <MenuHeader>
          <HeaderTitle>
            <AvatarIcon>
              <img src={userAvatar} alt='avatar' />
            </AvatarIcon>
            <div>{`${userName} ${userLastName}`}</div>
          </HeaderTitle>
          <IconOpen
            show={showChooseAccount}
            onClick={() => setShowChooseAccount((state) => !state)}
            src='/icons/menu_mobile/arrow_white.svg'
            alt='arrow'
          />
        </MenuHeader>
        <ChooseAccountBlock>
          <ChooseAccountHover
            onChooseAccount={onChooseAccount}
            show={showChooseAccount}
            accounts={accounts}
            account={account}
          />
        </ChooseAccountBlock>
        <MenuMainBlock onSaveSearchPrevRoute={onSaveSearchPrevRoute} setShow={setShow} />
        <SubMenuBlock />
        <ExitBlock onClick={onLogOut}>
          <img src='/icons/menu_mobile/exit.svg' alt='exit' />
          Вихід
        </ExitBlock>
      </MenuContent>
    </MenuMobileStyled>
    </Swipe>
  );
};

export default MenuMobile;
