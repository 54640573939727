import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const AlreadyRegisteredStyled = styled.div`
  margin-top: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #778899;
  a {
    margin-left: 10px;
    color: #0A87AA;
  }
`;

const AlreadyRegistered = () => {
  return (
    <AlreadyRegisteredStyled>
      <span>Вже зареєстровані?</span>
      <Link to='/auth/login'>Авторизуйтесь</Link>
    </AlreadyRegisteredStyled>
  )
}

export default AlreadyRegistered;