import React from 'react';
import { useParams } from 'react-router-dom';
import { dancersList } from '../../mockData/mockData';
import AlbumsBlock from './AlbumsBlock';
import PhotoBlock from './PhotoBlock';
import UserPageHeader from './UserPageHeader';

const UserPageAlbums = () => {
  const params = useParams();
  const user = dancersList.find((item) => item.id === +params.id);

  return (
    <>
      <UserPageHeader userName={user.userName} liked={user.liked} />
			<AlbumsBlock userId={params.id} />
      <PhotoBlock />
    </>
  );
};

export default UserPageAlbums;
