import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import AuthPage from '../pages/authPages/AuthPage';
import MainPage from '../pages/MainPage';
import store from '../store/store';
import { checkAuth } from '../actions/loginAction';

const App = () => {

  const history = useHistory();

  useEffect(() => {
    store.dispatch(checkAuth());
    if (localStorage.getItem('access') === null) {
      history.push('/auth/login/');
    }
  },[localStorage])

  const [auth, setAuth] = useState(false);


  return (
    <Switch>
      <Route path='/auth'>
        <AuthPage auth={auth} setAuth={setAuth} />
      </Route>
      <Route path='/'>
        <MainPage auth={auth} setAuth={setAuth} />
      </Route>
    </Switch>
  );
};

export default App;
