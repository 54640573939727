import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TrainerDancersListPopup from './TrainerDancersListPopup';
import MobileListPopup from './MobileListPopup';
import { useResizeMode } from '../../hooks/useResizeMode';

const DancerListStyled = styled.div`
  display: flex;
  align-items: center;
  width: 175px;
  justify-content: space-between;
`;

const ListButton = styled.div`
  padding: 3px 12px;
  margin-left: 20px;
  height: 36px;
  background: #ffffff;
  box-shadow: 1px 1px 2px #f6f6f6, -1px -1px 2px #f6f6f6;
  border-radius: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.07em;
  color: #1e87a9;
  cursor: pointer;
  :hover {
    background: #f1f2f3;
  }
  img {
    margin-left: 10px;
  }

	@media(max-width: 576px) {
		padding: 0 12px;
    font-weight: 500;
		font-size: 14px;
		line-height: 33px;
		height: 33px;
  }
`;

const TrainerDancerList = ({value}) => {
  const [showList, setShowList] = useState(false);
  const isMobile = useResizeMode();

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const onClick = () => {
    window.scroll(0, 0);
    document.body.style.overflow = 'hidden';
    setShowList(true);
  };

  return (
    <DancerListStyled>
      {value}
      <ListButton onClick={onClick}>
        Список
        <img src="/icons/user_inform/listArrow.svg" alt="arrow" />
      </ListButton>
        <MobileListPopup show={isMobile ? showList : false} setShow={setShowList} />
        <TrainerDancersListPopup show={isMobile ? false : showList} setShow={setShowList} />
    </DancerListStyled>
  );
};

export default TrainerDancerList;
