import React, { useState } from 'react';
import styled from 'styled-components';
import { ItemIconStyled } from './ItemIcon';
import { useResizeMode } from '../../../hooks/useResizeMode';

const ItemIconLike = styled(ItemIconStyled)`
  width: ${({ itemHover }) => (itemHover ? 'unset' : '36px')};
  box-shadow: ${({isGallery}) => isGallery ? '-1px -1px 2px #FFFFFF, 1px 1px 2px rgba(174, 174, 192, 0.5)' : '1px 1px 2px #f6f6f6, -1px -1px 2px #f6f6f6'};
  ${({isGallery, liked}) => isGallery && liked ? 'box-shadow: 1px 1px 2px #FFFFFF, -1px -1px 2px rgba(174, 174, 192, 0.3);' : ''}
  background: ${({isGallery}) => isGallery ? '#F6F6F6' : '#FFF' };
  border-radius: ${({isGallery}) => isGallery ? '12px' : '5px'};
  :hover {
    background: ${({isGallery}) => isGallery ? '#F6F6F6' : '#FFF' };
  }
`;

const LikeHoverBlock = styled.div`
  display: ${({ itemHover }) => (itemHover ? 'block' : 'none')};
  background: ${({isGallery}) => isGallery ? '#F6F6F6' : '#FFF' };
  margin-left: 10px;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: ${({ liked }) => (liked ? '#1E87A9' : '#A0ACB7')};
`;

const LikeBlock = ({ liked, top='20px', right='20px',  isGallery=false, topCenter}) => {
  const [itemLiked, setItemLiked] = useState(liked);
  const [itemHover, setItemHover] = useState(false);
  const [itemClicked, setItemClicked] = useState(false);
  const isMobile = useResizeMode();

  let itemImage = '/icons/search/like.svg';

  if (itemLiked) {
    itemImage = itemClicked ? '/icons/search/like_clicked.svg' : '/icons/search/liked.svg';
  } else {
    itemImage =
      itemHover && itemClicked
        ? '/icons/search/like_clicked.svg'
        : itemHover
        ? '/icons/search/like_hover.svg'
        : '/icons/search/like.svg';
  }

  const onMouseLeave = () => {
    if (!isMobile) {
      setItemHover(false);
      setItemClicked(false);
    }
  };

  const onMouseEnter = () => {
    if (!isMobile) {
      setItemHover(true);
    }
  };

  return (
    <ItemIconLike
      itemHover={itemHover}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={() => setItemClicked(true)}
      onMouseUp={() => setItemClicked(false)}
      onClick={() => setItemLiked((state) => !state)}
      top={top}
      right={right}
      isGallery={isGallery}
      liked={itemLiked}
      topCenter={topCenter}
    >
      <LikeHoverBlock isGallery={isGallery} liked={itemLiked} itemHover={itemHover}>
        {itemLiked ? 'В закладках' : 'Додати в закладки'}
      </LikeHoverBlock>
      <img src={itemImage} alt="like" />
    </ItemIconLike>
  )
};

export default LikeBlock;
