import React from 'react';
import styled from 'styled-components';

const InputTitleStyled = styled.div`
  margin-top: 32px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #223344;
  @media (max-width: 1000px){
    max-width: ${({mw1000}) => mw1000};
    margin: ${({m1000}) => m1000};
  }@media (max-width: 480px){
    max-width: ${({mw480}) => mw480};
  }
`;

const InputTitle = ({title ,mw1000, m1000, mw480}) => {
  return (
    <InputTitleStyled mw1000={mw1000} m1000={m1000} mw480={mw480}>{title}</InputTitleStyled>
  )
}

export default InputTitle;