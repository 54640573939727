import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SearchPage from '../../pages/SearchPage';
import BookmarksSearchMobile from '../bookmarks/bookmarksContent/BookmarksSearchMobile';
import MainMobileLayout from '../mobileLayout/MainMobileLayout';
import SearchTabsMobile from './SearchTabsMobile';
import SearchFilter from './searchFilter/SearchFilter';
import SearchClubsFilter from './searchClubs/SearchClubsFilter';
import SearchFilterOrg from './searchOrganizations/SearchFilterOrg';

const SearchMobile = ({ prevPath }) => {
  const history = useHistory();
  const location = useLocation();

  const [value, setValue] = useState('');

  return (
    <MainMobileLayout title="Пошук" onClose={() => history.push(prevPath)} withClose show>
      <BookmarksSearchMobile value={value} setValue={setValue} />
      <SearchTabsMobile
        tabsArr={[
          { title: 'Користувачі', path: '/search/users' },
          { title: 'Організації', path: '/search/organizations' },
          { title: 'Клуби', path: '/search/clubs' },
        ]}
      />
      {location.pathname.indexOf('/search/users') > -1 ? (
        <SearchFilter showInMobile />
      ) : location.pathname === '/search/organizations' ? (
        <SearchFilterOrg showInMobile />
      ) : (
        <SearchClubsFilter showInMobile />
      )}
      <SearchPage />
    </MainMobileLayout>
  );
};

export default SearchMobile;
