import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Switch, Route, Redirect } from "react-router-dom";
import Header from "../components/header/Header";
import SidePanel from "../components/sidePanel/SidePanel";
import BreadCrumbs from "../components/BreadCrumbs";
import Footer from "../components/footer/Footer";
import PrivacyPolicy from "../components/PrivacyPolicy";
import MyPage from "./MyPage";
import PaymentsPage from "./PaymentsPage";
import MyCardPage from "./MyCardPage";
import GalleryPage from "./GalleryPage";
import BookmarksPage from "./BookmarksPage";
import SearchPage from "./SearchPage";
import SettingsPage from "./SettingsPage";
import MenuPanel from "../components/menu/menuMobile/MenuPanel";
import { useResizeMode } from "../components/hooks/useResizeMode";
import BookmarksMobile from "../components/bookmarks/BookmarksMobile";
import SearchMobile from "../components/search/SearchMobile";
import UserPage from "./UserPage";
import UserPageAlbums from "../components/userPage/UserPageAlbums";
import AlbumItem from "../components/userPage/AlbumItem";
import PopupAddress from '../components/popups/popupAddress/PopupAddress';
import PopupPrivacy from '../components/popups/popupPrivacy/PopupPrivacy';
import MyClub from './MyClub';
import { useHistory } from 'react-router-dom';

const ContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1242px;
  margin: 0 auto;
  padding: 0 15px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContentStyled = styled.div`
  width: 75.5%;

  @media (max-width: 1200px) {
    width: 72%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const MainPage = ({ auth,setAuth }) => {
  const [bookmarksPrevPath, setBookMarksPrevPath] = useState(
    "/home/information/user"
  );
  const [searchPrevPath, setSearchPrevPath] = useState(
    "/home/information/user"
  );

  const isMobile = useResizeMode();
const history = useHistory()
  const onSavePrevRoute = (path) => {
    setBookMarksPrevPath(path);
  };
  // const userInfo = useSelector(state => state.userProfiles.currentProfile);
  const onSaveSearchPrevRoute = (path) => {
    setSearchPrevPath(path);
  };


  return (
    <>
          <PopupAddress />
          <PopupPrivacy />
          <Header auth={auth} />
          <ContainerStyled>
            <SidePanel onSaveSearchPrevRoute={onSaveSearchPrevRoute} />
            <ContentStyled>
              <BreadCrumbs />
              <Switch>
                <Route exact path="/">
                  {auth ? (
                    <Redirect to="/home/information/user" />
                  ) : (
                    <Redirect to="/auth/login" />
                  )}
                </Route>
                <Route path="/all-users/:id">
                  <UserPage />
                </Route>
                <Route exact path="/users-albums/all-users/:id/userGallery">
                  <UserPageAlbums />
                </Route>
                <Route path="/users-albums/all-users/:id/userGallery/:albumId">
                  <AlbumItem />
                </Route>
                <Route path="/home/information">
                  <MyPage />
                </Route>
                <Route path="/payments">
                  <PaymentsPage />
                </Route>
                <Route path="/my_card">
                  <MyCardPage />
                </Route>
                <Route path="/gallery">
                  <GalleryPage />
                </Route>
                <Route path="/bookmarks">
                  {isMobile ? (
                    <BookmarksMobile prevPath={bookmarksPrevPath} />
                  ) : (
                    <BookmarksPage />
                  )}
                </Route>
                <Route path="/my_club">
                  <MyClub />
                </Route>
                <Route path="/search">
                  {isMobile ? (
                    <SearchMobile prevPath={searchPrevPath} />
                  ) : (
                    <SearchPage />
                  )}
                </Route>
                <Route path="/settings">
                  <SettingsPage />
                </Route>
              </Switch>
            </ContentStyled>
          </ContainerStyled>
          <Footer />
          <PrivacyPolicy />
          <MenuPanel
            onSavePrevRoute={onSavePrevRoute}
            onSaveSearchPrevRoute={onSaveSearchPrevRoute}
          />
        </>
  );
}

export default MainPage;
