import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { statusesEnum } from '../../../mockData/mockData';
import HoverTitle from '../../hovers/HoverTitle';

const AlbumTitleStyled = styled.div`
  padding: 15px 10px 10px 5px;
  max-width: 208px;

  @media(max-width: 576px) { 
    padding: 12px 0 0 0;
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #223344;
  cursor: pointer;

  @media(max-width: 576px) { 
    font-size: 13px;
    line-height: 18px;
  }
`;

const AlbumStatus = styled.div`
  display: flex;
  align-items: center;}
`;

const PhotoCount = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #1e87a9;

  @media(max-width: 576px) { 
    font-size: 13px;
    line-height: 24px;
  }
`;

const StatusIcon = styled.div`
  margin-left: 25px;
  position: relative;
  :hover {
    .hover {
      display: block;
    }
  }
`;

const AlbumTitle = ({ title, count, status, id, isMove, albumLink='/gallery/album/' }) => {

  const history = useHistory();

  const onClick = () => {
    if (!isMove) {
      history.push(`${albumLink}${id}`)
    }
  }

  return (
    <AlbumTitleStyled>
      <Title onClick={onClick}>{title?.length > 55 ? `${title?.slice(0, 55)}...` : title}</Title>
      <AlbumStatus>
        <PhotoCount>{count} фото</PhotoCount>
        <StatusIcon>
          <img src={statusesEnum[status]?.icon} alt={statusesEnum[status]?.name} />
          <HoverTitle pos='center' title={statusesEnum[status]?.name}/>
        </StatusIcon>
      </AlbumStatus>
    </AlbumTitleStyled>
  );
};

export default AlbumTitle;
