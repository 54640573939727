import React, { useEffect } from 'react';
import styled from 'styled-components';
import RegisterContainer from '../../components/auth/register/RegisterContainer';
import AuthContainer from '../../components/auth/AuthContainer';
import AuthTitle from '../../components/auth/AuthTitle';
import AuthDivider from '../../components/auth/AuthDivider';
import ButtonSubmit from '../../components/auth/buttons/ButtonSubmit';
import ToLoginLink from '../../components/auth/register/ToLoginLink';
import { useHistory } from 'react-router-dom';
import NavCirclesBlock from '../../components/auth/register/NavCirclesBlock';
import { useSelector } from 'react-redux';

const CongratulationDescr = styled.div`
  position: relative;
  padding-bottom: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #223344;
  p {
    margin-top: 12px;
    :first-child {
      margin-top: 6px;
    }
  }
`;

const CongratulationFooter = styled.div`
  margin-top: 60px;
`;

const CongratulationPage = () => {
  const history = useHistory();
  const usersRole = useSelector((state) => state.users?.curentUser?.role);
useEffect(() => {},[usersRole])
  const onSubmit = () => {
    if (usersRole[0]) {
      history.push(`/auth/questionary_${usersRole[0]}`);
    } else {
      history.push('/auth/reg_data');
    }
  };

  return (
    <RegisterContainer>
      <NavCirclesBlock />
      <AuthContainer padding="0 0 32px 0" pdt360="0px">
        <AuthTitle title="Вітаємо!" />
        <CongratulationDescr>
          <p>Ви зарестрували свій акаунт як користувач платформи.</p>
          <p>
            Щоб користуватись платформою в повному обсязі необхідно заповнити відповідні анкети далі
            (Крок 3 і 4).
          </p>
          <p>
            Такі ж анкети ви завжди зможете знайти в особистому кабінеті – пункт меню “Додати роль
            користувачу”.
          </p>
          <AuthDivider />
        </CongratulationDescr>
        <CongratulationFooter>
          <ButtonSubmit
            onClick={onSubmit}
            type="button"
            width="296px"
            title="Продовжити реєстрацію"
            isActive
          />
          <ToLoginLink mt="32px" />
        </CongratulationFooter>
      </AuthContainer>
    </RegisterContainer>
  );
};

export default CongratulationPage;
