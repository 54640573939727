import React from 'react';
import styled from 'styled-components';
import BlockDivider from '../../../../BlockDivider';

const InputFileStyled = styled.div`
  position: relative;
  padding: 15px 0;
  height: ${({height}) => height};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media(max-width: 576px) {
    background: #fff;
    height: 80px;
    padding: 20px;
    align-items: unset;
  }
`;

const BlockTitle = styled.div`
  font-weight: 500;
  font-size: 25px;
  color: #778899;
  //margin-top: 60px;
  span {
    display: block;
    text-align: center;
    font-size: 20px;
  }

  @media(max-width: 576px) {
    display: none;
  }
`;

const InputFile = styled.div`
  margin-top: 15px;
  input {
    display: none;
  }
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 30px;
    height: 40px;
    border: 2.5px solid #1e87a9;
    border-radius: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #1e87a9;
    cursor: pointer;
  }

  @media(max-width: 576px) {
    margin-top: 0;
    label {
      font-size: 16px;
      border: 2px solid #1e87a9;
    }
  }
`;

const AttentionBlock = styled.div`
	position: absolute;
	top: 15px;
	left: 50%;
	transform: translateX(-50%);
	display: ${({show}) => show ? 'block' : 'none'};
	width: 95%;
	padding: 20px 25px 30px 25px;
	background: rgba(241, 242, 243, 0.7);
	border-radius: 5px;
	font-weight: 500;
	font-size: 17px;
	line-height: 25px;
	color: #445566;
	span {
		font-weight: 600;
		color: #1E87A9;
	}
`;

const PopupInputFileBlock = ({file, setFile, showAttention=false, height='620px', labelTitle='Вибрати фото з комп’ютера' }) => {
  const onDragEnter = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setFile(e.dataTransfer.files[0]);
  };

  const onChangeFile = (e) => {
    setFile(() => e.target.files[0]);
  };


  return (
    <InputFileStyled height={height} draggable onDragEnter={onDragEnter} onDragOver={onDragOver} onDrop={onDrop}>
			<AttentionBlock show={showAttention}>
				<span>Зверніть увагу!</span><br></br>
				Забороняється розміщати інформацію рекламного характеру, пропаганди насилля, війни чи расової дискримінації.
				Розміщення невідповідного контенту призведе до блокування акаунту.
			</AttentionBlock>
      <BlockTitle>
        Перетягніть фото сюди <br></br>
        <span>або</span>
      </BlockTitle>
      <BlockDivider height="2px" br="2px" />
      <InputFile>
        <label>
          {labelTitle}
          <input onChange={onChangeFile} type="file" />
        </label>
      </InputFile>
    </InputFileStyled>
  );
};

export default PopupInputFileBlock;
