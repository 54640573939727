import React from 'react';
import styled from 'styled-components';

const AttentionBlockStyled = styled.div`
  padding: 16px 20px;
  margin-top: 32px;
  background: #E7EAED;
  border-radius: 10px;
  
  @media (max-width: 360px){
    max-width: 296px;
  }
  
`;



const AttentionBlockTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #0A87AA;
`;

const AttentionBlockDescr = styled.div`
  margin-top: 8px;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #223344;
  p {
    margin-top: 15px;
    :first-child {
      margin-top: 0;
    }
  }
`;

const AttentionBlock = () => {
  return (
    <AttentionBlockStyled>
      <AttentionBlockTitle>Увага</AttentionBlockTitle>
      <AttentionBlockDescr>
        <p>Користувачем e-phan може бути людина, вік якого більше 14 років.</p>
        <p>Якщо танцюристу ще немає 14 років, зареєструватись спочатку повинен один з батьків або людина, яка їх заміняє.</p>
        <p>Для успішної реєстрації необхідно буде вкласти копії документів (паспорт  чи свідоцтво про народження) та особисте фото.</p>        
      </AttentionBlockDescr>
    </AttentionBlockStyled>
  )
}

export default AttentionBlock;