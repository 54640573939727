import React from 'react';
import SettingsSection from '../SettingsSection';
import DescrHeader from './DescrHeader';
import DescrItem from './DescrItem';

const DescriptionSection = () => {
  return (
    <SettingsSection>
      <DescrHeader name="Назва файлу" comment="Коментар" status="Статус" />
      <DescrItem name="Назва файлу.png" comment="Текст пояснення..." status="Надіслано" />
      <DescrItem name="Назва файлу.png" comment="Текст пояснення..." status="На розгляді" />
      <DescrItem nonDivider name="Назва файлу.png" comment="Текст пояснення..." status="Виконано" />
    </SettingsSection>
  );
};

export default DescriptionSection;
