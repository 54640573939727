import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const SearchBlockStyled = styled.div`
  position: relative;
  max-width: 230px;
  height: 40px;
  border-radius: 14px;
  margin: 0 auto; 
  input {
    height: 100%;
    padding: 12px;
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #445566;
    outline: none;
    background: #f6f6f6;
    box-shadow: 1px 1px 2px #ffffff, -1px -1px 2px rgba(174, 174, 192, 0.3);
    border-radius: 14px;
    border: none;
    ::placeholder {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: #778899;
    }
  }


  @media(max-width: 992px) {
    width: 100%;
    height: 40px;
  }

  @media(max-width: 576px) {
    input {
      font-size: 14px;
      line-height: 24px;
      color: #556677;
      ::placeholder {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
`;
const IconContainer = styled.div`
  position: absolute;
  top: 52%;
  right: 0;
  transform: translateY(-50%);
  padding: 12px 13px 28px 12px;
  width: 40px;
  height: 40px;
  box-shadow: -1px -1px 2px #FFFFFF, 1px 1px 2px #E5E5E5;
  border-radius: 14px;
  //border:none;
  cursor: pointer;
`
const IconSearch = styled.img`
position: absolute;
    top: 50%;
   left: 50%;
    transform: translate(-50%, -50%);
   // background: ${({active}) => active ?  "url('/icons/clubs/search-club-active.svg') center center/cover no-repeat" : "url('/icons/clubs/search-club.svg') center center/cover no-repeat"};
  width: 16px;
  height: 16px;
  border:transparent;
  &:hover{
    //background: url('/icons/clubs/search-club-active.svg') center center/cover no-repeat;
  }
`



// const IconClose = styled.img`
//   display: ${({isActive}) => isActive ? 'block' : 'none'};
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   right: 13.5px;
//   width: 13px;
// `;

const SearchClubInput = ({ searchTerm, setSearchTerm }) => {
  const [active,setActive] = useState(false);
  const [hover,setHover]= useState(false)
  useEffect(() => {
    searchTerm !== '' ? setActive(true) : setActive(false)}, [searchTerm,active]);
  return (
    <SearchBlockStyled>
      <input value={searchTerm} onChange={(e)=>setSearchTerm(e.target.value)} type="text" placeholder='Введіть слово...' />
      <IconContainer>
        <IconSearch
          src={active || hover ? '/icons/clubs/search-club-active.svg' : '/icons/clubs/search-club.svg'}
          alt='Поиск'
          onMouseOver={() => setHover(!hover)}
          onMouseLeave={() => setHover(!hover)}
        />
        {/*<IconSearch active={active}/>*/}
        {/*<IconSearch active={active}/>*/}
      </IconContainer>

      {/*<IconClose onClick={()=>onSearch('')} isActive={!!searchTerm} src='/icons/settings_page/close.svg' />*/}
    </SearchBlockStyled>
  )
};

export default SearchClubInput;
