import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LoginBlock = styled.div`
  margin-top: ${p => p.mt || '40px'};
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #778899;
  a {
    margin-left: 10px;
    color: #0A87AA;
  }
`;

const ToLoginLink = ({mt}) => {
  return (
    <LoginBlock mt={mt}>
      Перейти до входу: <Link to='/auth/login'>Авторизація</Link>
    </LoginBlock>
  )
}

export default ToLoginLink;