import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useResizeMode } from '../../hooks/useResizeMode';

const TabsItem = styled.li`
  padding: 3px 15px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border: 2px solid transparent;
  ${({ isActive }) =>
    isActive
      ? `
		border: 2px solid #1E87A9;
		border-radius: 15px;
	`
      : ''}
  :first-child {
    margin: 0;
  }
  :hover {
    border: ${({isActive}) => isActive ? '2px solid #1E87A9' : '2px solid rgba(253, 253, 253, 0.75)'};
    filter: drop-shadow(0px 1px 2px rgba(174, 174, 192, 0.2)) drop-shadow(-1px -1px 1px #FFFFFF) drop-shadow(1px 1px 1px #E5E5E5);
    border-radius: 15px;
  }
  -webkit-tap-highlight-color: transparent;

  @media (max-width: 992px) {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    ${({isBookmarks}) => !isBookmarks ? 'text-align: center;' : ''}
    margin: ${({ isBookmarks }) => (isBookmarks ? '20px 0 0 0' : '0 0 0 21px')};
    height: 36px;
    ${({isBookmarks}) => !isBookmarks ? 'width: 140px;' : ''}
    ${({ isActive, isBookmarks }) =>
      isActive
        ? `
		border: 2px solid #1E87A9;
		border-radius: 15px;
	`
    : isBookmarks
    ? ''
    : `box-shadow: 1px 1px 2px #ffffff, -1px -1px 2px rgba(174, 174, 192, 0.3);
      border-radius: 15px;`}
      :hover {
        border: ${({isActive}) => isActive ? '2px solid #1E87A9' : '2px solid transparent'};
        filter: unset;
      }
  }
`;

const TabName = styled.div`
  width: 100%;
  a {
    display: block;
    width: 100%;
    height: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: ${({ isBookmarks }) => (isBookmarks ? '0.07em' : '0.1em')};
    color: #223344;
  }

  @media (max-width: 768px) {
    a {
      ${({ isBookmarks, isActive }) => !isBookmarks && !isActive ? 'font-weight: 500;' : ''}
      ${({ isBookmarks, isActive }) => !isBookmarks && !isActive ? 'color: #778899;' : ''}
      font-size: ${({ isBookmarks }) => (isBookmarks ? '15px' : '14px')};
      letter-spacing: ${({ isBookmarks }) => (isBookmarks ? '0.07em' : '0.09em')};
      line-height: 18px;
    }
  }
`;

const TabCount = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #1e87a9;

  @media (max-width: 992px) {
    margin-left: 5px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

const BookmarksTabsItem = ({ path, title, isBookmarks = false, onShowTabs }) => {
  const location = useLocation();
  const isActive = location.pathname.indexOf(path) > -1;
  const isMobile = useResizeMode();

  const onClick = (e) => {
    if (isBookmarks) {
      onShowTabs(isActive);
    } else if (isMobile) {
      document.body.querySelector('#searchTabs').scrollLeft = 0;
    }
  }

  return (
    <TabsItem isActive={isActive} isBookmarks={isBookmarks}>
      <TabName onClick={onClick} isBookmarks={isBookmarks} isActive={isActive}>
        <Link to={isMobile && isActive && isBookmarks ? '/bookmarks' : path}>{title}</Link>
      </TabName>
      {isActive && isBookmarks ? <TabCount>10</TabCount> : ''}
    </TabsItem>
  );
};

export default BookmarksTabsItem;
