import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import IconWrapper from '../../IconWrapper';
import ButtonBlock from '../../ButtonBlock';
import BlockDivider from '../../../BlockDivider';
import PopupPhoneChange from '../../../popups/popupPhoneChange/PopupPhoneChange';
import PopupCodeNone from '../../../popups/popupPhoneChange/PopupCodeNone';
import PhoneNumberInput from './PhoneNumberInput';
import PopupResult from '../../../popups/popupPhoneChange/PopupResult';

const InputGroupe = styled.div`
  position: relative;
  padding: 20px 20px 40px 20px;
`;

const InputGroupeTitle = styled.div`
  display: flex;
  align-items: center;
`;

const InputTitle = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #445566;

  @media (max-width: 576px) {
    font-size: 15px;
    line-height: 18px;
  }
`;

const InputSubtitle = styled.div`
  padding-left: 40px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #1e87a9;

  @media (max-width: 576px) {
    padding-left: 0;
    margin-top: 4px;
    font-size: 13px;
  }
`;

const FormStyled = styled.form`
  @media (max-width: 576px) {
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

const FormPhoneNumber = ({ showContent }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [validate, setValidate] = useState(false);
  const [showPoppupPhoneChange, setShowPopupPhoneChange] = useState(false);
  const [showPoppupCodeNone, setShowPopupCodeNone] = useState(false);
  const [showPoppupResult, setShowPopupResult] = useState(false);
  const [access, setAccess] = useState(false);

  useEffect(() => {
    if (validate) {
      if (!phoneNumber.replace(' ', '').match(/^\+\d{12}$/) && phoneNumber) {
        setAlertMessage('Введіть номер телефону у такому форматі: +380980000000');
      } else {
        setAlertMessage('');
      }
    }
  }, [phoneNumber, validate]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!phoneNumber.replace(' ', '').match(/^\+\d{12}$/) && phoneNumber) {
      setAlertMessage('Введіть номер телефону у такому форматі: +380980000000');
      setValidate(true);
    } else {
      setAlertMessage('');
      setValidate(false);
      setShowPopupPhoneChange(true);
      setShowPopupCodeNone(false);
    }
  };

  const onReset = () => {
    setPhoneNumber('');
    setValidate(false);
    setAlertMessage('');
  };

  return (
    <>
      <FormStyled show={showContent} onSubmit={onSubmit} onReset={onReset}>
        <InputGroupe>
          <InputGroupeTitle>
            <IconWrapper iconUrl="/icons/settings_page/phone.svg" alt="phone" />
            <InputTitle>Введіть номер телефону, який ви вказували при реєстрації</InputTitle>
          </InputGroupeTitle>
          <InputSubtitle>Ми надішлемо код підтвердження в SMS.</InputSubtitle>
          <PhoneNumberInput
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            alertMessage={alertMessage}
          />
          <BlockDivider height="2px" br="2px" />
        </InputGroupe>
        <ButtonBlock
          submitActive={!!phoneNumber && !!!alertMessage}
          type="reset"
          submitTitle="Надіслати код"
          secondButton="Скасувати"
        />
        <PopupCodeNone
          show={showPoppupCodeNone}
          setShow={setShowPopupCodeNone}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          alertMessage={alertMessage}
        />
      </FormStyled>
      <PopupPhoneChange
        phoneNumber={phoneNumber}
        setShowCodeNone={setShowPopupCodeNone}
        show={showPoppupPhoneChange}
        setShow={setShowPopupPhoneChange}
        setAccess={setAccess}
        setShowPopupResult={setShowPopupResult}
      />
      <PopupResult setShow={setShowPopupResult} show={showPoppupResult} access={access} />
    </>
  );
};

export default FormPhoneNumber;
