import React, { useState } from 'react';
import styled from 'styled-components';
import PopupLayout from '../PopupLayout';
import PopupChoosePhoto from '../PopupChoosePhoto';
import PhotoToWrapperHeader from './PhotoToWrapperHeader';
import PopupInputFileBlock from '../PopupInputFileBlock';
import ButtonBlock from '../../../../ButtonBlock';
import GalleryBlock from './GalleryBlock';
import EditImgBlock from '../photoToAccount/EditImgBlock';
import { useResizeMode } from '../../../../../hooks/useResizeMode';
import PhotoToWrapperMobile from './photoToWrapperMobile/PhotoToWrapperMobile';
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, updateUserCover, updateUserPhoto } from "../../../../../../actions/profileAction";
import { rootURL } from "../../../../../../api/api";

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ withPhoto }) => (withPhoto ? 'auto' : '100%')};
`;

const FormOverflow = styled.div`
  height: calc(100% - 85px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #f1f2f3 #fff;
  ::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #f1f2f3;
    border-radius: 10px;
  }
`;

const PhotoToWrapper = ({ show, setShow }) => {
  const [file, setFile] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);

  const dispatch = useDispatch();
  const userId = useSelector(state => state.userProfiles?.currentProfile?.user?.id);


  const isMobile = useResizeMode();

  const onEdit = () => {
    setFile(null);
    setImgSrc(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setShow(false);
    setFile(null);
    setImgSrc(null);
    dispatch(updateUserCover(
      userId,
      file,
    ));
    setTimeout((
    ) => {dispatch(getUserProfile(userId))},200)
  };

  if (isMobile) {
    return (
      <PhotoToWrapperMobile
        show={show}
        setShow={setShow}
        onSubmit={onSubmit}
        file={file}
        setFile={setFile}
        onEdit={onEdit}
        imgSrc={imgSrc}
        setImgSrc={setImgSrc}
      />
    );
  }
  return (
    <PopupLayout wrapperWidth="89%" show={show}>
      <GalleryBlock setImgSrc={setImgSrc} curentSrc={imgSrc} />
      <PopupChoosePhoto setFile={setFile} setImgSrc={setImgSrc} setShow={setShow}>
        <PhotoToWrapperHeader />
        <FormOverflow>
          <FormStyled withPhoto={!!file || !!imgSrc} onSubmit={onSubmit}>
            {file || imgSrc ? (
              <EditImgBlock isWrapper imgSrc={imgSrc} file={file} />
            ) : (
              <PopupInputFileBlock height="620px" showAttention setFile={setFile} />
            )}
            <ButtonBlock
              submitActive={!!file || !!imgSrc}
              onEdit={onEdit}
              setShow={setShow}
              submitTitle="Зберегти зміни"
              secondButton="Скасувати"
            />
          </FormStyled>
        </FormOverflow>
      </PopupChoosePhoto>
    </PopupLayout>
  );
};

export default PhotoToWrapper;
