import React from "react";
import Paiments from "../components/payments/Paiments";
import Tabs from "../components/tabs/Tabs";

const PaymentsPage = () => {
  return (
    <>
      <Tabs 
        title='Платежі' 
        tabsArr={[
          {title: 'Рахунки', path: '/payments/accounts'},
          {title: 'Сплачені', path: '/payments/paid'},
          {title: 'Архів', path: '/payments/archive'}
        ]}
      />
      <Paiments />
    </>
  )
};

export default PaymentsPage;
