import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { closePopupPrivacy } from '../../../actions/popupsAction';
import PopupSubtitle from './PopupSubtitle'
import PopupText from './PopupText'

const PrivacyPopupBlur = styled.div`
  height: ${p=>p.show ? '100%' : '0'};
  width: ${p=>p.show ? '100%' : '0'};
  top: 0;
  left: 0;
  position:fixed;
  z-index: 100;
  
`

const PrivacyPopupOverlay = styled.div`
  width: ${p=>p.show ? '100vw' : '0'};
  top:0;
  left:0;
  height: ${p=>p.show ? '100vh' : '0'};
  position: absolute;
  z-index: 101;
  background: rgba(0, 0, 0, .4);`

const PrivacyPopupStyled = styled.div`
max-width: 912px;
  width: 100%;
  background: #fff;
  min-height: 342px;
  display: ${p => p.show ? 'flex' : 'none'};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 1001;
  flex-direction: column;
  border: 1px solid #E7EAED;
  border-radius: 5px;
  @media (max-width: 768px){
    width: 95%;
  }
  @media (max-width: 480px){
    max-width: 425px;
  }
`

const PrivacyPopupTitle = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #223344;
  @media (max-width: 480px){
    font-size: 16px;
  }
`
const PopupClose = styled.img`
  cursor:pointer;
background: url("/icons/popups/popup-close.svg") center center/cover;
  @media (max-width: 360px){
    width: 15px;
    height: 15px;
  }
`

const PrivacyPopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px;
  border-bottom:2px solid #E7EAED;
`

const PrivacyPopupBody = styled.div`
padding: 25px;
  border-bottom:2px solid #E7EAED;
  height: 170px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
  -webkit-appearance: none;
    width: 10px;
}
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #E7EAED;
  }
`
const PrivacyButtonContainer = styled.div`
padding: 25px;
  display: flex;
  justify-content: end;
`
const PrivacyButton = styled.button`
max-width: 220px;
  width: 100%;
  border: 2.5px solid #47A5BF;
  border-radius : 10px;
  cursor: pointer;
  padding: 10px 71px;
  background: #fff;
  &:hover{
    background-color: #F1F2F3
  }
  @media (max-width: 480px){
    max-width: 385px;
  }
`


const PrivacyPopup = () => {
  const showPopup = useSelector(state => state.popup.showPrivacyPopup)
  const dispatch = useDispatch()
  const onClosePopup = (e) => {
    e.preventDefault()
    dispatch(closePopupPrivacy())
  }
  return (
    <PrivacyPopupBlur show={showPopup}>
      <PrivacyPopupOverlay show={showPopup}>
    <PrivacyPopupStyled show={showPopup}>
<PrivacyPopupHeader>
  <PrivacyPopupTitle>
    {'Політика конфіденційності'}
  </PrivacyPopupTitle>
  <PopupClose onClick={onClosePopup} src='/icons/popups/popup-close.svg' alt='close-popup'/>
</PrivacyPopupHeader>
  <PrivacyPopupBody>
<PopupSubtitle children={'Заголовок 1'}/>
<PopupText children={'Тест'}/>
<PopupSubtitle children={'Заголовок 2'}/>
<PopupText children={'Текст'}/>
    <PopupSubtitle children={'Заголовок 3'}/>
<PopupText children={'Тест'}/>
<PopupSubtitle children={'Заголовок 4'}/>
<PopupText children={'Текст'}/>
  </PrivacyPopupBody>
      <PrivacyButtonContainer>
      <PrivacyButton onClick={(e) => onClosePopup(e)}>
        Закрити
      </PrivacyButton>
      </PrivacyButtonContainer>
</PrivacyPopupStyled>
      </PrivacyPopupOverlay>
    </PrivacyPopupBlur>

  )


}

export default PrivacyPopup;