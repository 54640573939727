import React, { useState } from 'react';
import styled from 'styled-components';
import BookmarksTabs from '../bookmarks/bookmarksTabs/BookmarksTabs';
import BookmarksContent from '../bookmarks/bookmarksContent/BookmarksContent';
import SearchFilter from './searchFilter/SearchFilter';


const SearchStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media(max-width: 576px) {
    width: 100%;
   }
`;

const SearchSidePanel = styled.div`
  width: 29%;
  > div {
    width: 100%;
  }

  @media(max-width: 576px) {
    display: none;
  }
`;

const Search = ({searchList}) => {

  const [searchTerm, setSearchTerm] = useState('');

  return (
    <SearchStyled>
      <BookmarksContent searchTerm={searchTerm} setSearchTerm={setSearchTerm} content={searchList} isSearch />
      <SearchSidePanel>
        <BookmarksTabs
          title="Пошук"
          tabsArr={[
            { title: 'Користувачі', path: '/search/users' },
            { title: 'Клуби', path: '/search/clubs' },
            { title: 'Організації', path: '/search/organizations' },
          ]}
        />
        <SearchFilter />
      </SearchSidePanel>
    </SearchStyled>
  );
};

export default Search;
