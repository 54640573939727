import React from 'react';
import styled from 'styled-components';

const TextAreaStyled = styled.textarea`
  margin-top: ${p => p.mt || '12px'};
  padding: 16px 12px;
  width: 100%;
  min-height: 92px;
  border: none;
  outline: none;
  background: #f6f6f6;
  box-shadow: -1px -1px 1px #FFFFFF, 1px 1px 1px #E7EAED;
  border-radius: 10px;
  resize: vertical;
  overflow: hidden;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #223344;
  ::placeholder {
    font-weight: normal;
    color: #778899;
  }
  ::-webkit-resizer {
    background: url('/icons/auth/resizer.svg') bottom right no-repeat;
  }
  @media (max-width: 480px){
    max-width: ${({w480}) => w480};
  }
`;

const AuthTextArea = ({placeholder, value, setValue, mt , w480}) => {
  return (
    <TextAreaStyled mt={mt} value={value} w480={w480} onChange={(e)=>setValue(e.target.value)} placeholder={placeholder}></TextAreaStyled>
  )
}

export default AuthTextArea;