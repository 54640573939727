import React from 'react';
import styled from 'styled-components';
import BookmarksItem from './bookmarksItem/BookmarksItem';
import BookmarksSearch from './BookmarksSearch';
import Pagination from '../../pagination/Pagination';

const BookmarksContentStyled = styled.div`
  width: 67%;
  ul {
    width: 100%;
  }

  @media(max-width: 1200px) {
    width: 64%;
  }

  @media (max-width: 992px) {
    width: 100%;
    order: 2;
  }

  @media(max-width: 576px) {
    width: 100%;
  }
`;

const BookmarksContent = ({content, isClub, isSearch, managerType, searchTerm, setSearchTerm}) => {
  return (
    <BookmarksContentStyled>
      <BookmarksSearch value={searchTerm} setValue={setSearchTerm} />
      <ul>
        {content.map((item) => {
          if (isClub) {
            return (
              <BookmarksItem
                key={item.id}
                avatarUrl={item.avatar ? item.avatar : '/icons/bookmarks/club_logo.svg'}
                clubName={item.name}
                userName={item.userName}
                country={item.country}
                isClub
                isSearch={isSearch}
                liked={item.liked}
                managerType={managerType}
              />
            )
          } else {
            return (
              <BookmarksItem
                key={item.id}
                userName={item.userName}
                avatarUrl={item.avatar ? item.avatar : '/icons/bookmarks/new_user.svg'}
                place={item.place}
                status={item.staus.join(' / ')}
                id={item.id}
                isSearch={isSearch}
                liked={item.liked}
              />
            )
          }
        })}
      </ul>
      <Pagination show={content.length >= 10}/>
    </BookmarksContentStyled>
  );
};

export default BookmarksContent;
