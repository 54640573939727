import React from 'react';
import styled from 'styled-components';

const ButtonStrokeStyled = styled.button`
  min-width: ${(p) => p.width};
  height: 48px;
  border: 3px solid #0a87aa;
  border-radius: 10px;
  background-color: transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.1em;
  color: #0a87aa;
  outline: none;
  cursor: pointer;
  margin-top: 0px;
  :hover {
    background: #e7eaed;
    transition: all 0.4s;
  }
  @media (max-width: 480px) {
    width: ${({w480}) => w480};
    //margin: ${({m480}) => m480};
    margin-top: ${({mt480}) => mt480};
  }
  @media (max-width: 360px) {
    width: ${({w360}) => w360};
    margin-top: ${(p) => p.mt || '0px'};
  }
  @media (max-width: 320px) {
    width: 296px;
    margin-top: ${(p) => p.mt || '0px'};
  }
`;

const ButtonStroke = ({ m480, w480, title, width, type, onClick, widthSd, mt, width320 , mt480, w360}) => {
  return (
    <ButtonStrokeStyled
      onClick={onClick}
      type={type || 'button'}
      width={width}
      widthSd={widthSd}
      w480={w480}
      width320={width320}
      mt480={mt480}
      mt={mt}
m480={m480}
  w360={w360}
    >
      {title}
    </ButtonStrokeStyled>
  );
};

export default ButtonStroke;
