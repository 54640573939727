import React from "react";
import AuthInputText from "../AuthInputText";
import AuthSelectClubs from "./AuthSelectClubs";

const ChooseClubWithAdd = ({ clubs, setClubs, options, validate, clubsNoList, setClubsNoList, mtp, setId }) => {

  return (
    <>
      {
        <AuthSelectClubs
          mt={mtp ? mtp : '44px'}
          value={clubs}
          setId={setId}
          setValue={setClubs}
          noInList
          filtered
          placeholder='Вибрати клуб'
          options={options}
          validate={validate}
          // validate={i === 0 ? validate : false}
          hint='Почніть вводити назву або виберіть зі списку' />
      }
      {
        clubs === "Немає в списку" ? (
          clubsNoList.map((item, i) => (
            <div key={i}>
              <AuthInputText
                mt='44px'
                hint='(якщо немає вашого клубу в списку)'
                value={clubsNoList[i].clubName}
                setValue={(value) => setClubsNoList(state => state.map(el => el.id - 1 === i ? { ...el, clubName: value } : el))}
                placeholder='Напишіть назву клубу та місто'
              />
              <AuthInputText
                mt='44px'
                hint='(якщо знаєте інформацію)'
                value={clubsNoList[i].manager}
                setValue={(value) => setClubsNoList(state => state.map(el => el.id - 1 === i ? { ...el, manager: value } : el))}
                placeholder='ПІБ та № тел. керівника клубу'
              />
            </div>
          ))) : ''

      }

    </>
  )
};

export default ChooseClubWithAdd;