import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import OptionsGroupe from './OptionsGroupe';

const FilterItemStyled = styled.div`
  position: relative;
  padding: 20px 15px;
  color: #445566;
  img {
    margin-right: 5px;
  }
  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 88%;
    height: 1px;
    background: #f6f6f6;
    box-shadow: -1px -1px 2px #ffffff, 1px 1px 2px #e5e5e5;
    border-radius: 5px;
  }

  @media (max-width: 576px) {
    img {
      max-width: 22px;
    }
    :after {
      width: 90%;
    }
  }
`;

const FilterItemHead = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5px;
	color: ${({isActive}) => isActive ? '#1E87A9' : '#445566'};
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.1em;
	cursor: pointer;
`;

const FilterItem = ({iconUrl, title, optionArr, path}) => {

  const history = useHistory();
  const location = useLocation();

  const isActive = location.pathname.indexOf(path) > -1;

  const onPush = () => {
    isActive ? history.push('/search/users') : history.push(path)
  }

	return (
		<FilterItemStyled>
        <FilterItemHead isActive={isActive} onClick={onPush}>
          <img src={`/icons/search/${isActive ? 'active_icons/' : ''}${iconUrl}`} alt={title} />
          {title}
        </FilterItemHead>
				<OptionsGroupe optionArr={optionArr} show={isActive}/>
      </FilterItemStyled>
	)
}

export default FilterItem;