import React from 'react';
import styled from 'styled-components';

export const HoverTitleStyled = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: calc(100% + 7px);
  ${({pos}) => pos === 'center' ? `
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    ` : ''}
  ${({pos}) => pos === 'right' ? `
  right: unset;
  left: 0;
  ` : ''}
  padding: 12px 11px;
  background: #fcfcfc;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  z-index: 1;
  color: ${({color}) => color};
  :after {
    content: '';
    display: block;
    position: absolute;
    top: -7px;
    right: 11px;
    ${({pos}) => pos === 'center' ? `
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    ` : ''}
    ${({pos}) => pos === 'right' ? `
    right: unset;
    left: 11px;
    ` : ''}
    width: 12px;
    height: 12px;
    background: url('/icons/checkbox_arrow.svg') center center/cover no-repeat;
    z-index: 2;
  }
`;

const HoverTitle = ({title, pos='left', color='#778899'}) => {
	return (
		<HoverTitleStyled className='hover' pos={pos} color={color}>
			{title}
		</HoverTitleStyled>
	)
}

export default HoverTitle;