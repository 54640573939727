import React from 'react';
import styled from 'styled-components';

const PopupText = styled.p`
font-weight: 500;
  margin-bottom: 15px;
`


const PopupAddressText = ({children}) => {
  return(
    <PopupText>
      {children}
    </PopupText>

  )
}

export default PopupAddressText;