import React from "react";
import styled from "styled-components";

const HeaderStyled = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 90px 10px 25px;
  height: 60px;
  background: #F6F6F6;
  box-shadow: -1px -1px 2px #FFFFFF, 1px 1px 2px #E5E5E5;
  border-radius: 2px 2px 0px 0px;

  @media(max-width: 1200px) {
    padding: 10px 50px 10px 25px;
  }

  @media(max-width: 768px) {
    display: none
  }
`;

const AccountsHeader = ({children}) => {
  return (
    <HeaderStyled>
			{children}
    </HeaderStyled>
  );
};

export default AccountsHeader;
