import React from 'react';
import styled from 'styled-components';

import TabsItem from './TabsItem';

const TabsList = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PaymentsTabsList = ({tabsArr}) => {
  return (
    <TabsList>
      {
				tabsArr.map((item, i)=>(
					<TabsItem key={i} path={item.path} title={item.title} />
				))
			}
    </TabsList>
  );
};

export default PaymentsTabsList;
