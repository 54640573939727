import React from 'react';
import styled from 'styled-components';
import HoverTitle from '../../hovers/HoverTitle';

const HeaderStyled = styled.div`
  width: 100%;
  padding: 15px 35px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
`;

const Logo = styled.div`
  width: 210px;
  display: flex;
  justify-content: space-between;
  img {
    :first-child {
      cursor: pointer;
    }
  }
`;

const OptionsBlock = styled.div`
  width: 215px;
  display: flex;
  justify-content: ${({isUserPhoto}) => isUserPhoto ? 'flex-end' : 'space-between'};
`;

const OptionsItem = styled.div`
  display: ${({show}) => show ? 'flex' : 'none'};
  position: relative;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    max-width: 100%;
  }
  :hover {
    background: rgba(241, 242, 243, 0.1);
    border-radius: 5px;
    .hover {
      display: block;
    }
  }
`;

const SliderHeader = ({ onShowPhoto, isUserPhoto }) => {
 
  const onClosePhoto = () => {
    onShowPhoto(false);
    document.body.style.overflow = '';
  };

  return (
    <HeaderStyled>
      <Logo>
        <img onClick={onClosePhoto} src="/icons/gallery/slider/close_slider.svg" alt="close" />
        <img src="/icons/logo.svg" alt="logo" />
      </Logo>
      <OptionsBlock isUserPhoto={isUserPhoto}>
        <OptionsItem show>
          <img src="/icons/gallery/slider/options/zoom_plus.svg" alt="zoom_plus" />
          <HoverTitle pos='center' color='#223344' title='Маштабувати' />
        </OptionsItem>
        <OptionsItem show>
          <img src="/icons/gallery/slider/options/zoom_minus.svg" alt="zoom_plus" />
          <HoverTitle pos='center' color='#223344' title='Маштабувати' />
        </OptionsItem>
        <OptionsItem show={!isUserPhoto} > 
          <img src="/icons/gallery/slider/options/rotate_left.svg" alt="zoom_plus" />
          <HoverTitle pos='center' color='#223344' title='Повернути ліворуч' />
        </OptionsItem>
        <OptionsItem show={!isUserPhoto}>
          <img src="/icons/gallery/slider/options/download_slider.svg" alt="zoom_plus" />
          <HoverTitle pos='center' color='#223344' title='Завантажити' />
        </OptionsItem>
        <OptionsItem show={!isUserPhoto}>
          <img src="/icons/gallery/slider/options/delete_slider.svg" alt="zoom_plus" />
          <HoverTitle pos='center' color='#223344' title='Видалити' />
        </OptionsItem>
      </OptionsBlock>
    </HeaderStyled>
  );
};

export default SliderHeader;
