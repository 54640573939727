import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import AuthContainer from './AuthContainer';
import AuthInputPassword from './AuthInputPassword';
import AuthTitle from './AuthTitle';
import ButtonSubmit from './buttons/ButtonSubmit';
import InpupHint from './InputHint';
import AuthDivider from './AuthDivider';
import ButtonStroke from './buttons/ButtonStroke';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { userVarification } from '../../actions/loginAction';
import { useEffect } from 'react';

const FormStyled = styled.form``;

const InputWrapper = styled.div`
  position: relative;
  padding-bottom: 31px;
`;

const NoResponse = styled.div`
  margin-top: 31px;
  min-height: ${(p) => (p.show ? '52px' : '0')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 480px){
    flex-direction: ${({fd480}) => fd480};
    align-items: start;
    
  }
  @media (max-width: 360px) {
    flex-wrap: wrap;
  }
`;

const NoResponseTitle = styled.div`
  display: ${(p) => (p.show ? 'block' : 'none')};
  font-weight: 600;
  padding-left: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #0a87aa;
  cursor: pointer;
  @media (max-width: 360px) {
    padding-left: 14px;
  }
  @media (max-width: 320px) {
    padding-left: 24px;
  }
`;

const ButtonWrapper = styled.div`
  display: ${(p) => (p.show ? 'block' : 'none')};
  @media (max-width: 360px) {
    width: 296px;
    margin: 24px auto 0;
  }
  @media (max-width: 320px) {
    width: 296px;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    justify-content: ${(p) => (p.reg ? 'center' : 'space-between')};
  }
  @media (max-width: 480px) {
    justify-content: ${(p) => (p.reg ? 'center' : 'space-between')};
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
  @media (max-width: 360px) {
  }
  @media (max-width: 320px) {
    //flex-direction: column;
    //justify-content: start;
    //align-items: center;
    /* margin: 60px 12px 0px; */
  }
`;

const RepeatMessage = styled.div`
  display: ${(p) => (p.show ? 'block' : 'none')};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #223344;
  @media (max-width: 480px){
    width: ${({w480}) => w480};
    line-height: 18px;
    padding-left: ${({pl480}) => pl480};
  }
  @media (max-width: 360px) {
    padding-left: 14px;
  }
  @media (max-width: 320px) {
    padding-left: 24px;
  }
`;

const EnterCodePage = ({ reg }) => {
  const varify = useSelector(state => state.login?.userVarify);
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [validMessage, setValidMessage] = useState('');
  const [showButton, setShowButton] = useState(false);
  const [repeatCodeSanded, setRepeatCodeSanded] = useState(false);
  const [repeatMessage, setRepeatMessage] = useState(
    'Код надіслано повторно, на вказаний вами номер',
  );
  const [repeatCount, setRepeatCount] = useState(0);
  const history = useHistory();

  const codeValidator = () => {
    if (varify === 400) {
      return (
        <>
          <span>Ви ввели невірний код.</span>
          <div>Перевірте надіслане нами SMS та повторіть спробу.</div>
        </>
      );
    } else {
      return '';
    }
  };
  useEffect(() => {
    setValidMessage(codeValidator);

  }, [varify]);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(userVarification(code));
  };

  useEffect(() => {
      if (varify === 200) {
        history.push('/auth/reg_congratulation');
      }
    },[varify]
  );
  const onRepeatSend = () => {
    setCode('');
    setShowButton(false);
    setValidMessage('');
    setRepeatCodeSanded(true);
    if (repeatCount > 2) {
      setRepeatMessage(`
        ${<>
        Ви вичерпали ліміт надісланих SMS з кодом.<br />
        <br />
        Спробуйте пізніше або зверніться у підтримку: support@e-phan.com +38(000)000-00-00
      </>}
      `);
      setTimeout(() => {
        setRepeatCount(0);
        setRepeatMessage('Код надіслано повторно, на вказаний вами номер');
        setRepeatCodeSanded(false);
      }, 10000);
    }
    setRepeatCount((count) => count + 1);
  };

  const onReset = () => {
    history.push('/auth/login');
  };
  return (
    <AuthContainer padding={reg ? '35px 0' : ''} pdt360='0px'>
      <AuthTitle small title='Введіть код:' mt480='84px' />
      <FormStyled onSubmit={onSubmit} onReset={onReset}>
        <InputWrapper>
          <AuthInputPassword
            message={validMessage}
            mtLog='36px'
            value={code}
            setValue={setCode}
            placeholder='Введіть код'
            w360='296px'
          />
          <InpupHint show={!validMessage} title='Код з SMS-повідомлення' />
          <NoResponse show={repeatCount <= 10} fd480='column' ai480='start'>
            <NoResponseTitle
              show={repeatCount <= 10}
              onClick={() => {
                setShowButton(true);
                setRepeatCodeSanded(false);
              }}
            >
              Не отримали код?
            </NoResponseTitle>
            <ButtonWrapper show={showButton}>
              <ButtonSubmit
                onClick={onRepeatSend}
                title='Надіслати повторно'
                type='button'
                width='250px'
                widthMd='210px'
                mt='24px'
                widthSd='312px'
                ml320='320px'
                width320='296px'
                isActive
                width480='312px'
                w360='296px'
              />

            </ButtonWrapper>
          </NoResponse>
          <RepeatMessage show={repeatCodeSanded} w480='300px' pl480='10px'>{repeatMessage}</RepeatMessage>
          <AuthDivider />
        </InputWrapper>
        <ButtonsContainer reg={reg}>
          <ButtonSubmit
            title='Продовжити'
            isActive={code}
            width='210px'
            widthMd='250px'
            widthSd='312px'
            width320='296px'
            mb='16px'
            mr12='12px'
            m480='16px'
            width480='312px'
            w360='296px'
          />

            <ButtonStroke
              title='Скасувати'
              width='210px'
              type='reset'
              widthSd='312px'
              widthMd='250px'
              width320='296px'
              w480='312px'
              w360='296px'
            />
        </ButtonsContainer>
      </FormStyled>
    </AuthContainer>
  );
};

export default EnterCodePage;