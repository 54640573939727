import React, { useEffect } from 'react';
import styled from 'styled-components';
import SliderHeader from './SliderHeader';
import SliderPhoto from './SliderPhoto';
import SliderSidePanel from './SliderSidePanel';
import { useResizeMode } from '../../hooks/useResizeMode';
import SliderMobile from '../sliderMobile/SliderMobile';

const SliderStyled = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
`;

const Slider = ({ show, onShowPhoto, album, curentSlide, setCurentSlide, isUserPhoto }) => {
  const isMobile = useResizeMode();

  useEffect(() => {
    return () => {
      document.body.style.overflow = '';
    };
  }, [album?.photos]);

  if (isMobile) {
    return (
      <SliderMobile
        onShowPhoto={onShowPhoto}
        album={album}
        setCurentSlide={setCurentSlide}
        show={show}
        curentSlide={curentSlide}
        isUserPhoto={isUserPhoto}
      />
    );
  }

  return (
    <SliderStyled show={show}>
      <SliderHeader isUserPhoto={isUserPhoto} onShowPhoto={onShowPhoto} />
      <SliderSidePanel
        curentSlide={curentSlide}
        countSlide={album?.photos.length}
        setCurentSlide={setCurentSlide}
      />
      <SliderPhoto photos={album?.photos} curentSlide={curentSlide} />
      <SliderSidePanel
        isRight
        curentSlide={curentSlide}
        countSlide={album?.photos.length}
        setCurentSlide={setCurentSlide}
      />
    </SliderStyled>
  );
};

export default Slider;
