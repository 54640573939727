import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SettingsCommon from './settingsCommon/SettingsCommon';
import SettingsExtended from './settingsExtended/SettingsExtended';
import SettingsPassword from './settingsPassword/SettingsPassword';

const Settings = () => {
  return (
    <Switch>
      <Route path="/settings/common">
        <SettingsCommon />
      </Route>
      <Route path="/settings/passwords">
        <SettingsPassword />
      </Route>
      <Route path="/settings/extended">
        <SettingsExtended />
      </Route>
    </Switch>
  );
};

export default Settings;
