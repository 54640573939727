import React from 'react';
import styled from 'styled-components';
import AlertMessage from '../../AlertMessage';

const InputBlock = styled.div`
  padding: 20px 20px 0 40px;
	${({plNone}) => plNone ? 'padding-left: 0;' : ''}
  display: flex;
  input {
    margin-right: 25px;
    padding: 0 10px;
    display: block;
    font-weight: 500;
    font-size: 17px;
    color: #445566;
    width: 350px;
    height: 40px;
    border: 1px solid ${({ isActive }) => (isActive ? '#1E87A9' : '#778899')};
    border-radius: 5px;
    outline: none;
    :disabled {
      background: #fff;
    }
    ::placeholder {
      color: #445566;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
    padding: 0;
    margin-top: 16px;
    input {
      height: 36px;
      font-size: 14px;
    }
  }
`;

const InputDescr = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #778899;

  @media (max-width: 576px) {
    font-size: 13px;
    line-height: 18px;
  }
`;

const AlertMessageWrapper = styled.div`
	padding-left: ${({plNone}) => plNone ? '0' : '40px'};

  @media (max-width: 576px) {
    padding-left: 0;
  }
`;

const PhoneNumberInput = ({phoneNumber, setPhoneNumber, alertMessage, plNone=false}) => {

	const onChange = (e) => {
    const value = e.target.value.length === 3 ? e.target.value + ' ' : e.target.value;
    setPhoneNumber((state) => (state.match(/.{3} /) ? e.target.value : value));
  };

	return (
		<>
			<InputBlock plNone={plNone} isActive={!!phoneNumber}>
				<input value={phoneNumber} onChange={onChange} type="tel" placeholder="+38" />
				<InputDescr show={!!!alertMessage && !plNone}>
					Номер повинен містити код країни та оператора. Наприклад: +380987654321
				</InputDescr>
			</InputBlock>
			<AlertMessageWrapper plNone={plNone}>
				<AlertMessage show={!!alertMessage} message={alertMessage} />
			</AlertMessageWrapper>
		</>
	)
}

export default PhoneNumberInput;