import React from 'react';
import styled from 'styled-components';
import FilterItemOptions from './FilterItemOptions';

const OptionsGroupeStyled = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const OptionsGroupe = ({ optionArr, show }) => {
  return (
    <OptionsGroupeStyled show={show}>
      {optionArr.map((item, i) => (
        <FilterItemOptions
          key={i}
          title={item.title}
          imgUrl={item.imgUrl}
          subOptions={item.subOptions}
          itemActive={show}
          focusPlaceholder={item.focusPlaceholder}
          value={item.value}
          setValue={item.setValue}
        />
      ))}
    </OptionsGroupeStyled>
  );
};

export default OptionsGroupe;
