import React from 'react';
import styled from 'styled-components';

const Amount = styled.div`
  flex: ${({ratio}) => ratio};
  font-weight: 600;
  font-size: 16px;
  color: #445566;
  text-align: right;

  @media(max-width: 576px) {
    flex: unset;
    margin-top: 16px;
    font-size: 15px;
    line-height: 20px;
  }
`;

const AmountTitle = styled.div`
  display: none;
  margin-right: 16px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #1E87A9;

  @media(max-width: 576px) {
    display: inline;
  }
`;

const ItemAmountBlock = ({ account, amountRatio=1 }) => {
  return <Amount ratio={amountRatio}><AmountTitle>Сума, грн </AmountTitle>{account.amount}</Amount>;
};

export default ItemAmountBlock;
