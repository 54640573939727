import React from 'react';
import styled from 'styled-components';
import TabsClubItem from './TabsClubItem';

const TabsClubListStyled = styled.ul`
  display: flex;
  max-width: 400px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`


const TabsClubList = ({tabsArr}) => {
  return(
    <TabsClubListStyled>
      {
        tabsArr.map((item,i) => (
        <TabsClubItem key={i} path={item.path} title={item.title} />

      ))}
    </TabsClubListStyled>

  )
}

export default TabsClubList