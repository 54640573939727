import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import CheckBoxBlock from "../checkBox/CheckBoxBlock";
import OptionIconHover from "./OptionIconHover";
import { useResizeMode } from "../hooks/useResizeMode";
import { rootURL } from "../../api/api";
import Preloader from "../../utils/Preloader";
import { useDispatch } from "react-redux";

const GalleryItemstyled = styled.div`
  position: relative;
  margin: 5px;
  width: 208px;
  height: 208px;
  border-radius: 5px;

  @media (max-width: 576px) {
    margin: 0;
    width: unset;
    height: unset;
    border-radius: 3px;
  }
`;

const ImgWrapper = styled.div`
  position: relative;
  background: #f1f2f3;
  width: 208px;
  height: 208px;
  border-radius: 5px;
  cursor: move;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  @media (max-width: 576px) {
    width: 100%;
    height: ${({ isAlbum }) => isAlbum ? "172px" : "100%"};
    border-radius: 3px;
  }
`;


const ItemOverLay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${({ show }) => show ? "flex" : "none"};
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);

  img {
    max-width: 52px;
    max-height: 44px;
  }
`;


const GalleryImgItem = ({
                          currentOffset,
                          userId,
                          imgUrl,
                          isAlbum,
                          onDragStart,
                          onDragOver,
                          onDragEnter,
                          onDrop,
                          withOption = true,
                          id,
                          withCheck = false,
                          checkedAll,
                          onCheckedAll,
                          checkedItems,
                          onCheckedItems,
                          onUnCheckedItems,
                          onShowPhoto,
                          setShowPopupRemove,
                          onShowRemoveAlbum,
                          setShowPopupMove,
                          isMove = false,
                          onCheckedAlbumToMove,
                          showOverlay,
                          albumLink = "/gallery/album/",
                        }) => {

    const history = useHistory();
    const isMobile = useResizeMode();



    useEffect(() => {
    }, [imgUrl]);

    const onDoubleClick = () => {
      if (isMobile) {

      } else if (isAlbum) {
        history.push(`${albumLink}${id}`);
      } else {
        onShowPhoto(id);
        document.body.style.overflow = "hidden";
      }

    };


    const onClick = () => {
      if (isMove) {
        onCheckedAlbumToMove(id);
      }
      if (isMobile) {
        if (isAlbum) {
          if (!isMove) {
            history.push(`${albumLink}${id}`);
          }
        } else {
          onShowPhoto(id);
          document.body.style.overflow = "hidden";
        }
      }
    };

    return (

      <GalleryItemstyled
        onDragStart={onDragStart}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDoubleClick={onDoubleClick}
        onClick={onClick}
        draggable
        data-id={id}
      >

        <ImgWrapper isAlbum={isAlbum} data-id={id}>
          {imgUrl !== undefined ?
            <img src={imgUrl.includes("http://phn.pmapplications.com/") ? imgUrl : rootURL + imgUrl} alt='dancers' data-id={id} /> : ""}
          <ItemOverLay show={showOverlay}><img src='/icons/gallery/album/folder.svg'
                                               alt='folder' /></ItemOverLay>
        </ImgWrapper>

        {withOption ? <OptionIconHover setShowPopupMove={setShowPopupMove}
                                       userId={userId}
                                       currentOffset={currentOffset}
                                       onShowRemoveAlbum={onShowRemoveAlbum}
                                       setShowPopupRemove={setShowPopupRemove} isAlbum={isAlbum}
                                       id={id} /> : ""}
        {withCheck ? (
          <CheckBoxBlock
            id={id}
            checkedAll={checkedAll}
            onCheckedAll={onCheckedAll}
            checkedItems={checkedItems}
            onCheckedItems={onCheckedItems}
            onUnCheckedItems={onUnCheckedItems}
            isAlbumEdit
          />
        ) : (
          ""
        )}
      </GalleryItemstyled>

    );
  }
;

export default GalleryImgItem;
