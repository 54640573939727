import React from "react";
import ArchiveItem from "./ArchiveItem";

const ArchiveBody = ({filteredArr}) => {
  return (
    <ul>
      {filteredArr.map((item) => (
        <ArchiveItem key={item.id} account={item}/>
      ))}
    </ul>
  );
};

export default ArchiveBody;
