import React, { useEffect, useMemo, useState } from 'react';

import UserInformationItem from './UserInformationItem';
import SportCategory from './SportCategory';
import { useSelector } from 'react-redux';

const InformationListUser = ({ mockData }) => {

  const dancerInfo = useSelector(state => state.userProfiles?.currentProfile?.dancer);
  const [classArr, setClassArr] = useState([]);
  const [clubsArr, setClubsArr] = useState([]);
  const [trainersArr, setTrainersArr] = useState([]);
  const [partnersArr, setPartnersArr] = useState([]);
  const [classList, setClassList] = useState('')
  const [clubList, setClubList] = useState('')
  const [trainersList, setTrainersList] = useState('')
  const [partnersList, setPartnersList] = useState('')


   useEffect(() => {
    setClassArr(() => dancerInfo?.dancing_classes.length > 0 ? [...dancerInfo?.dancing_classes] : []);
    setClubsArr(() => dancerInfo?.clubs.length > 0 ? [...dancerInfo?.clubs] : []);
    setTrainersArr(() => dancerInfo?.trainers?.length > 0 ? [...dancerInfo?.trainers] : [])
    setPartnersArr(() => dancerInfo?.partners?.length > 0 ? [...dancerInfo?.partners] : [])

  },[dancerInfo]);

  useEffect(() => {
    setClassList(() => classArr.join(''))
    setClubList(() => clubsArr.join(''))
    setTrainersList(() => trainersArr.join(''))
    setPartnersList(() => partnersArr.join(''))
  },[classArr,clubsArr,trainersArr,partnersArr])

  return (
    <ul>
      <UserInformationItem
        image='/icons/dancer_inform/status.svg'
        title='Статус'
        value={mockData.status}
      />
      <UserInformationItem
        image='/icons/dancer_inform/age-category.svg'
        title='Вікова Категорія'
        value={mockData.ageCategory}
      />
      <UserInformationItem
        image='/icons/dancer_inform/organization.svg'
        title='Організація'
        value={mockData.organization}
      />

      <UserInformationItem
        image='/icons/dancer_inform/club.svg'
        title='Клуб'
        value={clubsArr?.length > 0 ? clubList : 'Нема'}
      />
      <UserInformationItem
        image='/icons/dancer_inform/trainer.svg'
        title='Тренери клубу'
        value={trainersArr?.length > 0 ? trainersList : 'Нема'}
      />
      <UserInformationItem
        image='/icons/dancer_inform/partner.svg'
        title='Партнер/ка'
        value={partnersArr?.length > 0 ? partnersList : 'Нема'}
      />
      <UserInformationItem
        image='/icons/dancer_inform/clas.svg'
        title='Клас'
        value={classArr?.length > 0 ? classList : 'Нема'}
      />
      <UserInformationItem
        image='/icons/dancer_inform/category.svg'
        title='Спортивний розряд'
        value={<SportCategory />}
      />
    </ul>
  );
};

export default InformationListUser;
