import React from 'react';
import styled from 'styled-components';
import RegisterCheckBox from './RegisterCheckBox';
import RegisterCheckBoxBack from "./RegisterCheckBoxBack";

const CheckBoxBlockStyled = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 480px){
    max-width: 312px;
  }
`;

const CheckBoxTitle = styled.div`
  margin-left: 16px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #223344;
`;

const RegisterCheckBoxBackBlock = ({positionItems,setPositionItems,checked, setChecked, title,item,checkedPersonal,setCheckedPersonal}) => {
  const addList = () => {
    setPositionItems((p)=>[...p,item])
    if(positionItems.includes(item)) {
      setPositionItems((p)=>[...p,item].filter(d => d.id !== item.id))
    }
  }



  return (
    <CheckBoxBlockStyled>
      <RegisterCheckBoxBack checked={checked} checkedPersonal={checkedPersonal} setCheckedPersonal={setCheckedPersonal} setChecked={setChecked} onClick={addList}/>
      <CheckBoxTitle>{title}</CheckBoxTitle>
    </CheckBoxBlockStyled>
  )
}

export default RegisterCheckBoxBackBlock;