import React from 'react';
import styled from 'styled-components';
import AlertMessage from './AlertMessage';

const SelectCategory = styled.div`
  position: relative;
  box-shadow: 0px 0px 2px #d4d4d4;
  border-radius: 5px;
`;

const SelectCategoryHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ withAvatar }) => (withAvatar ? '2px 40px 2px 10px' : '10px 40px 10px 10px')};
  ${({ isActive }) => (isActive ? 'padding-left: 5px;' : '')};
  input {
    display: block;
    width: 100%;
    font-size: ${({ withAvatar }) => ( withAvatar ? '17px' : '15px')};
    line-height: 20px;
    font-weight: ${({ withAvatar }) => ( withAvatar ? '600' : '500')};
    letter-spacing: 0.07em;
    color: ${({ withAvatar }) => withAvatar ? '#223344' : '#1E87A9'};
    border: none;
    outline: none;
    ::placeholder {
      font-weight: 500;
      font-size: 15px;
      color: #778899;
      letter-spacing: 0.05em;
    }
  }

  @media(max-width: 576px) {
    input {
      font-size: 14px;
      ::placeholder {
        font-size: 14px;
      }
    }
  }
`;

const OptionsList = styled.ul`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: relative;
  padding: 12px 0;
  :after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: #f1f2f3;
    border-radius: 5px;
  }
`;

const OptionsListItem = styled.li`
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.07em;
  color: #445566;
  cursor: pointer;
  :first-child {
    margin-top: 0;
  }
  :hover {
    color: ${({ withAvatar }) => (withAvatar ? '#445566' : '#1e87a9')};
    background: ${({ withAvatar }) => (withAvatar ? '#F1F2F3' : 'unset')};
  }
`;

const ArrowDown = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) ${({ open }) => (open ? ' rotate(180deg)' : '')};
  right: 15px;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
`;

const AvatarIcon = styled.div`
  display: ${({ withAvatar }) => (withAvatar ? 'block' : 'none')};
  margin-right: 10px;
  width: 36px;
  height: 36px;
  img {
    max-width: 100%;
  }
`;

const CloseIcon = styled(ArrowDown)``;

const SettingsSelect = ({
  optionsList,
  isActive,
  setShowOptionsList,
  onCloseOptions,
  open,
  onChooseOption,
  showAlert,
  disabled,
  withAvatar = false,
  value,
  onChange,
  placeholder
}) => {

  const onShowOptions = () => {
    if (!disabled) {
      setShowOptionsList((state) => !state);
    }
  };

  return (
    <div>
      <SelectCategory>
        <SelectCategoryHeader withAvatar={withAvatar} isActive={isActive} disabled={disabled}>
          <AvatarIcon withAvatar={withAvatar}>
            <img src="/icons/settings_page/avatar.svg" alt="avatar" />
          </AvatarIcon>
          <input
            onClick={onShowOptions}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            type="text"
          />
          {isActive ? (
            <CloseIcon src="/icons/close_icon.svg" alt="close" onClick={onCloseOptions} />
          ) : (
            <ArrowDown
              onClick={onShowOptions}
              open={open}
              disabled={disabled}
              src="/icons/search/filter_options/arrow_down.svg"
              alt="arrow down"
            />
          )}
        </SelectCategoryHeader>
        <OptionsList show={open}>
          {optionsList.map((item, i) => (
            <OptionsListItem withAvatar={withAvatar} onClick={() => onChooseOption(item)} key={i}>
              <AvatarIcon withAvatar={withAvatar}>
                <img src="/icons/settings_page/avatar.svg" alt="avatar" />
              </AvatarIcon>
              {item}
            </OptionsListItem>
          ))}
        </OptionsList>
      </SelectCategory>
      <AlertMessage show={showAlert} message="Обов’язкове поле!" />
    </div>
  );
};

export default SettingsSelect;
