import React from 'react';
import Search from '../../Search';
import { dancersList } from '../../../../mockData/mockData';

const searchList = dancersList.filter((item) => item.staus.includes('Танцюрист'));

const SearchDancers = () => {
	return (
		<Search searchList={searchList} />
	)
}

export default SearchDancers;