import React from 'react';
import GalleryMobilePage from '../galleryMedia/GalleryMobilePage';
import SliderActionBlock from './SliderActionBlock';
import SliderMobileHeader from './SliderMobileHeader';
import SliderPhotoBlock from './SliderPhotoBlock';

const SliderMobile = ({ show, onShowPhoto, album, curentSlide, setCurentSlide, isUserPhoto }) => {

  const onClosePhoto = () => {
    onShowPhoto(false);
    document.body.style.overflow = '';
  };

  return (
    <GalleryMobilePage show={show}>
      <SliderMobileHeader title={album.name} onClose={onClosePhoto} />
      <SliderPhotoBlock
        photos={album.photos}
        curentSlide={curentSlide}
        setCurentSlide={setCurentSlide}
      />
      {isUserPhoto ? '' : <SliderActionBlock />}
    </GalleryMobilePage>
  );
};

export default SliderMobile;
