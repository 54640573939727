import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import RoleSelect from './RoleSelect'
import { myInfo } from '../../mockData/mockData';
import { useSelector } from 'react-redux';

const MyInfoStyled = styled.div`
  padding: 10px 20px;
  min-height: 60px;
  display: flex;
  align-items: center;
  background: #f6f6f6;
  border-radius: 0px 0px 2px 2px;
  //filter: drop-shadow(-1.5px -1.5px 3px #FFFFFF) drop-shadow(1px 1px 2px #E1E1E1);
  box-shadow: -1.5px -1.5px 3px #FFFFFF, 1px 1px 2px #E1E1E1;
  //border-radius: 2px 2px 0px 0px;
  border-bottom: 1px solid #e1e1e1;

  @media(max-width: 576px) {
    padding: 10px 16px;
`;

const ImgWrapper = styled.div`
  width: 50px;
  height: 50px;
  img {
    border-radius: 12px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media(max-width: 576px) {
    width: 44px;
    height: 44px;
  }
`;

const Name = styled.div`
  margin-left: 25px;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.1em;
  color: #445566;

  @media(max-width: 576px) {
    margin-left: 16px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.07em;
  }
`;



const MyInfo = () => {
  const userInfo = useSelector(state => state.userProfiles?.currentProfile?.user)
  useEffect(()=>{},[userInfo])
  return (
    <>
    <MyInfoStyled>
      <ImgWrapper>
        <img src={userInfo?.photo} alt="avatar" />
      </ImgWrapper>
      <Name>
        {userInfo?.first_name} {userInfo?.last_name}
      </Name>
        <RoleSelect d1440='flex'/>
    </MyInfoStyled>
      <RoleSelect d425='flex'/>
    </>
  );
};

export default MyInfo;
