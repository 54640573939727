import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import QuestionaryParentBlock from '../../components/auth/register/QuestionaryParentBlock';
import RegisterContainer from '../../components/auth/register/RegisterContainer';
import RegisterNav from '../../components/auth/register/RegisterNav';
import ButtonSubmit from '../../components/auth/buttons/ButtonSubmit';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { registUserParent } from '../../actions/registerAction';
import { getUserCountry, getUserRegion } from "../../actions/countryAction";

const ButtonContainer = styled.div`
  padding: 60px 0;
  @media (max-width: 1000px) {
    display: ${({df}) => df};
    justify-content: ${({jc1000}) => jc1000};
  }
`;


const QuestionaryParentPage = () => {
  const dispatch = useDispatch();
  const [kids, setKids] = useState('');
  const [user, setUser] = useState('');
  const [parents, setParents] = useState('');
  const [childs, setChilds] = useState([{ valid: false }]);
  const [validate, setValidate] = useState(false);
  const usersRole = useSelector(state => state.users?.curentUser?.role);
  const history = useHistory();
  const [onSubmitCheck, setOnSubmitCheck] = useState(false)
  const userCurrent = useSelector(state => state.registerUsers.registersUser?.data?.pk);
  // const userCurrentData = useSelector(state=>state.registerUsers.registersUser)

  const countryList = useSelector((state) => state.userCountry?.country?.results)
  const regionList = useSelector((state) => state.userRegion?.region?.results)
  useEffect(() => {
    dispatch(getUserCountry())
    dispatch(getUserRegion())
  },[]);

  const [formValid, setFormValid] = useState(false);

  const parentId = () => {
    if (parents === 'Батько') {
      return 1;
    } else if (parents === 'Мати') {
      return 2;
    } else if (parents === 'Інше (опікун, дідусь, бабуся тощо)') {
      return 3;
    } else {
      return null;
    }
  };


  useEffect(() => {
  }, [parentId(),
    kids,
    user,
    userCurrent
  ]);


  const onSubmit = () => {
    setValidate(true);
    if (!formValid) {
      return
    }
    dispatch(registUserParent(
      parentId(),
      kids,
      user,
      userCurrent,
    ));
    if (childs.length !== childs.filter(item => item.valid).length) {
      return;
    }

    if (!usersRole.length) {
      history.push('/auth/reg_data');
    } else {
      const idx = usersRole.findIndex(item => item === 'parents');
      if (usersRole[idx + 1]) {
        history.push(`/auth/questionary_${usersRole[idx + 1]}`);
      } else {
        if (usersRole.length === 1) {
          history.push('/auth/reg_success');
        } else {
          history.push('/auth/reg_add_doc');
        }
      }
    }
  };

  return (
    <RegisterContainer>
      <RegisterNav backLink='/auth/questionary_dancer' currentStep={2} />
      {
        childs.map((item, i) => (
          <QuestionaryParentBlock countryList={countryList} regionList={regionList} formValid={formValid} setFormValid={setFormValid} setUser={setUser} setKids={setKids}
                                  setParents={setParents} validate={validate} setValidate={setValidate} idx={i} key={i}
                                  setChilds={setChilds} onSubmitCheck={onSubmitCheck} setOnSubmitCheck={setOnSubmitCheck}/>
        ))
      }
      <ButtonContainer df='flex' jc1000='center'>
        <ButtonSubmit
          // isActive={childs.length === childs.filter(item=>item.valid).length}
          isActive={formValid}
          onClick={onSubmit}
          title='Продовжити'
          width480='312px'
          width='210px' />
      </ButtonContainer>
    </RegisterContainer>

  );
};

export default QuestionaryParentPage;