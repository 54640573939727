import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';

import InformationListUser from './InformationListUser';
import InformationListDancer from './InformationListDancer';
import {
  userInformation,
  dancerInformation,
  trainerInformation,
  judgeInformation,
  managerInformation,
} from '../../../mockData/mockData';
import InformationListTrainer from './InformationListTrainer';
import InformationListJudge from './InformationListJudge';
import InformationListManager from './InformationListManager';
import { useSelector } from 'react-redux';

const UserInformationStyled = styled.div`
  padding: 20px 20px;
  width: 607px;
  background: #ffffff;
  box-shadow: 0px 0px 2px #d4d4d4;
  border-radius: 0px 5px 5px 0px;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

const AboutMeTitle = styled.div`
  display: flex;

  img {
    margin-right: 10px;
  }

  div {
    font-weight: 500;
    font-size: 15px;
    line-height: 40px;
    color: #1e87a9;
  }

  @media (max-width: 576px) {
    img {
      max-width: 15px;
    }

    div {
      font-size: 13px;
      line-height: 24px;
    }
  }
`;

const AboutMeDescr = styled.div`
  margin-left: 40px;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #445566;

  @media (max-width: 576px) {
    font-size: 13.5px;
    line-height: 20px;
  }
`;

const UserInformation = () => {

const userInfo = useSelector(state => state.userProfiles?.currentProfile)

  const [userAbout, setUserAbout] = useState('');

useEffect(() => setUserAbout(() => userInfo?.user?.about),[userInfo])

  return (
    <UserInformationStyled>
      <Switch>
        <Route path='/home/information/user'>
          <InformationListUser mockData={userInformation} />
          <AboutMeTitle>
            <img src='/icons/user_inform/about_me.svg' alt='about_me' />
            <div>Про себе:</div>
          </AboutMeTitle>
          <AboutMeDescr>{userAbout}</AboutMeDescr>
        </Route>
        <Route path='/all-users/:id/information/user'>
          <InformationListUser mockData={userInformation} />
          <AboutMeTitle>
            <img src='/icons/user_inform/about_me.svg' alt='about_me' />
            <div>Про себе:</div>
          </AboutMeTitle>
          <AboutMeDescr>{userAbout}</AboutMeDescr>
        </Route>
        <Route path='/home/information/dancer'>
          <InformationListDancer mockData={dancerInformation} />
        </Route>
        <Route path='/all-users/:id/information/dancer'>
          <InformationListDancer mockData={dancerInformation} />
        </Route>
        <Route path='/home/information/trainer'>
          <InformationListTrainer mockData={trainerInformation} />
        </Route>
        <Route path='/all-users/:id/information/trainer'>
          <InformationListTrainer mockData={trainerInformation} />
        </Route>
        <Route path='/home/information/judge'>
          <InformationListJudge mockData={judgeInformation} />
        </Route>
        <Route path='/all-users/:id/information/judge'>
          <InformationListJudge mockData={judgeInformation} />
        </Route>
        <Route path='/home/information/manager'>
          <InformationListManager mockData={managerInformation} />
        </Route>
        <Route path='/all-users/:id/information/manager'>
          <InformationListManager mockData={managerInformation} />
        </Route>
      </Switch>
    </UserInformationStyled>
  );
};

export default UserInformation;
