import React from 'react';
import styled from 'styled-components';

const IconToggleStyled = styled.div`
display: none;

@media(max-width: 576px) {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: calc(50% - 20px);
	right: 20px;
	width: 40px;
	height: 40px;
	background: #FFFFFF;
	box-shadow: 1px 1px 2px #F6F6F6, -1px -1px 2px #F6F6F6;
	border-radius: 10px;
	img {
		max-width: 100%;
		transform: rotate(180deg);
		${({open}) => open ? 'transform: rotate(360deg);' : ''}
	}
}
`;

const IconToggle = ({open, setOpen}) => {
	return (
		<IconToggleStyled onClick={()=>setOpen(state=>!state)} open={open}>
			<img src='/icons/settings_page/toggle_icon.svg' alt='toggle' />
		</IconToggleStyled>
	)
}

export default IconToggle;