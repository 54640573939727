import React from 'react';
import styled from 'styled-components';

const ActionItemStyled = styled.div`
  display: flex;
  height: 44px;
  margin-top: 16px;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #445566;
  :first-child {
    margin-top: 0;
  }
  img {
    max-width: 18px;
    margin-right: 20px;
  }
`;

const ActionItem = ({title, src, onClick}) => {
	return (
	<ActionItemStyled onClick={onClick}>
		<img src={src} alt={title} />
		{title}
	</ActionItemStyled>
	)
}

export default ActionItem;