import React from 'react';
import styled from 'styled-components';
import { useResizeMode } from '../../hooks/useResizeMode';

const DescrHeaderStyled = styled.div`
  position: relative;
  padding: 10px 0;
  display: flex;
  background: #f1f2f3;
  box-shadow: -1px -1px 2px #ffffff, 1px 1px 2px #e5e5e5;
  border-radius: 5px 5px 0px 0px;

  @media(max-width: 576px) {
	padding: 8px 0;
  }
`;

const HeaderName = styled.div`
  flex: 1;
  position: relative;
  padding: 5px 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: #778899;

  @media(max-width: 576px) {
    flex: 2;
	padding: 9px 20px;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.05em;
  }
`;

const HeaderComment = styled(HeaderName)`
  flex: 1.7;
`;

const HeaderStatus = styled(HeaderName)`
  flex: 1;

  @media(max-width: 576px) {
  flex: 1;
	padding: 9px 20px 9px 16px;
  }
`;

const HeaderDivider = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 2px;
  background: #f1f2f3;
  box-shadow: 1px 1px 2px #ffffff, -1px -1px 2px rgba(174, 174, 192, 0.3);
  border-radius: 5px;
`;

const HederBottomDivider = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background: rgba(253, 253, 253, 0.75);
  box-shadow: -1px -1px 2px #ffffff, 1px 1px 2px #e5e5e5;
  border-radius: 5px;
`;

const DescrHeader = ({ name, comment, status }) => {
  const isMobile = useResizeMode();

  if (isMobile) {
    return (
      <DescrHeaderStyled>
        <HeaderName>
          Назва файлу та коментар
          <HeaderDivider></HeaderDivider>
        </HeaderName>
        <HeaderStatus>{status}</HeaderStatus>
        <HederBottomDivider></HederBottomDivider>
      </DescrHeaderStyled>
    );
  }

  return (
    <DescrHeaderStyled>
      <HeaderName>
        {name}
        <HeaderDivider></HeaderDivider>
      </HeaderName>
      <HeaderComment>
        {comment}
        <HeaderDivider></HeaderDivider>
      </HeaderComment>
      <HeaderStatus>{status}</HeaderStatus>
      <HederBottomDivider></HederBottomDivider>
    </DescrHeaderStyled>
  );
};

export default DescrHeader;
