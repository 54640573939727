import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Photo from '../gallery/photo/Photo';
import { albums } from '../../mockData/mockData';
import { useResizeMode } from '../hooks/useResizeMode';

const UserPageGalleryStyled = styled.div`
  margin-top: 25px;

  @media (max-width: 576px) {
    margin-top: 18px;
  }
`;

const GalleryTitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    display: block;
    padding: 0 10px;
    height: 30px;
    background: #f1f2f3;
    box-shadow: -1px -1px 2px #ffffff, 1px 1px 2px #e5e5e5;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #1e87a9;
    border: 1.5px solid transparent;
    :hover {
      border: 1.5px solid #1e87a9;
    }
    img {
      margin-left: 10px;
    }
  }

  @media (max-width: 576px) {
    a {
      font-weight: 500;
      font-size: 14px;
      background: transparent;
      box-shadow: none;
      padding-right: 20px;
    }
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #445566;

  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
    padding-left: 20px;
  }
`;

const PhotoWrapper = styled.div`
  background: #fff;
  margin-top: 10px;
  box-shadow: 0px 0px 2px #d4d4d4;
  border-radius: 5px;
`;

const UserPageGallery = ({ id }) => {
  const isMobile = useResizeMode();

  const album = isMobile
    ? { name: 'Фото', photos: albums[0].photos.slice(0, 3) }
    : { name: 'Фото', photos: albums[0].photos.slice(0, 4) };

  return (
    <UserPageGalleryStyled>
      <GalleryTitleBlock>
        <Title>Фотогалерея</Title>
        <Link to={`/users-albums/all-users/${id}/userGallery`}>
          Дивитись усі фото <img src="/icons/user_inform/listArrow.svg" alt="to albums" />
        </Link>
      </GalleryTitleBlock>
      <PhotoWrapper>
        <Photo isUserPhoto album={album} withOption={false} showAddItem={false} />
      </PhotoWrapper>
    </UserPageGalleryStyled>
  );
};

export default UserPageGallery;
