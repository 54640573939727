import React from 'react';
import styled from 'styled-components';

const AuthSubTitleStyled = styled.div`
  max-width: ${(p) => (p.fullWidth ? '100%' : '384px')};
  margin-top: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  @media (max-width: 1000px){
    // max-width: ${({mw1000}) => mw1000};
    // margin: ${({m1000}) => m1000};
  }
  
  @media (max-width: 480px) {
    font-size: ${({fs,fs480}) => fs || fs480 || '16px'};
    line-height: 20px;
    width: ${({w480}) => w480};
  }
  @media (max-width: 360px) {
    padding: 0;
    font-size: 15px;
    width: ${({w360}) => w360};
    //padding: ${(p) => p.p0rd};
    /* font-size: ${(p) => p.fs}; */
  }
  @media (max-width: 320px) {
    padding: 0 12px;
    font-size: 15px;
  }
`;

const AuthSubTitle = ({ title, fullWidth, pd, fs, p0rd, ta1000, mw1000, m1000 , fs480, w480, w360}) => {
  return (
    <AuthSubTitleStyled fullWidth={fullWidth} w360={w360} w480={w480} fs480={fs480} pd={pd} fs={fs} p0rd={p0rd} ta1000={ta1000} mw1000={mw1000} m1000={m1000}>
      {title}
    </AuthSubTitleStyled>
  );
};

export default AuthSubTitle;
