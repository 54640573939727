import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import BlockDivider from '../BlockDivider';

const AlbumsBlockStyled = styled.div`
	position: relative;
	padding: 20px;
`;

const Album = styled.div`
	display: flex;
	margin-top: 20px;
	:first-child {
		margin-top: 0;
	}
`;

const AlbumPhoto = styled.div`
	min-width: 75px;
	max-width: 75px;
	height: 75px;
	margin-right: 16px;
	background: #F1F2F3;
	border-radius: 2px;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 2px;
	}
`;

const AlbumTitle = styled.div`
	font-weight: 600;
	font-size: 13.5px;
	line-height: 18px;
	color: #223344;
	span {
		display: block;
		font-weight: 500;
		font-size: 13px;
		line-height: 24px;
		color: #1E87A9;
	}
`;

const AlbumsBlockMobile = ({albums, showDivider, userId}) => {

	const history = useHistory();

	return (
		<AlbumsBlockStyled>
		{
			albums.map(item=>(
				<Album onClick={()=>history.push(`/users-albums/all-users/${userId}/userGallery/${item.id}`)} key={item.id}>
					<AlbumPhoto>{item.photos[0] ? <img src={item.photos[0]} alt='album' /> : ''}</AlbumPhoto>
					<AlbumTitle>{item.name}<span>{item.photos.length} фото</span></AlbumTitle>
				</Album>
			))
		}
		{showDivider ? <BlockDivider height='2px' /> : ''}
		</AlbumsBlockStyled>
	)
}

export default AlbumsBlockMobile;