import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import AvatarBlock from "./AvatarBlock";
import LikeBlock from '../../bookmarks/bookmarksContent/bookmarksItem/LikeBlock';
import { useSelector } from 'react-redux';

const UserPromoBlockStyled = styled.div`
  box-shadow: 0px 0px 2px #d4d4d4;
  border-radius: 5px;
`;

const PromoTitle = styled.div`
  position: relative;
  padding: 15px 30px;
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  color: #223344;
  background: #fff;
  border-radius: 0 0 5px 5px;

  @media(max-width: 1200px) {
    font-size: 22px;
  }

  @media(max-width: 576px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    padding: 14px 20px;
    border-radius: 0;
  }

`;

const UserPromoBlock = ({ myPage=true, liked }) => {
  const userInfo = useSelector(state => state.userProfiles?.currentProfile)

  const [userName,setUserName] = useState('');
  const [userLastName,setUserLastName] = useState('');

  useEffect(() => {
    setUserName(() => userInfo?.user?.first_name);
    setUserLastName(() => userInfo?.user?.first_name);
  },[userInfo])
  return (
    <UserPromoBlockStyled>
      <AvatarBlock />
      <PromoTitle>
        {`${userName} `}
        {userLastName}
        {myPage ? '' : <LikeBlock isSearch liked={liked} top='15px' right='25px' />}
      </PromoTitle>
    </UserPromoBlockStyled>
  );
};

export default UserPromoBlock;
