import { api } from '../api/api';


export const getUserAlbums = (id,count) => {

  return (dispatch) => {
    api.gallery
      .getUserAlbums(id,count)
      .then(res => {
        dispatch(getUserAlbumsSuccess(res.data));
      })
      .catch(err => {
        console.log(err);
        dispatch(getUserAlbumsError('something-wrong'));
      });
  };
};

export const addUserAlbum = (title, gallery ,photos) => {
  return(dispatch) => {

    let formData = new FormData();

    formData.append('title', title);
    formData.append('gallery', gallery);
    formData.append('photos', photos);

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    // dispatch(addUserAlbumRequest());
    api.gallery
      .addUserAlbums(formData,config)
      .then(res => {
        dispatch(addUserAlbumSuccess(res.data));
      })
      .catch(err => {
        console.log(err)
        dispatch(addUserAlbumError('something-wrong'))
      })
  }
}


export const getUserPhotos = (id,count,limit) => {
  return (dispatch) => {
    // dispatch(getUserPhotosRequest());
    api.gallery
      .getUserPhotos(id,count,limit)
      .then(res => {
        dispatch(getUserPhotosSuccess(res.data));
      })
      .catch(err => {
        console.log(err);
        dispatch(getUserPhotosError('something-wrong'));
      });

  };
};

export const deleteUserPhoto = (id) => {
  return (dispatch) => {
    api.gallery
      .delUserPhoto(id)
      .then(res => {
        dispatch(deleteUserPhotoSuccess(res.data));
      })
      .catch(err => {
        console.log(err);
        dispatch(deleteUserPhotoError('something-wrong'));
      });
  };
};
export const addUserPhoto = (image, gallery, position) => {
  return (dispatch) => {

    let formData = new FormData();

    formData.append('image', image);
    formData.append('gallery', gallery);
    formData.append('position', position);

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    // dispatch(addUserPhotoRequest());
    api.gallery
      .addUserPhoto(formData, config)
      .then(res => {
        dispatch(addUserPhotoSuccess(res.data));
      })
      .catch(err => {
        console.log(err);
        dispatch(addUserPhotoError('something-wrong'));
      });
  };
};

export const addPhotoToAlbum = (photos) => {
  return(dispatch) => {
    dispatch(addPhotosToAlbum(photos))
  };
};export const handleItsAlbum = (bool) => {
  return(dispatch) => {
    dispatch(changeItsAlbum(bool))
  };
};

export const getUserAlbumsRequest = () => {
  return {
    type: 'GET-USER-ALBUM-REQUEST',
  };
};

export const getUserAlbumsSuccess = (payload) => {
  return {
    type: 'GET-USER-ALBUM-SUCCESS',
    payload,
  };
};

export const getUserAlbumsError = (err) => {
  return {
    type: 'GET-USER-ALBUM-ERROR',
    err,
  };
};
export const addUserAlbumRequest = () => {
  return {
    type: 'ADD-USER-ALBUM-REQUEST',
  };
};

export const addUserAlbumSuccess = (payload) => {
  return {
    type: 'ADD-USER-ALBUM-SUCCESS',
    payload,
  };
};

export const addUserAlbumError = (err) => {
  return {
    type: 'ADD-USER-ALBUM-ERROR',
    err,
  };
};

export const getUserPhotosRequest = () => {
  return {
    type: 'GET-USER-PHOTOS-REQUEST',
  };
};
export const getUserPhotosSuccess = (payload) => {
  return {
    type: 'GET-USER-PHOTOS-SUCCESS',
    payload,
  };
};

export const getUserPhotosError = (err) => {
  return {
    type: 'GET-USER-PHOTOS-ERROR',
    err,
  };
};

export const deleteUserPhotoRequest = () => {
  return {
    type: 'DELETE-USER-PHOTO-REQUEST',
  };
};
export const deleteUserPhotoSuccess = (payload) => {
  return {
    type: 'DELETE-USER-PHOTO-SUCCESS',
    payload,
  };
};
export const deleteUserPhotoError = (err) => {
  return {
    type: 'DELETE-USER-PHOTO-ERROR',
    err,
  };
};

export const addUserPhotoRequest = () => {
  return {
    type: 'ADD-USER-PHOTO-REQUEST',
  };
};
export const addUserPhotoSuccess = (data) => {
  return {
    type: 'ADD-USER-PHOTO-SUCCESS',
    payload: data,
  };
};
export const addUserPhotoError = (err) => {
  return {
    type: 'ADD-USER-PHOTO-ERROR',
    err,
  }
    };
export const addPhotosToAlbum = (data) => {
  return {
    type: 'ADD-PHOTOS-TO-ALBUM',
    payload: data,
  }

};
export const changeItsAlbum = (bool) => {
  return {
    type: 'CHANGE-ITS-ALBUM',
    payload: bool,
  }
}