import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import GalleryAddItem from "../GalleryAddItem";
import GalleryImgItem from "../GalleryImgItem";
import Slider from "../slider/Slider";
import PopupRemovePhoto from "../../popups/removePhoto/PopupRemovePhoto";
import PopupMoveToAlbum from "../../popups/moveToAlbum/PopupMoveToAlbum";
import { useDispatch, useSelector } from "react-redux";
import { getUserPhotos } from "../../../actions/galleryAction";

const PhotoStyled = styled.div`
  position: relative;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  background: #FFF;
  border-radius: 5px;

  @media (max-width: 576px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: ${({ showAddItem = true }) => showAddItem ? "43px" : "115px"};
    grid-gap: 4px;
    grid-auto-rows: 115px;
  }
`;

const PaginationList = styled.ul`
  display: flex;
  justify-content: center;
`;

const PaginationPages = styled.li`
  padding: 15px;

  span {
    cursor: pointer;
    color: ${({ number, activePage }) => number === activePage ? "#1E87A9" : "#445566"};
    border: ${({ number, activePage }) => number === activePage ? "1px solid #1E87A9" : "none"};
    padding: 5px 10px;
    border-radius: 10px;
    background: ${({ number, activePage }) => number === activePage ? "#f1f2f3" : "transparent"};

    :hover {
      color: #1E87A9
    }
  }
`;

const Photo = ({
                 album,
                 withOption,
                 withCheck,
                 checkedAll,
                 onCheckedAll,
                 checkedItems,
                 onCheckedItems,
                 onUnCheckedItems,
                 showAddItem,
                 isUserPhoto,

               }) => {

  const dispatch = useDispatch();



  const userId = useSelector(state => state.login?.currentUser?.id);
  const userPhotos = useSelector(state => state.userGallery?.dataPhoto?.results?.photos);
  const userPhotosCount = useSelector(state => state.userGallery?.dataPhoto?.count);
  const itsAlbum = useSelector(state => state.userGallery?.itsAlbum)



  const [photos, setPhotos] = useState([]);
  const [showPhoto, setShowPhoto] = useState(false);
  const [curentSlide, setCurentSlide] = useState(0);
  const [showPopupRemove, setShowPopupRemove] = useState(false);
  const [showPopupMove, setShowPopupMove] = useState(false);
  const [userPhotoPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(1);
  const userTotalPage = [];
  const [currentOffset, setCurrentOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [countPhotosFromAlbum, setCountPhotosFromAlbum] = useState(0)


  const paginate = (pageNumber) => {
    setCurrentOffset(() => pageNumber === 1 ? 0 : pageNumber * 7 - 7);
    setActivePage(pageNumber);
  };

  useEffect(() => {
    if (userId !== "") {
      dispatch(getUserPhotos(userId, currentOffset));
    }

  }, [userId,currentPage,currentOffset]);

  useEffect(() => {},[album])

  if (itsAlbum){
    for (let i = 1; i<=Math.ceil(album?.photos?.length / userPhotoPerPage); i++) {
      userTotalPage.push(i)
    }
  }
  else{
    for (let i = 1; i <= Math.ceil(userPhotosCount / userPhotoPerPage); i++) {
      userTotalPage.push(i);
    }
  }

  let firstIndex = 0;
  const onDragStart = (e) => {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.dropEffect = "move";
    firstIndex = photos.findIndex((item, i) => i === +e.target.getAttribute("data-id"));
  };

  const onDragEnter = (e) => {
    e.preventDefault();
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    if (e.target.hasAttribute("data-id")) {
      setPhotos((state) => {
        let lastIndex = photos.findIndex((item, i) => i === +e.target.getAttribute("data-id"));
        const sortedArr = state.slice();
        sortedArr[firstIndex] = state[lastIndex];
        sortedArr[lastIndex] = state[firstIndex];
        return sortedArr;
      });
    }
  };

  const onShowPhoto = (id) => {
    if (id === false) {
      setShowPhoto(false);
    } else {
      setShowPhoto(true);
      setCurentSlide(id);
    }
  };


  return (
    <>
      <PhotoStyled showAddItem={showAddItem}>
        <GalleryAddItem isAlbum={false} show={showAddItem} userPhotos={userPhotos} userId={userId}
                        currentOffset={currentOffset} />
        {album === undefined ? userPhotos?.map((item, i) => (
            <GalleryImgItem
              userId={userId}
              currentOffset={currentOffset}
              key={item?.id + i}
              id={item?.id}
              imgUrl={item?.image}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDragEnter={onDragEnter}
              onDrop={onDrop}
              withOption={withOption}
              withCheck={withCheck}
              checkedAll={checkedAll}
              onCheckedAll={onCheckedAll}
              checkedItems={checkedItems}
              onCheckedItems={onCheckedItems}
              onUnCheckedItems={onUnCheckedItems}
              onShowPhoto={onShowPhoto}
              setShowPopupRemove={setShowPopupRemove}
              setShowPopupMove={setShowPopupMove}
            />
          ))
          :
          album?.photos?.map((item, i) => (
            <GalleryImgItem
              userId={userId}
              currentOffset={currentOffset}
              key={item?.id + i}
              id={item?.id}
              imgUrl={item?.image}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDragEnter={onDragEnter}
              onDrop={onDrop}
              withOption={withOption}
              withCheck={withCheck}
              checkedAll={checkedAll}
              onCheckedAll={onCheckedAll}
              checkedItems={checkedItems}
              onCheckedItems={onCheckedItems}
              onUnCheckedItems={onUnCheckedItems}
              onShowPhoto={onShowPhoto}
              setShowPopupRemove={setShowPopupRemove}
              setShowPopupMove={setShowPopupMove}
            />
          ))
        }
        <Slider
          // album={userAlbum}
          show={showPhoto}
          onShowPhoto={onShowPhoto}
          curentSlide={curentSlide}
          setCurentSlide={setCurentSlide}
          isUserPhoto={isUserPhoto}
        />
        <PopupRemovePhoto show={showPopupRemove} setShow={setShowPopupRemove} />
        <PopupMoveToAlbum show={showPopupMove} setShow={setShowPopupMove} />
      </PhotoStyled>
      <PaginationList>
        {userTotalPage?.map((number, i) => (
          <PaginationPages key={number + i} number={number} activePage={activePage}>
            <span onClick={() => paginate(number)}>
              {number}
            </span>

          </PaginationPages>
        ))}
      </PaginationList>
    </>
  );
};

export default Photo;
