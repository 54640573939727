import React from 'react';
import styled from 'styled-components';
import SliderMobileHeader from '../../../../../../gallery/sliderMobile/SliderMobileHeader';
import PopupInputFileBlock from '../../PopupInputFileBlock';
import PhotoToAccountHeader from '../PhotoToAccountHeader';
import EditImgBlock from '../EditImgBlock';
import ButtonBlock from '../../../../../ButtonBlock';
import BlockDivider from '../../../../../../BlockDivider';
import { listAlertsForAvatar } from '../../../../../../../mockData/mockData';

export const ContainerStyled = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #e5e5e5;
  z-index: 10;
`;

const FormStyled = styled.form`
  height: calc(100% - 80px);
  overflow: auto;
`;

const Hint = styled.div`
  position: relative;
  padding: 11px 20px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #445566;
  background: #fff;
`;

const ButtonWrapper = styled.div`
  position: relative;
  background: #fff;
`;

const PhotoToAccountMobile = ({ show, setShow, setFile, file, onEdit, onSubmit, setImgSrc=false }) => {

	const onClosePopup = () => {
    setShow(false);
    setFile(null);
    if (setImgSrc) {
      setImgSrc(null);
    }
  }

  return (
    <ContainerStyled show={show}>
      <SliderMobileHeader
        divider={false}
        title="Зміна фото акаунту"
        withClose
        onClose={onClosePopup}
      />
      <FormStyled onSubmit={onSubmit}>
        {file ? (
          <>
            <EditImgBlock file={file} />
            <Hint>
              Перетягніть для зміни положення
              <BlockDivider height='2px' />
            </Hint>
            <ButtonWrapper>
              <ButtonBlock
                submitTitle="Зберегти зміни"
                submitActive
                secondButton="Завантажити інше фото"
								onEdit={onEdit}
              />
              <BlockDivider height='2px' />
            </ButtonWrapper>
          </>
        ) : (
          <PopupInputFileBlock setFile={setFile} labelTitle="Вибрати фото" />
        )}

        <PhotoToAccountHeader showDescr title='Увага! Завантажте лише своє особисте фото.' listItem={listAlertsForAvatar} />
      </FormStyled>
    </ContainerStyled>
  );
};

export default PhotoToAccountMobile;
