import React, { useState } from 'react';
import PopupLayout from '../PopupLayout';
import PopupContent from './PopupContent';
import { albums } from '../../../mockData/mockData';

const RemoveAlbum = ({ show, setShow, albumId }) => {
  const [inputValue, setInputValue] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const onRemove = () => {
    const albumName = albums.find((item) => item.id === albumId).name;
    if (albumName === inputValue) {
      setShow(false);
      setAlertMessage('');
			setInputValue('');
    } else {
      setAlertMessage(
        <>
          Назва альбому введена невірно!<br></br>Введіть назву ще раз і повторіть спробу.
        </>
      );
    }
  };

	const onEdit = () => {
		setShow(false);
		setAlertMessage('');
		setInputValue('');
	}

  return (
    <PopupLayout show={show} setShow={setShow} title="Видалити альбом" onAccess={onRemove} onEdit={onEdit}>
      <PopupContent
        value={inputValue}
        setValue={setInputValue}
        albumId={albumId}
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
      />
    </PopupLayout>
  );
};

export default RemoveAlbum;
