import React from 'react';
import styled from 'styled-components';
import { useLocation, Link } from 'react-router-dom';


const LinkClubStyled = styled(Link)`
  margin-left: 20px;
  //height: 100%;
  &:first-child {
    margin-left: 0px;
  }
`;

const ItemClubStyled = styled.li`
  position: relative;
  width: 120px;
  //margin: 0 12px;
  height: 100%;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #667788;
  justify-content: center;
  letter-spacing: 0.1em;
  align-items: center;
  a {
    font-weight: ${({ isActive }) => (isActive ? '600' : '500')};
    letter-spacing: ${({ isActive }) => (isActive ? '0.09em' : '0.1em')};
    color: ${({ isActive }) => (isActive ? '#1E87A9;' : '#445566;')};
  }
  :after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: ${({ isActive }) => (isActive ? '#1E87A9' : 'transparent')};
    border-radius: 5px;
  }
  :hover {
    :after {
      background: ${({ isActive }) => (isActive ? '#1E87A9' : '#778899')};
    }
  }

`;


const TabsClubItem = ({ path, title }) => {

  const location = useLocation();
  const isActive = location.pathname.indexOf(path) > -1;
  return (

    <ItemClubStyled isActive={isActive}>
      <LinkClubStyled to={path}>{title}</LinkClubStyled>
    </ItemClubStyled>

  );

};

export default TabsClubItem;