import React, { useEffect, useMemo } from "react";
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from "react-redux";
import { deleteUserPhoto, getUserPhotos } from "../../../actions/galleryAction";

const PhotoOptionsHoverStyled = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  top: calc(100% + 7px);
  right: ${({ left, isAlbum }) => (!left ? '0px' : isAlbum ? '-207px' : '-282px')};
  padding: 5px 10px;
  padding-bottom: 10px;
  width: ${({ isAlbum }) => (isAlbum ? '235px' : '310px')};
  background: #fcfcfc;
  border-radius: 5px;
  z-index: 1;
  :after {
    content: '';
    display: block;
    position: absolute;
    top: -7px;
    right: ${({ left, isAlbum }) => (!left ? '0px' : isAlbum ? '207px' : '282px')};
    width: 30px;
    height: 12px;
    background: url('/icons/checkbox_arrow.svg') center center/cover no-repeat;
    z-index: 2;
  }
`;

const OptionList = styled.ul`
  list-style: none;
`;

const OptionItem = styled.li`
  margin-top: 5px;
  padding: 5px 10px;
  height: 30px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #445566;
  :hover {
    background: #f1f2f3;
    border-radius: 2px;
  }
`;



const PhotoOptionsHover = ({ show, left, isAlbum, id, setShowPopupRemove, onShowRemoveAlbum, setShowPopupMove,userId,currentOffset }) => {

  const dispatch = useDispatch()

  const userPhotos = useSelector(state => state.userGallery?.dataPhoto?.results?.photos)

  useMemo(() => {
    dispatch(getUserPhotos(userId, currentOffset));
  },[])

  const handleDeletePhoto = (id) => {
    dispatch(deleteUserPhoto(id));

    // setShowPopupRemove(true);
  }

  const history = useHistory();

  return (
    <PhotoOptionsHoverStyled show={show} left={left} isAlbum={isAlbum}>
      {isAlbum ? (
        <OptionList>
          <OptionItem onClick={()=>{history.push(`/gallery/album/${id}/edit`)}}>Редагувати альбом</OptionItem>
          <OptionItem>Завантажити альбом</OptionItem>
          <OptionItem onClick={()=>onShowRemoveAlbum(id)} >Видалити альбом</OptionItem>
        </OptionList>
      ) : (
        <OptionList>
          <OptionItem onClick={() => handleDeletePhoto(id)} >Видалити фото</OptionItem>
          <OptionItem>Обернути ліворуч</OptionItem>
          <OptionItem>Завантажити фото</OptionItem>
          <OptionItem>Зробити обкладинкою акаунту</OptionItem>
          <OptionItem onClick={() => setShowPopupMove(true)} >Перенести в альбом</OptionItem>
        </OptionList>
      )}
    </PhotoOptionsHoverStyled>
  );
};

export default PhotoOptionsHover;
