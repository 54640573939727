import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AuthDivider from '../AuthDivider';
import AuthTitle from '../AuthTitle';
import AgreementBlockPopup from './AgreementBlockPopup';
import RegisterCheckBox from './RegisterCheckBox';
import ValidMessage from '../ValidMessage';

const AgreementBlockStyled = styled.div`
  position: relative;
  padding: 40px 0;
  @media (max-width: 1000px){
    margin: 0 auto;
    max-width: 432px;
  }
`;

const AgreementItem = styled.div`
  margin-top: 32px;
  width: 432px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const CheckBoxBlock = styled.div`
  display: flex;
  align-items: center;
`;

const CheckBoxBlockTitle = styled.div`
  max-width: 320px;
  margin-left: 16px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #223344;
`;

const AgreementLink = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #0a87aa;
  cursor: pointer;
`;

const AgreementBlock = ({ validate, personalData, setPersonalData, rule, setRule }) => {
  const [showPersonalPopup, setShowPersonalPopup] = useState(false);
  const [showRulelPopup, setShowRulePopup] = useState(false);

  const [message, setMessaage] = useState('');

  useEffect(() => {
    if (validate) {
      !personalData || !rule ? setMessaage('Необхідно поставити галочки') : setMessaage('');
    }
  }, [validate, personalData, rule]);

  return (
    <AgreementBlockStyled>
      <AuthTitle small title="Згоди" fs480="20px" p0rd="0" />
      <AgreementItem>
        <CheckBoxBlock>
          <RegisterCheckBox checked={personalData} setChecked={setPersonalData} />
          <CheckBoxBlockTitle>Згода на обробку персональних даних</CheckBoxBlockTitle>
        </CheckBoxBlock>
        <AgreementLink onClick={() => setShowPersonalPopup(true)}>Читати</AgreementLink>
      </AgreementItem>
      <AgreementItem>
        <CheckBoxBlock>
          <RegisterCheckBox checked={rule} setChecked={setRule} />
          <CheckBoxBlockTitle>Правила користування платформою</CheckBoxBlockTitle>
        </CheckBoxBlock>
        <AgreementLink onClick={() => setShowRulePopup(true)}>Читати</AgreementLink>
      </AgreementItem>
      <ValidMessage mt="34px" ml="5px" message={message} ml0rd="5px" />
      <AuthDivider mlcd="0" />
      <AgreementBlockPopup
        subtitle="Ознайомтеся, будь ласка, уважно з текстом Згоди.."
        text="Текст"
        title="Згода на обробку персональних даних"
        show={showPersonalPopup}
        setShow={setShowPersonalPopup}
        setChecked={setPersonalData}
      />
      <AgreementBlockPopup
        subtitle="Ознайомтеся, будь ласка, уважно з текстом Правила користування платформою.."
        text="Текст"
        title="Правила користування платформою"
        show={showRulelPopup}
        setShow={setShowRulePopup}
        setChecked={setRule}
      />
    </AgreementBlockStyled>
  );
};

export default AgreementBlock;
