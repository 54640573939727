import React, { useEffect } from 'react';
import ContactListItem from './ContactListItem';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const ValidateMessage = styled.span`
  display: ${({validate}) => validate ? 'flex' : 'none'};
font-size: 13px;
color:red

`

const ContactList = ({ onChange, valueObj,validate, disabled , valueEmail,valueFacebook,valueInstagram,valueTelegram,valueTikTok,setInputMail,setInputFacebook,setInputInstagram,setInputTikTok,setInputTelegram }) => {
  // const userInfo = useSelector(state => state.userProfiles?.currentProfile)
  useEffect(() => {}, [valueEmail,valueFacebook,valueTelegram,valueTikTok,valueInstagram])

  const userInfo = useSelector(state => state.userProfiles?.currentProfile?.user)

  useEffect(() => {},[userInfo])
  return (
    <ul>
      <ContactListItem
        name="email"
        // value
        mail={valueEmail}
        defaultValue={userInfo?.email}
        valueObj={valueEmail}
        onChange={setInputMail}
        iconUrl="/icons/user_inform/mail.svg"
        title="e-mail"
        disabled={disabled}

      />
      <ContactListItem
        name="instagram"
        valueObj={valueInstagram}
        defaultValue={userInfo?.instagram}
        onChange={setInputInstagram}
        iconUrl="/icons/user_inform/instagram.png"
        title="Instagram"
        disabled={disabled}

      />
      <ContactListItem
        name="facebook"
        valueObj={valueFacebook}
        defaultValue={userInfo?.facebook}
        onChange={setInputFacebook}
        iconUrl="/icons/user_inform/facebook.svg"
        title="Facebook"
        disabled={disabled}

      />
      <ContactListItem
        name="telegram"
        valueObj={valueTelegram}
        defaultValue={userInfo?.telegram}
        onChange={setInputTelegram}
        iconUrl="/icons/user_inform/telegram.svg"
        title="Telegram"
        disabled={disabled}

      />
      <ContactListItem
        name="tiktok"
        valueObj={valueTikTok}
        defaultValue={userInfo?.tiktok}
        onChange={setInputTikTok}
        iconUrl="/icons/user_inform/tiktok.svg"
        title="TikTok"
        disabled={disabled}
      />
      <ValidateMessage validate={validate}>введіть дані у вигляді http://www.example.com <br/> або адресу example@exam.net</ValidateMessage>
    </ul>
  );
};

export default ContactList;
