import React from 'react';
import styled from 'styled-components';
import SearchResultHover from './SearchResultHover';
import {useResizeMode} from '../../hooks/useResizeMode';

import { searchResult } from '../../../mockData/mockData';


const SearchHoverStyled = styled.div`
	display: ${({ show }) => (show ? '-webkit-flex' : 'none')};
	padding: 5px 11px;
	align-items: center;
  margin-top: -10px;
  position: relative;
	height: 48px;
  border: 1px solid #E7EAED;
	background: #FFFFFF;
	border-radius: ${({ isActive }) => (isActive ? '10px 10px 0 0;' : '10px;')};
	@media(max-width: 768px) {
		top: 3px;
		background: ${({ isActive }) => (isActive ? '#F6F6F6' : 'transparent')};
		box-shadow: 1px 1px 2px #FFFFFF, -1px -1px 2px rgba(212, 212, 212, 0.5);
    -moz-box-shadow: 1px 1px 2px #FFFFFF, -1px -1px 2px rgba(212, 212, 212, 0.5);
		-webkit-box-shadow: 1px 1px 2px #FFFFFF, -1px -1px 2px rgba(212, 212, 212, 0.5);
		border-radius: ${({ isActive }) => (isActive ? '14px 14px 0 0;' : '14px;')}
  }
`;

const Search = styled.form`
  width: 100%;
  input {
    padding-right: 30px;
    width: 100%;
    border: none;
    outline: none;
    margin-left: 10px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: #223344;
    ::placeholder {
      color: #778899;
      font-weight: 400;
    }
  }

	@media(max-width: 768px) {
		input {
			background: transparent;
			font-size: 15px;
			color: #778899;
     ::placeholder {
				color: #778899;
		}
      &-webkit-::placeholder {
				color: #778899;
		}
  }
`;



const SearchIcon = styled.div`
  display: ${({ show }) => (show ? 'block;' : 'none;')};

	@media(max-width: 768px) {
		display: none;
	}
`;

const CloseHover = styled.div`
	display: ${({ show }) => (show ? 'block;' : 'none;')}
	position: absolute;
	top: 14px;
	right: 14px;
`;

const SearchHover = ({ show, setSearchTerm, searchTerm }) => {



	const isMobile = useResizeMode();
  const onChangeInput = (e) => {
    setSearchTerm(e.target.value);
  };

  const isActive = !!(searchResult.length && searchTerm);

  return (
    <SearchHoverStyled show={show} isActive={isActive}>
      <SearchIcon show={!!!searchTerm }>
        <img src="/icons/icon-search.svg" alt="search" />
      </SearchIcon>
      <Search>
        <input
          onChange={onChangeInput}
          value={searchTerm}
          type="text"
          placeholder={isMobile ? 'Пошук' : 'Шукаю назву...'}
        />
      </Search>
      <CloseHover show={!!searchTerm} onClick={() => setSearchTerm('')}>
        <img src="/icons/search-close.svg" alt="close search" />
      </CloseHover>
      <SearchResultHover
        isActive={isActive}
        searchResult={searchResult}
        setSearchTerm={setSearchTerm}
      />
    </SearchHoverStyled>
  );
};

export default SearchHover;
