import React from 'react';
import styled from 'styled-components';
import CheckBoxBlock from '../../../checkBox/CheckBoxBlock';

const AlbumBodyTitleStyled = styled.div`
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
`;

const CountPhoto = styled.div`
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: #1e87a9;
`;

const ChoosedBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckedCount = styled.div`
  margin-right: 15px;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: #445566;
`;

const CheckAll = styled(CheckedCount)`
  margin-right: 0;
  > div {
    label {
      color: ${({checked}) => checked ? '#1E87A9;' : '#445566;'};
      width: unset;
      height: unset;
      background: unset;
      :after {
        content: unset;
      }
    }
    :hover {
      label {
        background: none;
      }
    }
  }
`;

const AlbumBodyTitle = ({
  album,
  onCheckedAll,
  onAddALLtoChecked,
  onRemoveAllFromChecked,
  countPhoto,
  checkedItems,
  checkedAll
}) => {
  return (
    <AlbumBodyTitleStyled>
      <CountPhoto>{album.photos.length} Фото</CountPhoto>
      <ChoosedBlock>
        <CheckedCount>Вибрано {countPhoto} фото</CheckedCount>
        <CheckAll checked={checkedAll}>
          <CheckBoxBlock
            label="Вибрати всі"
            id="advanced"
            value="advanced"
            onCheckedAll={onCheckedAll}
            onAddALLtoChecked={onAddALLtoChecked}
            onRemoveAllFromChecked={onRemoveAllFromChecked}
            checkedAll={checkedAll}
            checkedItems={checkedItems}
          />
        </CheckAll>
      </ChoosedBlock>
    </AlbumBodyTitleStyled>
  );
};

export default AlbumBodyTitle;
