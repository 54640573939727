import React from 'react';
import styled from 'styled-components';

const AddInputLinkStyled = styled.div`
  margin-top: ${p => p.mt || '32px'};
  display: ${p => p.show ? 'flex' : 'none'};
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0A87AA;
  cursor: pointer;
  @media (max-width: 1000px){
    max-width: ${({mw1000}) => mw1000};
    margin: ${({m1000}) => m1000};
  }@media (max-width: 1000px){
    max-width: ${({mw480}) => mw480};
  }
  img {
    margin-right: 10px;
  }
`;

const AddInputLink = ({title, onClick, show, mt, m1000, mw1000, mw480}) => {
  return (
    <AddInputLinkStyled mt={mt} show={show} onClick={onClick} mw1000={mw1000} m1000={m1000} mw480={mw480}>
      <img src='/icons/gallery/photo/add_photo_active.svg' alt='plus' ></img>
      {title}
    </AddInputLinkStyled>
  )
}

export default AddInputLink;