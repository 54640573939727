import React from 'react';
import UserInformationItem from './UserInformationItem';
import TrainerDancerList from './TrainerDancerList';
import ManagerTrainersList from './ManagerTrainersList';


const InformationListManager = ({ mockData }) => {
  return (
    <ul>
      <UserInformationItem
        image='/icons/dancer_inform/status.svg'
        title='Статус'
        value={mockData.status}
      >
      </UserInformationItem>

      <UserInformationItem
        image='/icons/dancer_inform/organization.svg'
        title='Організація'
        value={mockData.organization}
      >
      </UserInformationItem>

      <UserInformationItem
        image='/icons/dancer_inform/club.svg'
        title='Клуб'
        value={mockData.club}
      >
      </UserInformationItem>

      <UserInformationItem
        image='/icons/dancer_inform/trainer.svg'
        title='Тренери клубу'
        value={<ManagerTrainersList />}
      >
      </UserInformationItem>

      <UserInformationItem
        image='/icons/search/active_icons/dancers.svg'
        title='Танцюристи клубу'
        value={<TrainerDancerList value='105'/>}
      >
      </UserInformationItem>
    </ul>


  )
}

export default InformationListManager;