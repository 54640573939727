import React from 'react';
import styled from 'styled-components';

const InputLabelStyled = styled.div`
  display: ${p => p.show ? 'block' : 'none'} ;
  position: absolute;
  padding: 0 5px;
  background: #F6F6F6;
  top: 0;
  transform: translateY(-50%);
  left: ${p => p.text ? '20px' : '66px'};
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: ${p => !p.valid ? '#C70000' : p.focus ? '#0A87AA' : '#778899'};
`;

const InputLabel = ({title, focus, valid, show, text,m1000}) => {
  return (
    <InputLabelStyled m1000={m1000} text={text} valid={valid} show={focus || show} focus={focus} >{title}</InputLabelStyled>
  )
}

export default InputLabel;