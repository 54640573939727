import React, { useState } from 'react';
import styled from 'styled-components';

const DateBlockStyled = styled.div`
  display: flex;
  align-items: center;
  
`;

const DateWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
	margin-right: ${({mr}) => mr ? '15px' : 0};
	label {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    img {
      max-width: 100%;
    }
  }
  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;
    ::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
  }

  @media(max-width: 576px) {
    label {
      width: 24px;
      height: 24px;
    }
  }
`;

const DateEndWrapper = styled(DateWrapper)`
	margin-right: 15px;
	label {
		${({show}) => show ? '' : 'width: 1px;'}
		${({show}) => show ? '' : 'margin-right: 5px;'}
		img {
			display: ${({show}) => show ? 'block' : 'none'};
		}
	}
`;

const DateValue = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #556677;
  white-space: nowrap;

  @media(max-width: 576px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

const IconClose = styled.img`
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 13px;
	cursor: pointer;
`;

const DateBlock = () => {
  const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

  const onChangeStartDate = (e) => {
    setStartDate(e.target.value);
  };

	const onChangeEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const onRemoveDate = () => {
    setStartDate('');
		setEndDate('');
  };

  return (
    <DateBlockStyled>
      <DateWrapper mr={!!!endDate}>
        <label>
          <img src="/icons/calendar.svg" alt="calendar" />
          <input value={startDate} onChange={onChangeStartDate} type="date" />
        </label>
        <DateValue>
          {startDate ? startDate.split('-').reverse().join('.') : 'Вибрати період'}
        </DateValue>
      </DateWrapper>
      {startDate ? (
        <DateEndWrapper show={!!!endDate}>
          <label>
            <img src="/icons/calendar.svg" alt="calendar" />
            <input value={endDate} onChange={onChangeEndDate} type="date" />
          </label>
          <DateValue>
            {endDate ?'- ' + endDate.split('-').reverse().join('.') : 'Кінець періоду'}
          </DateValue>
        </DateEndWrapper>
      ) : (
        ''
      )}
      <IconClose
        show={!!endDate}
        onClick={onRemoveDate}
        src="/icons/close_icon.svg"
        alt="close"
      />
    </DateBlockStyled>
  );
};

export default DateBlock;
