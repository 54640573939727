import React from 'react';
import styled from 'styled-components';

const ContainerStyled = styled.div`
  margin: 0 auto;
  max-width: 904px;
  //padding: 0 15px;

  @media (max-width: 1000px) {
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
  }
  @media (max-width: 360px) {
    width: 100%;
    padding: 0 12px;
  }
  @media (max-width: 320px) {
    width: 100%;
    
  }
`;

const RegisterContainer = ({ children }) => {
  return <ContainerStyled>{children}</ContainerStyled>;
};

export default RegisterContainer;
