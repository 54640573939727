import React from 'react';
import styled from 'styled-components';
import BlockDivider from '../../../BlockDivider';

const PhotoAccountStyled = styled.div`
  position: relative;
  padding: 20px 25px;

  @media(max-width: 576px) {
    padding: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DescrBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;

const DescrBlockIcon = styled.img`
  display: block;
  margin-right: 16px;
  margin-top: 8px;

  @media(max-width: 576px) {
    max-width: 16px;
    margin-top: 6px;
  }
`;

const DescrBlockTitle = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  color: #445566;

  @media(max-width: 576px) {
    font-size: 15px;
    line-height: 24px;
  }
`;

const DescrBlockAlert = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #1e87a9;
  max-width: 600px;
  span {
    color: #778899;
  }

  @media(max-width: 576px) {
    margin-top: 12px;
    font-size: 13px;
  }
`;

const ButtonBlock = styled.button`
  position: absolute;
  top: calc(50% - 20px);
  right: 25px;
  font-weight: 500;
  font-size: 17px;
  line-height: 33px;
  color: #1e87a9;
  padding: 0 18.5px;
  background: #fff;
  border: 1.5px solid #1e87a9;
  border-radius: 8px;
  cursor: pointer;
  &:hover{
    background: #F1F2F3;

  }

  @media(max-width: 576px) {
    position: static;
    padding: 0 16px;
    font-size: 15px;
  }
`;

const PhotoAccountBlock = ({ title, alert = false, onShowPopup }) => {
  return (
    <PhotoAccountStyled>
      <Wrapper>
        <DescrBlock>
          <DescrBlockIcon src="/icons/settings_page/camera.svg" />
          <DescrBlockTitle>{title}</DescrBlockTitle>
        </DescrBlock>
        <ButtonBlock onClick={onShowPopup}>Змінити</ButtonBlock>
      </Wrapper>
      {alert ? (
        <>
          <DescrBlockAlert>
            Увага! Фото акаунту буде змінено лише після його верифікації. <span>Процедура верифікації може зайняти декілька днів.</span>
          </DescrBlockAlert>
          <BlockDivider height="2px" br="2px" />
        </>
      ) : (
        ''
      )}
    </PhotoAccountStyled>
  );
};

export default PhotoAccountBlock;
