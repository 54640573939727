import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ValidMessage from '../ValidMessage';

const InputBlockWrapper = styled.div`
  width: 432px;
  margin-top: 32px;

  @media(max-width: 480px) {
    width: 100%;
  }
`;

const InputBlockStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InputBlockTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #223344;
  img {
    margin-right: 11px;
  }
`;

const InputLabel = styled.label`
  padding: 10px 24px;
  border: 2px solid #1E87A9;
  border-radius: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #0A87AA;
  cursor: pointer;
  input {
    display: none;
  }
`;

const FileNameBlock = styled.div`
  display: block;
  position: relative;
  padding: 10px 37px 10px 12px;
  margin-top: 32px;
  box-shadow: -1px -1px 1px #FFFFFF, 1px 1px 1px #E7EAED;
  border-radius: 10px;
  word-wrap: break-word;
`;

const IconClose = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
  cursor: pointer;
`;


const RegisterFileInputBlock = ({files, onChange, onRemoveFile, multiple=false ,validate}) => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (validate) {
      if (!files.length){
        setMessage('')
      }
      else if (!files[0]?.name?.match(/.*\.(jpeg|jpg|png|pdf)$/igm)){
        setMessage('Невірний формат файлу')
      }
      else {
        setMessage('')
      }
    }else{

    }

  }, [validate, files]);
  return (
    <InputBlockWrapper>
      <InputBlockStyled>
        <InputBlockTitle>
          <img src='/icons/settings_page/file.svg' alt='file'></img>
          Вкласти файл
        </InputBlockTitle>
        <InputLabel>
          Вибрати
          <input multiple={multiple} onChange={onChange} type='file' />
        </InputLabel>
      </InputBlockStyled>
      {
        files?.map((item, i)=>(
        <FileNameBlock key={i}>
          {item.name}
          <IconClose onClick={()=>onRemoveFile(i)} src='/icons/close_icon.svg' alt='close'></IconClose>
        </FileNameBlock>
      ))
    }
      <ValidMessage message={message}/>
      {/* <FileNameBlock show={files}>
        {files ? files[0]?.name : ''}
        <IconClose onClick={onRemoveFile} src="/icons/close_icon.svg" alt='close'></IconClose>
      </FileNameBlock> */}
    </InputBlockWrapper>
  )
}

export default RegisterFileInputBlock;