import React from 'react';
import styled from 'styled-components';
import BlockDivider from '../../../../../BlockDivider';

const HeaderStyled = styled.div`
  padding: 20px 25px 15px 25px;
  position: relative;

  @media(max-width: 576px) {
    background: #FFF;
    padding: 16px 20px 20px 20px;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 19px;
  line-height: 30px;
  color: #223344;
  span {
    margin-left: 15px;
    font-size: 15px;
    line-height: 17px;
    color: #1e87a9;
  }

  @media(max-width: 576px) {
    span {
      margin-left: 0;
      font-size: 13.5px;
      line-height: 18px;
    }
  }
`;

const HeaderDescr = styled.ul`
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin-top: 11px;
  li {
    padding-left: 25px;
    position: relative;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #778899;
    :after {
      content: '.';
      top: -7px;
      left: 11px;
      font-size: 22px;
      position: absolute;
    }
  }

  @media(max-width: 576px) {
    font-size: 13px;
    line-height: 18px;
  }
`;

const PhotoAccountText = styled.div`
@media(max-width: 576px) {
  display: none;
}
`;

const PhotoToAccountHeader = ({ showDescr, title, listItem }) => {
  return (
    <HeaderStyled>
      <HeaderTitle>
        <PhotoAccountText>Фото акаунту</PhotoAccountText><span>{title}</span>
      </HeaderTitle>
      <HeaderDescr show={showDescr}>
        {
          listItem.map((item, i)=><li key={i}>{item}</li>)
        }
      </HeaderDescr>
      <BlockDivider height="2px" br="2px" />
    </HeaderStyled>
  );
};

export default PhotoToAccountHeader;
