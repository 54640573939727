import React from 'react';
import styled from 'styled-components';

const ValidMessageStyled = styled.div`
  display: ${(p) => (p.show ? 'block' : 'none')};
  margin-top: ${(p) => p.mt || p.mt480 || '6px'};
  font-weight: 500;
  margin-left: ${(p) => p.ml || p.ml360 || p.ml480 || '12px'};
  font-size: 13px;
  line-height: 18px;
  color: #c70000;
  width: 260px;
  img {
    margin-right: 8px;
    max-width: 16px;
  }
  @media (max-width: 360px) {
    //margin-left: 34px;
    margin-left: ${(p) => p.ml0rd || p.ml360};
  }
  @media (max-width: 320px) {
    //margin-left: 23px;
    //margin-left: ${(p) => p.ml0rd};
  }
`;

const ValidMessage = ({ message, mt, ml, ml0rd , ml480, mt480, ml360}) => {

  return (

    <ValidMessageStyled mt={mt} ml={ml} show={message} ml0rd={ml0rd} ml360={ml360} ml480={ml480} mt480={mt480}>
      <img src="/icons/auth/danger.svg" alt="danger"></img>
      {message}
    </ValidMessageStyled>
  );
};

export default ValidMessage;
