import React from 'react';
import styled from 'styled-components';
import AuthContainer from '../../components/auth/AuthContainer';
import AuthDivider from '../../components/auth/AuthDivider';
import AuthSubTitle from '../../components/auth/AuthSubTitle';
import AuthTitle from '../../components/auth/AuthTitle';
import RegisterContainer from '../../components/auth/register/RegisterContainer';
import RegisterNav from '../../components/auth/register/RegisterNav';
import ToLoginLink from '../../components/auth/register/ToLoginLink';

const TitlesBlock = styled.div`
  position: relative;
  padding-bottom: 40px;
`;

const AlreadyRegPage = () => {
  return (
    <RegisterContainer>
      <RegisterNav onlyBack backLink='/auth/reg_data' />
      <AuthContainer padding='0'>
        <TitlesBlock>
          <AuthTitle title='Увага!' />
          <AuthSubTitle title='Користувач з таким номером телефону вже зареєстрований на платформі.' />
          <AuthDivider />
        </TitlesBlock>
        <ToLoginLink />
      </AuthContainer>
    </RegisterContainer>
  )
}

export default AlreadyRegPage;