let initialState = {
    error: "",
    errorPhone:[],
    errorMail:[],
    registersUser: [],
    userDancer: [],
    userParent: [],
    userJudge: [],
    userTrainer: [],
    userClubMan: [],
    userPersonal: [],
    userSponsor:[]
};

const registerUsers = (state = initialState, action) => {
    switch (action.type) {
        case "REGISTER_PERSONAL_ACTION":
            return {
                ...state,
                userPersonal: action.payload
            }
        case "REGISTER_CLUB_MANAGER_ACTION":
            return {
                ...state,
                userClubMan: action.payload
            }
        case "REGISTER_USER_TRAINER_ACTION":
            return {
                ...state,
                userTrainer: action.payload
            }
        case "REGISTER_USER_JUDGE_ACTION":
            return {
                ...state,
                userJudge: action.payload
            }
        case "REGISTER_USER_DANCER_ACTION":
            return {
                ...state,
                userDancer: action.payload
            }
        case "REGISTER_USER_PARENT_ACTION":
            return {
                ...state,
                userParent: action.payload
            }
            case "REGISTER_SPONSOR_ACTION":
            return {
                ...state,
                userSponsor: action.payload
            }

        case "REGISTER_USER_ACTION":
            return {
                ...state,
                errorUser: '',
                registersUser: action.payload,

            };
//---------------------------------------------------ERRORS
        case "ERROR_PERSONAL":
            return {
                ...state,
                error: action.err
            }
            case "ERROR_SPONSOR":
            return {
                ...state,
                error: action.err
            }
        case "ERROR_CLUBMAN":
            return {
                ...state,
                error: action.err
            }
        case "ERROR_TRAINER":
            return {
                ...state,
                error: action.err
            }
        case "ERROR_JUDGE":
            return {
                ...state,
                error: action.err
            }
        case "ERROR_USER":
            return {
                ...state,
                errorUser: action.err,
                errorPhone: action.err.response.data.phone_number,
                errorMail: action.err.response.data.email,
            }
        case "ERROR_DANCER":
            return {
                ...state,
                error: action.err
            }
        case "ERROR_PARENT":
            return {
                 ...state,
                errorUser: action.err,

            }
            default:
                return {
                    ...state
                }
    }
};


export default registerUsers;