import React from 'react';
import styled from 'styled-components';

const SearchBlockStyled = styled.div`
	display: none;

	@media(max-width: 768px) {
		display: block;
    padding: 28px 20px;
		background: #F6F6F6;
  }
`;

const SearchIcon = styled.div`
position: absolute;
top: 0;
right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	background: #F6F6F6;
	box-shadow: -1px -1px 2px #FFFFFF, 1px 1px 2px #E5E5E5;
	border-radius: 14px;
	img{
		max-width: 16px;
	}
`;

const InputBlock = styled.div`
	position: relative;
	input {
		display: block;
		padding: 0 48px 0 12px;
		background: #F6F6F6;
		box-shadow: 1px 1px 2px #FFFFFF, -1px -1px 2px rgba(212, 212, 212, 0.5);
		border-radius: 14px;
		border: none;
		outline: none;
		height: 40px;
		width: 100%;
		font-weight: 500;
		font-size: 14px;
		color: #778899;
	}
`;

const CloseIcon = styled.img`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 12px;
	max-width: 15px;
`;

const BookmarksSearchMobile = ({value, setValue}) => {

	const onChange = (e) => {
		setValue(e.target.value)
	}
	
	return (
		<SearchBlockStyled>
			<InputBlock>
				<input value={value} onChange={onChange} type='text' placeholder='Введіть слово для пошуку' />
				{
					value ? 
					<CloseIcon onClick={()=>setValue('')} src="/icons/close_icon.svg" alt="close" /> :
					<SearchIcon><img src='/icons/account_search.svg' alt='search'/></SearchIcon>
				}
			</InputBlock>
		</SearchBlockStyled>
	)
}

export default BookmarksSearchMobile;