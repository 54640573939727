import React from 'react';
import styled from 'styled-components';
import SettingsBlock from '../../SettingsBlock';
import SettingsBlockTitle from '../../SettingsBlockTitle';
import BlockPermision from './BlockPermision';
import { useResizeMode } from '../../../hooks/useResizeMode';

const MediaBlock = styled.div`
  display: none;

  @media(max-width: 576px) {
    display: block;
  }
`;

const MediaBlockItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #445566;
  span {
    color: #778899;
  }
`;

const InformationAgeBlock = ({ iconUrl, title, id, checked, onChange, disabled, partner }) => {

  const isMobile = useResizeMode();

  return (
    <SettingsBlock isFlex={isMobile ? false : true}>
      {isMobile ? '' : <SettingsBlockTitle partner={partner} title={title} iconUrl={iconUrl} />}
      {isMobile ? '' : <BlockPermision id={id} checked={checked} onChange={onChange} disabled={disabled}/>}  
      <MediaBlock>
        <MediaBlockItem>
          <SettingsBlockTitle title={title} iconUrl={iconUrl} />
          <BlockPermision id={id} checked={checked} onChange={onChange} disabled={disabled}/>
        </MediaBlockItem>
        <MediaBlockItem>
          <div>Відобразити на головній сторінці</div>
          <span>{checked ? 'Публічно' : 'Приховано'}</span>
        </MediaBlockItem>
      </MediaBlock>
    </SettingsBlock>
  );
};

export default InformationAgeBlock;
