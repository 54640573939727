import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ChooseLanguageBlock from './ChooseLanguageBlock';
import MenuMobile from './MenuMobile';

const MenuMainBlockStyled = styled.div`
  position: relative;
  padding: 12px 20px 12px 33px;
  :after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #1e87a9;
    opacity: 0.9;
    box-shadow: -1.5px -1.5px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
  }
`;

const MenuMainItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  height: 36px;
  :first-child {
    margin-top: 0;
  }
  img {
    max-width: 20px;
    margin-right: 22px;
  }
  a {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.07em;
    color: #445566;
  }
`;

const MenuMainBlock = ({ setShow, onSaveSearchPrevRoute }) => {

  const location = useLocation();


  const onClinkLink = () => {
    setShow(false);
    window.scroll(0, 0);
    if (onSaveSearchPrevRoute) {
      onSaveSearchPrevRoute(location.pathname);
    }
  };

  return (
    <MenuMainBlockStyled>
      <MenuMainItem>
        <Link onClick={onClinkLink} to="/gallery/photo">
          <img src="/icons/links/galery.svg" alt="gallery" />
          Фотогалерея
        </Link>
      </MenuMainItem>
      <MenuMainItem>
        <Link onClick={onClinkLink} to="/search/users">
          <img src="/icons/links/advanced_search_icon.svg" alt="search" />
          Розширений пошук
        </Link>
      </MenuMainItem>
      <ChooseLanguageBlock />
      <MenuMainItem>
        <Link onClick={onClinkLink} to="/home/information/user">
          <img src="/icons/menu_mobile/add_role.svg" alt="add_role" />
          Додати роль користувачу
        </Link>
      </MenuMainItem>
      <MenuMainItem>
        <Link onClick={onClinkLink} to="/settings/common">
          <img src="/icons/menu_mobile/settings.svg" alt="add_role" />
          Налаштування
        </Link>
      </MenuMainItem>

    </MenuMainBlockStyled>
  );
};

export default MenuMainBlock;
