import React, { useState } from 'react';
import styled from 'styled-components';
import AuthContainer from '../../components/auth/AuthContainer';
import AuthDivider from '../../components/auth/AuthDivider';
import AuthInputPassword from '../../components/auth/AuthInputPassword';
import AuthSubTitle from '../../components/auth/AuthSubTitle';
import AuthTitle from '../../components/auth/AuthTitle';
import ButtonStroke from '../../components/auth/buttons/ButtonStroke';
import ButtonSubmit from '../../components/auth/buttons/ButtonSubmit';
import InpupHint from '../../components/auth/InputHint';
import { useValidate } from '../../components/hooks/useValidate';
import { validatePassword } from '../../utils/validatePassword';
import { validateConfirmPassword } from '../../utils/validateConfirmPassword';
import { useHistory } from 'react-router-dom';

const InputWrapper = styled.div`
  position: relative;
  padding-bottom: 46px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  @media (max-width: 360px) {
    flex-direction: column;
    justify-content: start;
    margin: 60px 24px 0;
  }
  @media (max-width: 320px) {
    flex-direction: column;
    justify-content: start;
    margin: ${(p) => p.marginCenter};
    margin: 60px 12px 0;
  }
`;

const SuccessMessage = styled.div`
  padding: 4px 0 0 10px;
  display: ${(p) => (p.show ? 'flex' : 'none')};
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #0a87aa;
  img {
    margin-right: 10px;
  }
  @media (max-width: 360px) {
    padding: 4px 0 0 38px;
  }
  @media (max-width: 320px) {
    padding: 4px 0 0 24px;
  }
`;

const ChangePasswordPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validate, setValidate] = useState(false);

  const history = useHistory();

  const newPasswordInvalid = useValidate(newPassword, validatePassword, validate);
  const confirmPasswordInvalid = useValidate(
    newPassword,
    validateConfirmPassword,
    validate,
    confirmPassword
  );

  const onSubmit = (e) => {
    e.preventDefault();

    setValidate(true);
    if (validatePassword(newPassword) || validateConfirmPassword(newPassword, confirmPassword)) {
      return;
    }

    history.push('/auth/login');
  };

  const onReset = () => {
    history.push('/auth/login');
  };

  return (
    <AuthContainer>
      <AuthTitle small title="Зміна паролю:" />
      <AuthSubTitle title="Придумайте надійний пароль, який ви не використовуєте в інших місцях" />
      <form onReset={onReset} onSubmit={onSubmit}>
        <InputWrapper>
          <AuthInputPassword
            message={newPasswordInvalid}
            mtLog="36px"
            value={newPassword}
            setValue={setNewPassword}
            placeholder="Новий пароль"
          />
          <InpupHint title="Від 5 символів, включаючи цифри, великі та малі літери" />
          <AuthInputPassword
            message={confirmPasswordInvalid}
            mtLog="36px"
            value={confirmPassword}
            setValue={setConfirmPassword}
            placeholder="Повторіть пароль"
          />
          <SuccessMessage show={validate && !confirmPasswordInvalid}>
            <img src="/icons/auth/confirm.svg" alt="confirm"></img>
            Паролі співпадають
          </SuccessMessage>
          <AuthDivider />
        </InputWrapper>
        <ButtonsContainer marginCenter="margin:0 auto">
          <ButtonSubmit
            title="Зберегти"
            isActive={newPassword && confirmPassword}
            width="210px"
            widthSd="312px"
            widthMd="210px"
            width320="296px"
          />
          <ButtonStroke
            title="Скасувати"
            width="210px"
            type="reset"
            mt="16px"
            widthSd="312px"
            widthMd="210px"
            width320="296px"
          />
        </ButtonsContainer>
      </form>
    </AuthContainer>
  );
};

export default ChangePasswordPage;
