import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const LinkStyled = styled(Link)`
  display: block;
  text-align: center;
  line-height: 20px;
  font-size: 16px;

  @media(max-width: 992px) {
    font-size: 15px;
  }

  @media(max-width: 576px) {
    font-size: 15px;
    line-height: 19px;
  }
`;

const ItemStyled = styled.li`
  position: relative;
  width: 108px;
  margin: 0 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    font-weight: ${({ isActive }) => (isActive ? '600' : '500')};
    letter-spacing: ${({ isActive }) => (isActive ? '0.09em' : '0.1em')};
    color: ${({ isActive }) => (isActive ? '#1E87A9;' : '#445566;')};
  }
  :after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -1.5px;
    width: 100%;
    height: 3px;
    background: ${({ isActive }) => (isActive ? '#1E87A9' : 'transparent')};
    border-radius: 5px;
  }
  :hover {
    :after {
      background: ${({ isActive }) => (isActive ? '#1E87A9' : '#778899')};
    }
  }

  
  @media(max-width: 992px) {
    width: 90px;
  }

  @media(max-width: 576px) {
    width: 90px;
    margin: 0 10px;
    font-size: 15px;
    :first-child {
      margin-left: 0;
    }
  }
`;

const TabsItem = ({ path, title }) => {

  const location = useLocation();
  const isActive = location.pathname.indexOf(path)>-1;
  return (
    <ItemStyled isActive={isActive}>
      <LinkStyled to={path}>{title}</LinkStyled>
    </ItemStyled>
  );
};

export default TabsItem;
