import React from 'react';
import styled from 'styled-components';

const QrCodeStyled = styled.div`
  padding: 20px 25px 40px 25px;
  background: #fff;

	@media (max-width: 576px) {
		margin-bottom: 40px;
	}
`;

const QrWrapper = styled.div`
  max-width: 280px;
  height: 280px;
  margin-bottom: 20px;
  img {
    max-width: 100%;
  }

  @media (max-width: 576px) {
		margin: 0 auto;
  }
`;

const ButtonWrapper = styled.div`
  width: 280px;
  button {
    padding: 16px 55px;
    display: block;
    margin: 0 auto;
    background-color:#47A5BF;
    border-radius: 10px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #ffffff;
    cursor: pointer;
    &:hover{
      background: #0A87AA;

    }
  }

	@media (max-width: 576px) {
		margin: 20px auto 0;
		button {
			font-size: 15px;
		}
	}
  @media (max-width: 360px) {
		margin: 1px 1px;
    button{
      margin: 0;
    }
	}
`;

const QrCode = () => {
  return (
    <QrCodeStyled>
      <QrWrapper>
        <img src="/image/qr_code.png" alt="qr code" />
      </QrWrapper>
      <ButtonWrapper>
        <button>Завантажити</button>
      </ButtonWrapper>
    </QrCodeStyled>
  );
};

export default QrCode;
