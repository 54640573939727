import React from 'react';
import { useParams } from 'react-router-dom';
import UserPageHeader from './UserPageHeader';
import { albums, dancersList } from '../../mockData/mockData';
import UserPageBlock from './UserPageBlock';
import Photo from '../gallery/photo/Photo';

const AlbumItem = () => {

	const params = useParams();
	const user = dancersList.find(item=>item.id === +params.id);
	const album = albums.find(item=>item.id === +params.albumId);

	return (
		<>
			<UserPageHeader userName={user.userName} liked={user.liked}/>
			<UserPageBlock title={album.name} count={`${album.photos.length} фото`} withClose userName={user.userName} album={album}>
			<Photo isUserPhoto album={album} withOption={false} showAddItem={false}  />
			</UserPageBlock>		
		</>
	)
}

export default AlbumItem;