export const validatePassword = (password) => {
  if (
    !password ||
    !(password.match(/[a-z]+/) &&
    password.match(/[A-Z]+/) &&
    password.match(/[0-9]+/)) ||
    !(password.length >= 8)
    ) {
    return 'Вкажіть коректний пароль'
  }

  return ''

} 