import React, { useState } from 'react';
import styled from 'styled-components';

const FooterItemStyled = styled.div`
  padding-left: 25px;
  border-left: 2px solid #f1f2f3;
  :last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    padding: 0;
    padding-top: 0;
    border-left: none;
    border-bottom: 2px solid #f1f2f3;
    :last-child {
      border-bottom: none;
    }
  }
`;

const FooterItemContentStyled = styled.div`
  max-width: 375px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #ffffff;
  > div {
    margin-top: 10px;
    :first-child {
      margin-top: 0;
    }
  }
  a {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #ffffff;
  }

  @media (max-width: 992px) {
    max-width: 260px;
    a {
      font-size: 14px;
    } 
  }

  @media (max-width: 768px) {
    display: ${({ show }) => (show ? 'block' : 'none')};
    padding: 0 40px;
    padding-bottom: 12px;
    max-width: 100%;
    font-size: 14px;
    letter-spacing: 0.05em;
    a {
      font-size: 14px;
      letter-spacing: 0.05em;
    }
    > div {
      margin-top: 4px;
    }
  }
`;

const FooterItemTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: #445566;

  @media (max-width: 768px) {
    position: relative;
    padding: 12px 40px;
    font-size: 15px;
    line-height: 24px;
    cursor: pointer;
    :after {
      content: '';
      position: absolute;
      top: 21px;
      right: 21px;
      display: block;
      width: 14px;
      height: 8px;
      background: url('/icons/dancer_inform/arrow_black.svg') center center/cover no-repeat;
      ${({ show }) => (show ? 'transform: rotate(180deg);' : null)}
    }
  }
`;

const FooterItem = ({ children, title = 'kl' }) => {
  const [show, setShow] = useState(false);

  return (
    <FooterItemStyled>
      <FooterItemTitle show={show} onClick={() => setShow((state) => !state)}>
        {title}
      </FooterItemTitle>
      <FooterItemContentStyled show={show}>{children}</FooterItemContentStyled>
    </FooterItemStyled>
  );
};

export default FooterItem;
