import React from 'react';
import styled from 'styled-components';
import BlockDivider from '../../../../../BlockDivider';

const HeaderStyled = styled.div`
	position: relative;
	padding: 20px 25px 15px 25px;
`;

const HeaderTitle = styled.div`
	font-weight: 600;
	font-size: 19px;
	line-height: 30px;
	color: #223344;
`;

const HeaderSubtitle = styled.div`
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #778899;
`;

const PhotoToWrapperHeader = () => {
	return (
		<HeaderStyled>
			<HeaderTitle>Фото на обкладинку</HeaderTitle>
			<HeaderSubtitle>Завантажте своє фото або виберіть з галереї. Рекомендований розмір обкладинки 912 x 347 px.</HeaderSubtitle>
			<BlockDivider height='2px' br='2px' />
		</HeaderStyled>
	)
}

export default PhotoToWrapperHeader;