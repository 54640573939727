import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FileInputBlock from './FileInputBlock';
import TextInputBlock from './TextInputBlock';
import BlockDivider from '../../../BlockDivider';
import ButtonBlock from '../../ButtonBlock';
import SettingsSelect from '../../SettingsSelect';
import { sportCategoryList } from '../../../../mockData/mockData';

const InputblockWrapper = styled.div`
  position: relative;
  padding: 30px 25px 25px 60px;

  @media (max-width: 576px) {
    padding: 20px;
  }
`;

const InputBlock = styled.div`
  width: 535px;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

const FormStyled = styled.form`
  @media (max-width: 576px) {
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

const MediaDescr = styled.div`
  display: none;

  @media (max-width: 576px) {
    margin-top: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #445566;
    display: block;
  }
`;

const SportCategoryForm = ({
  showContnet,
  organization,
  doc,
  files,
  setOrganization,
  setDoc,
  setFiles,
  curentOption,
  setCurentOption,
  setStatusCheck,
}) => {
  const [showAlertSelect, setShowAlertSelect] = useState(false);
  const [showAlertOrganization, setShowAlertOrganization] = useState(false);
  const [showAlertDoc, setShowAlertDoc] = useState(false);
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [searchRequest, setSearchRequest] = useState('');
  const [submitActive, setSubmitActive] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(sportCategoryList);

  useEffect(() => {
    if (files.length) {
      setSubmitActive(true);
    } else {
      setSubmitActive(false);
    }
  }, [files.length]);

  const onChangeSearchRequest = (e) => {
    setSearchRequest(e.target.value);
    setFilteredOptions(
      sportCategoryList.filter(
        (item) => item.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      )
    );
  };

  const onChooseCategory = (category) => {
    setCurentOption(category);
    setSearchRequest(category);
    setShowCategoryList(false);
    setShowAlertSelect(false);
  };

  const onCloseOptions = (e) => {
    e.stopPropagation();
    setSearchRequest('');
    setCurentOption('');
    setFilteredOptions(sportCategoryList);
    setShowCategoryList(false);
  };

  const onChangeOrganization = (e) => {
    setOrganization(e.target.value);
    if (e.target.value) {
      setShowAlertOrganization(false);
    }
  };

  const onChangeDocument = (e) => {
    setDoc(e.target.value);
    if (e.target.value) {
      setShowAlertDoc(false);
    }
  };

  const onChangeFiles = (e) => {
    const fileList = Object.values(e.target.files).filter((item) => isNaN(item));
    setFiles(fileList);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!curentOption) {
      setShowAlertSelect(true);
    }
    if (!organization) {
      setShowAlertOrganization(true);
    }
    if (!doc) {
      setShowAlertDoc(true);
    }
    setStatusCheck(true);
  };

  const onReset = () => {
    setDoc('');
    setFiles([]);
    setOrganization('');
    setCurentOption('');
    setSearchRequest('');
  };

  return (
    <FormStyled show={showContnet} onSubmit={onSubmit} onReset={onReset}>
      <InputblockWrapper>
        <InputBlock>
          <SettingsSelect
            optionsList={filteredOptions}
            isActive={!!searchRequest}
            showAlert={showAlertSelect}
            setShowOptionsList={setShowCategoryList}
            curentOption={curentOption}
            onCloseOptions={onCloseOptions}
            open={showCategoryList}
            onChooseOption={onChooseCategory}
            value={searchRequest}
            onChange={onChangeSearchRequest}
            placeholder="Виберіть спортивний розряд"
          />
          <TextInputBlock
            onChangeValue={onChangeOrganization}
            name="organization"
            value={organization}
            title="Ким присвоїно:"
            placeholder="Введіть назву державного органу"
            showAlert={showAlertOrganization}
          />
          <TextInputBlock
            descr="Наприклад: наказ №1234 від 01.01.2000р."
            onChangeValue={onChangeDocument}
            name="doc"
            value={doc}
            title="Документ:"
            placeholder="Введіть номер та дату наказу"
            showAlert={showAlertDoc}
          />
        </InputBlock>
        <MediaDescr>
          Для підтвердження інформації необхідно вкласти сканкопію підтверджуючого документу:
        </MediaDescr>
        <FileInputBlock onChangeFiles={onChangeFiles} files={files} setFiles={setFiles} />
        <BlockDivider />
      </InputblockWrapper>
      <ButtonBlock
        type="reset"
        secondButton="Скасувати"
        submitTitle="Надіслати"
        submitActive={submitActive}
      />
    </FormStyled>
  );
};

export default SportCategoryForm;
