import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import BlockDivider from '../../../../../BlockDivider';
import { useResizeMode } from '../../../../../hooks/useResizeMode';
import { drawRect, resizeRect, drawImg } from '../../../../.././../utils/photoToAvatar/resizeRect';

const Canvas = styled.canvas``;

const CanvasRect = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 576px) {
    top: 0;
  }
`;

const CanvasWrapper = styled.div`
  position: relative;
  height: 500px;
  padding: 0;

  @media (max-width: 576px) {
    height: ${({ isWrapper }) => (isWrapper ? '260px' : '500px')};
    padding: 0;
  }
`;

const HintBlock = styled.div`
  display: ${({ show, isWrapper }) => (show && isWrapper ? 'block' : 'none')};
  position: absolute;
  top: calc(50% + 43px);
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 11px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  z-index: 1;
  color: #445566;
  :after {
    content: '';
    display: block;
    position: absolute;
    top: -3px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 6px;
    background: url('/icons/checkbox_arrow.svg') center center/cover no-repeat;
    opacity: 0.7;
    z-index: 5;
  }

  @media (max-width: 576px) {
    display: none;
  }
`;

const EditImgBlockTest = ({ file, imgSrc, isWrapper = false }) => {
  const [canvasPhoto, setCanvasPhoto] = useState(null);
  const [canvasRect, setCanvasRect] = useState(null);
  const [canvasWrapperWidth, setCanvasWrapperWidth] = useState(null);
  const [canvasWrapperHeight, setCanvasWrapperHeight] = useState(null);
  const [mouseDown, setMouseDown] = useState(false);
  const [showHint, setShowHint] = useState(true);
  const [resizeFunc, setResizeFnc] = useState(false);
  const [topLeftActive, setTopLeftActive] = useState(false);
  const [topRightActive, setTopRightActive] = useState(false);
  const [bottomRightActive, setBottomRightActive] = useState(false);
  const [bottomLefttActive, setBottomRLeftActive] = useState(false);
  const [tempImgWidth, setTempImgWidth] = useState(0);
  const [tempImgHeight, setTempImgHeight] = useState(0);
  const [xTemp, setXtemp] = useState(0);
  const [yTemp, setYtemp] = useState(0);
  const isMobile = useResizeMode();

  // const [topLeftX, setTopLeftX] = useState(0);
  // const [topLeftY, setTopLeftY] = useState(0);

  const [resizeRectsPos, setResizeREctsPos] = useState([
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ]);

  const [newRectsPos, setNewResizeRectsPos] = useState([
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ]);

  useEffect(() => {
    setCanvasPhoto(document.querySelector('#canvasPhoto'));
    setCanvasRect(document.querySelector('#canvasRect'));
    setCanvasWrapperWidth(
      parseInt(window.getComputedStyle(document.querySelector('#canvasWrapper')).width)
    );
    setCanvasWrapperHeight(
      parseInt(window.getComputedStyle(document.querySelector('#canvasWrapper')).height)
    );
  }, [canvasPhoto]);

  const drawForWrapper = useCallback(drawRectForWrapper, [
    canvasPhoto,
    canvasRect,
    canvasWrapperWidth,
    isMobile,
  ]);
  const drawForAccount = useCallback(drawRectForAccount, [
    canvasRect,
    canvasWrapperWidth,
    canvasPhoto,
  ]);

  useMemo(() => {
    if (canvasPhoto && (file || imgSrc) && canvasRect) {
      const ctx = canvasPhoto.getContext('2d');
      const image = new Image();

      image.onload = () => {
        setShowHint(true);
        const wrapperHeight = canvasWrapperHeight; // 500-30;
        const wrapperWidth = canvasWrapperWidth;
        canvasPhoto.height = wrapperHeight;

        ctx.clearRect(0, 0, wrapperWidth, wrapperHeight);
        const ratio = image.width / image.height;
        const albumOrintation = ratio >= 1;
        const bigger = image.width > wrapperWidth || image.height > wrapperHeight;
        if (bigger) {
          if (albumOrintation) {
            //розмір під ширину блоку

            // const height = wrapperWidth / ratio;
            // canvasPhoto.height = height < wrapperHeight ? wrapperHeight : height;
            // const y = image.height < wrapperHeight ? (wrapperHeight - height) / 2 : 0;
            // ctx.drawImage(image, 0, y, wrapperWidth, height);

            // з фіксованою висотою

            //sdsd
            const width = image.height > wrapperHeight ? wrapperHeight * ratio : image.width;
            const height = image.height > wrapperHeight ? wrapperHeight : image.height;
            setTempImgWidth(width);
            setTempImgHeight(height);
            const x = (wrapperWidth - width) / 2;
            const y = (wrapperHeight - height) / 2;
            ctx.drawImage(image, x, y, width, height);

            // ctx.drawImage(image, -500, -500, image.width, image.height);
          } else {
            // під ширину блоку, повний по висоті

            // const width = image.width > wrapperWidth ? wrapperWidth : image.width;
            // const height = image.width > wrapperWidth ? wrapperWidth / ratio : image.height;
            // canvasPhoto.height = height;
            // const x = (canvasWrapperWidth - width) / 2;
            // ctx.drawImage(image, x, 0, width, height);

            // з фіксованою висотою

            const width =
              wrapperHeight * ratio > wrapperWidth ? wrapperWidth : wrapperHeight * ratio;
            const height =
              wrapperHeight * ratio > wrapperWidth ? wrapperWidth / ratio : wrapperHeight;
              setTempImgWidth(width);
              setTempImgHeight(height);
            const x = (wrapperWidth - width) / 2;
            const y = (wrapperHeight - height) / 2;
            ctx.drawImage(image, x, y, width, height);
          }
        } else {
          setTempImgWidth(image.width);
          setTempImgHeight(image.height);
          ctx.drawImage(
            image,
            (canvasWrapperWidth - image.width) / 2,
            (wrapperHeight - image.height) / 2,
            image.width,
            image.height
          );
        }
        if (isWrapper) {
          drawForWrapper();
        } else {
          drawForAccount();
        }
      };
      imgSrc ? (image.src = imgSrc) : (image.src = URL.createObjectURL(file));
    }
  }, [
    canvasPhoto,
    canvasRect,
    canvasWrapperWidth,
    drawForWrapper,
    file,
    imgSrc,
    isWrapper,
    drawForAccount,
    canvasWrapperHeight,
  ]);

  const onMouseDown = (e) => {
    setMouseDown(true);
    const offsetX = canvasPhoto.getBoundingClientRect().x;
    const offsetY = canvasPhoto.getBoundingClientRect().y;
    const x = e.clientX - offsetX;
    const y = e.clientY - offsetY;

    const topLeftX = x >= resizeRectsPos[0][0] - 5 && x <= resizeRectsPos[0][0] + 5;
    const topLeftY = y >= resizeRectsPos[0][1] - 5 && y <= resizeRectsPos[0][1] + 5;

    const topRightX = x >= resizeRectsPos[1][0] - 5 && x <= resizeRectsPos[1][0] + 5;
    const topRightY = y >= resizeRectsPos[1][1] - 5 && y <= resizeRectsPos[1][1] + 5;

    const bottomRightX = x >= resizeRectsPos[2][0] - 5 && x <= resizeRectsPos[2][0] + 5;
    const bottomRightY = y >= resizeRectsPos[2][1] - 5 && y <= resizeRectsPos[2][1] + 5;

    const bottomLeftX = x >= resizeRectsPos[3][0] - 5 && x <= resizeRectsPos[3][0] + 5;
    const bottomLeftY = y >= resizeRectsPos[3][1] - 5 && y <= resizeRectsPos[3][1] + 5;

    if (topLeftX && topLeftY) {
      setTopLeftActive(true);
      setTopRightActive(false);
      setBottomRightActive(false);
      setBottomRLeftActive(false);
      setResizeFnc(true);
    }
    if (topRightX && topRightY) {
      setTopRightActive(true);
      setTopLeftActive(false);
      setBottomRightActive(false);
      setBottomRLeftActive(false);
      setResizeFnc(true);
    }
    if (bottomRightX && bottomRightY) {
      setBottomRightActive(true);
      setTopRightActive(false);
      setTopLeftActive(false);
      setBottomRLeftActive(false);
      setResizeFnc(true);
    }
    if (bottomLeftX && bottomLeftY) {
      setBottomRLeftActive(true);
      setBottomRightActive(false);
      setTopRightActive(false);
      setTopLeftActive(false);
      setResizeFnc(true);
    }
  };

  const onMouseUp = (e) => {
    setMouseDown(false);

    setTopLeftActive(false);
    setTopRightActive(false);
    setBottomRightActive(false);
    setBottomRLeftActive(false);
    setResizeREctsPos(newRectsPos);

    if (resizeFunc) {
      drawImg(
        canvasPhoto.getContext('2d'),
        canvasWrapperWidth,
        canvasWrapperHeight,
        file,
        newRectsPos,
        tempImgWidth,
        tempImgHeight,
        setTempImgWidth,
        setTempImgHeight,
        xTemp,
        yTemp,
        setXtemp,
        setYtemp
      );
      drawRect(
        canvasWrapperWidth / 2 - 150,
        canvasWrapperHeight / 2 - 150,
        canvasWrapperWidth,
        canvasWrapperHeight,
        canvasRect.getContext('2d'),
        300,
        300,
        setNewResizeRectsPos
      );
      setResizeREctsPos([
        [canvasWrapperWidth / 2 - 150, canvasWrapperHeight / 2 - 150],
        [canvasWrapperWidth / 2 - 150 + 300, canvasWrapperHeight / 2 - 150],
        [canvasWrapperWidth / 2 - 150 + 300, canvasWrapperHeight / 2 - 150 + 300],
        [canvasWrapperWidth / 2 - 150, canvasWrapperHeight / 2 - 150 + 300],
      ]);
    }
    setResizeFnc(false);
  };

  const onMouseMove = (e) => {
    if (mouseDown) {
      if (isWrapper) {
        moveRectForWrapper(e);
        setShowHint(false);
      } else {
        moveRectForAccount(e);
      }
    }
  };

  const onMouseOut = () => {
    setMouseDown(false);
    setResizeFnc(false);
  };

  function drawRectForAccount() {
    const canvasHeight = canvasPhoto.getBoundingClientRect().height;
    const canvasWidth = canvasWrapperWidth;
    canvasRect.height = canvasHeight;
    canvasRect.width = canvasWidth;
    const ctxRect = canvasRect.getContext('2d');

    drawRect(
      (canvasWidth - 300) / 2,
      (canvasHeight - 300) / 2,
      canvasWidth,
      canvasHeight,
      ctxRect,
      300,
      300,
      setNewResizeRectsPos
    );
    // setTopLeftX((canvasWidth - 300) / 2);
    // setTopLeftY((canvasHeight - 300) / 2);
    setResizeREctsPos([
      [(canvasWidth - 300) / 2, (canvasHeight - 300) / 2],
      [(canvasWidth - 300) / 2 + 300, (canvasHeight - 300) / 2],
      [(canvasWidth - 300) / 2 + 300, (canvasHeight - 300) / 2 + 300],
      [(canvasWidth - 300) / 2, (canvasHeight - 300) / 2 + 300],
    ]);
  }

  function drawRectForWrapper() {
    const rectHeight = isMobile ? 150 : 347;
    const canvasHeight = canvasPhoto.getBoundingClientRect().height;
    canvasRect.height = canvasHeight;
    canvasRect.width = canvasWrapperWidth;
    const ctxRect = canvasRect.getContext('2d');
    ctxRect.clearRect(0, 0, canvasWrapperWidth, canvasHeight);
    ctxRect.fillStyle = 'rgba(246, 246, 246, 0.5)';
    ctxRect.fillRect(0, 0, canvasWrapperWidth, canvasHeight);
    ctxRect.clearRect(0, (canvasHeight - rectHeight) / 2, canvasWrapperWidth, rectHeight);
    ctxRect.strokeStyle = 'rgba(255, 255, 255, 0.5)';
    ctxRect.lineWidth = 2;
    ctxRect.strokeRect(0, (canvasHeight - rectHeight) / 2, canvasWrapperWidth, rectHeight);
    drawHint(ctxRect, canvasWrapperWidth / 2 - 20, canvasHeight / 2 - 20);
  }

  function moveRectForWrapper(e) {
    const canvasHeight = canvasPhoto.getBoundingClientRect().height;
    canvasRect.height = canvasHeight;
    canvasRect.width = canvasPhoto.getBoundingClientRect().width;
    const ctxRect = canvasRect.getContext('2d');
    const offsetY = canvasPhoto.getBoundingClientRect().y;
    const x = 0;
    let y = e.clientY - offsetY - 173;
    if (y < 0) {
      y = 0;
    }
    if (y > canvasHeight - 347) {
      y = canvasHeight - 347;
    }
    ctxRect.clearRect(0, 0, canvasWrapperWidth, canvasHeight);
    ctxRect.fillStyle = 'rgba(246, 246, 246, 0.5)';
    ctxRect.fillRect(0, 0, canvasWrapperWidth, canvasHeight);
    ctxRect.clearRect(x, y, canvasWrapperWidth, 347);
    ctxRect.strokeStyle = 'rgba(255, 255, 255, 0.5)';
    ctxRect.lineWidth = 2;
    ctxRect.strokeRect(x, y, canvasWrapperWidth, 347);
    // drawHint(ctxRect, (canvasWrapperWidth / 2) - 20, y+153)
  }

  function moveRectForAccount(e) {
    const canvasHeight = canvasPhoto.getBoundingClientRect().height;
    const canvasWidth = canvasWrapperWidth;
    canvasRect.height = canvasHeight;
    canvasRect.width = canvasWrapperWidth;
    const offsetX = canvasPhoto.getBoundingClientRect().x;
    const offsetY = canvasPhoto.getBoundingClientRect().y;
    const rectWidth = resizeRectsPos[1][0] - resizeRectsPos[0][0];
    const rectHeight = resizeRectsPos[2][1] - resizeRectsPos[1][1];
    const x = e.clientX - offsetX - rectWidth / 2;
    const y = e.clientY - offsetY - rectHeight / 2;
    const ctxRect = canvasRect.getContext('2d');

    if (resizeFunc) {
      resizeRect(
        e,
        canvasWidth,
        canvasHeight,
        ctxRect,
        resizeRectsPos,
        offsetX,
        offsetY,
        setNewResizeRectsPos,
        topLeftActive,
        topRightActive,
        bottomRightActive,
        bottomLefttActive
      );
    } else {
      drawRect(
        x,
        y,
        canvasWidth,
        canvasHeight,
        ctxRect,
        rectWidth,
        rectHeight,
        setNewResizeRectsPos
      );
    }
  }

  function drawHint(ctxRect, x, y) {
    const startX = x;
    const startY = y;
    ctxRect.beginPath();
    ctxRect.moveTo(startX + 5, startY);
    ctxRect.lineTo(startX + 35, startY);
    ctxRect.arcTo(startX + 40, startY, startX + 40, startY + 5, 5);
    ctxRect.lineTo(startX + 40, startY + 35);
    ctxRect.arcTo(startX + 40, startY + 40, startX + 35, startY + 40, 5);
    ctxRect.lineTo(startX + 5, startY + 40);
    ctxRect.arcTo(startX, startY + 40, startX, startY + 35, 5);
    ctxRect.lineTo(startX, startY + 5);
    ctxRect.arcTo(startX, startY, startX + 5, startY, 5);
    ctxRect.strokeStyle = 'rgba(255, 255, 255, 0.7)';
    ctxRect.fill();
    const image = new Image();
    image.onload = () => {
      ctxRect.drawImage(image, startX + 15, startY + 7);
    };
    image.src = '/icons/settings_page/arrow_up_down.svg';
  }

  return (
    <CanvasWrapper id="canvasWrapper" isWrapper={isWrapper}>
      <Canvas width={canvasWrapperWidth} height={canvasWrapperHeight} id="canvasPhoto"></Canvas>
      <CanvasRect
        onMouseOut={onMouseOut}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        width={canvasWrapperWidth}
        height={canvasWrapperHeight}
        id="canvasRect"
      ></CanvasRect>
      <BlockDivider />
      <HintBlock show={showHint} isWrapper={isWrapper}>
        Перетягніть для зміни положення
      </HintBlock>
    </CanvasWrapper>
  );
};

export default EditImgBlockTest;
