import React from 'react';
import styled from 'styled-components';
import BlockDivider from '../../BlockDivider';

const PopupStyled = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 74px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);

  @media (max-width: 576px) {
    position: static;
  }
`;

const PopupBlock = styled.div`
  margin: 0 auto;
  margin-top: 70px;
  width: 618px;
  height: 600px;
  background: #fff;
  box-shadow: 0px 0px 2px #d4d4d4;
  border-radius: 5px;

  @media (max-width: 576px) {
    margin-top: 0;
    width: 100%;
  }
`;

const PopupHeader = styled.div`
  position: relative;
  padding: 25px 25px 25px 25px;
  display: flex;

  @media (max-width: 576px) {
    padding: 16px 20px;
    background: #F6F6F6;
  }
`;

const TabsItem = styled.div`
  padding: 5px 15px;
  align-items: center;
  height: 35px;
  border-radius: 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.07em;
  color: #223344;

  @media (max-width: 576px) {
    font-weight: 500;
    font-size: 14px;
  }
`;

const IconClose = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  max-width: 15px;
  cursor: pointer;

  @media (max-width: 576px) {
    display: none;
  }
`;

const PopupBody = styled.div`
  display: block;
  padding: 10px 25px;
  height: calc(100% - 75px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #f1f2f3 #fff;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #f1f2f3;
    border-radius: 10px;
  }

  @media (max-width: 576px) {
    padding: 10px 20px;
  }
`;

const ListItem = styled.div`
  position: relative;
  padding: 15px 0;
  display: grid;
  grid-template-columns: 50px 172px auto;
  //grid-template-rows: 25px 20px 20px;
  column-gap: 15px;
  row-gap: 5px;
  align-items: center;


  :after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 1.5px;
    background: #fcfcfc;
  }

  @media (max-width: 576px) {
    grid-template-columns: 50px 148px auto;
  }
`;

const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  // border: 2px solid #F1F2F3;
  grid-row: 1 / 4;
  align-self: start;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const UserName = styled.div`
  grid-column: 2 / 4;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  color: #223344;
`;

const ItemName = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #1e87a9;

  img {
    max-width: 17px;
    margin-right: 11px;
    vertical-align: middle;
  }

  @media (max-width: 576px) {
    font-size: 13px;
    img {
      max-width: 12px;
    }
  }
`;
const PartnerIcon = styled.img`
  width: 50px;
  height: 50px;
  grid-row: 1 / 5;
  align-self: start;
  margin-top: 4px;
`;

const ManagerTrainersListPopup = ({ show, setShow }) => {
  // const [showDancers, setShowDancers] = useState(true);
  // const [showPartners, setShowPartners] = useState(false);

  // const onShowDancers = () => {
  //   setShowDancers(true);
  //   setShowPartners(false);
  // };
  //
  // const onShowPartners = () => {
  //   setShowDancers(false);
  //   setShowPartners(true);
  // };

  const onClose = () => {
    setShow(false);
    document.body.style.overflow = 'auto';
  };

  const trainersArr = () => {
    let arr = [];
    for (let i = 0; i < 10; i++) {
      arr.push({ name1: `Ім’я Прізвище` });
    }
    return arr;
  };

  const onClickOverlay = (e) => {
    if (e.target.classList.contains('popup')) {
      onClose();
    }
  };

  return (
    <PopupStyled className='popup' onClick={onClickOverlay} show={show}>
      <PopupBlock>
        <PopupHeader>
          <TabsItem>
            Тренери клубу
          </TabsItem>
          <IconClose onClick={onClose} src='/icons/close_icon.svg' alt='close' />
          <BlockDivider height='2px' />
        </PopupHeader>
        <PopupBody>
          <ListItem>
            <Avatar>
              <img src='/image/avatar.png' alt='avatar' />
            </Avatar>
            <UserName>Ім’я Прізвище</UserName>
            <ItemName>
              Тренер
            </ItemName>
          </ListItem>
          {trainersArr().map((item, i) => (
            <ListItem key={i}>
              <PartnerIcon src='/icons/bookmarks/new_user.svg' alt='partner' />
              <UserName>{item.name1}</UserName>
              <ItemName>
                Тренер
              </ItemName>
            </ListItem>
          ))}
        </PopupBody>
      </PopupBlock>
    </PopupStyled>
  );
};

export default ManagerTrainersListPopup;
