import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AuthTitle from '../../components/auth/AuthTitle';
import RegisterContainer from '../../components/auth/register/RegisterContainer';
import RegisterNav from '../../components/auth/register/RegisterNav';
import RadioButton from '../../components/auth/register/RadioButton';
import AuthDivider from '../../components/auth/AuthDivider';
import InputTitle from '../../components/auth/register/InputTitle';
import AuthInputText from '../../components/auth/AuthInputText';
import AuthTextArea from '../../components/auth/AuthTextArea';
import InpupHint from '../../components/auth/InputHint';
import RegisterCheckBoxBlock from '../../components/auth/register/RegisterCheckBoxBlock';
import AgreementBlockPopup from '../../components/auth/register/AgreementBlockPopup';
import ButtonsContainer from '../../components/auth/ButtonsContainer';
import ButtonSubmit from '../../components/auth/buttons/ButtonSubmit';
import ValidMessage from '../../components/auth/ValidMessage';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { registUserSponsor } from "../../actions/registerAction";

const PageSection = styled.div`
  padding: 32px 0;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  @media (max-width: 1000px){
    max-width: ${({mw1000}) => mw1000};
    margin: ${({m1000}) => m1000};
  }
  @media (max-width: 480px){
    max-width: ${({mw480}) => mw480};
  }@media (max-width: 360px){
    max-width: 296px;
  }
`;

const DescrSection = styled.div`
  padding-bottom: 40px;
  position: relative;
  @media (max-width: 1000px){
    max-width: 432px;
    margin: ${({m480}) => m480};
  }
  @media (max-width: 480px){
    max-width: ${({mw480}) => mw480};
    margin: ${({m480}) => m480};
  }@media (max-width: 360px){
    max-width: 296px;
  }
`;

const InputContainer = styled.div`
  width: 432px;
  margin-top: 44px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 44px;
  @media (max-width: 1000px){
    max-width: ${({mw1000}) => mw1000};
    margin: ${({m1000}) => m1000};
  }@media (max-width: 480px){
    max-width: ${({mw480}) => mw480};
  }@media (max-width: 360px){
    max-width: 296px;
  }
`;

const AgrSection = styled.div`
  padding: 40px 0;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  @media (max-width: 1000px){
    max-width: ${({mw1000}) => mw1000};
    margin: ${({m1000}) => m1000};
  }@media (max-width: 480px){
    max-width: ${({mw480}) => mw480};
  }@media (max-width: 360px){
    max-width: 296px;
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  span {
    margin-left: 16px;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #0A87AA;
    cursor: pointer;
  }
  @media (max-width: 1000px){
    max-width: ${({mw1000}) => mw1000};
    margin: ${({m1000}) => m1000};
  } 
  @media (max-width: 480px){
    max-width: ${({mw480}) => mw480};
  }@media (max-width: 360px){
    max-width: 296px;
  }
`;

const QuestionarySponsorPage = () => {

  const usersRole = useSelector(state => state.users.curentUser.role);
  const currentUser = useSelector(state => state.registerUsers.registersUser.data?.pk);

  const [experiance, setExperiance] = useState([
    {title: 'Так', checked: false},
    {title: 'Ні', checked: false},
  ]);
  const [experianceFlag,setExparianceFlag] = useState(0);
  const [dateFirst, setDateFirst] = useState('');
  const [countCompetitions, setCountCompetitions] = useState('');
  const [compDescr, setCompDescr] = useState('');
  const [agreement, setAgreement] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [validate, setValidate] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch()

  useEffect(()=>{
      if (experiance.filter(item => item.checked).length && agreement) {
        setFormValid(true)
      } else {
        setFormValid(false)
      }
    {
      experiance.map(item => {
          if (item.checked && item.title === 'Так') {
            setExparianceFlag(1)
          } else if (item.checked && item.title === 'Ні') {
            setExparianceFlag(0)
          }
        }
      )

    }
  }, [agreement, experiance]);

  console.log(currentUser)

  const onSubmit = (e) => {
    e.preventDefault();

    setValidate(true);
    if(!formValid) {
      return
    }
dispatch(registUserSponsor(
  currentUser,
  experianceFlag,
  dateFirst,
  countCompetitions,
  compDescr
))
    if (!usersRole.length) {
      history.push('/auth/reg_data')
    } else {
     const idx = usersRole.findIndex(item => item === 'sponsor')
      if (usersRole[idx + 1]) {
         history.push(`/auth/questionary_${usersRole[idx + 1]}`)
      } else {
        history.push('/auth/reg_add_doc')
      }
    }
  }

  return (
    <RegisterContainer>
      <RegisterNav backLink='/auth/questionary_dancer' currentStep={2} />
      <AuthTitle fs480='24px' w480='312px' mw1000='432px' m1000='0 auto' title='Анкета організатора змагань' />
      <form onSubmit={onSubmit}>
        <PageSection mw1000='432px' mw480='312px' m1000='0 auto'>
          <AuthTitle fs480='16px' w480='312px' fsz1000='20px' mw1000='432px' m1000='0 auto' small title='Організовували ви змагання раніше?' />
          {
            experiance.map((item, i)=>(
                <RadioButton
                  key={i}
                  idx={i} 
                  name='expSponsor'
                  title={item.title}
                  checked={item.checked}
                  setChecked={setExperiance}
                />
            ))
          }
          <ValidMessage ml480='5px' mt='0' message={validate && !experiance.filter(item=>item.checked).length ? 'Потрібно вибрати варіант' : ''} />
          <AuthDivider  w480='312px'/>
        </PageSection>
        <DescrSection mw480='312px' m480='32px auto 0'>
          <InputTitle mw1000='432px' mw480='312px' m1000='32px auto 0' title='Якщо «так» – то заповніть наступну інформацію:' />
          <InputContainer mw1000='432px' mw480='312px' m1000='44px auto 0'>
            <AuthInputText 
              value={dateFirst}
              setValue={setDateFirst}
              placeholder='Введіть рік'
              hint='Рік проведення першого заходу'
            />
            <AuthInputText 
              value={countCompetitions}
              setValue={setCountCompetitions}
              placeholder='Введіть число'
              hint='Кількість організованих змагань'
            />
            <div>
              <AuthTextArea 
                value={compDescr}
                setValue={setCompDescr}
                placeholder='Введіть текст'
              />
              <InpupHint title='Назви та дати найбільших (найуспішніших) організованих заходів' w480='312px'/>
            </div>
          </InputContainer>
          <AuthDivider  w480='312px'/>
        </DescrSection>
        <AgrSection mw1000='432px' mw480='312px' m1000='0 auto'>
          <AuthTitle fs480='20px' w480='312px' small title='Вимоги до організаторів' />
          <CheckBoxWrapper>
            <RegisterCheckBoxBlock 
              checked={agreement}
              setChecked={setAgreement}
              title='Прочитав та згоден виконувати дані умови'
            />
            <span onClick={()=>setShowPopup(true)}>Читати</span>
          </CheckBoxWrapper>
          <ValidMessage ml480='5px' mt='0' message={validate && !agreement ? 'Необхідна ваша згода' : ''} />
          <AuthDivider w480='312px'/>
          <AgreementBlockPopup
            subtitle='Ознайомтеся, будь ласка, уважно з текстом Вимоги до організаторів..' 
            text='Текст'
            title='Вимоги до організаторів' 
            show={showPopup} 
            setShow={setShowPopup}
            setChecked={setAgreement} />
        </AgrSection>
        <ButtonsContainer w480='312px' jc1000='center' m480='60px auto 0' h480='60px'>
          <ButtonSubmit
            isActive={formValid} 
            title='Продовжити'
            width='250px'
            width480='312px'
            // m480='0 auto'
            h480='60px'
            w360='296px'
          />
        </ButtonsContainer>
      </form>
    </RegisterContainer>
  )
}

export default QuestionarySponsorPage;