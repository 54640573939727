import React from "react";
import { Link, useLocation } from 'react-router-dom';
import styled from "styled-components";

const SearchLinkBlock = styled.div`
  a {
    display: flex;
    align-items: center;
    margin-top: 25px;
    padding: 10px;
    height: 50px;
    background: #f6f6f6;
    box-shadow: -1px -1px 2px #ffffff, 1px 1px 2px #e5e5e5;
    border-radius: 5px;
    font-weight: ${({isActive}) => isActive ? '600' : '500'};
    letter-spacing: ${({isActive}) => isActive ? '0.06em' : '0.07em'};
    font-size: 15px;
    color: ${({isActive}) => isActive ? '#1E87A9' : '#445566'};
    cursor: pointer;
    img {
      margin-right: 10px;
    }
    :hover {
      background: #F1F2F3;
      box-shadow: -1px -1px 2px #FFFFFF, 1px 1px 2px #E5E5E5;
      border-radius: 5px;
    }
  }
`;

const AdvancedSearchButton = () => {
  
  const location = useLocation();
  const isActive = location.pathname.indexOf('/search') > -1;
  const image = isActive ? '/icons/active_links/advanced_search_icon.svg' : '/icons/links/advanced_search_icon.svg';

  

  return (
    <SearchLinkBlock isActive={isActive}>
      <Link to="/search/users">
        <img src={image} alt="search_icon" />
        Розширений пошук
      </Link>
    </SearchLinkBlock>
  );
};

export default AdvancedSearchButton;
