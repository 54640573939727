import React from 'react';
import InformationSection from './informationsSection/InformationSection';
import PartnerSection from './partnerSection/PartnerSection';
import PromoSection from './promoSection/PromoSection';
import SportCategorySection from './sportCategorySection/SportCategorySection';

const SettingsCommon = () => {
  return (
    <>
      <PromoSection />
      <InformationSection />
      <PartnerSection />
      <SportCategorySection />
    </>
  );
};

export default SettingsCommon;
