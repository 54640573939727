

let initialState = {
  loginSuccess: false,
  loading: false,
  userVarify: "",
  error: "",
  userName: "Antoren Nigotis",
  email: "antoren.nigotis@gmail.com",
  statusText: "",
  errorPass:'',
  changePass: "",
  currentUser: {
    id: "",
    name: "",
    phone_number: "",
    email: "",
  },
};

export const CHANGE_USER_PASS_SUCCESS = 'CHANGE-USER-PASS-SUCCESS'
export const CHANGE_USER_PASS_ERROR = 'CHANGE-USER-PASS-ERROR'
export const CLEAR_MESSAGE = 'CLEAR-MESSAGE'
export const CLEAR_ALERT_MESSAGE = 'CLEAR-ALERT-MESSAGE'

const login = (state = initialState, action) => {
  switch (action.type) {

    case "LOGIN-REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "LOGIN-SUCCESS":
      return {
        ...state,
        loginSuccess: true,
        loading: false,
        currentUser: action.payload,
        error: "",
        statusText: "",
      };
    case "LOGIN-ERROR":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case "LOGIN-STATUS":
      return {
        ...state,
        statusText: action.payload,
        loading: false,
      };
    case "LOGIN-STATUS-RESET":
      return {
        ...state,
        statusText: "",
        loading: false,
      };
    case "LOGOUT-REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "LOGOUT-SUCCESS":
      return {
        ...state,
        loading: false,
        currentUser: null,
        loginSuccess: false,
      };

    case "LOGOUT-ERROR":
      return {
        ...state,
        error: action.payload,
      };


    case "VARIFICATION":
      return {
        ...state,
        userVarify: action.payload,
      };
    case CHANGE_USER_PASS_SUCCESS:
      return {
        ...state,
        error: "",
        errorPass: '',
        changePass: action.payload,
        loading: false,
      };
    case CHANGE_USER_PASS_ERROR:
      return {
        ...state,
        errorPass: action.err,
        loading: false,
      };
      case CLEAR_MESSAGE:
      return {
        ...state,
        changePass: '',
        loading: false,
      };
      case CLEAR_ALERT_MESSAGE:
      return {
        ...state,
        errorPass: '',
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default login;