import React from 'react';
import styled from 'styled-components';

const PopupChoosePhotoStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const IconClosePopup = styled.div`
	position: absolute;
  top: 20px;
  right: 20px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  img {
    max-width: 100%;
  }
`;

const PopupChoosePhoto = ({ children,file, setShow, setFile, setImgSrc }) => {


  const onClosePopup = () => {
    setShow(false);
    setFile(null);
    if (setImgSrc) {
      setImgSrc(null);
    }
  }

  return (
    <PopupChoosePhotoStyled>
      <IconClosePopup onClick={onClosePopup}><img src='/icons/settings_page/close.svg' alt='close'/></IconClosePopup>
      {children}
    </PopupChoosePhotoStyled>
  )
};

export default PopupChoosePhoto;
