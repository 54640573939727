import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import MenuMobile from './MenuMobile';



const MenuPanelStyled = styled.div`
  display: none;

  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 36px;
    background: rgba(119, 136, 153, 0.95);
  }
  @media (max-width: 480px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 36px;
    background: rgba(119, 136, 153, 0.95);
  }
`;

const MenuButton = styled.div`
  width: 22px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  span {
    display: block;
    height: 1.5px;
    background: #fff;
  }
`;

const MenuItemLink = styled(Link)``;

const MenuPanel = ({onSavePrevRoute, onSaveSearchPrevRoute}) => {

	const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  const onClick = () => {
    window.scroll(0, 0);
    if (onSavePrevRoute) {
      onSavePrevRoute(location.pathname);
    }
  }

  return (
    <>
    <MenuPanelStyled>
      <MenuButton onClick={() => setShowMenu(true)}>
        <span></span>
        <span></span>
        <span></span>
      </MenuButton>
      <MenuItemLink onClick={onClick} to="/payments/accounts">
        <img src="/icons/menu_mobile/payments_link.svg" alt="payments" />
      </MenuItemLink>
      <MenuItemLink onClick={onClick} to="/my_card">
        <img src="/icons/menu_mobile/my_card_link.svg" alt="card" />
      </MenuItemLink>
      <MenuItemLink onClick={onClick} to="/bookmarks">
        <img src="/icons/menu_mobile/bookmarks_link.svg" alt="bokmarks" />
      </MenuItemLink>
      <MenuItemLink onClick={onClick} to="/home/information/user">
        <img src="/icons/menu_mobile/my-page_link.svg" alt="my_page" />
      </MenuItemLink>

    </MenuPanelStyled>
      <MenuMobile onSaveSearchPrevRoute={onSaveSearchPrevRoute} show={showMenu} setShow={setShowMenu}/>
    </>
  );
};

export default MenuPanel;
