import React, { useEffect, useState } from 'react';

import UserInformationItem from './UserInformationItem';
import ContactsBlock from './ContactsBlock';
import { useSelector } from 'react-redux';
import Preloader from '../../../utils/Preloader';

const InformationListUser = ({ mockData }) => {
  const userInfo = useSelector(state => state.userProfiles?.currentProfile);

  const [userSex,setUserSex] = useState(1)
  const [userCountry, setUserCountry] = useState('')
  const [userRegion, setUserRegion] = useState('')


  useEffect(() => {
    setUserSex(() => userInfo?.user?.sex);
    setUserCountry(() => userInfo?.user?.country?.name_uk);
    setUserRegion(() => userInfo?.user?.region?.name_uk);
  },[userInfo]);



const ageResult = (nowDate, userDate) => {
      let usersYear = userDate?.split('-');
      let userYear = usersYear[0] !== undefined ? usersYear[0] : 2022;
      let age =  +nowDate - +userYear
      let str = age < 5 ? ' роки' : ' років'
      return age + str
  };

  const splittedBirthday = (birthday) => {
      let userBirthday = birthday?.split('-');
      let swapped = [userBirthday[2], userBirthday[1], userBirthday[0]];
      return swapped?.join('.');
  };
  useEffect(() => {
  }, [ageResult, splittedBirthday, userInfo]);




  return (


    <ul>

      {userInfo === null ? (
          <Preloader/>
        )
        :
        (<>
            <UserInformationItem
              image={
                mockData.male.toLowerCase() === 'чоловіча'
                  ? '/icons/user_inform/male_icon.svg'
                  : '/icons/user_inform/female_icon.svg'
              }
              title='Стать'
              value={userSex === 1 ? 'Чоловіча' : 'Жіноча'}
            />
            <UserInformationItem
              image='/icons/user_inform/age.svg'
              title='Вік'
              value={ageResult(new Date().getFullYear(), userInfo?.user?.birthdate)}
            />
            <UserInformationItem
              image='/icons/user_inform/cake.svg'
              title='Дата народження'
              value={splittedBirthday(userInfo?.user?.birthdate)}
            />
            <UserInformationItem
              image='/icons/user_inform/earth.svg'
              title='Країна'
              value={userCountry !== null? userCountry : localStorage.getItem('country')}
            />
            <UserInformationItem
              image='/icons/user_inform/map.svg'
              title='Область'
              value={userRegion !== null ? userRegion : localStorage.getItem('region')}
            />
            <UserInformationItem
              image='/icons/user_inform/contacts.svg'
              title='Контактні дані'
              value={<ContactsBlock value={mockData.contacts} />}
            />
          </>
        )}

    </ul>
  );
};

export default InformationListUser;
