import React from 'react';
import styled from 'styled-components';
import SettingsCheckBox from '../../SettingsCheckBox';

const BlockPermisionStyled = styled.div`
  display: flex;
  align-items: center;
`;

const PermisionTitle = styled.div`
  margin-right: 50px;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  color: #445566;

  @media(max-width: 576px) {
    display: none;
  }
`;

const BlockPermision = ({ id, checked, onChange }) => {
  return (
    <BlockPermisionStyled>
      <PermisionTitle>Відобразити на головній сторінці</PermisionTitle>
      <SettingsCheckBox id={id} checked={checked} onChange={onChange} />
    </BlockPermisionStyled>
  );
};

export default BlockPermision;
