export const showPopupAddress = () => {
  return (dispatch) => {
    dispatch(openAddressPopup())
  }
}

export const closePopupAddress = () => {
  return (dispatch) => {
    dispatch(closeAddressPopup())
  }
}

export const showPopupPrivacy = () => {
  return (dispatch) => {
    dispatch(openPrivacyPopup())
  }
}

export const closePopupPrivacy = () => {
  return (dispatch) => {
    dispatch(closePrivacyPopup())
  }
}

//---------------------- Types


const openAddressPopup = () => {
  return {
  type: "OPEN-ADDRESS-POPUP"
}
}

const closeAddressPopup = () => {
  return {
    type: "CLOSE-ADDRESS-POPUP"
  }
}

const openPrivacyPopup = () => {
  return {
    type: "OPEN-PRIVACY-POPUP"
  }
}

const closePrivacyPopup = () => {
  return {
    type: "CLOSE-PRIVACY-POPUP"
  }
}