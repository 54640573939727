import React from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
const SubMenuStyled = styled.div`
display: flex;
  margin-left: 50px;
  width: 200px;
  padding: 10px;
  :hover {
    background: #F1F2F3;
    box-shadow: ${({isActive}) => (isActive ? 'none' : '-1px -1px 2px #FFFFFF, 1px 1px 2px #E5E5E5;')}
    border-radius: 10px;
    
  }
`
const SubTitle = styled.div`
  margin-left: 17px;
  font-size: 16px;
  font-weight: 500;
  color: ${({isActive}) => (isActive ? '#1E87A9' : '#445566')}
`
const IconBlock = styled.div`
  margin-left: 7px;
`
const LinkStyled = styled(Link)`
  
  display: block;
  
`;
const SubMenuItem = ({name, image, path}) => {
  const location = useLocation();
  const isActive = location.pathname.indexOf(path.split('/')[1]) > -1;
  return (
    <LinkStyled to={path}>
      <SubMenuStyled isActive={isActive}>
        <IconBlock>
          <img src={isActive ? `/icons/active_links${image}` : `/icons/links${image}`}
               alt={name}/>
        </IconBlock>
        <SubTitle isActive={isActive}>{name}</SubTitle>
      </SubMenuStyled>
    </LinkStyled>
  );
};

export default SubMenuItem;