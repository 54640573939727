import React, { useEffect } from 'react';
import styled from 'styled-components';

const ButtonSubmitStyled = styled.button`
  min-width: ${(p) => p.width};
  height: 48px;
  border-radius: 10px;
  background: ${(p) => (p.isActive ? '#47A5BF' : '#A0ACB7')};
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.1em;
  color: #ffffff;
  cursor: pointer;
  margin-right: ${({mr12}) => mr12};
  
  :hover {
    background: ${(p) => (p.isActive ? '#0A87AA' : '#A0ACB7')};
    transition: all 0.4s;
  }

  @media (min-width: 1000px) {
    width: ${(p) => p.widthMd || p.width};
  }
  @media (max-width: 480px) {
    width: ${({width480}) => width480};
    margin: ${({m480}) => m480};
    margin-top: ${({mt}) => mt};
  }
  @media (max-width: 360px) {
    width: ${({w360}) => w360};
    margin-top: ${(p) => p.mt || '0'};
    margin-bottom: ${(p) => p.mb || '0'};
  }
  @media (max-width: 320px) {
    width: ${(p) => p.width320};
    margin-bottom: ${(p) => p.mb || '0'};
  }
`;

const ButtonSubmit = ({
  title,
  width,
  widthMd,
  widthSd,
  width480,
  width320,
  isActive,
  mb,
  type = 'submit',
  onClick,
  mt,
  mr12,
  m480,
  w360,
}) => {
  useEffect(() => {},[isActive])
  return (
    <ButtonSubmitStyled
      onClick={onClick}
      isActive={isActive}
      width={width}
      widthMd={widthMd}
      widthSd={widthSd}
      width320={width320}
      width480={width480}
      type={type}
      mb={mb}
      mt={mt}
      mr12={mr12}
      m480={m480}
      w360={w360}
    >
      {title}
    </ButtonSubmitStyled>
  );
};

export default ButtonSubmit;
