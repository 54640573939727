import React from 'react';
import UserPageBlock from './UserPageBlock';
import {albums} from '../../mockData/mockData';
import Photo from '../gallery/photo/Photo';

const PhotoBlock = () => {
	return (
		<UserPageBlock title='Фото' count={albums[0].photos.length}>
			<Photo isUserPhoto album={albums[0]} withOption={false} showAddItem={false} />
		</UserPageBlock>
	)
}

export default PhotoBlock;