import React from 'react';
import styled from 'styled-components';

const Descr = styled.div`
  flex: ${({ratio}) => ratio};

  @media(max-width: 1200px) {
    padding-left: 15px;
  }

  @media(max-width: 768px) {
    flex: unset;
    width: 100%;
    padding-left: 0;
  }
`;

const DescrTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #445566;

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 992px) {
    font-size: 14px;
  }

  @media(max-width: 768px) {
    margin-top: 19px;
    font-size: 14px;
    line-height: 18px;
  }
`;

const Recipient = styled.div`
  display: flex;
	margin-top: 5px;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	color: #445566;
	span {
		margin-right: 15px;
		font-size: 15px;
		line-height: 18px;
		color: #1E87A9;
	}

  @media (max-width: 992px) {
    font-size: 15px;
    span {
      font-size: 14px;
    }
  }

  @media(max-width: 768px) {
    margin-top: 19px;
    font-size: 14px;
    line-height: 18px;
    span {
      display: block;
      font-size: 13px;
      line-height: 18px;
    }
  }
`;

const RecipientTemp = styled(Recipient)`
  display: none;

  @media(max-width: 768px) {
    display: block;
    margin-top: 4px;
  }
`;

const RecipientDescr = styled.div`

  @media(max-width: 768px) {
    display: none;
  }
`;

const ItemDescrBlock = ({ account, descrRatio=3.6, }) => {
  return (
    <Descr ratio={descrRatio}>
      <DescrTitle>{account.name}</DescrTitle>
      <Recipient>
        <span>Отримувач:</span>
        <RecipientDescr>{account.recipient}</RecipientDescr>
        <RecipientTemp>СГОСТУ “Спілка громадських організацій спортивного танцю України”</RecipientTemp>
      </Recipient>
    </Descr>
  );
};

export default ItemDescrBlock;
