import React from 'react';
import styled from 'styled-components';
import {
  clubInformation
} from '../../../mockData/mockData';
import ContactsClubBlock from './ContactsClubBlock';
import ClubInformationItem from './ClubInformationItem';
import AddressClubBlock from './AddressClubBlock';

const ClubInformationStyled = styled.div`
  background: #fff;
  border-radius: 5px;
  
`
const ClubInformationList = styled.ul`
  &:first-child{
    padding-top: 30px;
    margin-top: 20px;
  }
`

const AboutMeTitle = styled.div`
  display: flex;

  img {
    margin-right: 10px;
  }

  div {
    font-weight: 500;
    font-size: 15px;
    line-height: 40px;
    color: #1e87a9;
  }

  @media (max-width: 576px) {
    img {
      max-width: 15px;
    }

    div {
      font-size: 13px;
      line-height: 24px;
    }
  }
`;

const AboutMeDescr = styled.div`
  margin-left: 40px;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #445566;

  @media (max-width: 576px) {
    font-size: 13.5px;
    line-height: 20px;
  }
`;

const AboutMeStyled = styled.div`
  padding: 21px 26px 56px;
`

const ClubInformation = () => {
  return (
    <ClubInformationStyled>
      <ClubInformationList>
        <ClubInformationItem
          image='/icons/dancer_inform/status.svg'
          title="Статус"
          value={clubInformation.status}
        />
        <ClubInformationItem
          image='/icons/dancer_inform/organization.svg'
          title="Організація"
          value={clubInformation.organization}
        />
        <ClubInformationItem
          image="/icons/clubs/date.svg"
          title="Дата заснування"
          value={clubInformation.foundationDate}
        />
        <ClubInformationItem
          image="/icons/user_inform/position.svg"
          title="Керівник клубу"
          value={clubInformation.supervisorClub}
        />
        <ClubInformationItem
          image="/icons/user_inform/earth.svg"
          title="Країна"
          value={clubInformation.country}
        />
        <ClubInformationItem
          image="/icons/user_inform/map.svg"
          title="Область"
          value={clubInformation.region}
        />
        <ClubInformationItem
          image="/icons/clubs/address.svg"
          title="Адреса"
          value={<AddressClubBlock value={clubInformation.address}/>}
        />
        <ClubInformationItem
          image="/icons/user_inform/contacts.svg"
          title="Контактні дані"
          value={<ContactsClubBlock value={clubInformation.contacts} />}
        />
      </ClubInformationList>
      <AboutMeStyled>
      <AboutMeTitle>
        <img src='/icons/user_inform/about_me.svg' alt='about_me' />
        <div>Про себе:</div>
      </AboutMeTitle>
      <AboutMeDescr>{clubInformation.aboutMe}</AboutMeDescr>
      </AboutMeStyled>
    </ClubInformationStyled>
  );
};

export default ClubInformation;