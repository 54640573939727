import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ItemOption = styled.div`
  margin-top: 20px;
  box-shadow: 1px 1px 2px #ffffff, -1px -1px 2px rgba(174, 174, 192, 0.3);
  border-radius: 10px;
`;

const Optionstitle = styled.input`
  display: block;
  width: 100%;
  ${({ wrapPlaceholder, value }) =>
    wrapPlaceholder && !!!value
      ? `
  height: 35px;
  padding-bottom: 23px;
  `
      : ``}
  border: none;
  outline: none;
  background: #f6f6f6;
  font-weight: 500;
  font-size: 15px;
  color: #1e87a9;
  letter-spacing: ${({isActive}) => isActive ? '0.05em' : '0.07em'};
  line-height: 20px;
  margin-right: 10px;
  ::placeholder {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: ${({isActive}) => isActive ? '#778899' : '#445566'};
    white-space: pre-wrap;
    letter-spacing: ${({isActive}) => isActive ? '0.05em' : '0.07em'};
  }
`;

const ArrowDown = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) ${({ open }) => (open ? ' rotate(180deg)' : '')};
  right: 0;
  cursor: pointer;
`;

const ItemOptionHeader = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  :after {
    content: '';
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 1px;
    background: rgba(253, 253, 253, 0.75);
    box-shadow: -1px -1px 2px #ffffff, 1px 1px 2px #e5e5e5;
    border-radius: 5px;
  }
  :hover {
    background: #f1f2f3;
    input {
      background: #f1f2f3;
    }
  }
`;

const SubOptions = styled.ul`
  display: ${({ show }) => (show ? 'block' : 'none')};
  max-height: 345px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #f1f2f3 #f0f0f0;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #f0f0f0;
    box-shadow: -1px -1px 2px #ffffff, 1px 1px 2px rgba(174, 174, 192, 0.5);
    border-radius: 5px;
  }
`;

const SubOptionsItem = styled.li`
  margin-top: 12px;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.07em;
  color: #445566;
  cursor: pointer;
  :first-child {
    margin-top: 0;
  }
  :hover {
    color: #1e87a9;
  }
`;

const CloseIcon = styled(ArrowDown)``;

const FilterItemOptions = ({ imgUrl, title, focusPlaceholder, subOptions = [], itemActive, value, setValue }) => {
  const [showSuboptions, setShowSuboptions] = useState(false);
  const [placeholder, setPlaceholder] = useState(title);

  useEffect(() => {
    if (!itemActive) {
      setValue('');
    } else {
      
    }
  }, [itemActive, focusPlaceholder, title, setValue]);

  useEffect(() => {
    if (showSuboptions) {
      setPlaceholder(focusPlaceholder)
    } else {
      setPlaceholder(title)
    }
  }, [showSuboptions, focusPlaceholder, title])

  const onChangeValue = (e) => {
    setValue(e.target.value);
  };

  const onFocus = () => {
    setShowSuboptions(true);
  }

  return (
    <ItemOption>
      <ItemOptionHeader isActive={showSuboptions}>
        {value || showSuboptions ? '' : <img src={imgUrl} alt={title} />}
        <Optionstitle
          wrapPlaceholder={title.length > 18}
          onFocus={onFocus}
          onChange={onChangeValue}
          value={value}
          type="text"
          placeholder={placeholder}
          isActive={showSuboptions}
        />
        {value ? (
          <CloseIcon
            onClick={() => setValue('')}
            src="/icons/search/suboptions/close.svg"
            alt="close"
          />
        ) : (
          <ArrowDown
            onClick={() => setShowSuboptions((state) => !state)}
            open={showSuboptions}
            src="/icons/search/filter_options/arrow_down.svg"
            alt="arrow down"
          />
        )}
      </ItemOptionHeader>
      <SubOptions show={showSuboptions}>
        {subOptions.map((item, i) => (
          <SubOptionsItem
            onClick={() => {
              setValue(item);
              setShowSuboptions(false);
            }}
            key={i}
          >
            {item}
          </SubOptionsItem>
        ))}
      </SubOptions>
    </ItemOption>
  );
};

export default FilterItemOptions;
