import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ActionIcon from '../albumMobilePage/ActionIcon';
import Divider from '../../galleryMedia/Divider';
import { statusesEnum } from '../../../../mockData/mockData';
import { ButtonSubmit } from '../../../styles/StyledComponents';
import AlbumStatusesBlock from '../albumMobilePage/AlbumStatusesBlock';

const EditBlockStyled = styled.div`
  position: relative;
  padding: 25px 20px;
  background: #fff;
`;

const InputStyled = styled.input`
  display: block;
  padding: 8px 12px;
  width: 100%;
  height: 40px;
  border: 1px solid #778899;
  border-radius: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #223344;
  outline: none;
  ::placeholder {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #778899;
  }
`;

const EditFooterBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const StatusBlock = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin: 0;
  }
  span {
    margin-left: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1e87a9;
  }
`;

const EditAlbumBlock = ({ album }) => {
  const [value, setValue] = useState(album.name);
  const [showStatuses, setShowStatuses] = useState(false);

  useEffect(() => {
    setValue(album.name);
  }, [album]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onShowStatuses = () => {
    setShowStatuses((state) => !state);
  };

  return (
    <EditBlockStyled>
      <form>
        <InputStyled
          value={value}
          onChange={onChange}
          type="text"
          placeholder="Введіть назву альбому..."
        />
        <EditFooterBlock>
          <StatusBlock>
            <ActionIcon
              isActive={showStatuses}
              src={statusesEnum[album.status].black}
              alt="status"
              onShowOptions={onShowStatuses}
            />
            <span>{album.photos.length} фото</span>
          </StatusBlock>
          <ButtonSubmit isActive >Зберегти</ButtonSubmit>
        </EditFooterBlock>
      </form>
      <AlbumStatusesBlock show={showStatuses} />
      <Divider />
    </EditBlockStyled>
  );
};

export default EditAlbumBlock;
