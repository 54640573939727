import React, { useEffect } from 'react';
import styled from 'styled-components';
import SettingsBlockTitle from '../../SettingsBlockTitle';
import SettingsBlock from '../../SettingsBlock';
import { ItemInput, ItemData } from './ContactListItem';
import { useSelector } from 'react-redux';

const InputBlock = styled.div`
  margin-top: 10px;
  margin-left: 18px;

  @media(max-width: 576px) {
    margin-left: 0;
  }
`;

const Input = styled(ItemInput)`
  width: 100%;
`;

const InformationAboutBlock = ({ onChange, valueObj, disabled, isMobile,valueAbout }) => {
  const userInfo = useSelector(state => state.userProfiles?.currentProfile)
  useEffect(() => {},[userInfo])
  return (
    <SettingsBlock isFlex={false}>
      <SettingsBlockTitle title="Про себе" iconUrl="/icons/user_inform/about_me.svg" />
      <InputBlock>
        {disabled ? (
          <ItemData>{valueAbout}</ItemData>
        ) : (
          <Input
            name="about"
            onChange={onChange}
            value={valueAbout}
            type="text"
            placeholder="Введіть додаткову інформацію"
            isActive={valueObj.about}
          />
        )}
      </InputBlock>
    </SettingsBlock>
  );
};

export default InformationAboutBlock;
