import React from 'react';
import styled from 'styled-components';

const MobilePageStyled = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #e5e5e5;
  z-index: ${({ zi }) => zi};
  overflow: auto;
`;

const GalleryMobilePage = ({ children, show, zi = '1' }) => {
  return (
    <MobilePageStyled zi={zi} show={show}>
        {children}
    </MobilePageStyled>
  );
};

export default GalleryMobilePage;
