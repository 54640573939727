import React from "react";
import { Route, Switch } from 'react-router-dom';
import Accounts from "./accounts/Accounts";
import Archive from "./archive/Archive";
import Paid from "./paid/Paid";

const Paiments = () => {
  return (
    <Switch>
      <Route path='/payments/accounts'>
        <Accounts />
      </Route>
      <Route path='/payments/paid'>
        <Paid />
      </Route>
      <Route path='/payments/archive'>
        <Archive />
      </Route>
    </Switch>    
  );
}

export default Paiments;
