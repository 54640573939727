import React from 'react';
import styled from 'styled-components';
import BlockDivider from '../BlockDivider';
import IconToggle from './settingsCommon/IconToggle';

const BlockHeaderStyled = styled.div`
  position: relative;
  padding: 20px 25px 15px 25px;

  @media(max-width: 576px) {
    padding: 20px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: ${({img}) => img ? '16px' : '19px'};
  line-height: 30px;
  color: #223344;
  img {
    margin-right: 16px;
  }

  @media(max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
    img {
      display: none;
    }
  }
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #778899;
  padding-left: ${({img}) => img ? '35px' : '0'};

  @media(max-width: 576px) {
    display: ${({show}) => show ? 'block' : 'none'};
    font-size: 13px;
    line-height: 20px;
    padding-left: 0;
    max-width: calc(100% - 60px);
  }
`;


const BlockHeader = ({ title, subtitle, divider=false, show, setShow, img=false }) => {

  return (
    <BlockHeaderStyled>
      <Title img={img}>{img ? <img src={img} alt='dancer' /> : ''}{title}</Title>
      <SubTitle img={img} show={show}>{subtitle}</SubTitle>
      <IconToggle open={show} setOpen={setShow} />
      {
        divider ? <BlockDivider height='2px' br='2px' bg='#1E87A9'/> : ''
      }
    </BlockHeaderStyled>
  );
};

export default BlockHeader;
