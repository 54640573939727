import axios from "axios";
import store from '../store/store';

const baseURL = 'https://phn.pmapplications.com';

const instanceLogin = axios.create({
      // withCredentials: true,
    baseURL
})


// const instanceRefresh = axios.create({baseURL});

// instanceRefresh.interceptors.request.use(config => {
//     console.log('refr')
//     config.headers.Authrization = `Bearer ${localStorage.getItem('refresh')}`;
//     return config;
// })

instanceLogin.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('access')}`;
    return config;
  })
  instanceLogin.interceptors.response.use((config) => {
    return config
  }, async (error) => {
    const originalRequest = error.config;
  
    if (error.response?.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.post(`${baseURL}/api/users/login/refresh/`, {refresh: localStorage.getItem('refresh')});
        localStorage.setItem('access', response.data?.access);
        console.log(response)
        return instanceLogin.request(originalRequest);
      } catch (e) {
        console.log(e)
        store.dispatch({type: 'LOGOUT-SUCCESS'})
      }
    }
  
    throw error;
  } )
  
  export default instanceLogin;