import React, { useState } from 'react';
import BlockHeader from '../../BlockHeader';
import SettingsSection from '../../SettingsSection';
import SettingsBlock from '../../SettingsBlock';
import SettingsSelect from '../../SettingsSelect';
import { userList } from './../../../../mockData/mockData';
import SettingsBlockTitle from '../../SettingsBlockTitle';
import styled from 'styled-components';
import SettingsCheckBox from '../../SettingsCheckBox';
import AddUserBlock from './AddUserBlock';
import { useResizeMode } from '../../../hooks/useResizeMode';
import ButtonBlockEdit from './ButtonBlockEdit';

const Overlay = styled.div`
    display: ${({show}) => show ? 'block' : 'none'};
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5)
  `;

const SelectWrapper = styled.div`
  max-width: 535px;
`;

const FormStyled = styled.form`
@media (max-width: 576px) {
  display: ${({ show }) => (show ? 'block' : 'none')};
}
`;

const AccessAcountSection = () => {
  const [searchRequest, setSearchRequest] = useState('');
  const [curentUser, setCurentUser] = useState('');
  const [checkeEditAcount, setCheckedEditAcount] = useState(false);
  const [checkedAccessPayment, setCheckedAccessPayment] = useState(false);
  const [showOptionsList, setShowOptionsList] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [filteredOptions, setFilteredOptions] = useState(userList);
  const [showContent, setShowContent] = useState(false);

  const isMobile = useResizeMode();

  const onChooseOption = (option) => {
    setCurentUser(option);
    setSearchRequest(option);
    setShowOptionsList(false);
    setShowOptionsList(false);
  };

  const onChangeSearchRequest = (e) => {
    setSearchRequest(e.target.value);
    setFilteredOptions(userList.filter(item=>item.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1))
  }

  const onCloseOptions = (e) => {
    e.stopPropagation();
    setSearchRequest('');
    setCurentUser('');
    setFilteredOptions(userList);
    setShowOptionsList(false);
  };

  const onEdit = () => {
    setDisabled(state=>!state)
  }

  const onChangeEditAcaunt = (e) => {
    setCheckedEditAcount(e.target.checked)
  }

  const onChangeAccessPayment = (e) => {
    setCheckedAccessPayment(e.target.checked)
  }

  const onSubmit = (e) => {
    e.preventDefault(e);
  }

  return (
    <SettingsSection>
      <BlockHeader
        title="Надати доступ до акаунту"
        divider={isMobile ? (showContent ? true : false) : true}
        subtitle="Користувачі з правом редагувати ваш акаунт зможуть змінювати вашу інформацію."
        show={showContent}
        setShow={setShowContent}
      />
      <FormStyled show={showContent} onSubmit={onSubmit}>
        <SettingsBlock display="block">
          <SelectWrapper>
            <SettingsSelect
              optionsList={filteredOptions}
              isActive={!!searchRequest}
              setShowOptionsList={setShowOptionsList}
              onChooseOption={onChooseOption}
              curentOption={curentUser}
              onCloseOptions={onCloseOptions}
              open={showOptionsList}
              disabled={disabled}
              withAvatar
              placeholder='Введіть Ім’я та Прізвище'
              onChange={onChangeSearchRequest}
              value={searchRequest}
            />
          </SelectWrapper>
          <Overlay show={disabled}></Overlay>
        </SettingsBlock>
				<SettingsBlock isFlex>
					<SettingsBlockTitle title='Редагувати ваш акаунт' iconUrl='/icons/settings_page/edit.svg' />
          <SettingsCheckBox onChange={onChangeEditAcaunt} id='editAcount' checked={checkeEditAcount} />
          <Overlay show={disabled}></Overlay>
				</SettingsBlock>
        <SettingsBlock isFlex >
					<SettingsBlockTitle title='Доступ до оплат' iconUrl='/icons/settings_page/access.svg' />
          <SettingsCheckBox onChange={onChangeAccessPayment} id='accessPayment' checked={checkedAccessPayment} />
          <Overlay show={disabled}></Overlay>
				</SettingsBlock>
        <ButtonBlockEdit onEdit={onEdit} submitActive={!disabled} editActive={!disabled} />
        <AddUserBlock />
      </FormStyled>
    </SettingsSection>
  );
};

export default AccessAcountSection;
