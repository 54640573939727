import React, { useState } from 'react';
import styled from 'styled-components';
import BlockHeader from '../BlockHeader';
import FileInputBlock from '../settingsCommon/sportCategorySection/FileInputBlock';
import SettingsSection from '../SettingsSection';
import CommentBlock from '../settingsCommon/partnerSection/CommentBlock';
import ButtonBlock from '../ButtonBlock';
import DescriptionSection from './DescriptionSection';
import { useResizeMode } from '../../hooks/useResizeMode';

const InputWrapper = styled.div`
  padding: 0 25px;
`;

const FormStyled = styled.form`
@media (max-width: 576px) {
  display: ${({ show }) => (show ? 'block' : 'none')};
}
`;

const SettingsExtended = () => {
  const [files, setFiles] = useState([]);
  const [comment, setComment] = useState('');
  const [showContent, setShowContent] = useState(false);
  const isMobile = useResizeMode();

  const onChangeFiles = (e) => {
    const fileList = Object.values(e.target.files).filter((item) => isNaN(item));
    setFiles(fileList);
  };

  const onChangeComment = (e) => {
    setComment(e.target.value);
  };

  return (
    <>
      <SettingsSection>
        <BlockHeader
          divider={isMobile ? (showContent ? true : false) : true}
          title="Верифікація"
          subtitle="Завантажте файл для верифікації."
          show={showContent}
          setShow={setShowContent}
        />
        <FormStyled show={showContent}>
          <InputWrapper>
            <FileInputBlock
              onChangeFiles={onChangeFiles}
              setFiles={setFiles}
              files={files}
              mt="30px"
            />
          </InputWrapper>
          <CommentBlock
            value={comment}
            onChange={onChangeComment}
            pl="25px"
            isActive={comment}
          />
          <ButtonBlock
            flexEnd
            submitActive={!!files.length}
            submitTitle="Надіслати"
            showEditButton={false}
          />
        </FormStyled>
      </SettingsSection>
      <DescriptionSection />
    </>
  );
};

export default SettingsExtended;
