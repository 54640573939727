import React from 'react';
import styled from 'styled-components';
import HeaderClub from '../components/myClub/header/HeaderClub';
import TabsClub from '../components/myClub/TabsClub/TabsClub';
import ClubCategories from '../components/myClub/ClubCategories';
import SearchClub from '../components/myClub/search/SearchClub';

const MyClubStyled = styled.div`
  display: flex;
  justify-content: space-between;
`

const MyClubMainContiner = styled.div`
max-width: 612px;
`

const MyClub = () => {
  return (
    <MyClubStyled>
      <MyClubMainContiner>
      <HeaderClub/>
      <TabsClub
        tabsArr={[
          {title: 'Головна', path: '/my_club/main'},
          {title: 'Тренери', path: '/my_club/trainers'},
          {title: 'Учасники', path: '/my_club/participants'}
        ]}
      />
      <ClubCategories/>
      </MyClubMainContiner>
      <SearchClub/>
    </MyClubStyled>
  )
}

export default MyClub;
