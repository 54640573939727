export const drawRect = (
  startX,
  startY,
  canvasWidth,
  canvasHeight,
  ctx,
  rectWidth,
  rectHeight,
  setPos
) => {
  const startXsmall = startX - 5;
  const startYsmall = startY - 5;

  ctx.clearRect(0, 0, canvasWidth, canvasHeight);
  ctx.fillStyle = 'rgba(246, 246, 246, 0.5)';
  ctx.fillRect(0, 0, canvasWidth, canvasHeight);
  ctx.clearRect(startX, startY, rectWidth, rectHeight);
  ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)';
  ctx.lineWidth = 2;
  ctx.strokeRect(startX, startY, rectWidth, rectHeight);
  ctx.strokeStyle = '#223344';
  ctx.fillStyle = '#FFF';
  ctx.lineWidth = 1;
  ctx.strokeRect(startXsmall, startYsmall, 10, 10);
  ctx.fillRect(startXsmall, startYsmall, 10, 10);
  ctx.strokeRect(startXsmall + rectWidth, startYsmall, 10, 10);
  ctx.fillRect(startXsmall + rectWidth, startYsmall, 10, 10);
  ctx.strokeRect(startXsmall, startYsmall + rectHeight, 10, 10);
  ctx.fillRect(startXsmall, startYsmall + rectHeight, 10, 10);
  ctx.strokeRect(startXsmall + rectWidth, startYsmall + rectHeight, 10, 10);
  ctx.fillRect(startXsmall + rectWidth, startYsmall + rectHeight, 10, 10);

  setPos([
    [startX, startY],
    [startX + rectWidth, startY],
    [startX + rectWidth, startY + rectHeight],
    [startX, startY + rectHeight],
  ]);
};

export const resizeRect = (
  e,
  canvasWidth,
  canvasHeight,
  ctx,
  rectsPos,
  offsetX,
  offsetY,
  setPos,
  topLeft,
  topRight,
  bottomRight,
  bottomLeft
) => {
  const x = e.clientX - offsetX;
  const y = e.clientY - offsetY;

  if (topLeft) {
    let startX = x;
    let startY = y;
    let initRectWidth = rectsPos[1][0] - rectsPos[0][0];
    let initRectHeight = rectsPos[2][1] - rectsPos[0][1];

    let rectWidth = initRectWidth - (e.clientX - rectsPos[0][0] - offsetX);
    let rectHeight = initRectHeight - (e.clientY - rectsPos[0][1] - offsetY);
    startX = rectWidth > rectHeight ? x + (rectWidth - rectHeight) : startX;
    startY = rectHeight > rectWidth ? y + (rectHeight - rectWidth) : startY;

    rectWidth = rectWidth > rectHeight ? rectHeight : rectWidth;
    rectHeight = rectHeight > rectWidth ? rectWidth : rectHeight;

    drawRect(startX, startY, canvasWidth, canvasHeight, ctx, rectWidth, rectHeight, setPos);
  }

  if (topRight) {
    let startX = rectsPos[0][0];
    let startY = rectsPos[0][1];
    let initRectWidth = rectsPos[1][0] - rectsPos[0][0];
    let initRectHeight = rectsPos[2][1] - rectsPos[1][1];
    let rectWidth = initRectWidth - (rectsPos[1][0] - x);
    let rectHeight = initRectHeight - (y - rectsPos[1][1]);
    startY = rectHeight > rectWidth ? y + (rectHeight - rectWidth) : y;
    rectWidth = rectWidth > rectHeight ? rectHeight : rectWidth;
    rectHeight = rectHeight > rectWidth ? rectWidth : rectHeight;

    drawRect(startX, startY, canvasWidth, canvasHeight, ctx, rectWidth, rectHeight, setPos);
  }

  if (bottomRight) {
    let startX = rectsPos[0][0];
    let startY = rectsPos[0][1];
    let initRectWidth = rectsPos[1][0] - rectsPos[0][0];
    let initRectHeight = rectsPos[2][1] - rectsPos[1][1];
    let rectWidth = initRectWidth - (rectsPos[1][0] - x);
    let rectHeight = initRectHeight - (rectsPos[2][1] - y);
    rectWidth = rectWidth > rectHeight ? rectHeight : rectWidth;
    rectHeight = rectHeight > rectWidth ? rectWidth : rectHeight;

    drawRect(startX, startY, canvasWidth, canvasHeight, ctx, rectWidth, rectHeight, setPos);
  }

  if (bottomLeft) {
    let startX = x;
    let startY = rectsPos[0][1];
    let initRectWidth = rectsPos[1][0] - rectsPos[0][0];
    let initRectHeight = rectsPos[2][1] - rectsPos[1][1];
    let rectWidth = initRectWidth - (x - rectsPos[0][0]);
    let rectHeight = initRectHeight - (rectsPos[2][1] - y);
    startX = rectWidth > rectHeight ? x + (rectWidth - rectHeight) : startX;
    rectWidth = rectWidth > rectHeight ? rectHeight : rectWidth;
    rectHeight = rectHeight > rectWidth ? rectWidth : rectHeight;

    drawRect(startX, startY, canvasWidth, canvasHeight, ctx, rectWidth, rectHeight, setPos);
  }
};

export const drawImg = (
  ctx,
  wrapperWidth,
  wrapperHeight,
  file,
  rectsPos,
  tempImgWidth,
  tempImgHeight,
  setTempImgWidth,
  setTempImgHeight,
  xTemp,
  yTemp,
  setXtemp,
  setYtemp
) => {
  const image = new Image();

  image.onload = () => {
    ctx.clearRect(0, 0, wrapperWidth, wrapperHeight);
    let rectWidth = rectsPos[1][0] - rectsPos[0][0];
    let scale = 300 / rectWidth;
    const centerX = rectsPos[0][0] + (rectsPos[1][0] - rectsPos[0][0]) / 2;
    const centerY = rectsPos[0][1] + (rectsPos[1][0] - rectsPos[0][0]) / 2;
    let width = tempImgWidth * scale;
    let height = tempImgHeight * scale;
    let x = xTemp ? xTemp : (wrapperWidth - width) / 2;
    let y = yTemp ? yTemp : (wrapperHeight - height) / 2;
    const centerCanvasX = wrapperWidth / 2;
    const centerCanvasY = wrapperHeight / 2;
    const curentOffsetX = centerCanvasX - centerX;
    const curentOffsetY = centerCanvasY - centerY;
    const curentPosXInSmallImg = centerX - x;
    const curentPosYInSmallImg = centerY - y;
    const curentPosXInBigImg = curentPosXInSmallImg * scale;
    const curentPosYInBigImg = curentPosYInSmallImg * scale;
    x = !xTemp ? x + curentOffsetX * scale : centerCanvasX - curentPosXInBigImg;
    y = !yTemp ? y + curentOffsetY * scale : centerCanvasY - curentPosYInBigImg;
    setXtemp(x);
    setYtemp(y);
    setTempImgWidth(width);
    setTempImgHeight(height);

    ctx.drawImage(image, x, y, width, height);
  };
  image.src = URL.createObjectURL(file);
};
