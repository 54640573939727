import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeaderStyled = styled.header`
  min-height: 70px;
  box-shadow: 0px 1px 0px #E7EAED;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Headerlogo = styled.div`
  width: 115px;
  cursor: pointer;
  img {
    max-width: 100%;
  }
`;

const LogInHeader = () => {
  return (
    <HeaderStyled>
      <Headerlogo>
        <Link to='/'>
        <img src='/icons/logo_active.svg' alt='logo'/>
        </Link>
      </Headerlogo>
    </HeaderStyled>
  )
}

export default LogInHeader;