import React, { useEffect } from "react";
import styled from "styled-components";
import AuthInputText from "../AuthInputText";
import AuthSelect from "../AuthSelect";
import AuthInputPhone from "../AuthInputPhone";
import AuthInputDate from "../AuthInputDate";
import AuthInputPassword from "../AuthInputPassword";
import AuthInputEmail from "../AuthInputEmail.js";
import AuthDivider from "../AuthDivider";
import { useDispatch, useSelector } from "react-redux";
import { getUserCountry, getUserRegion } from "../../../actions/countryAction";
import AuthSelectForId from "../AuthSelectForId";
import AuthSelectLocations from "./AuthSelectLocations";


const ContactDataBlockStyled = styled.div`
  position: relative;
  padding-bottom: 40px;
  @media (max-width: 1000px) {
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //min-width: 432px;
  }
  // @media (max-width: 480px){
    //   margin: ${({ m480 }) => m480};
  // }

  @media (max-width: 360px) {
    margin-top: 0;
  }
`;

const InputsContainer = styled.div`
  margin-top: 22px;
  display: flex;
  justify-content: space-between;
  min-height: 95px;

  :first-child {
    margin-top: 44px;
  }

  @media (max-width: 1000px) {
    margin-top: 0;
    flex-direction: column;
    justify-content: flex-start;
  }
  @media (max-width: 480px) {
    max-width: 312px;
    margin: 0 auto;
    :first-child {
      margin-top: 0;
    }
  }
  @media (max-width: 360px) {
    max-width: 296px;
    :first-child {
      margin-top: 0;
    }
  }
`;
const InputWrapper = styled.div`
  min-width: 432px;

  @media (max-width: 1000px) {
    margin: 36px auto 0;
    //margin-top: 36px;
    //width: 100%;
  }
  @media (max-width: 480px) {
    max-width: 312px;
    min-width: 312px;
  }
  @media (max-width: 360px) {
    max-width: 296px;
    min-width: 296px;
  }
`;

const ContactDataBlock = ({
  validPhone,validMail,setValidPhone,setValidMail,
                            validate,
                            phoneValidMessage,
                            passwordValidMessage,
                            passwordValidMessageConf,
                            emailValidMessage,
                            inputValidName,
                            inputValidLastName,
                            inputValidNameLatin,
                            inputValidLastNameLatin,
                            inputValidDate,
                            name,
                            setName,
                            nameLatin,
                            setNameLatin,
                            birthday,
                            setBirthDay,
                            country,
                            countryId,
                            setCountry,
                            setCountryId,
                            countryList,
                            phone,
                            setPhone,
                            password,
                            setPassword,
                            passwordConf,
                            setPasswordConf,
                            lastName,
                            setLastName,
                            lastNameLatin,
                            setLastNameLatin,
                            sex,
                            setSex,
                            region,
                            regionList,
                            regionId,
                            setRegion,
                            setRegionId,
                            email,
                            setEmail,
                            realizeDateField,
                            backSpaceButton,
                          }) => {

  // console.log(countryList)

  return (

    <ContactDataBlockStyled m480='0 auto'>
      <InputsContainer>
        <InputWrapper>
          <AuthInputText
            validate={validate}
            message={inputValidName}
            hint='(як у паспорті)'
            value={name}
            lang={"UA"}
            setValue={(e) => setName(e)}
            placeholder='Ім’я українською мовою'
            m1000='0 auto'
          />
        </InputWrapper>
        <InputWrapper>
          <AuthInputText
            validate={validate}
            message={inputValidLastName}
            hint='(як у паспорті)'
            value={lastName}
            lang={"UA"}
            setValue={(e) => setLastName(e)}
            placeholder='Прізвище українською мовою'
            m1000='0 auto'
          />
        </InputWrapper>
      </InputsContainer>
      <InputsContainer>
        <InputWrapper>
          <AuthInputText
            validate={validate}
            message={inputValidNameLatin}
            hint='(як у закордонному паспорті)'
            value={nameLatin}
            lang={"ENG"}
            setValue={(e) => setNameLatin(e)}
            placeholder='Ім’я латиницею'
            m1000='0 auto'
          />
        </InputWrapper>
        <InputWrapper>
          <AuthInputText
            validate={validate}
            message={inputValidLastNameLatin}
            hint='(як у закордонному паспорті)'
            value={lastNameLatin}
            lang={"ENG"}
            setValue={(e) => setLastNameLatin(e)}
            placeholder='Прізвище латиницею'
            m1000='0 auto'
          />
        </InputWrapper>
      </InputsContainer>
      <InputsContainer>
        <InputWrapper>
          <AuthInputDate
            message={inputValidDate}
            validate={validate}
            hint='(дд.мм.рррр)'
            value={birthday}
            setValue={setBirthDay}
            placeholder='Дата народження'
            m1000='0 auto'
          />
        </InputWrapper>
        <InputWrapper>
          <AuthSelect
            validate={validate}
            mt='0'
            ml='0'
            placeholder='Вибрати стать'
            options={["Чоловіча", "Жіноча"]}
            id={[1, 2]}
            iconsArr={["/icons/auth/male.svg", "/icons/auth/female.svg"]}
            value={sex}
            setValue={setSex}
            m1000='0 auto'
          />
        </InputWrapper>
      </InputsContainer>
      <InputsContainer>
        <InputWrapper>
          <AuthSelectLocations
            validate={validate}
            filtered
            mt='0'
            mtMd='22px'
            placeholder='Вибрати країну'
            options={countryList}
            setId={setCountryId}
            value={country}
            setValue={setCountry}
            m1000='0 auto'
          />
        </InputWrapper>
          <InputWrapper>
            <AuthSelectLocations
              validate={validate}
              filtered
              mt='0'
              mtMd='22px'
              placeholder='Вибрати Область'
              options={regionList}
              setId={setRegionId}
              value={region}
              setValue={setRegion}
              m1000='0 auto'
            />
          </InputWrapper>
      </InputsContainer>
      <InputsContainer>
        <InputWrapper>
          <AuthInputPhone
            message={validPhone === '' ? phoneValidMessage : validPhone}
            validPhone={validPhone}
            mtLog='0'
            value={phone}
            setValidPhone={setValidPhone}
            setValue={setPhone}
            checkComponent='checkComponent'
            hint='(у форматі 0981234567)'
            maxLength='10'
            m1000='0 auto'
            w480='312px'
            m480='0'
          />
        </InputWrapper>
        <InputWrapper>
          <AuthInputEmail
            setValidMail={setValidMail}
            message={validMail === '' ? emailValidMessage : validMail}
            validate={validate}
            value={email}
            setValue={(e) => setEmail(e)}
            placeholder='E-mail'
            m1000='0 auto'
          />
        </InputWrapper>
      </InputsContainer>
      <InputsContainer>
        <InputWrapper>
          <AuthInputPassword
            message={passwordValidMessage}
            mtLog='0'
            value={password}
            setValue={setPassword}
            placeholder='Пароль'
            hint='Від 8 символів, включаючи цифри, великі та малі літери'
            m1000='0 auto'
            w480='312px'
            m480='0'
          />
          <AuthInputPassword
            message={passwordValidMessageConf}
            mtLog='30px'
            value={passwordConf}
            setValue={setPasswordConf}
            placeholder='Підтвердіть пароль'
            m1000='0 auto'
            w480='312px'
            m480='36px 0 0'
          />
        </InputWrapper>
      </InputsContainer>
      <AuthDivider mlcd='0' />
    </ContactDataBlockStyled>
);
};

export default ContactDataBlock;
