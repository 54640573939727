import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ActionItem from '../../sliderMobile/ActionItem';

const AlbumActionBlockStyled = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  grid-column: 1 / 3;
  padding-top: 24px;
`;

const AlbumOptionsBlock = ({ show, albumId,  onShowRemoveAlbum }) => {

  const history = useHistory();

  return (
    <AlbumActionBlockStyled show={show}>
      <ActionItem
        onClick={()=>history.push(`/gallery/album/${albumId}/edit`)}
        title="Редагувати альбом"
        src="/icons/slider_mobile/edit.svg"
      />
      <ActionItem title="Завантажити альбом" src="/icons/slider_mobile/download.svg" />
      <ActionItem onClick={onShowRemoveAlbum} title="Видалити альбом" src="/icons/slider_mobile/cart.svg" />
    </AlbumActionBlockStyled>
  );
};

export default AlbumOptionsBlock;
