import React from 'react';
import styled from 'styled-components';
import { statusesEnum } from '../../../../mockData/mockData';

const StatusMenuHoverStyled = styled.ul`
	display: none;
  list-style: none;
  padding: 10px;
  position: absolute;
  top: calc(100% + 7px);
	right: 0;
  background: #fcfcfc;
  border-radius: 5px;
  z-index: 10;
	:after {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    right: 6px;
    width: 30px;
    height: 12px;
    background: url('/icons/checkbox_arrow.svg') center center/cover no-repeat;
    z-index: 2;
  }
`;

const StatusMenuItem = styled.li`
  display: flex;
`;

const ImgWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #f1f2f3;
  border-radius: 2px;
`;

const StatusNameBlock = styled.div`
	min-width: 205px;
  margin-top: 5px;
  padding: 5px 10px;
  font-weight: ${({isActive}) => isActive ? '600' : '500'};
  letter-spacing: ${({isActive}) => isActive ? '0.04em' : '0.05em'};
  font-size: 15px;
  line-height: 18px;
  color: ${({isActive}) => isActive ? '#1E87A9' : ' #445566'};
  white-space: nowrap;
	cursor: pointer;
	:hover {
		background: #F1F2F3;
	}
`;

const StatusMenuHover = ({ activeStatus }) => {
  return (
    <StatusMenuHoverStyled className='menu_hover'>
      {Object.keys(statusesEnum).map((item, i) => (
        <StatusMenuItem key={i}>
          <ImgWrapper>
            <img
              src={
                activeStatus === item ? statusesEnum[item].activeIcons : statusesEnum[item].icon
              }
              alt={statusesEnum[item].name}
            />
          </ImgWrapper>
          <StatusNameBlock isActive={activeStatus === item}>{statusesEnum[item].name}</StatusNameBlock>
        </StatusMenuItem>
      ))}
    </StatusMenuHoverStyled>
  );
};

export default StatusMenuHover;
