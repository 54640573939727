import React from 'react';
import styled from 'styled-components';
import SearchBlock from './SearchBlock';
import DateBlock from './DateBlock';

const SubheaderStyled = styled.div`
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
  background: #f6f6f6;
  //border-radius: 0px 0px 2px 2px;
  box-shadow: -1.5px -1.5px 3px #FFFFFF, 1px 1px 2px #E1E1E1;
  border-radius: 2px 2px 0px 0px;

  @media(max-width: 992px) {
    padding: 30px 20px;
    flex-direction: column;
    height: 152px;
  }
`;

const PaymentsSubheader = ({ searchTerm, onSearch }) => {
  return (
	<SubheaderStyled>
		<DateBlock />
		<SearchBlock searchTerm={searchTerm} onSearch={onSearch}/>
	</SubheaderStyled>
  )
};

export default PaymentsSubheader;
