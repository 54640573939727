import React from 'react';
import styled from 'styled-components';

const ClubInformationContainer = styled.div``

const ClubInformationItemStyled = styled.li`
  display: flex;
  padding: 0 26px;
  &:last-child {
    align-items: flex-start;
  }
`;

const ClubInformationItemName = styled.div`
  padding: 5px 0;
  min-height: 30px;

  @media (max-width: 768px) {
    padding-top: ${({ac}) => ac ? '8px' : '0'};
    padding-bottom: 18px;
    width: 37%;
    min-height: unset;
  }
`;

const ClubNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;


const ClubItemNameTitle = styled.div`
  width: 170px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #1e87a9;

  @media (max-width: 1200px) {
    width: 120px;
  }

  @media (max-width: 576px) {
    width: 94px;
    font-size: 13px;
    line-height: 14px;
  }
`;

const ClubItemNameIcon = styled.div`
  margin-right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
  }

  @media (max-width: 576px) {
    width: 13px;
    height: auto;
  }
`;

const ClubInformationItemValue = styled.div`
  position: relative;
  padding: 5px 0;
  padding-left: 25px;
  font-weight: 500;
  font-size: 16px;
  color: #445566;
  border-left: 3px solid #f1f2f3;
  line-height: 30px;

  @media (max-width: 1200px) {
    font-size: 15px;
    line-height: 24px;
  }

  @media(max-width: 576px) {
    width: 62%;
    padding-top: 0;
    padding-bottom: 18px;
    padding-left: 20px;
    font-size: 13.5px;
    line-height: 18px;
    :last-child {
      padding-bottom: 0;
    }
  }
`;

const ClubInformationItemSubValue = styled.div`
  margin-top: 10px;
  :first-child {
    margin-top: 0;
  }

  @media (max-width: 1200px) {
    margin-top: 5px;
  }
`;



const ClubInformationItem = ({ image, title, value, ac=false }) => {


  return (
    <ClubInformationContainer>
    <ClubInformationItemStyled>
      <ClubInformationItemName ac={ac}>
        <ClubNameWrapper>
          <ClubItemNameIcon><img src={image} alt={title} /></ClubItemNameIcon>
          <ClubItemNameTitle>{title}</ClubItemNameTitle>
        </ClubNameWrapper>
      </ClubInformationItemName>
      <ClubInformationItemValue>
        {Array.isArray(value) ? (
          value.map((item, i) => (<ClubInformationItemSubValue key={i}>
            <>
              <div>{item.name}</div>
            </>
          </ClubInformationItemSubValue>))
        ) : (
          <div>{value}</div>
        )}
      </ClubInformationItemValue>
    </ClubInformationItemStyled>
    </ClubInformationContainer>
  );
};

export default ClubInformationItem;
