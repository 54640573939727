import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import TabsItem from './TabsItem';
import { useResizeMode } from '../../hooks/useResizeMode';

const TabsStyled = styled.div`
  padding: 30px 25px;
  width: 300px;
  border: 2px solid #f6f6f6;
  border-radius: 5px 0px 0px 5px;
  filter: drop-shadow(-1px -1px 1px #FFFFFF) drop-shadow(1px 1px 1px #E5E5E5);

  @media (max-width: 992px) {
    position: relative;
    padding: 25px 0 8px 0;
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: unset;
    filter: unset;

    :after {
      content: '';
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      display: block;
      background: #f6f6f6;
      box-shadow: 0px -1px 2px #ffffff, 0px 1px 2px #e5e5e5;
    }
  }
`;

const TabsList = styled.ul`
  @media (max-width: 992px) {
    width: ${({ width }) => width};
    padding: 0;
    display: flex;
    align-items: center;

  }
`;

const TabsListOverflow = styled.div`
  @media (max-width: 992px) {
    margin-left: 20px;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const CircleBlock = styled.div`
  display: none;

  @media (max-width: 992px) {
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    margin-left: 20px;
  }
`;
const CircleItem = styled.div`
  margin: 0 3px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: ${({ isActive }) => (isActive ? '#1E87A9' : '#C9CFD5')};
`;

const UserInformationtTabs = ({ tabsArr }) => {

  const [sortedArr, setSortedArr] = useState(tabsArr);

  const isMobile = useResizeMode();
  const location = useLocation();
  const activeIndex = tabsArr?.filter((item) => item !== null).findIndex(item =>  location?.pathname?.indexOf(item?.path) > -1 );

  useEffect(() => {
    if (isMobile) {
      setSortedArr([tabsArr[activeIndex], ...tabsArr.slice(0, activeIndex), ...tabsArr.slice(activeIndex + 1)]);
      document.querySelector('.tabs_overflow').scrollLeft = 0;
    } else {
      setSortedArr(tabsArr);
    }
  }, [activeIndex, tabsArr, isMobile]);
  return (
    <TabsStyled>
      <TabsListOverflow className='tabs_overflow'>
        <TabsList width={`${tabsArr?.filter((item) => item !== null).length * 160}px`}>
          {
            sortedArr?.filter((item) => item !== null).map((item, i) => <TabsItem item={item} key={i}
                                                  isActive={isMobile ? i === 0 : i === activeIndex}
                                                  title={item?.title}
                                                  path={item?.path} />)
          }
        </TabsList>
      </TabsListOverflow>
      <CircleBlock>
        {
          tabsArr?.filter((item) => item !== null).map((item, i) => <CircleItem isActive={i === activeIndex} key={i} />)
        }
      </CircleBlock>
    </TabsStyled>
  );
};

export default UserInformationtTabs;
