import React from 'react';
import styled from 'styled-components';
import BlockDivider from '../../../BlockDivider';

const AddUserBlockStyled = styled.div`
  position: relative;
  padding: 25px;

	@media(max-width: 576px) {
		padding: 20px;
	}
`;

const AddUserButton = styled.button`
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 2px #d4d4d4;
  border-radius: 5px;
  padding: 12px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #1e87a9;
  outline: none;
  background: #fff;
  border: none;
  cursor: pointer;
  img {
    margin-right: 10px;
  }

	@media(max-width: 576px) {
		margin: 0 auto;
		box-shadow: unset;
		padding: 0;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		img {
			max-width: 13px;
		}
	}
`;

const AddUserBlock = () => {
  return (
    <AddUserBlockStyled>
      <BlockDivider posTop bg="#1E87A9" height="2px" br="2px" />
      <AddUserButton>
        <img src="/icons/gallery/photo/add_photo_active.svg" alt="add user" />
        Користувача
      </AddUserButton>
    </AddUserBlockStyled>
  );
};

export default AddUserBlock;
