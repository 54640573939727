import React, { useState } from 'react';
import { useResizeMode } from '../../../hooks/useResizeMode';
import BlockHeader from '../../BlockHeader';
import SettingsSection from '../../SettingsSection';
import FormPhoneNumber from './FormPhoneNumber';

const PhoneNumberSection = () => {

  const [showContent, setShowContent] = useState(false);

  const isMobile = useResizeMode()

  return (
    <SettingsSection>
      <BlockHeader
        title="Змінити номер телефону"
        subtitle="Для зміни номеру телефону, вам потрібно підтвердити свою особу.
        Верифікуйте номер телефону, який прив’язаний до цього акаунту"
        divider={isMobile ? (showContent ? true : false) : true}
        show={showContent}
        setShow={setShowContent}
      />
      <FormPhoneNumber showContent={showContent} />
    </SettingsSection>
  );
};

export default PhoneNumberSection;
