let initialState = {
  error: '',
  eventPosition: {},
  clubsList:{},
  trainersList:{}
};

export const GET_EVENT_POSITION_SUCCESS = 'GET-EVENT-POSITION-SUCCESS';
export const GET_EVENT_POSITION_ERROR = 'GET-EVENT-POSITION-ERROR';
export const GET_CLUBS_LIST_SUCCESS = 'GET-CLUBS-LIST-SUCCESS'
export const GET_CLUBS_LIST_ERROR = 'GET-CLUBS-LIST-ERROR'
export const GET_TRAINERS_LIST_SUCCESS = 'GET-TRAINERS-LIST-SUCCESS'
export const GET_TRAINERS_LIST_ERROR = 'GET-TRAINERS-LIST-ERROR'


const positionsReducer = (state = initialState,action) => {
  switch (action.type) {
    case GET_EVENT_POSITION_SUCCESS:
    return {
      ...state,
      error : '',
      eventPosition:action.payload
    }
    case GET_EVENT_POSITION_ERROR:
    return {
      ...state,
      error : action.err,

    }
    case GET_CLUBS_LIST_SUCCESS:
    return {
      ...state,
      error:'',
      clubsList: action.payload
    }
    case GET_CLUBS_LIST_ERROR:
    return {
      ...state,
      error:action.err
    }
    case GET_TRAINERS_LIST_SUCCESS:
    return {
      ...state,
      error:'',
      trainersList: action.payload
    }
    case GET_TRAINERS_LIST_ERROR:
    return {
      ...state,
      error:action.err
    }
    default:
      return {
        ...state
    }


  }
}

export default positionsReducer;