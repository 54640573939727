import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import AlbumItemEdidHeader from './AlbumItemEdidHeader';
import { albums } from '../../../../mockData/mockData';
import AlbumItemEditBody from './AlbumItemEditBody';
import { useResizeMode } from '../../../hooks/useResizeMode';
import AlbumEditMobilePage from '../../album/albumEditMobilePage/AlbumEditMobilePage';
import PopupRemovePhoto from '../../../popups/removePhoto/PopupRemovePhoto';
import PopupMoveToAlbum from '../../../popups/moveToAlbum/PopupMoveToAlbum';


const AlbumItemEditStyled = styled.div`
  margin-top: 20px;
  box-shadow: 0px 0px 2px #d4d4d4;
	background: #fff;
	border-radius: 5px;
`;

const AlbumItemEdit = ({isCreate, itsAlbum, setItsAlbum,}) => {

  const [album, setAlbum] = useState({status: 'unlock', name: '', photos: []});
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedPhotos, setCheckedPhotos] = useState([]);
  const [showPopupRemove, setShowPopupRemove] = useState(false);
  const [showPopupMove, setShowPopupMove] = useState(false);


  const params = useParams();
  const isMobile = useResizeMode();

  const [albumTitle, setAlbumTitle] = useState('');
  useEffect(() => {
    if (albums.find((item) => +item.id === +params.id)) {
      setAlbum(albums.find((item) => +item.id === +params.id));
    }
  }, [params.id]);

  const onCheckedAll = (checked) => {
    setCheckedAll(checked);
  };

  const onCheckedPhotos = (id) => {
      if (!checkedPhotos.includes(id)) {
        if (album.photos.length-1 === checkedPhotos.length) {
          setCheckedAll(true)          
        }
        setCheckedPhotos((state) => [...state, id]);
      }
    };

  const onUnCheckedPhotos = (id) => {
    if (checkedPhotos.length) {
      setCheckedAll(false);
      setCheckedPhotos((state) => state.filter((item) => item !== id));
    }
  };

  const onAddALLtoChecked = () => {
    setCheckedPhotos(album.photos.map((item, i) => i));
  };

  const onRemoveAllFromChecked = () => {
    setCheckedPhotos([]);
  };

  if (isMobile) {
      return <AlbumEditMobilePage isCreate={isCreate} show album={album} setShowPopupMove={setShowPopupMove} showPopupMove={showPopupMove} showPopupRemove={showPopupRemove}  setShowPopupRemove={ setShowPopupRemove} />
    } 


  return (
    <AlbumItemEditStyled>
      <AlbumItemEdidHeader
        itsAlbum={itsAlbum}
        setItsAlbum={setItsAlbum}
        album={album}
        albumTitle={albumTitle}
        setAlbumTitle={setAlbumTitle}
        checkedItems={checkedPhotos}
        setShowPopupRemove={setShowPopupRemove}
        setShowPopupMove={setShowPopupMove}
      />
      <AlbumItemEditBody
        album={album} 
        checkedAll={checkedAll}
        onCheckedAll={onCheckedAll}
        checkedItems={checkedPhotos}
        onCheckedItems={onCheckedPhotos}
        onUnCheckedItems={onUnCheckedPhotos}
        onAddALLtoChecked={onAddALLtoChecked}
        onRemoveAllFromChecked={onRemoveAllFromChecked}
      />
      <PopupRemovePhoto show={showPopupRemove} setShow={setShowPopupRemove} />
      <PopupMoveToAlbum show={showPopupMove} setShow={setShowPopupMove} />
    </AlbumItemEditStyled>
  )
};

export default AlbumItemEdit;
