import React
  from 'react';
import styled
  from 'styled-components';

const AuthTitleStyled = styled.div`
  font-weight: bold;
  font-size: ${(p) => (p.small ? '24px' : '32px')};
  line-height: ${(p) => (p.small ? '30px' : '38px')};
  color: #223344;

  @media (max-width: 1000px) {
    max-width: ${({ mw1000 }) => mw1000};
    margin: ${({ m1000 }) => m1000};
    margin-top: ${({ mt1000 }) => mt1000};
    font-size: ${({fsz1000}) => fsz1000};
  }

  @media (max-width: 480px) {
    max-width: ${({w480}) => w480};
    font-size: ${({fs480}) => fs480};
    line-height: ${(p) => (p.small ? '28px' : '30px')};
    margin-top: ${({mt480}) => mt480};
  }
  @media (max-width: 360px) {
    font-size: 20px;
    max-width: ${({w360}) => w360};
    //padding: 0 24px;
    //padding: ${(p) => p.p0rd};
  }
  @media (max-width: 320px) {
    padding: 0 12px;
  }
`;

const AuthTitle = ({
                     title,
  mt480,
  w480,
                     small,
                     pd,
                     fs0,
                     p0rd,
                     ta1048000,
                     mw1000,
                     m1000,
                     mt1000,
  w360,
  fs480,
  fsz1000,

                   }) => {
  return (
    <AuthTitleStyled
      small={small}
      pd={pd}
      fs480={fs480}
      p0rd={p0rd}
      mt480={mt480}
      // ta1000={ta1000}
      mw1000={mw1000}
      m1000={m1000}
      mt1000={mt1000}
    fsz1000={fsz1000}
      w480={w480}
      w360={w360}
    >
      {title}
    </AuthTitleStyled>
  );
};

export default AuthTitle;
