import React, {useState} from "react";
import styled from "styled-components";

import MenuItem from "./MenuItem";
import SubMenuItem from "./SubMenuItem";

const MenuStyled = styled.nav`
  padding: 15px 0;
  background: #F6F6F6;
  box-shadow: 1px 1px 2px #FFFFFF, -1px -1px 2px rgba(174, 174, 192, 0.3);
  border-radius: 5px;
`;

const MenuDivider = styled.div`
  height: 2px;
  background: #1E87A9;
  opacity: 0.9;
  box-shadow: -1.5px -1.5px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
  border-radius: 1.5px;
`;

const SubMenuTitle = styled.div`
  margin-top: 5px;
  padding: 0 20px;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  color: #1E87A9;
  text-shadow: -1px -1px 1px #FFFFFF;

  @media(max-width: 992px) {
    line-height: 17px;
  }
`;
const MenuFlex = styled.div`
  position: relative;
`
const ArrowDown = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  ${({ transformArrow }) =>
    transformArrow ? "transform: rotate(180deg);" : ""}
`;
const Menu = () => {
    const [show,setShow] = useState(false)
  return (
    <MenuStyled>
      <ul>
        <MenuItem image={'/user.svg'} title='Моя сторінка' path='/home/information/user' />
        <MenuItem image={'/payment.svg'} title='Платежі' path='/payments/accounts' />
        <MenuItem image={'/qr_icon.svg'} title='E-phan картка' path='/my_card' />
        <MenuItem image={'/galery.svg'} title='Фотогалерея' path='/gallery/photo' />
        <MenuItem image={'/heart.svg'} title='Закладки' path='/bookmarks/dancers' />
        <MenuItem image={'/calendar.svg'} title='Календар подій' path='/calendar_events' />
      </ul>
      <MenuDivider />
      <SubMenuTitle>Обрані аккаунти:</SubMenuTitle>
      <ul>
        <MenuItem isSubMenu image={'/my_club.svg'} title='Мій клуб' path='/my_club/main'/>
        <MenuItem isSubMenu image={'/my_organization.svg'} title='Моя організація' path='/my-organization' />
      </ul>
        <MenuFlex onClick={() => setShow(show => !show)}>
            <MenuItem isSubMenu image={'/my_events.svg'} title='Мої події' path='/my-event' />
            <ArrowDown transformArrow={show} >
                <img src="/icons/arrow_down_events.svg" alt="arrow down" />
            </ArrowDown>
        </MenuFlex>
        {show &&
        <>
            <SubMenuItem name='Участь' image={'/participation.svg'} path='/my-participation' />
            <SubMenuItem name='Збережені' image={'/saved.svg'} path='/my-saved' />
        </>}
    </MenuStyled>
  );
};

export default Menu;
