import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputLabel from './InputLabel';
import ValidMessage from './ValidMessage';
import InputHint from './InputHint';

export const AuthInputStyled = styled.div`
  position: relative;
  margin-top: ${(p) => p.mt || 0};
  min-height: 52px;
  padding: 16px 12px;
  box-shadow: ${(p) =>
    p.focus
      ? '1px 1px 1px #FFFFFF, -1px -1px 1px #E7EAED'
      : '-1px -1px 1px #FFFFFF, 1px 1px 1px #E7EAED'};
  border: ${(p) => (!p.isValid && !p.focus ? '1px solid #C70000' : '1px solid transparent')};
  border-radius: 10px;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    margin-top: ${(p) => p.mtMd || 0};
    max-width: 432px;
  }@media (max-width: 480px) {
    max-width: 312px;
  }
`;

export const InputStyled = styled.input`
  width: 100%;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  background: #f6f6f6;
  outline: none;
  border: none;
  color: #223344;
  ::placeholder {
    font-weight: normal;
    color: #778899;
  }
`;
const AuthInputEmail = ({ mt, hint, value, setValue, placeholder, validate, message,setValidMail }) => {
  const [focus, setFocus] = useState(false);
  const [validMessage, setvalidMessage] = useState('');

  useEffect(() => {
    if (validate && !value) {
      setvalidMessage('Обов’язкове поле');
    } else {
      setvalidMessage('');
    }
  }, [validate, value]);

  const handleMail = (e) => {
    setValue(e.target.value);
    setValidMail('');
  }

  return (
    <div>
      <AuthInputStyled mt={mt} focus={focus} isValid={message ? !message : !validMessage}>
        <InputStyled
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={value}
          type="email"
          placeholder={focus ? '' : placeholder}
          onChange={handleMail}
        />
        <InputLabel
          text
          valid={message ? !message : !validMessage}
          focus={focus}
          title={placeholder}
          show={value}
        />
      </AuthInputStyled>
      <ValidMessage message={message ? message : validMessage} ml0rd="10px" />
      {hint ? <InputHint title={hint} /> : ''}
    </div>

  );
}

export default AuthInputEmail;
