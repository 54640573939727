import React from 'react';
import PaymentsListItem from '../../paymentsListItem/PaymentsListItem';
import ItemDateBlock from '../../paymentsListItem/ItemDateBlock';
import ItemDateBlockMobile from '../../paymentsListItem/listItemMedia/ItemDateBlockMobile';
import { useResizeMode } from '../../../hooks/useResizeMode';
import ItemDateMobile from '../../paymentsListItem/listItemMedia/ItemDateMobile';

const PaidItem = ({ account }) => {

  const isMobile = useResizeMode();

  return (
    <PaymentsListItem account={account.accountDescr} paid>
      {
        isMobile ? (
          <ItemDateBlockMobile>
            <ItemDateMobile paid dateTitle='Дата рахунку:' date={account.dateAccount}/>
            <ItemDateMobile paid dateTitle='Дата сплати:' date={account.datePaid}/>
          </ItemDateBlockMobile>
        ) : (
          <>
          <ItemDateBlock date={account.dateAccount}/>
          <ItemDateBlock date={account.datePaid}/>
          </>
        )
      }
    </PaymentsListItem>
  );
};

export default PaidItem;
