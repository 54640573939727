import React from 'react';
import MyInfo from '../components/myCard/MyInfo';
import QrCode from '../components/myCard/QrCode';
import Tabs from '../components/tabs/Tabs';

const MyCardPage = () => {
  return (
    <>
      <Tabs title={'E-phan картка'} ls='0.05em' />
      <MyInfo />
      <QrCode />
    </>
  );
};

export default MyCardPage;
