import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import AuthDivider from "../../components/auth/AuthDivider";
import AuthTextArea from "../../components/auth/AuthTextArea";
import AuthTitle from "../../components/auth/AuthTitle";
import ButtonSubmit from "../../components/auth/buttons/ButtonSubmit";
import ButtonsContainer from "../../components/auth/ButtonsContainer";
import InpupHint from "../../components/auth/InputHint";
import RadioButton from "../../components/auth/register/RadioButton";
import RegisterCheckBoxBlock from "../../components/auth/register/RegisterCheckBoxBlock";
import RegisterContainer from "../../components/auth/register/RegisterContainer";
import RegisterNav from "../../components/auth/register/RegisterNav";
import ValidMessage from "../../components/auth/ValidMessage";
import { useSelector, useDispatch } from "react-redux";
import { registUserEventStuff } from "../../actions/registerAction";
import { getEventPosition } from "../../actions/positionsAction";
import RegisterCheckBoxBackBlock from "../../components/auth/register/RegisterCheckBoxBackBlock";

const PositionContainer = styled.div`
  position: relative;
  padding: 32px 0;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  @media (max-width: 1000px){
    max-width: ${({mw1000}) => mw1000};
    margin: ${({m1000}) => m1000};
  }
`;

const ExperianceConatiner = styled.div`
  padding: 32px 0 40px 0;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  @media (max-width: 1000px){
    max-width: ${({mw1000}) => mw1000};
    margin: ${({m1000}) => m1000};
  }
`;

const InputContainer = styled.div`
  width: 432px;
  @media (max-width: 480px){
    max-width: ${({w480}) => w480};
  }@media (max-width: 360px){
    max-width: 296px;
  }
`;



const QuestionaryPersonalPage = () => {


  useEffect(() => {
    dispatch(getEventPosition())
  },[])



  const eventPositions = useSelector(state => state.positionsReducer?.eventPosition?.results)



  const [otherPosition, setOtherPosition] = useState("");
  const [positionItems, setPositionItems] = useState([]);
  // const [positionId, setPositionId] = useState([]);
  const [experiance, setExperiance] = useState([
    { title: "Так", checked: false },
    { title: "Ні", checked: false },
  ]);
  const [expDescr, setExpDescr] = useState("");
  const [checkedPersonal,setCheckedPersonal] = useState(false);

  const [validate, setValidate] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const usersRole = useSelector((state) => state.users.curentUser?.role);
  const userCurrent = useSelector(
    (state) => state.registerUsers.registersUser?.data?.pk
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      positionId?.filter((item) => item).length &&
      experiance.filter((item) => item.checked).length
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  })


  const history = useHistory();

  const positionId = positionItems.map(item => item.id)

  console.log(positionId)


  const onSubmit = (e) => {
    e.preventDefault();

    setValidate(true);
    if (!formValid) {
      return;
    }

    dispatch(
      registUserEventStuff({
        user: userCurrent,
        position: positionId,
      })
    );
    if (!usersRole.length) {
      history.push("/auth/reg_data");
    } else {
      const idx = usersRole.findIndex((item) => item === "personal");
      if (usersRole[idx + 1]) {
        history.push(`/auth/questionary_${usersRole[idx + 1]}`);
      } else {
        history.push("/auth/reg_add_doc");
      }
    }
  };
  return (
    <RegisterContainer>
      <RegisterNav backLink="/auth/questionary_dancer" currentStep={2} />
      <AuthTitle mw1000='432px' m1000='0 auto' fs480='24px' title="Персонал змагань" />
      <form onSubmit={onSubmit}>
        <PositionContainer mw1000='432px' m1000='0 auto'>
          <AuthTitle w480='312px' fs480='16px' small title="Ким ви працюєте на змаганнях?" />
          {eventPositions?.map((item, i) => (
            <RegisterCheckBoxBackBlock
              i={i}
              item={item}
              positionItems={positionItems}
              setPositionItems={setPositionItems}
              key={i}
              title={item.name}
              checkedPersonal={checkedPersonal}
              setCheckedPersonal={setCheckedPersonal}
              // setChecked={(checked) =>
              //   setPositions((state) =>
              //     state.map((el, j) => (i === j ? { ...item, checked } : el))
              //   )
              // }
            />
          ))}
          <ValidMessage
            ml480='6px'
            message={
              validate && !positionId.filter((item) => item).length
                ? "Потрібно вибрати варіант"
                : ""
            }
          />
          <InputContainer w480='312px'>
            <div>
              <AuthTextArea
                w480='312px'
                value={otherPosition}
                setValue={setOtherPosition}
                placeholder="Введіть текст"
              />
              <InpupHint title="Якщо інше, то вкажіть чим ви можете допомагати в проведенні змагань" w480='312px'/>
            </div>
          </InputContainer>
          <AuthDivider w480='312px'/>
        </PositionContainer>
        <ExperianceConatiner mw1000='432px' m1000='0 auto'>
          <AuthTitle fs480='16px' w480='312px' small title="Чи маєте досвід в даній справі?" />
          {experiance.map((item, i) => (
            <InputContainer key={i} w480='312px'>
              <RadioButton
                idx={i}
                name="exp"
                title={item.title}
                checked={item.checked}
                setChecked={setExperiance}
              />
            </InputContainer>
          ))}
          <ValidMessage
            ml480='6px'
            message={
              validate && !experiance.filter((item) => item.checked).length
                ? "Потрібно вибрати варіант"
                : ""
            }
          />
          <InputContainer w480='312px'>
            <AuthTextArea
              value={expDescr}
              setValue={setExpDescr}
              placeholder="Введіть текст"
              mt="0"
              w480='312px'
            />
            <InpupHint title="Якщо «так» – то вкажіть назви і дати найбільших заходів" w480='312px'/>
          </InputContainer>
          <AuthDivider />
        </ExperianceConatiner>
        <ButtonsContainer jc1000='center'>
          <ButtonSubmit isActive={formValid} title="Продовжити" width="250px" width480='312px' w360='296px'/>
        </ButtonsContainer>
      </form>
    </RegisterContainer>
  );
};

export default QuestionaryPersonalPage;


