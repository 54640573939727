import React from 'react';
import styled from 'styled-components';
import BlockDivider from '../../BlockDivider';
import { useResizeMode } from '../../hooks/useResizeMode';

const DescrItemStyled = styled.div`
  position: relative;
  display: flex;
`;

const DescrItemName = styled.div`
  flex: 1;
  position: relative;
  padding: 25px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #556677;

  @media (max-width: 576px) {
    flex: 2;
    padding: 12px 20px;
    font-size: 13px;
    line-height: 18px;
    span {
      font-weight: 500;
      font-size: 13.5px;
      color: #445566;
    }
  }
`;

const DescrItemComment = styled(DescrItemName)`
  flex: 1.7;
  font-weight: 500;
  color: #445566;
`;

const DescrItemStatus = styled(DescrItemName)`
  color: #1e87a9;

  @media (max-width: 576px) {
    flex: 1;
  }
`;

const DescrItem = ({ name, comment, status, nonDivider = false }) => {
  const isMobile = useResizeMode();

  if (isMobile) {
    return (
      <DescrItemStyled>
        <DescrItemName>
          <div>{name}</div>
          <span>{comment}</span>
        </DescrItemName>
        <DescrItemStatus>{status}</DescrItemStatus>
        {nonDivider ? '' : <BlockDivider height="2px" br="2px" />}
      </DescrItemStyled>
    );
  }

  return (
    <DescrItemStyled>
      <DescrItemName>{name}</DescrItemName>
      <DescrItemComment>{comment}</DescrItemComment>
      <DescrItemStatus>{status}</DescrItemStatus>
      {nonDivider ? '' : <BlockDivider height="2px" br="2px" />}
    </DescrItemStyled>
  );
};

export default DescrItem;
