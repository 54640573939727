import React from 'react';
import styled from 'styled-components';
import CheckBoxBlock from '../../../checkBox/CheckBoxBlock';
import PaymentsListItem from '../../paymentsListItem/PaymentsListItem';
import ItemDateBlock from '../../paymentsListItem/ItemDateBlock';
import { useResizeMode } from '../../../hooks/useResizeMode';
import ItemDateMobile from '../../paymentsListItem/listItemMedia/ItemDateMobile';

const CheckBoxWrapper = styled.div`
  flex: 0.45;

  @media(max-width: 768px) {
    position: absolute;
    top: 52px;
    left: 20px;
    >div {
      :hover {
        .hover {
          display: none;
        }
      }
    }
  }
`;

const AccountItem = ({
  onCheckedAll,
  checkedAll,
  checkedItems,
  onCheckedItems,
  onUnCheckedItems,
  account,
}) => {

  const isMobile = useResizeMode();

  return (
    <PaymentsListItem account={account.accountDescr} accountPage descrRatio={4} buttonTitle='Архівувати'>
        <CheckBoxWrapper>
        <CheckBoxBlock
          onCheckedAll={onCheckedAll}
          checkedAll={checkedAll}
          checkedItems={checkedItems}
          onCheckedItems={onCheckedItems}
          onUnCheckedItems={onUnCheckedItems}
          value={account.id}
          id={account.id}
        />
        </CheckBoxWrapper>
        {
          isMobile ? <ItemDateMobile dateTitle='Дата рахунку:' date={account.date} /> : <ItemDateBlock date={account.date} />
        }
    </PaymentsListItem>
  );
};

export default AccountItem;
