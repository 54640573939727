import React, { useState } from 'react';
import styled from 'styled-components';

const ChooseLanguageBlockStyled = styled.div`
  display: flex;
  margin-top: 20px;
  min-height: 28px;
  justify-content: space-between;
  align-items: flex-start;
`;

const LanguageTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.07em;
  color: #445566;
  img {
    max-width: 20px;
    margin-right: 22px;
  }
`;

const SelectLanguage = styled.div`
`;

const SelectLanguageHeader = styled.div`
  font-weight: 500;
  font-size: 13.5px;
  line-height: 16px;
  color: #1e87a9;
  text-align: right;
`;

const LangList = styled(SelectLanguageHeader)`
  display: ${({ show }) => (show ? 'block' : 'none')};
  text-align: right;
  margin-top: 12px;
  padding-right: 20px;
`;

const ChooseLanguageBlock = () => {
  const langList = ['Українська UA', 'Русский  RU', 'English  EN'];
  const [curentLanguage, setCurentLanguge] = useState(langList[0]);
  const [show, setShow] = useState(false);

  const onShowLangList = () => {
    setShow((state) => !state);
  };

  const onSetCurentLang = (lang) => {
    setCurentLanguge(lang);
    setShow(false);
  };

  return (
    <ChooseLanguageBlockStyled>
      <LanguageTitle>
        <img src="/icons/menu_mobile/language.svg" alt="language" />
        Мова
      </LanguageTitle>
      <SelectLanguage>
        <SelectLanguageHeader onClick={onShowLangList}>
          {curentLanguage} <img src="/icons/arrow_down.svg" alt="arrow down" />
        </SelectLanguageHeader>
        {langList
          .filter((el) => el !== curentLanguage)
          .map((item, i) => (
            <LangList key={i} onClick={() => onSetCurentLang(item)} show={show}>
              {item}
            </LangList>
          ))}
      </SelectLanguage>
    </ChooseLanguageBlockStyled>
  );
};

export default ChooseLanguageBlock;
