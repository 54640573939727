import React from 'react';
import styled from 'styled-components';
import AuthContainer from '../../components/auth/AuthContainer';
import AuthDivider from '../../components/auth/AuthDivider';
import AuthSubTitle from '../../components/auth/AuthSubTitle';
import AuthTitle from '../../components/auth/AuthTitle';
import ToLoginLink from '../../components/auth/register/ToLoginLink';

const PageStyled = styled.div`
  position: relative;
  padding-bottom: 40px;
`;

const RegisterSuccessPage = () => {
  return (
    <PageStyled>
      <AuthContainer>
        <AuthTitle fs480='24px' title='Дякуємо!' />
        <AuthSubTitle fs480='16px' fullWidth title='Ваші дані успішно надіслані та появляться в акаунті після верифікації.' />
        <AuthDivider />
        <ToLoginLink />
      </AuthContainer>
    </PageStyled>
  )
}

export default RegisterSuccessPage;