import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import AuthSubTitle from "../../components/auth/AuthSubTitle";
import AuthTitle from "../../components/auth/AuthTitle";
import AgreementBlock from "../../components/auth/register/AgreementBlock";
import ContactDataBlock from "../../components/auth/register/ContactDataBlock";
import DownloadPhotoBlock from "../../components/auth/register/DownloadPhotoBlock";
import RegisterContainer from "../../components/auth/register/RegisterContainer";
import RegisterNav from "../../components/auth/register/RegisterNav";
import UsersGroupeBlock from "../../components/auth/register/UsersGroupeBlock";
import ButtonSubmit from "../../components/auth/buttons/ButtonSubmit";
import { useValidate } from "../../components/hooks/useValidate";
import { validatePhone } from "../../utils/validatePhone";
import { validatePassword } from "../../utils/validatePassword";
import { passConf } from "../../utils/validatePassConf";
import { validateEmail } from "../../utils/validateEmail";
import { validateName } from "../../utils/validateName";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserRole } from "../../actions/actions";
import { registUser } from "../../actions/registerAction";
import { validateLastName } from "../../utils/validateLastName";
import { validateNameLatin } from "../../utils/validateNameLatin";
import { validateLastNameLatin } from "../../utils/validateLastNameLatin";
import { validateDate } from "../../utils/validateDate";
import { validatePhoto } from "../../utils/validatePhoto";
import { getUserCountry, getUserRegion } from "../../actions/countryAction";
import { api } from "../../api/api";


const UsersGroupeStyled = styled.div`
  position: relative;
  padding: 40px 0;
  @media (max-width: 1000px) {
    margin: 0 auto;
    max-width: 432px;
  }
  @media (max-width: 480px) {
    margin: 0 auto;
    max-width: 312px;
  }
`;

const ButtonBlock = styled.div`
  padding: 60px 0;

  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
  }
  /* @media (max-width: 360px) {
    display: flex;
    justify-content: center;
    padding: 0 24px;
  } */
`;

const RegisterDataPage = ({
                            phoneCode = "+38",
                            oldPhone = "+380000000000",
                          }) => {
  const dispatch = useDispatch();
  const { errorPhone, errorMail } = useSelector(state => state.registerUsers);

  const countryList = useSelector((state) => state.userCountry?.country?.results);
  const regionList = useSelector((state) => state.userRegion?.region?.results);
  // const regError = useSelector(state => state.registerUsers)

  useEffect(() => {
    dispatch(getUserCountry());
    dispatch(getUserRegion());
  }, []);

  useEffect(() => {
  }, [errorPhone, errorMail]);


  const [name, setName] = useState("");
  const [nameLatin, setNameLatin] = useState("");
  const [birthday, setBirthDay] = useState("");
  const [country, setCountry] = useState("");
  const [countryId, setCountryId] = useState(0);
  const [region, setRegion] = useState("");
  const [regionId, setRegionId] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConf, setPasswordConf] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameLatin, setLastNameLatin] = useState("");
  const [sex, setSex] = useState("");
  const [validPhone, setValidPhone] = useState("");
  const [validMail, setValidMail] = useState("");

  const [email, setEmail] = useState("");
  const [files, setFiles] = useState([]);
  const [usersGroupe, setUsersGroupe] = useState([
    { id: 1, groupe: "dancer", title: "Танцюрист", checked: false },
    {
      id: 2,
      groupe: "parents",
      title: "Один з батьків танцюриста (до 14 років)",
      checked: false,
    },
    { id: 3, groupe: "trainer", title: "Тренер чи суддя", checked: false },
    { id: 4, groupe: "sponsor", title: "Організатор змагань", checked: false },
    { id: 5, groupe: "personal", title: "Персонал змагань", checked: false },
    { id: 6, groupe: "manager", title: "Керівник клубу", checked: false },
  ]);
  const [personalData, setPersonalData] = useState(false);
  const [rule, setRule] = useState(false);


  const history = useHistory();

  const storePassMail = () => {
    localStorage.setItem("password", password);
    localStorage.setItem("password2", passwordConf);
    localStorage.setItem("email", email);

  };
  const sexId = () => {
    if (sex === "Чоловіча") {
      return 1;
    } else if (sex === "Жіноча") {
      return 2;
    } else {
      return null;
    }
  };
  const [validate, setValidate] = useState(false);

  const phoneValidMessage = useValidate(
    phoneCode + phone,
    validatePhone,
    validate,
  );
  const passwordValidMessage = useValidate(
    password,
    validatePassword,
    validate,
    passwordConf,
  );
  const emailValidMessage = useValidate(email, validateEmail, validate);
  const passwordValidMessageConf = useValidate(
    passwordConf,
    passConf,
    validate,
    password,
  );

  const inputValidName = useValidate(name, validateName, validate);
  const inputValidLastName = useValidate(lastName, validateLastName, validate);
  const inputValidNameLatin = useValidate(nameLatin, validateNameLatin, validate);
  const inputValidLastNameLatin = useValidate(lastNameLatin, validateLastNameLatin, validate);


  const inputValidDate = useValidate(birthday, validateDate, validate);

  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (
      name &&
      nameLatin &&
      birthday &&
      lastName &&
      lastNameLatin &&
      country &&
      region &&
      sex &&
      files.length &&
      personalData &&
      rule &&
      !validatePhone(phoneCode + phone) &&
      !validatePassword(password) &&
      !validateEmail(email) &&
      !passConf(password, passwordConf) &&
      !validateName(name) &&
      !validateLastName(lastName) &&
      !validateNameLatin(nameLatin) &&
      !validateLastNameLatin(lastNameLatin) &&
      !validateDate(birthday) &&
      files[0]?.name?.match(/.*\.(jpeg|jpg|png)$/igm) &&
      usersGroupe.filter((item) => item.checked).length > 0 &&
      validMail === "" &&
      validPhone === ""
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [
    validMail,
    errorPhone,
    name,
    nameLatin,
    birthday,
    lastName,
    lastNameLatin,
    countryId,
    regionId,
    sex,
    phoneCode,
    phone,
    password,
    passwordConf,
    email,
    usersGroupe,
    files,
    personalData,
    rule,
    formValid,
  ]);
  const onChangeFile = (e) => {
    const fileList = Object.values(e.target.files).filter((item) =>
      isNaN(item),
    );
    setFiles(fileList);
  };
  useEffect(() => {
    localStorage.setItem("parentPhone", phone);
  });
  const onRemoveFile = () => {
    setFiles([]);
  };
  const splitted = birthday.includes(".") && birthday !== "" ? birthday.split(".") : "";
  const swapped = splitted ? [splitted[2], splitted[1], splitted[0]] : "";
  const birthdayForm = swapped !== "" ? swapped.join("-") : ""; // заменяет точку в дате на тире для отправки на запроса

  // const alreadyRegUser = useCallback(() => {
  //   if (errorPhone.length !== 0) {
  //     alert("этот номер уже зарегестрирован");
  //     setFormValid(false);
  //   } else if (errorMail.length !== 0) {
  //     alert("этот эмейл уже зарегестрирован");
  //     setFormValid(false);
  //   } else if (errorPhone.length !== 0 && errorMail.length !== 0) {
  //     alert("этот номер уже зарегестрирован и эмейл уже зарегестрирован");
  //     setFormValid(false);
  //   } else {
  //     history.push("/auth/reg_code");
  //   }
  // },[errorPhone,errorMail])


  const onSubmit = (e) => {
    e.preventDefault();
    setValidate(true);
    if (formValid) {
      if (oldPhone === phoneCode + phone) {
        history.push("/auth/already_reg");
      } else {
        (dispatch(
          setUserRole(
            usersGroupe
              .filter((item) => item.checked)
              .map((item) => item.groupe),
          ),
        ));

        // dispatch(registUser(
        //   password,
        //   passwordConf,
        //   phoneCode + phone,
        //   email,
        //   name,
        //   lastName,
        //   nameLatin,
        //   lastNameLatin,
        //   birthdayForm, // пока отправляется в формате YYYY.MM.DD, потом бэк переделает на DD.MM.YYYY
        //   sexId(),
        //   countryId,
        //   regionId,
        //   files[0],
        //   ),
        // );

        let formData = new FormData();
        formData.append("photo", files[0]);

        api.users
          .registerUser({
              password: password,
              password2: passwordConf,
              phone_number: phoneCode + phone,
              email: email,
              first_name: name,
              last_name: lastName,
              first_name_cyr: nameLatin,
              last_name_cyr: lastNameLatin,
              birthdate: birthdayForm,
              sex: sexId(),
              country: countryId,
              region: regionId,
              // photo: files[0]
              formData,
            }, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then((res) => {
            localStorage.setItem("access", res.data.access);
            localStorage.setItem("refresh", res.data.refresh);
            dispatch(registUser(res))
            history.push("/auth/reg_code");
          })
          .catch((err) => {
              console.log(err.response);
              if (err.response?.data?.phone_number?.includes("Це поле повинне бути унікальним.") && err.response?.data?.email?.includes("Це поле повинне бути унікальним.")) {
                setValidPhone("Це поле повинне бути унікальним.");
                setValidMail("Це поле повинне бути унікальним.");
                setFormValid(false);
              } else if (err.response?.data?.email?.includes("Це поле повинне бути унікальним.")) {
                setValidMail("Це поле повинне бути унікальним.");
                setFormValid(false);
              } else if (err.response?.data?.phone_number?.includes("Це поле повинне бути унікальним.")) {
                setValidPhone("Це поле повинне бути унікальним.");
                setFormValid(false);
              } else if (err.response?.data?.phone_number?.includes("Введіть коректний номер телефону.")) {
                setValidPhone("Введіть коректний номер телефону.");
                setFormValid(false);
              }
            },
          );
      }

    }
  };

  return (
    <RegisterContainer>
      <RegisterNav currentStep={0} backLink='/auth/register' />
      <AuthTitle
        small
        title='Введіть свої контактні дані'
        fs480='20px'
        p0rd='0'
        ta1000='center'
      />
      <AuthSubTitle
        fullWidth
        title='Для верифікації номеру телефону через SMS-код'
        fs='15px'
        p0rd='0'
        ta1000='center'
      />
      <form onSubmit={onSubmit}>
        <ContactDataBlock
          phoneValidMessage={phoneValidMessage}
          passwordValidMessage={passwordValidMessage}
          validPhone={validPhone}
          setValidPhone={setValidPhone}
          validMail={validMail}
          setValidMail={setValidMail}
          emailValidMessage={emailValidMessage}
          passwordValidMessageConf={passwordValidMessageConf}
          inputValidName={inputValidName}
          inputValidLastName={inputValidLastName}
          inputValidNameLatin={inputValidNameLatin}
          inputValidLastNameLatin={inputValidLastNameLatin}
          inputValidDate={inputValidDate}
          validate={validate}
          name={name}
          setName={(e) => setName(e)}
          nameLatin={nameLatin}
          setNameLatin={(e) => setNameLatin(e)}
          birthday={birthday}
          setBirthDay={setBirthDay}
          country={country}
          countryId={countryId}
          countryList={countryList}
          setCountry={setCountry}
          setCountryId={setCountryId}
          phone={phone}
          setPhone={setPhone}
          password={password}
          setPassword={setPassword}
          passwordConf={passwordConf}
          setPasswordConf={setPasswordConf}
          lastName={lastName}
          setLastName={(e) => setLastName(e)}
          lastNameLatin={lastNameLatin}
          setLastNameLatin={(e) => setLastNameLatin(e)}
          sex={sex}
          setSex={setSex}
          region={region}
          setRegion={setRegion}
          regionId={regionId}
          setRegionId={setRegionId}
          regionList={regionList}
          email={email}
          setEmail={(e) => setEmail(e)}
          // realizeDateField={(e) => realizeDateField(e)}
          // backSpaceButton={(e) => backSpaceButton(e)}
        />
        <UsersGroupeStyled>
          <AuthTitle
            small
            title='До якої групи користувачів ви відноситесь?'
            fs480='20px'
            p0rd='0'
            ta1000='center'
          />
          <AuthSubTitle
            fullWidth
            fs='15px'
            title='Виберіть один або декілька варіантів:'
            p0rd='0'
            ta1000='center'
          />
          <UsersGroupeBlock
            usersGroupe={usersGroupe}
            setUsersGroupe={setUsersGroupe}
            validate={validate}
          />
        </UsersGroupeStyled>
        <DownloadPhotoBlock
          files={files}
          onChange={onChangeFile}
          onRemoveFile={onRemoveFile}
          validate={validate}
          title='Завантажте своє фото'
        />
        <AgreementBlock
          validate={validate}
          personalData={personalData}
          setPersonalData={setPersonalData}
          rule={rule}
          setRule={setRule}
        />
        <ButtonBlock>
          <ButtonSubmit
            width480='312px'
            width='250px'
            widthSd='312px'
            title='Продовжити'
            isActive={formValid}
            onClick={storePassMail}
          />
        </ButtonBlock>
      </form>
    </RegisterContainer>
  );
};

export default RegisterDataPage;