import React from 'react';
import styled from 'styled-components';
import SliderMobileHeader from '../../sliderMobile/SliderMobileHeader';
import AlbumDescrBlock from './AlbumDescrBlock';
import Photo from '../../photo/Photo';
import GalleryMobilePage from '../../galleryMedia/GalleryMobilePage';
import { useHistory } from 'react-router-dom';
import RemoveAlbum from '../../../popups/removeAlbum/RemoveAlbum';

const PhotoWrapper = styled.div`
  background: #fff;
`;

const AlbumMobilePage = ({album, showPopupRemoveAlbum, setShowPopupRemoveAlbum, onShowRemoveAlbum}) => {
  const history = useHistory();

  return (
    <GalleryMobilePage show>
      <SliderMobileHeader title="Альбоми" onClose={()=>history.push('/gallery/album')}/>
      <AlbumDescrBlock  onShowRemoveAlbum={ onShowRemoveAlbum}  album={album} />
      <PhotoWrapper>
        <Photo album={album} />
      </PhotoWrapper>
      <RemoveAlbum show={showPopupRemoveAlbum} setShow={setShowPopupRemoveAlbum} albumId={album.id} />
    </GalleryMobilePage>
  );
};

export default AlbumMobilePage;
