import React from 'react';
import styled from 'styled-components';

const InformationItemStyled = styled.li`
  display: flex;
  :last-child {
    align-items: flex-start;
  }
`;

const InformationItemName = styled.div`
  padding: 5px 0;
  min-height: 30px;

  @media (max-width: 768px) {
    padding-top: ${({ac}) => ac ? '8px' : '0'};
    padding-bottom: 18px;
    width: 37%;
    min-height: unset;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;


const ItemNameTitle = styled.div`
  width: 170px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #1e87a9;

  @media (max-width: 1200px) {
    width: 120px;
  }

  @media (max-width: 576px) {
    width: 94px;
    font-size: 13px;
    line-height: 14px;
  }
  @media (max-width: 480px) {
    width: 94px;
    font-size: 11px;
    line-height: 14px;
  }
`;

const ItemNameIcon = styled.div`
  margin-right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
  }

  @media (max-width: 576px) {
    width: 13px;
    height: auto;
  }
`;

const InformationItemValue = styled.div`
  position: relative;
  padding: 5px 0;
  padding-left: 25px;
  font-weight: 500;
  font-size: 16px;
  color: #445566;
  border-left: 3px solid #f1f2f3;
  line-height: 30px;

  @media (max-width: 1200px) {
    font-size: 15px;
    line-height: 24px;
  }

  @media(max-width: 576px) {
    width: 62%;
    padding-top: 0;
    padding-bottom: 18px;
    padding-left: 20px;
    font-size: 13.5px;
    line-height: 18px;
    :last-child {
      padding-bottom: 0;
    }
  }@media(max-width: 480px) {
    width: 62%;
    padding-top: 0;
    padding-bottom: 18px;
    padding-left: 20px;
    font-size: 12.5px;
    line-height: 18px;
    :last-child {
      padding-bottom: 0;
    }
  }
`;

const InformationItemSubValue = styled.div`
  margin-top: 10px;
  :first-child {
    margin-top: 0;
  }

  @media (max-width: 1200px) {
    margin-top: 5px;
  }
`;

const Position = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  span {
    margin-right: 5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #1E87A9;
  }
  img {
    margin-right: 9px;
  }

  @media(max-width: 576px) {
    margin-top: 10px;
    padding-bottom: 18px;
    flex-wrap: nowrap;
    align-items: flex-start;
    span {
      font-size: 13px;
      line-height: 15px;
    }
    img {
      max-width: 10px;
      margin-top: 2px;
    }
  }
;
  @media(max-width: 480px) {
    margin-top: 10px;
    padding-bottom: 18px;
    flex-wrap: nowrap;
    align-items: flex-start;
    span {
      font-size: 10px;
      line-height: 15px;
    }
    img {
      max-width: 10px;
      margin-top: 2px;
    }
  }
`;

const UserInformationItem = ({ image, title, value, ac=false }) => {
  return (
    <InformationItemStyled>
      <InformationItemName ac={ac}>
        <NameWrapper>
          <ItemNameIcon><img src={image} alt={title} /></ItemNameIcon>
          <ItemNameTitle>{title}</ItemNameTitle>
        </NameWrapper>
      </InformationItemName>
      <InformationItemValue>
        {Array.isArray(value) ? (
          value.map((item, i) => (<InformationItemSubValue key={i}>
            <>
            <div>{item.name}</div>
            {item.position && (
              <Position>
              <img src='/icons/user_inform/position.svg' alt='position'/>
              <span>посада:</span>{ item.position }
            </Position>
            )}
            
            </>
          </InformationItemSubValue>))
        ) : (
          <div>{value}</div>
        )}
      </InformationItemValue>
    </InformationItemStyled>
  );
};

export default UserInformationItem;
