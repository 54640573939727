import React from "react";
import styled from "styled-components";

const Footer = styled.div`
  padding: 40px 25px;
  background: #fff;
  button {
    margin-top: 20px;
    padding: 10px 46px;
    //background: linear-gradient(
    //    184.06deg,
    //    #f6f6f6 -84.07%,
    //    #f6f6f6 -84.05%,
    //    rgba(251, 251, 251, 0.407481) 18.48%,
    //    rgba(255, 255, 255, 0) 97.79%
    //  ),
    //  #1e87a9;
    background-color: #47A5BF;
    border-radius: 10px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #ffffff;
    cursor: pointer;
    &:hover{
      background: #0A87AA;

    }
  }

  @media(max-width: 576px) {
    padding: 32px 20px;
    button {
      margin-top: 16px;
      width: 100%;
      font-size: 15px;
    }
  }
`;

const Amount = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #1e87a9;
  span {
    color: #445566;
    margin-left: 25px;
  }

  @media(max-width: 576px) {
    font-size: 17px;
    line-height: 24px;
  }
`;

const AccountsFooter = ({amount}) => {
  return (
    <Footer>
      <Amount>
        Сума до сплати: <span>{amount} грн.</span>
      </Amount>
      <button>Сплатити</button>
    </Footer>
  );
};

export default AccountsFooter;
