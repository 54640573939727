import React from 'react';
import styled from 'styled-components';

const AuthContainerStyled = styled.div`
  padding: ${(p) => p.padding || '84px 0 96px 0'};
  margin: 0 auto;
  width: 90%;
  max-width: 432px;

  @media (max-width: 1000px) {
  }
  @media (max-width: 480px) {
    max-width: ${({w480}) => w480};
    margin: ${({m480}) => m480};
    //padding: 84px 24px 60px;
    padding: ${(p) => p.pd};
    padding-top: ${(p) => p.pdt360};
  }
  @media (max-width: 360px) {
    padding: ${(p) => p.pd360 || '0 0 60px 0'};
    max-width: 296px;
    width: 100%;
  }
  @media (max-width: 320px) {
    width: 100%;
    max-width: unset;
    padding: ${(p) => p.pd320};
    /* padding-top: ${(p) => p.pdt360}; */
  }
`;

const AuthContainer = ({ children, padding, pd, pdt360, pd320, pd360 , w480, m480}) => {
  return (
    <AuthContainerStyled padding={padding}
                         pd={pd}
                         pdt360={pdt360}
                         pd320={pd320}
                         pd360={pd360}
                    m480={m480}
                         w480={w480}
    >
      {children}
    </AuthContainerStyled>
  );
};

export default AuthContainer;
