import React from 'react';
import styled from 'styled-components';
import BlockDivider from '../../../BlockDivider';
import { ItemInput, ItemData } from '../informationsSection/ContactListItem';

const CommentBlockStyled = styled.div`
  position: relative;
  padding: 25px;
  padding-left: ${({ pl }) => pl};

  @media(max-width: 576px) {
    padding: 20px;
  }
`;

const CommentBlockTitle = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #445566;
`;

const Input = styled(ItemInput)`
  width: 100%;
  margin-top: 5px;
`;

const ItemDataStyled = styled(ItemData)`
  padding-left: 0;
  width: 100%;
`;

const CommentBlock = ({isActive, value, disabled, onChange, pl = '60px' }) => {
  return (
    <CommentBlockStyled pl={pl}>
      <CommentBlockTitle>Коментар:</CommentBlockTitle>
      {disabled ? (
        <ItemDataStyled>{value}</ItemDataStyled>
      ) : (
        <Input
          disabled={disabled}
          isActive={isActive}
          value={value}
          onChange={onChange}
          type="text"
          placeholder="Введіть текст..."
        />
      )}
      <BlockDivider />
    </CommentBlockStyled>
  );
};

export default CommentBlock;
