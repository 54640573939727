import React from 'react';
import { useHistory } from 'react-router-dom';
import MainMobileLayout from '../mobileLayout/MainMobileLayout';
import Bookmarks from './Bookmarks';

const BookmarksMobile = ({prevPath}) => {

	const history = useHistory();

	return (
		<MainMobileLayout title='Закладки' onClose={()=>history.push(prevPath)} withClose show>
			<Bookmarks />
		</MainMobileLayout>
	)
}

export default BookmarksMobile;