import React from 'react';
import styled from 'styled-components';

const SettingsSectionStyled = styled.section`
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 2px #d4d4d4;
  border-radius: 2px;

  @media (max-width: 576px) {
    margin-top: 28px;
  }
`;

const SettingsSection = ({ children }) => {
  return <SettingsSectionStyled>{children}</SettingsSectionStyled>;
};

export default SettingsSection;
