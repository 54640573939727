import React from 'react';
import styled from 'styled-components';

const ItemDateStyled = styled.div`
	flex: 1;
  font-weight: 400;
  font-size: 16px;
  color: #556677;

  @media (max-width: 1200px) {
    flex: 1.2;
  }

  @media (max-width: 992px) {
    font-size: 15px;
    margin-left: 5px;
  }
`;

const ItemDateBlock = ({date}) => {
	return (
		<ItemDateStyled>{date}</ItemDateStyled>
	)
}

export default ItemDateBlock;