import React from 'react';
import styled from 'styled-components';

const ButtonBlockStyled = styled.div`
  padding: 25px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 576px) {
    flex-direction: ${({ flexEnd }) => (flexEnd ? 'row' : 'column')};
    padding: 20px;
  }
  @media (max-width: 360px) {
    flex-direction: column;
    justify-content: start;
  }
`;

const ButtonEdit = styled.button`
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin-right: ${({ mr }) => (mr ? '25px' : '0')};
  padding: 7.5px 27.5px;
  border: 2.5px solid #1e87a9;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 20px;
  background: #fff;
  color: #1e87a9;
  cursor: pointer;
  outline: none;
  &:hover{
    background: #F1F2F3;

  }

  @media (max-width: 576px) {
    font-size: 15px;
    margin: 0;
    order: 2;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 9px;
      }
    }
  }
`;

const ButtonSubmit = styled(ButtonEdit)`
  display: ${({ show }) => (show ? 'block' : 'none')};
  height: 40px;
  margin-right: 0;
  color: #ffffff;
  background: #47A5BF;
&:hover{
  background: #0A87AA;

}
  ${({ isActive }) =>
    isActive
      ? 'background: #47A5BF'
      : ''}
  border-radius: 10px;
  border: none;
  //cursor: ${({ isActive }) => (isActive ? 'pointer' : 'unset')};
  cursor: pointer;
  @media (max-width: 576px) {
    order: 1;
    margin-bottom: ${({ mb }) => (mb ? '16px' : '0')};
    height: 40px;
  }
`;

const ButtonBlock = ({
  submitTitle,
  submitActive,
  onEdit,
  secondButton = 'Редагувати',
  type = 'button',
  mainType = 'submit',
  onAccess,
  showEditButton = true,
  flexEnd = false,
  onSubmit,
  setDisabled,
  disabled,
  setShowContent,
  onConfirm,

}) => {
  return (
    <ButtonBlockStyled flexEnd={flexEnd}>
      <ButtonEdit mr={!!submitTitle} show={showEditButton} onClick={onEdit} type={type}>
        {secondButton}
      </ButtonEdit>
      <ButtonSubmit
        mb={showEditButton}
        show={!!submitTitle}
        disabled={!submitActive}
        isActive={submitActive}
        type={mainType}
      >
        {submitTitle}
      </ButtonSubmit>
    </ButtonBlockStyled>
  );
};

export default ButtonBlock;
