import React from 'react';
import styled from 'styled-components';

const ActionIconStyled = styled.div`
  margin-left: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1.22222px 1.22222px 2.44444px #f6f6f6, -1.22222px -1.22222px 2.44444px #f6f6f6;
  border-radius: 10px;
  ${({ isActive }) => (isActive ? 'border: 2px solid #1E87A9;' : '')}
  img {
    max-width: 100%;
  }
`;

const ActionIcon = ({isActive, onShowOptions, src, alt}) => {
	return (
		<ActionIconStyled isActive={isActive} onClick={onShowOptions}>
			<img src={src} alt={alt} />
		</ActionIconStyled>
	)
}

export default ActionIcon;