import React from 'react';
import styled from 'styled-components';
import { ContainerStyled } from '../../photoToAccount/photoToAccountMobile/PhotoToAccountMobile';
import SliderMobileHeader from '../../../../../../gallery/sliderMobile/SliderMobileHeader';
import BlockDivider from '../../../../../../BlockDivider';
import EditImgBlock from '../../photoToAccount/EditImgBlock';
import ButtonBlock from '../../../../../ButtonBlock';
import PopupInputFileBlock from '../../PopupInputFileBlock';
import PhotoToAccountHeader from '../../photoToAccount/PhotoToAccountHeader';
import { listAlertsForWrapper } from '../../../../../../../mockData/mockData';
import GalleryBlock from '../GalleryBlock';

const Hint = styled.div`
  position: relative;
  padding: 20px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #778899;
  background: #fff;
`;

const FormStyled = styled.form`
  height: calc(100% - 80px);
  overflow: auto;
`;

const ButtonWrapper = styled.div`
  position: relative;
  background: #fff;
`;

const PhotoToWrapperMobile = ({
  show,
  setShow,
  onSubmit,
  file,
  setFile,
  onEdit,
  setImgSrc = false,
  imgSrc,
}) => {
  const onClosePopup = () => {
    setShow(false);
    setFile(null);
    if (setImgSrc) {
      setImgSrc(null);
    }
  };
  return (
    <ContainerStyled show={show}>
      <SliderMobileHeader title="Зміна фото обкладинки" withClose onClose={onClosePopup} />
      <FormStyled onSubmit={onSubmit}>
			<Hint>
        Завантажте своє фото або виберіть з галереї. Рекомендований розмір 912 x 347 px.
        <BlockDivider height="2px" />
      </Hint>
        {file || imgSrc ? (
          <>
            <EditImgBlock isWrapper imgSrc={imgSrc} file={file} />
						<GalleryBlock setImgSrc={setImgSrc} />
            <ButtonWrapper>
              <ButtonBlock
                submitTitle="Зберегти зміни"
                submitActive
                secondButton="Завантажити інше фото"
                onEdit={onEdit}
              />
              <BlockDivider height="2px" />
            </ButtonWrapper>
          </>
        ) : (
          <PopupInputFileBlock setFile={setFile} labelTitle="Вибрати фото" />
        )}

        <PhotoToAccountHeader showDescr listItem={listAlertsForWrapper} title="Зверніть увагу!" />
      </FormStyled>
    </ContainerStyled>
  );
};

export default PhotoToWrapperMobile;
