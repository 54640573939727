import React from "react";
import styled from "styled-components";
import TabsList from "./TabsList";

const TabsStyled = styled.div`
  padding: 0 25px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #F6F6F6;
  filter: drop-shadow(-1px -1px 1px #FFFFFF) drop-shadow(1px 1px 1px #E5E5E5);
  border-radius: 2px 2px 0px 0px;

  @media(max-width: 576px) {
    display: ${({show}) => show ? 'flex' : 'none'};
    width: 100%;
    padding: 0 20px;
    height: 60px;
    justify-content: flex-start;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: ${({ls}) => ls};
  color: #445566;

  @media (max-width: 992px) {
    font-size: 17px;
  }

  @media(max-width: 576px) {
    display: none;
  }
`;

const TitleMobile = styled.div`
  display: none;

  @media(max-width: 576px) {
    display: block;
    padding: 0 20px 12px 20px;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #445566;
  }
`;

const Tabs = ({title, tabsArr=[], ls='0.07em'}) => {
  return (
    <>
    <TitleMobile>{title}</TitleMobile>
    <TabsStyled show={tabsArr.length}>
      <Title ls={ls}>{title}</Title>
      <TabsList tabsArr={tabsArr}/>
    </TabsStyled>
    </>
  );
};

export default Tabs;
