import React, { useState } from 'react';
import styled from 'styled-components';
import AvatarBlock from '../../../myPage/userPromo/AvatarBlock';
import PhotoAccountBlock from './PhotoAccountBlock';
import BlockHeader from '../../BlockHeader';
import SettingsSection from '../../SettingsSection';
import PhotoToAccount from './popup/photoToAccount/PhotoToAccount';
import PhotoToWrapper from './popup/photoToWrapper/PhotoToWrapper';

const AvatarWrapper = styled.div`
  > div {
    border-radius: unset;
  }
`;

const ContentWrapper = styled.div`
  @media(max-width: 576px) {
    display: ${({show}) => show ? 'block' : 'none'};
  }
`;

const PromoSection = () => {
  const [showPhotoToAccount, setShowPhotoToAccount] = useState(false);
  const [showPhotoTowrapper, setShowPhotoToWrapper] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const onShowPhotoToAccount = () => {
    setShowPhotoToAccount(true);
    window.scroll(0, 0);
  };

  const onShowPhotoToWrapper = () => {
    setShowPhotoToWrapper(true);
    window.scroll(0, 0);
  };

  return (
    <SettingsSection>
      <BlockHeader
        show={showContent}
        setShow={setShowContent}
        title="Фото акаунту та обкладинка"
        subtitle="Завантажте свої зображення."
      />
      <ContentWrapper show={showContent}>
        <AvatarWrapper>
          <AvatarBlock />
        </AvatarWrapper>
        <PhotoAccountBlock onShowPopup={onShowPhotoToAccount} title="Фото акаунту" alert />
        <PhotoAccountBlock onShowPopup={onShowPhotoToWrapper} title="Фото обкладинки" />
        <PhotoToAccount show={showPhotoToAccount} setShow={setShowPhotoToAccount} />
        <PhotoToWrapper show={showPhotoTowrapper} setShow={setShowPhotoToWrapper} />
      </ContentWrapper>
    </SettingsSection>
  );
};

export default PromoSection;
