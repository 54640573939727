import React, { useState } from 'react';
import styled from 'styled-components';
import ItemDescrBlock from './ItemDescrBlock';
import ListItemHover from './ListItemHover';
import ItemAmountBlock from './ItemAmountBlock';
import BlockDivider from '../../BlockDivider';
import { useResizeMode } from '../../hooks/useResizeMode';

const ListItem = styled.li`
  position: relative;

  :last-child {
    .divider {
      display: ${({accountPage}) => accountPage ? 'block' : 'none'};
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 90px 15px 25px;
  min-height: 80px;
  background: ${({ show }) => (show ? '#FCFCFC;' : '#ffffff;')}
  :hover {
	background: #FCFCFC;
  };

  @media(max-width: 1200px) {
    padding: 10px 50px 10px 25px;
  }

  @media(max-width: 768px) {
    position: static;
    flex-direction: column;
    align-items: flex-start;
    padding: ${({ accountPage }) => (accountPage ? '16px 20px 60px 64px' : '16px 20px 60px 20px')};
    background: #ffffff;
    :hover {
      background: #ffffff;
      }
    ${({ show }) => (show ? 'padding-bottom: 0;' : '')}
  }
`;

const IconHover = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  cursor: pointer;
  img {
    ${({ show }) => (show ? 'transform: rotate(180deg);' : '')}
  }

  @media (max-width: 768px) {
    top: unset;
    transform: unset;
    right: 20px;
    bottom: 25px;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.07em;
    color: #778899;
    img {
      margin-left: 8px;
    }
  }
`;


const PaymentsListItem = ({
  account,
  children,
  paid = false,
  archive = false,
  accountPage = false,
  descrRatio,
  amountRatio,
  buttonTitle,
}) => {
  const [showHover, setShowHover] = useState(false);
  const isMobile = useResizeMode();

  const onShowHover = () => {
    setShowHover((state) => !state);
  };

  return (
    <ListItem accountPage={accountPage}>
      <Wrapper show={showHover} accountPage={accountPage}>
        {children}
        <ItemDescrBlock account={account} descrRatio={descrRatio} />
        {showHover && isMobile ? (
          ''
        ) : (
          <ItemAmountBlock show={showHover} account={account} amountRatio={amountRatio} />
        )}
        <IconHover onClick={onShowHover} show={showHover}>
          {isMobile ? (showHover ? 'Згорнути' : 'Детальніше') : ''}
          <img  src="/icons/arrow_account.svg" alt="arrow" />
        </IconHover>
      </Wrapper>
      <ListItemHover
        isMobile={isMobile}
        show={showHover}
        paid={paid}
        archive={archive}
        account={account}
        accountPage={accountPage}
        buttonTitle={buttonTitle}
      />
      <BlockDivider />
    </ListItem>
  );
};

export default PaymentsListItem;
