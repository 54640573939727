import React from "react";
import styled from "styled-components";
import PaymentsHeader from '../../header/PaymentsHeader';

const TitleItemStyled = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #556677;

  @media (max-width: 1200px) {
    flex: 1.2;
  }

  @media (max-width: 992px) {
    margin-left: 5px;
  }
`;

const TitleItemAmmount = styled(TitleItemStyled)`
  flex: 1;
  text-align: right;

  @media (max-width: 992px) {
    margin-left: 0;
  }
`;

const TitleBlockName = styled(TitleItemStyled)`
  flex: 4;

  @media (max-width: 1200px) {
    padding-left: 15px;
  }

  @media (max-width: 992px) {
    margin-left: 0;
  }
`;

const ArchiveHeader = () => {
  return (
    <PaymentsHeader>
      <TitleItemStyled>Дата</TitleItemStyled>
      <TitleBlockName>Найменування</TitleBlockName>
      <TitleItemAmmount>Сума, грн</TitleItemAmmount>
    </PaymentsHeader>
  );
};

export default ArchiveHeader;
