import React from 'react';
import styled from 'styled-components';

const IconWrapperStyled = styled.div`
  width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  img {
    max-width: 100%;
  }

  @media (max-width: 576px) {
    min-width: 15px;
    max-width: 15px;
  }
`;

const IconWrapper = ({ iconUrl, alt = '' }) => {
  return <IconWrapperStyled>{iconUrl ? <img src={iconUrl} alt={alt} /> : ''}</IconWrapperStyled>;
};

export default IconWrapper;
