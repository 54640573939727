import React from 'react';
import styled from 'styled-components';

const CirclesWrapper = styled.div`
  padding: 32px 0;
  display: flex;
  justify-content: flex-end;
`;

const CirclesBlock = styled.div`
  width: 152px;
  display: flex;
  justify-content: space-between;
`;

const CircleItem = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  border: 1.5px solid #BBC3CC;
  border-radius: 100%;
  background: ${p => p.background || 'transparent'};
  :after {
    content: '';
    width: 16px;
    height: 1.5px;
    background:  #BBC3CC;
    position: absolute;
    top: calc(50% - 0.75px);
    left: calc(100% + 8px);
  }
  :last-child {
    :after {
      display: none;
    }
  }
`;

const NavCirclesBlock = () => {
  return (
  <CirclesWrapper>
    <CirclesBlock>
      <CircleItem background='#47A5BF'/>
      <CircleItem background='#47A5BF'/>
      <CircleItem background='#BBC3CC'/>
      <CircleItem/>
    </CirclesBlock>
  </CirclesWrapper>
  )
}

export default NavCirclesBlock;