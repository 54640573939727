import React from 'react';
import styled from 'styled-components';
import { ItemInput } from '../informationsSection/ContactListItem';
import AlertMessage from '../../AlertMessage';

const TextInputBlockStyled = styled.div`
  margin-top: 20px;

  @media(max-width: 576px) {
    margin-top: 16px;
  }
`;

const TextInputBlockTitle = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #445566;

  @media(max-width: 576px) {
    font-size: 13px;
  }
`;

const InputStyled = styled(ItemInput)`
  margin-top: 5px;
  width: 100%;
  resize: none;
  height: ${({long}) => long ? 'auto' : '40px'};
  ::placeholder {
    font-weight: 400;
    font-size: 15px;
    color: #778899;
  }
`;

const InputDescr = styled.div`
  margin-top: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #778899;

  @media(max-width: 576px) {
    font-size: 13px;
    margin-top: 4px;
  }
`;

const TextInputBlock = ({
  title,
  placeholder,
  value,
  onChangeValue,
  name,
  descr = '',
  showAlert
}) => {
  return (
    <TextInputBlockStyled>
      <TextInputBlockTitle>{title}</TextInputBlockTitle>
      <InputStyled as='textarea'
        name={name}
        value={value}
        onChange={onChangeValue}
        type="text"
        placeholder={placeholder}
        long={ value.length > 50 ? true : false}
        isActive={value}
      />
      <AlertMessage show={showAlert} message="Обов’язкове поле!" />
      {descr ? <InputDescr>{descr}</InputDescr> : ''}
    </TextInputBlockStyled>
  );
};

export default TextInputBlock;
