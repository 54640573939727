import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { userLogOut } from '../../../actions/loginAction';


import ChooseAccountHover from './ChooseAccountHover';

const AuthenticatedStyled = styled.div`
  display: ${({ show }) => show ? 'block;' : 'none;'};
  position: absolute;
  padding-top: 12px;
  background: #FFF;
  border-radius: 5px 5px 0 0;
  top: 0;
  left: -315px;
  min-width: 315px;
z-index: 1;
  opacity: 1;
  :after {
    content: "";
    position: absolute;
    top: 5px;
    right: -7px;
    display: block;
    width: 10px;
    height: 20px;
    background: url("/icons/arrow.svg") center center/cover no-repeat;
  }
  
`;

const User = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 12px 15px 45px;
  margin: 0 auto;
  margin-bottom: 12px;
  min-width: 236px;
  height: 35px;
  background: #ffffff;
  border-radius: 5px 5px 0 0;
  cursor: pointer;

  ${({ hover }) => hover ?
          ':hover {background: #F1F2F3; border-radius: 25px;}' : ''}
  div {
    font-weight: 600;
    font-size: 15px;
  }
`;

const ArrowDown = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  ${({ transformArrow }) =>
          transformArrow ? 'transform: rotate(180deg);' : ''}
`;

const UserIconBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-right: 10px;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background: #1e87a9;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    max-width: 100%;
    height: 100%;
  }
`;

const AuthAction = styled.div`
  padding-top: 10px;
  border-top: 2px solid #f1f2f3;
  display: block;
  min-width: 260px;
  height: 140px;
  background: #ffffff;
  border-radius-bottom: 5px;
  z-index: 1;
`;

const AuthActionItem = styled.div`
  padding: 0 10px;
  display: flex;
  height: 45px;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;

  :hover {
    color: #1e87a9;
    font-weight: 600;
    letter-spacing: 0.04em;
  }

  img {
    margin-right: 10px;
  }

  div {
    display: flex;
    align-items: center;
  }

  :last-child {
    background: #f1f2f3;
    border-radius: 5px;
  }
`;

const AuthenticatedHover = ({ accounts, curentAccount, show, setShow }) => {
  const [account, setAccount] = useState(curentAccount);
  const [showChooseAccount, setShowChooseAccount] = useState(false);
  // const [localStorage] = useState(localStorage.getItem('access'))


  const history = useHistory();

  const dispatch = useDispatch();

  const onChooseAccount = (e) => {
    let target = e.target;

    while (!target.hasAttribute('data-id')) {
      target = target.parentElement;
    }

    const id = target.getAttribute('data-id');
    setAccount(accounts.find((item) => +item.id === +id));
    setShowChooseAccount(false);
  };

  const onSettings = () => {
    history.push('/settings/common');
    setShow(false);
  };


  const onLogOut = (e) => {
    dispatch(userLogOut(
      {refresh_token : localStorage.getItem('refresh')}
    )); // временно пока бэк не настроят (не работает)
    e.preventDefault();

    setTimeout(() => {
      history.push('/auth/login')
      // localStorage.clear();
    },200)


  };


  const userInfo = useSelector(state => state.userProfiles?.currentProfile);
  // const logOut = useSelector(state => state.login?.loginSuccess);

  const [userName,setUserName] = useState('');
  const [userLastName,setUserLastName] = useState('');
  const [userPhoto,setUserPhoto] = useState('');


  useEffect(() => {
    setUserName(() => userInfo?.user?.first_name);
    setUserLastName(() => userInfo?.user?.last_name);
    setUserPhoto(() => userInfo?.user?.photo);
  },[userInfo])

  return (
    <AuthenticatedStyled
      show={show}
      className='hover'
      onMouseLeave={() => setShowChooseAccount(false)}
    >
      <User hover={showChooseAccount} onClick={() => setShowChooseAccount((state) => !state)}>
        <UserIconBlock>
          <img src={`${userPhoto}`} alt='user icon' />
        </UserIconBlock>
        <div>{`${userName} ${userLastName}`}</div>
        <ArrowDown transformArrow={showChooseAccount}>
          <img src='/icons/arrow_down.svg' alt='arrow down' />
        </ArrowDown>
      </User>
      <ChooseAccountHover
        onChooseAccount={onChooseAccount}
        show={showChooseAccount}
        accounts={accounts}
        account={account}
      />
      <AuthAction>
        <AuthActionItem onClick={() => setShow(false)}>
          <img src='/icons/auth/add_role.svg' alt='add user' />
          <div>Додати роль користувачу</div>
        </AuthActionItem>
        <AuthActionItem onClick={onSettings}>
          <img src='/icons/auth/settings.svg' alt='add user' />
          <div>Налаштування</div>
        </AuthActionItem>

        <AuthActionItem onClick={(e) => onLogOut(e)}>
          <img src='/icons/auth/exit.svg' alt='add user' />
          <div>Вихід</div>
        </AuthActionItem>

      </AuthAction>
    </AuthenticatedStyled>
  );
};

export default AuthenticatedHover;
