import React from 'react';
import styled from 'styled-components';


const PopupSubtitle = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  margin-bottom: 5px;
  
`
const popupSubTitle = ({children}) => {
  return (
    <PopupSubtitle>
      {children}
    </PopupSubtitle>
  )

}

export default popupSubTitle;