import React from "react";
import PaidItem from "./PaidItem";

const PaidBody = ({filteredArr}) => {
  return (
    <ul>
      {filteredArr.map((item) => (
        <PaidItem 
          key={item.id} 
          account={item}
        />
      ))}
    </ul>
  );
};

export default PaidBody;
