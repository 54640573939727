import React from 'react';
import styled from 'styled-components';

const DividerStyled = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    background: #F1F2F3;
    border-radius: 1.5px;
    height: 2px;
    width: 100%;
`;

const Divider = () => {
    return (
        <DividerStyled></DividerStyled>
    )
}

export default Divider;