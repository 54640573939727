import React, { useState } from 'react';
import styled from 'styled-components';
import BookmarksTabsItem from './BookmarksTabsItem';

export const BookmarksTabsStyled = styled.div`
  display: ${({ showInMobile }) => (!showInMobile ? 'block' : 'none')};
  width: 28%;
  background: #f6f6f6;
  border: 2px solid #f6f6f6;
  box-shadow: -1px -1px 2px #ffffff, 1px 1px 2px #e5e5e5;
  border-radius: 5px;

  @media (max-width: 1200px) {
    width: 32%;
  }

  @media (max-width: 992px) {
    width: 100%;
    order: 1;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    display: ${({ showInMobile }) => (showInMobile ? 'block' : 'none')};
    width: 100%;
    border-radius: 0;
  }
`;

export const TabsTitle = styled.div`
  position: relative;
  padding: 20px 15px;
  font-weight: bold;
  font-size: 19px;
  line-height: 25px;
  letter-spacing: 0.07em;
  color: #445566;
  img {
    display: none;
  }
  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 92%;
    height: 2px;
    background: #f6f6f6;
    box-shadow: -1px -1px 2px #ffffff, 1px 1px 2px #e5e5e5;
    border-radius: 5px;
  }


  @media (max-width: 768px) {
    color: ${({ isActiveFilter }) => (isActiveFilter ? '#1E87A9' : '#445566')};
    display: flex;
    padding: 18px 31px 18px 20px;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1em;
    img {
      display: block;
      max-width: 18px;
    }
    :after {
      width: 100%;
      display: ${({ showTabs }) => (showTabs ? 'block' : 'none')};
    }
  }
`;

const UlBlock = styled.ul`
  padding: 15px;

  @media (max-width: 992px) {
    display: flex;
  }

  @media (max-width: 768px) {
    display: ${({ show }) => (show ? 'block' : 'none')};
    padding: 20px;
  }
`;

const BookmarksTabs = ({ title, tabsArr = [], isBookmarks, showInMobile = false }) => {
  const [showTabs, setShowTabs] = useState(false);
  const [isActiveFilter, setActiveFilter] = useState(false);


  const onShowTabs = (isActiveTab) => {
    if (isActiveTab) {
      setActiveFilter(false);
    } else {
      setActiveFilter(true);
    }

    setShowTabs(false);
  };

  return (
    <BookmarksTabsStyled showInMobile={showInMobile}>
      <TabsTitle
        isActiveFilter={isActiveFilter}
        showTabs={showTabs}
        onClick={() => setShowTabs((state) => !state)}
      >
        {title}
        <img src={isActiveFilter ? '/icons/filter_active.svg' : '/icons/filter.svg'} alt="filter" />
      </TabsTitle>
      <UlBlock show={showTabs}>
        {tabsArr.map((item, i) => (
          <BookmarksTabsItem
            key={i}
            id={i}
            onShowTabs={onShowTabs}
            title={item.title}
            path={item.path}
            isBookmarks={isBookmarks}
          />
        ))}
      </UlBlock>
    </BookmarksTabsStyled>
  );
};

export default BookmarksTabs;
