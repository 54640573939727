import styled from 'styled-components';

export const ButtonSubmit = styled.button`
  height: 40px;
  padding: ${({ pad = '10px 30px' }) => pad};
  border-radius: ${({ br = '10px' }) => br};
  font-weight: bold;
  font-size: ${({ fz = '16px' }) => fz};
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #ffffff;
  outline: none;
  border: none;
  background: linear-gradient(
      88.74deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.125) 100%
    ),
    #778899;
  cursor:pointer;
  :hover{
  ${({isActive}) => isActive ? 'background-color: #0A87AA;': ''}
  }
  ${({ isActive }) =>
    isActive
      ? 'background-color:#47A5BF;'
      : ''};

  @media (max-width: 576px) {
    font-size: 15px;
  }
`;
