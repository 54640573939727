import React from 'react';
import UserPromoBlock from '../components/myPage/userPromo/UserPromoBlock';
import UserInformationBlock from '../components/myPage/userInformation/UserInformationBlock';
import UserTable from '../components/myPage/userTable/UserTable';
import { rewards, chronology, dancersList } from '../mockData/mockData';
import { useParams } from 'react-router-dom';
import UserPageGallery from '../components/userPage/UserPageGallery';

const UserPage = () => {
  const params = useParams();
  const user = dancersList.find((item) => item.id === +params.id);

  let tabsArr = [
    { title: 'Користувач', path: `/all-users/${params.id}/information/user` },
    { title: 'Танцюрист', path: `/all-users/${params.id}/information/dancer` },
  ];

  if (user.staus.includes('Тренер')) {
    tabsArr.push({ title: 'Тренер', path: `/all-users/${params.id}/information/trainer` });
  }

  if (user.staus.includes('Суддя')) {
    tabsArr.push({ title: 'Суддя', path: `/all-users/${params.id}/information/judge` });
  }

  if (user.staus.includes('Керівник клубу')) {
    tabsArr.push({ title: 'Керівник клубу', path: `/all-users/${params.id}/information/manager` });
  }

  return (
    <>
      <UserPromoBlock firstName={user.userName} lastName='' myPage={false} liked={user.liked} />
      <UserInformationBlock tabsArr={tabsArr} />
      <UserPageGallery id={params.id} />
      <UserTable title='Нагороди' count={3} mockData={rewards} isReward />
      <UserTable title='Хронологія' count={3} mockData={chronology} />
    </>
  );
};

export default UserPage;
