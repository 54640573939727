import React, { useState } from 'react';
import styled from 'styled-components';
import PopupRemovePhoto from '../../popups/removePhoto/PopupRemovePhoto';
import ActionItem from './ActionItem';
import PopupMoveToAlbum from '../../popups/moveToAlbum/PopupMoveToAlbum';

const ActionBlockStyled = styled.div`
  padding: 16px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 1px #d4d4d4;
`;

const SliderActionBlock = () => {

  const [showPopupRemovePhoto, setShowPopupRemovePhoto] = useState(false);
  const [showPopupMove, setShowPopupMove] = useState(false);

  const onRemovePhoto = (e) => {
    setShowPopupRemovePhoto(true);
  }

  return (
    <ActionBlockStyled>
      <ActionItem onClick={onRemovePhoto} title="Видалити фото" src="/icons/slider_mobile/cart.svg" />
      <ActionItem title="Обернути ліворуч" src="/icons/slider_mobile/rotate.svg" />
      <ActionItem title="Завантажити фото" src="/icons/slider_mobile/download.svg" />
      <ActionItem title="Зробити обкладинкою акаунту" src="/icons/slider_mobile/wrapper.svg" />
      <ActionItem onClick={()=>setShowPopupMove(true)} title="Перенести в альбом" src="/icons/slider_mobile/move.svg" />
      <PopupRemovePhoto show={showPopupRemovePhoto} setShow={setShowPopupRemovePhoto} />
      <PopupMoveToAlbum show={showPopupMove} setShow={setShowPopupMove}/>
    </ActionBlockStyled>
  );
};

export default SliderActionBlock;
