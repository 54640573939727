import React, { useState } from 'react';
import styled from 'styled-components';
import SettingsSection from '../../SettingsSection';
import CommentBlock from './CommentBlock';
import ButtonBlock from '../../ButtonBlock';
import BlockHeader from '../../BlockHeader';
import { useResizeMode } from '../../../hooks/useResizeMode';
import InformationAgeBlock from '../informationsSection/InformationAgeBlock';

const ContentWrapper = styled.div`
  @media (max-width: 576px) {
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

const PartnerSection = () => {
  const [showContnet, setShowContent] = useState(false);
  const [coment, setComent] = useState('Текст...');
  const [checked, setChecked] = useState(true);
  const [disabled, setDisabled] = useState(true);

  const isMobile = useResizeMode();

  const onChangePermision = (e) => {
    setChecked(e.target.checked);
  };

  const onChangeComent = (e) => {
    setComent(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (disabled) {
      setComent('');
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onReset = () => {
    setComent('');
  };

  return (
    <SettingsSection>
      <BlockHeader
        title="Пошук партнера/ки"
        subtitle="При подачі заявки ви попадаєте в список користувачів, котрі шукають партнера / партнерку."
        img="/icons/dancer_inform/partner.svg"
        divider={isMobile ? (showContnet ? true : false) : true}
        show={showContnet}
        setShow={setShowContent}
      />
      <ContentWrapper show={showContnet}>
        <form onSubmit={onSubmit} onReset={onReset}>
          {disabled ? (
            <InformationAgeBlock
              partner
              iconUrl="/icons/dancer_inform/partner.svg"
              title="Пошук партнера/ки"
              id="status"
              onChange={onChangePermision}
              checked={checked}
            />
          ) : (
            ''
          )}
          <CommentBlock
            disabled={disabled}
            isActive={!!coment}
            value={coment}
            onChange={onChangeComent}
          />
          <ButtonBlock
            submitActive={!!coment}
            submitTitle={disabled ? 'Скасувати заявку' : 'Подати заявку'}
            showEditButton={!!coment && !disabled}
            secondButton="Скасувати"
            type="reset"
          />
        </form>
      </ContentWrapper>
    </SettingsSection>
  );
};

export default PartnerSection;
