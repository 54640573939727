import React, {useState} from 'react';
import styled from 'styled-components';
import BookmarksContent from '../../bookmarks/bookmarksContent/BookmarksContent';
import BookmarksTabs from '../../bookmarks/bookmarksTabs/BookmarksTabs';

import { clubsList } from '../../../mockData/mockData';
import SearchClubsFilter from './SearchClubsFilter';

const SearchStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media(max-width: 576px) {
    width: 100%;
    display: block;
  }
`;

const SearchSidePanel = styled.div`
  width: 29%;
  > div {
    width: 100%;
  }
`;

const SearchClubs = () => {

  const [searchTerm, setSearchTerm] = useState('');

  return (
    <SearchStyled>
      <BookmarksContent searchTerm={searchTerm} setSearchTerm={setSearchTerm} content={clubsList} isSearch isClub/>
      <SearchSidePanel>
        <BookmarksTabs
          title="Пошук"
          tabsArr={[
            { title: 'Користувачі', path: '/search/users' },
            { title: 'Клуби', path: '/search/clubs' },
            { title: 'Організації', path: '/search/organizations' },
          ]}
        />
        <SearchClubsFilter />
      </SearchSidePanel>
    </SearchStyled>
  );
};

export default SearchClubs;