import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";
import Photo from "./photo/Photo";
import Album from "./album/Album";
import AlbumItem from "./albumItem/AlbumItem";
import AlbumItemEdit from "./albumItem/albumItemEdit/AlbumItemEdit";
import { albums } from "../../mockData/mockData";
import AlbumCreate from "./albumItem/albumCreate/AlbumCreate";
import { useResizeMode } from "../hooks/useResizeMode";
import { useSelector } from "react-redux";

const PhotoWrapper = styled.div`
  margin-top: 20px;
  box-shadow: 0px 0px 2px #d4d4d4;
  background: #fff;
  border-radius: 5px;
`;

const Gallery = () => {

  const userAlbums = useSelector(state => state.userGallery?.dataAlbum?.results?.albums);
  useEffect(() => {
  }, [userAlbums]);

  const album = userAlbums?.map(item => item);

  const isMobile = useResizeMode();
  return (
    <Switch>
      <Route path='/gallery/photo'>
        <PhotoWrapper>
          <Photo album={album?.find((item) => item.title === "Фото")} />
        </PhotoWrapper>
      </Route>
      <Route exact path='/gallery/album'>
        <Album albums={album}/>
      </Route>
      <Route exact path='/gallery/album/:id'>
        <AlbumItem albums={album}/>
      </Route>
      <Route exact path='/gallery/album/new/create'>
        {
          isMobile ? <AlbumItemEdit isCreate/> : <AlbumCreate/>
        }
      </Route>
      <Route exact path='/gallery/album/:id/edit'>
        <AlbumItemEdit />
      </Route>
    </Switch>
  );
};

export default Gallery;
