import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import AlbumItemHeader from './AlbumItemHeader';
import Photo from '../photo/Photo';
import { albums } from '../../../mockData/mockData';
import { useResizeMode } from '../../hooks/useResizeMode';
import AlbumMobilePage from '../album/albumMobilePage/AlbumMobilePage';
import RemoveAlbum from '../../popups/removeAlbum/RemoveAlbum';

const AlbumItemStyled = styled.div`
  position: relative;
  margin-top: 20px;
  box-shadow: 0px 0px 2px #d4d4d4;
  background: #fff;
  border-radius: 5px;
`;

const AlbumItem = ({albums}) => {

  const [album, setAlbum] = useState([]);
  const [showPopupRemoveAlbum, setShowPopupRemoveAlbum] = useState(false);
	const param = useParams();
  const isMobile = useResizeMode();

	useEffect(() => {
		setAlbum(albums?.find(item => +item?.id === +param.id));
	}, [param.id,albums]);

  // useEffect(() => {},[album])

  console.log(albums)

  const onShowRemoveAlbum = () => {
    setShowPopupRemoveAlbum(true);
  }

  if (isMobile) {
    return <AlbumMobilePage album={album} showPopupRemoveAlbum={showPopupRemoveAlbum} onShowRemoveAlbum={onShowRemoveAlbum} setShowPopupRemoveAlbum={setShowPopupRemoveAlbum} />
  } 

  return (
    <AlbumItemStyled>
      <AlbumItemHeader onShowRemoveAlbum={onShowRemoveAlbum} album={album}/>
      <Photo album={album} />
      <RemoveAlbum show={showPopupRemoveAlbum} setShow={setShowPopupRemoveAlbum} albumId={album?.id} />
    </AlbumItemStyled>
  );
};

export default AlbumItem;
