import React from 'react';
import AuthInputText from '../AuthInputText';

const ChoosePartnerWithAdd = ({partners, setPartners, mt,lang}) => {
  return (
    <>
    {
      partners.map((item, i) => (
        <AuthInputText
          key={i}
          mt={i === 0 && mt ? mt : '44px'}
          lang={lang}
          value={item}
          setValue={(value)=>setPartners(state=>state.map((partner, j)=> i === j ? value : partner))}
          hint='Напишіть ім’я та прізвище партнера/ки'
          placeholder='Партнер/ка'
        />
      ))
    }
    </>
  )
};

export default ChoosePartnerWithAdd;