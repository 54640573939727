let initialState = {
  error: '',
  loading: '',
  varify: false,
  currentProfile: null,
  changePass: '',
}

export const UPDATE_USER_PHOTO_SUCCESS = 'UPDATE-USER-PHOTO-SUCCESS'
export const UPDATE_USER_PHOTO_ERROR = 'UPDATE-USER-PHOTO-ERROR'
export const UPDATE_USER_COVER_SUCCESS = 'UPDATE-USER-COVER-SUCCESS'
export const UPDATE_USER_COVER_ERROR = 'UPDATE-USER-COVER-SUCCESS'

export const userProfiles = (state = initialState, action) => {
  switch (action.type) {
    case 'GET-USER-PROFILE-REQUEST':
      return {
        ...state,
        error: '',
        loading: true,
      }

    case 'GET-USER-PROFILE-SUCCESS':
      return {
        ...state,
        error: '',
        loading: false,
        currentProfile: action.payload
      }

    case 'GET-USER-PROFILE-ERROR':
      return {
        ...state,
        error: action.err,
        loading: false
      }

    default :
      return{
        ...state
      }
  }
}

export const setUserProfiles = (state = initialState,action) => {
  switch (action.type){
    case 'SET-USER-PROFILE-REQUEST':
      return{
        ...state,
        error:'',
        varify: false,
        loading: true,
      }
    case 'SET-USER-PROFILE-SUCCESS':
      return{
        ...state,
        error:'11',
        loading:false,
        varify: false,
        currentProfile: action.payload,
      }
    case 'SET-USER-PROFILE-ERROR':
      return{
        ...state,
        error: action.err,
        varify: true,
        loading: false,
      }
    default:
      return{
        ...state
      }
  }

}

export const updateUserPhoto = (state = initialState, action) => {
  switch (action.type){
    case UPDATE_USER_PHOTO_SUCCESS:
      return{
        ...state,
        error:'',
        loading:false,
        currentProfile: action.payload,
      }
    case UPDATE_USER_PHOTO_ERROR:
      return{
        ...state,
        error: action.err,
        varify: true,
        loading: false,
      }
    default:
      return{
        ...state
      }
  }

}
export const updateUserCover = (state = initialState, action) => {
  switch (action.type){
    case UPDATE_USER_COVER_SUCCESS:
      return{
        ...state,
        error:'',
        loading:false,
        varify: false,
        currentProfile: action.payload,
      }
    case UPDATE_USER_COVER_ERROR:
      return{
        ...state,
        error: action.err,
        varify: true,
        loading: false,
      }
    default:
      return{
        ...state
      }
  }

}