import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { useSelector } from 'react-redux';

const ContactsBlockStyled = styled.ul`
    overflow-x: auto;
  li {
    display: flex;
    align-items: center;
    height: 40px;
  }
  img {
    margin-right: 5px;
  }
  span {
    margin-right: 5px;
    font-weight: 500;
    font-size: 15px;
    color: #1e87a9;
    line-height: 18px;
  }
  div {
    font-weight: 500;
    font-size: 16px;
    color: #445566; 
    line-height: 18px;
  }

  @media(max-width: 1200px) {
    div {
      font-size: 15px;
    }
    span {
      display: none;
    }
  }

  @media(max-width: 576px) {
    div {
      font-size: 13.5px;
      padding-top: 2px;
    }
    img {
      max-width: 22px;
    }
    li {
      height: 30px;
      align-items: flex-start;
    }
  }
  @media(max-width: 480px) {
    div {
      max-width: 100px;
      width: 100%;
      font-size: 12.5px;
      padding-top: 2px;
    }
    img {
      max-width: 22px;
    }
    li {
      height: 30px;
      align-items: flex-start;
    }
  }
`;

const ContactsBlock = ({ value }) => {
  const userInfo = useSelector(state => state?.userProfiles?.currentProfile);

  const [userEmail,setUserEmail] = useState('')
  const [userInstagram,setUserInstagram] = useState('')
  const [userFacebook,setUserFacebook] = useState('')
  const [userTelegram,setUserTelegram] = useState('')
  const [userTikTok,setUserTikTok] = useState('')



  useEffect(() => {
    setUserEmail(() => userInfo?.user?.email);
    setUserInstagram(() => userInfo?.user?.instagram);
    setUserFacebook(() => userInfo?.user?.facebook);
    setUserTelegram(() => userInfo?.user?.telegram);
    setUserTikTok(() => userInfo?.user?.tiktok);
  },[userInfo])

  return (
    <ContactsBlockStyled>
      <li>
        <img src="/icons/user_inform/mail.svg" alt="email" />
        <span>e-mail:</span>
        <div>{userEmail}</div>
      </li>
      <li>
        <img src="/icons/user_inform/instagram.png" alt="instagram" />
        <div>{userInstagram !== '' ? userInstagram : "Нема"}</div>
      </li>
      <li>
        <img src="/icons/user_inform/facebook.svg" alt="facebook" />
        <div>{userFacebook !== '' ? userFacebook : "Нема"}</div>
      </li>
      <li>
        <img src="/icons/user_inform/telegram.svg" alt="telegram" />
        <div>{userTelegram !== '' ? userTelegram : "Нема"}</div>
      </li>
      <li>
        <img src="/icons/user_inform/tiktok.svg" alt="tiktok" />
        <div>{userTikTok !== '' ? userTikTok: "Нема"}</div>
      </li>
    </ContactsBlockStyled>
  );
};

export default ContactsBlock;
