import React from 'react';
import styled from 'styled-components';
import Album from '../../gallery/album/Album';
import { albums } from '../../../mockData/mockData';
import PopupLayout from '../PopupLayout';

const AlbumWrapper = styled.div`
  > div {
    margin-top: 0;
  }
`;

const PopupMoveToAlbum = ({ show, setShow }) => {
  const onEdit = () => {
    setShow(false);
  };

  return (
    <PopupLayout
      onEdit={onEdit}
      show={show}
      title="Перенести в альбом"
      button={false}
      width="67%"
      ai="flex-start"
    >
      <AlbumWrapper>
        <Album albums={albums} showAddItem={false} withOption={false} isMove />
      </AlbumWrapper>
    </PopupLayout>
  );
};

export default PopupMoveToAlbum;
