import React, { useState } from 'react';
import styled from 'styled-components';
import { statusesEnum } from '../../../../mockData/mockData';
import ActionIcon from './ActionIcon';
import AlbumOptionsBlock from './AlbumOptionsBlock';
import AlbumStatusesBlock from './AlbumStatusesBlock';

const AlbumDescrBlockStyled = styled.div`
  position: relative;
  padding: 25px 20px;
  background: #ffffff;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 80px 35px;
  grid-auto-rows: auto;
`;

const AlbumIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
  grid-row: 1 / 3;
`;

const Placeholder = styled(AlbumIcon)`
  background: #f1f2f3;
`;

const AlbumTitle = styled.div`
  padding: 0 20px 0 12px;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #223344;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #1e87a9;
  }
`;

const ActionBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Divider = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #f1f2f3;
  border-radius: 1.5px;
`;

const AlbumDescrBlock = ({ album,  onShowRemoveAlbum }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showStatuses, setShowStatuses] = useState(false);

  const onShowOptions = () => {
    setShowOptions((state) => !state);
    setShowStatuses(false);
  };

  const onShowStatuses = () => {
    setShowStatuses((state) => !state);
    setShowOptions(false);
  };

  return (
    <AlbumDescrBlockStyled>
      {album.photos[0] ? (
        <AlbumIcon src={album.photos[0]} alt="album" />
      ) : (
        <Placeholder as="div"></Placeholder>
      )}
      <AlbumTitle>
        <div>{album.name}</div>
        <span>{album.photos.length} Фото</span>
      </AlbumTitle>
      <ActionBlock>
        <ActionIcon
          isActive={showStatuses}
          onShowOptions={onShowStatuses}
          src={statusesEnum[album.status].black}
          alt="status"
        />
        <ActionIcon
          isActive={showOptions}
          onShowOptions={onShowOptions}
          src="/icons/gallery/album/black_statuses/icon_options.svg"
          alt="options"
        />
      </ActionBlock>
      <AlbumOptionsBlock albumId={album.id} show={showOptions}  onShowRemoveAlbum={ onShowRemoveAlbum} />
      <AlbumStatusesBlock show={showStatuses} />
      <Divider></Divider>
    </AlbumDescrBlockStyled>
  );
};

export default AlbumDescrBlock;
