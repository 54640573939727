import React, { useState } from 'react';
import AuthContainer from '../../components/auth/AuthContainer';
import AuthSubTitle from '../../components/auth/AuthSubTitle';
import AuthTitle from '../../components/auth/AuthTitle';
import NavCirclesBlock from '../../components/auth/register/NavCirclesBlock';
import RegisterContainer from '../../components/auth/register/RegisterContainer';
import UsersGroupeBlock from '../../components/auth/register/UsersGroupeBlock';
import ButtonsContainer from '../../components/auth/ButtonsContainer';
import ButtonSubmit from '../../components/auth/buttons/ButtonSubmit';
import ButtonStroke from '../../components/auth/buttons/ButtonStroke';
import { useHistory } from 'react-router-dom';

const AddRolePage = () => {
  const history = useHistory();

  const [usersGroupe, setUsersGroupe] = useState([
    { id: 1, groupe: 'dancer', title: 'Танцюрист', checked: false },
    { id: 2, groupe: 'parents', title: 'Один з батьків танцюриста (до 14 років)', checked: false },
    { id: 3, groupe: 'trainer', title: 'Тренер чи суддя', checked: false },
    { id: 4, groupe: 'sponsor', title: 'Організатор змагань', checked: false },
    { id: 5, groupe: 'personal', title: 'Персонал змагань', checked: false },
    { id: 6, groupe: 'manager', title: 'Керівник клубу', checked: false },
  ]);

  const [validate, setValidate] = useState(false);

  const onSubmit = () => {
    setValidate(true);

    if (usersGroupe.filter((item) => item.checked).length > 0) {
    }
  };

  return (
    <RegisterContainer>
      <NavCirclesBlock />
      <AuthContainer padding="0">
        <AuthTitle title="Додати роль користувачу" />
        <AuthSubTitle fullWidth title="Виберіть один або декілька варіантів:" />
        <UsersGroupeBlock
          usersGroupe={usersGroupe}
          setUsersGroupe={setUsersGroupe}
          validate={validate}
        />
        <ButtonsContainer mt="60px">
          <ButtonSubmit
            onClick={onSubmit}
            title="Продовжити"
            width="210px"
            widthSd="312px"
            widthMd="210px"
            width320="296px"
            type="button"
            isActive={usersGroupe.filter((item) => item.checked).length > 0}
          />
          <ButtonStroke
            onClick={() => history.goBack()}
            title="Скасувати"
            width="210px"
            widthSd="312px"
            widthMd="210px"
            width320="296px"
          />
        </ButtonsContainer>
      </AuthContainer>
    </RegisterContainer>
  );
};

export default AddRolePage;
