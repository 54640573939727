import React from 'react';
import RegisterContainer from '../../components/auth/register/RegisterContainer';
import RegisterNav from '../../components/auth/register/RegisterNav';
import AuthEnterCodeBlock from '../../components/auth/AuthEnterCodeBlock';

const RegisterEnterCodePage = () => {
  return (
    <RegisterContainer>
      <RegisterNav backLink='/auth/reg_data' currentStep={1} />
      <AuthEnterCodeBlock reg/>
    </RegisterContainer>
  )
}

export default RegisterEnterCodePage;