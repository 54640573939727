import React, { useState } from 'react';
import { useResizeMode } from '../../../hooks/useResizeMode';
import BlockHeader from '../../BlockHeader';
import SettingsSection from '../../SettingsSection';
import SportCategoryForm from './SportCategoryForm';
import SportCategoryStausCheck from './SportCategoryStausCheck';

const SportCategorySection = () => {
  const [showContnet, setShowContent] = useState(false);
  const [organization, setOrganization] = useState('');
  const [doc, setDoc] = useState('');
	const [files, setFiles] = useState([]);
  const [curentOption, setCurentOption] = useState('');
  const [statusCheck, setStatusCheck] = useState(false);

  const isMobile = useResizeMode();

  return (
    <SettingsSection>
      <BlockHeader
        title="Спортивний розряд"
        img="/icons/dancer_inform/category.svg"
        subtitle={isMobile ? 'Введіть актуальні дані, та відправте на верифікацію ' : "Введіть актуальні дані, та відправте на верифікацію. Для підтвердження інформації необхідно вкласти сканкопію підтверджуючого документу."}
        show={showContnet}
        setShow={setShowContent}
        divider={isMobile ? (showContnet ? true : false) : true}
      />
      {
        statusCheck ? (
        <SportCategoryStausCheck 
          organization={organization}
          doc={doc}
          curentOption={curentOption}
          setStatusCheck={setStatusCheck}
        />
        ) : (
        <SportCategoryForm 
          showContnet={showContnet}
          organization={organization}
          doc={doc}
          files={files}
          setOrganization={setOrganization}
          setDoc={setDoc}
          setFiles={setFiles}
          curentOption={curentOption}
          setCurentOption={setCurentOption}
          setStatusCheck={setStatusCheck}
        />
        )
      }
      
    </SettingsSection>
  );
};

export default SportCategorySection;
