const rewards = [
  {
    date: '03.03.2020',
    name: '1-ше місце в Чемпіонаті Назва...',
  },
  {
    date: '02.02.2020',
    name: 'Переможець',
  },
  {
    date: '01.01.2020',
    name: 'Нагорода... bla-bla',
  },
];

const dancerClassOptions = [
  {
    title: 'Professionals',
    id: 1,
  },
  {
    title: 'S',
    id: 13,
  },
  {
    title: 'A',
    id: 14,
  },
  {
    title: 'B(RS)',
    id: 15,
  },
  {
    title: 'C',
    id: 16,
  },
  {
    title: 'D',
    id: 17,
  },
  {
    title: 'E',
    id: 38,
  },
  {
    title: 'F(N)',
    id: 39,
  },
  {
    title: 'G (Школа)',
    id: 40,
  },
  {
    title: 'H (Дебют)',
    id: 41,
  },
  {
    title: 'I (Перші кроки)',
    id: 42,
  },
];

const sportCategoryOptions = [
  {
    title: 'Майстер спорту України міжнародного класу',
    id: 6,
  },
  {
    title: 'Майстер спорту України',
    id: 30,
  },
  {
    title: 'Кандидат у майстри спорту України',
    id: 31,
  },
  {
    title: 'Перший розряд',
    id: 32,
  },
  {
    title: 'Другий розряд',
    id: 33,
  },
  {
    title: 'Третій розряд',
    id: 34,
  },
  {
    title: 'Перший юнацький розряд',
    id: 35,
  },
  {
    title: 'Другий юнацький розряд',
    id: 36,
  },
  {
    title: 'Третій юнацький розряд',
    id: 37,
  },
];

const chronology = [
  {
    date: '03.03.2020',
    name: 'Сталась якась зміна..',
  },
  {
    date: '02.02.2020',
    name: 'Сталась найбільша зміна в даному аккаунті, дуже велика і довга назва у цієї зміни..',
  },
  {
    date: '01.01.2020',
    name: 'Сталась якась зміна знову..',
  },
];

const userInformation = {
  male: 'Чоловіча',
  age: 16,
  birthday: '01-01-2005',
  country: 'Україна',
  district: 'Київська',
  aboutMe: 'Я розкажу тут про себе..',
  contacts: {
    email: 'Назва ел. пошти',
    instagram: 'Назва аккаунту',
    facebook: 'Назва аккаунту',
    telegram: 'Назва аккаунту',
    tiktok: 'Назва аккаунту',
  },
};

const clubInformation = {
  status: 'Активний до 31.12.2021',
  organization: 'Назва ОРГАНІЗАЦІЇ',
  foundationDate: '01.01.2005',
  supervisorClub: 'Ім’я Прізвище',
  country: 'Україна',
  region: 'Київська',
  address: ['вул. Назва, буд. 1','проспект. Довга назва-2, буд. 2, корпус 3','вул. Назва-3, буд. 3','вул. Назва-4, буд. 4','вул. Назва-5, буд. 5'],
  aboutMe: 'Розповідь...',
  contacts: {
    number: 'Номер телефону',
    website: 'Назва сайту',
    email: 'Назва ел. пошти',
    instagram: 'Назва аккаунту',
    facebook: 'Назва аккаунту',
    telegram: 'Назва аккаунту',
    tiktok: 'Назва аккаунту',
  },
};

const dancerInformation = {
  status: 'Активний до 31.12.2021',
  ageCategory: 'Юніори 2',
  organization: 'Назва ОРГАНІЗАЦІЇ',
  club: [{ name: 'Назва КЛУБУ-1' }, { name: 'Назва КЛУБУ-2' }],
  trainer: [{ name: 'Прізвище Ім’я тренера-1' }, { name: 'Прізвище Ім’я тренера-2' }, { name: 'Прізвище Ім’я тренера-3' }],
  partner: [{ name: 'Прізвище Ім’я партнера/ки-1' }, { name: 'Прізвище Ім’я партнера/ки-2' }, { name: 'Пошук партнера/ки' }],
  clas: 'La:D / St:D / Gen:S ',
};

const trainerInformation = {
  status: 'Активний до 31.12.2021',
  ageCategory: 'Юніори 2',
  organization: 'Назва ОРГАНІЗАЦІЇ',
  club: [{ name: 'Назва КЛУБУ (Місто)', position: 'Тренер' }],
  club1: [{ name: 'Назва КЛУБУ-2 (Місто)', position: 'Керівник клубу' }],
};

const judgeInformation = {
  status: 'Активний до 31.12.2021',
  organization: 'Назва ОРГАНІЗАЦІЇ',
  judgeCategory: 'Назва суддівської категорії',
  position: [{ name: 'Judge' }, { name: 'Main Judge' }, { name: 'Sports Inspector' }],
};

const managerInformation = {
  status: 'Активний до 31.12.2021',
  organization: 'Назва ОРГАНІЗАЦІЇ',
  club: [{ name: 'Назва КЛУБУ (Місто)' }],
};

const managerTrainersList = () => {
  let arr = [];
  for (let i = 0; i < 9; i++) {
    arr.push({ name: 'Ім’я Прізвище', status: 'Тренер' });
  }
  return arr;
};

const trainerDancersList = () => {
  let arr = [];
  for (let i = 0; i < 44; i++) {
    arr.push({ name: 'Ім’я Прізвище', avatar: '/icons/bookmarks/new_user.svg', clas: 'Назва', age: 'Назва' });
  }
  return arr;
};

const searchResult = [
  { name: 'Схожа назва-1', url: '/' },
  { name: 'Схожа назва-2', url: '/' },
];

const accounts = [
  { id: 1, firstName: 'Ім\'я', lastName: 'Прізвище' },
  { id: 2, firstName: 'Ім\'я', lastName: 'Прізвище-1' },
];

const sportCategory = {
  name: 'Назва спортивного розряду',
  organization: 'Управління фізичної культури і спорту Київської обласної державної адміністрації',
  document: 'Наказ №0000 від 01.01.2000р.',
};

const paymentsData = [
  {
    id: 1,
    date: '01.01.2021',
    accountDescr: {
      name: 'Щорічний внесок за танцюриста',
      recipient: 'СГОСТУ',
      amount: '350.00',
      account: 'UA000000000000000000000000000',
      indNumber: '39456152',
      purpose: 'За ... внесок, від Ім’я Прізвище',
    },
  },
  {
    id: 2,
    date: '01.01.2021',
    accountDescr: {
      name: 'Щорічний внесок за користування платформою чи чогось іншого',
      recipient: 'СГОСТУ',
      amount: '100.00',
      account: 'UA000000000000000000000000000',
      indNumber: '39456152',
      purpose: 'За ... внесок, від Ім’я Прізвище',
    },
  },
  {
    id: 3,
    date: '01.01.2021',
    accountDescr: {
      name: 'Плата якихось послуг і таке всяке інше',
      recipient: 'СГОСТУ',
      amount: '350.00',
      account: 'UA000000000000000000000000000',
      indNumber: '39456152',
      purpose: 'За ... внесок, від Ім’я Прізвище',
    },
  },
];

const paidData = [
  {
    id: 1,
    dateAccount: '01.01.2021',
    datePaid: '01.01.2021',
    accountDescr: {
      name: 'Щорічний внесок за танцюриста',
      recipient: 'СГОСТУ',
      amount: '350.00',
      account: 'UA000000000000000000000000000',
      indNumber: '39456152',
      purpose: 'За ... внесок, від Ім’я Прізвище',
    },
  },
  {
    id: 2,
    dateAccount: '01.01.2021',
    datePaid: '01.01.2021',
    accountDescr: {
      name: 'Щорічний внесок за користування платформою чи чогось іншого',
      recipient: 'СГОСТУ',
      amount: '100.00',
      account: 'UA000000000000000000000000000',
      indNumber: '39456152',
      purpose: 'За ... внесок, від Ім’я Прізвище',
    },
  },
  {
    id: 3,
    dateAccount: '01.01.2021',
    datePaid: '01.01.2021',
    accountDescr: {
      name: 'Плата якихось послуг і таке всяке інше',
      recipient: 'СГОСТУ',
      amount: '350.00',
      account: 'UA000000000000000000000000000',
      indNumber: '39456152',
      purpose: 'За ... внесок, від Ім’я Прізвище',
    },
  },
];

const myInfo = {
  url: '/icons/avatar_my_info.png',
  firstName: 'Ім\'я',
  lastName: 'Прізвище',
  status: 'dancer',
};

const albums = [
  {
    id: 1,
    name: 'Фото',
    status: 'unlock',
    photos: [
      '/image/gallery/photo/test.jpg',
      '/image/gallery/photo/test2.jpg',
      '/image/gallery/photo/test3.jpg',
      '/image/gallery/photo/test4.jpg',
      '/image/gallery/photo/photo5.jpg',
      '/image/gallery/photo/photo6.jpg',
      '/image/gallery/photo/photo7.jpg',
    ],
  },
  {
    id: 2,
    name: 'Альбом з дуже великою назвою, але не більше 55',
    status: 'like',
    photos: [
      '/image/gallery/photo/photo4.jpg',
      '/image/gallery/photo/photo2.jpg',
      '/image/gallery/photo/photo3.jpg',
      '/image/gallery/photo/photo1.jpg',
    ],
  },
  {
    id: 3,
    name: 'Назва Альбому Фотосесія 2020',
    status: 'locked',
    photos: [
      '/image/gallery/photo/photo5.jpg',
      '/image/gallery/photo/photo2.jpg',
      '/image/gallery/photo/photo3.jpg',
      '/image/gallery/photo/photo4.jpg',
      '/image/gallery/photo/photo1.jpg',
    ],
  },
  {
    id: 4,
    name: 'Альбом інший',
    status: 'unlock',
    photos: ['/image/gallery/photo/photo7.jpg'],
  },
  {
    id: 5,
    name: 'Альбом',
    status: 'groupe',
    photos: [],
  },
];

const statusesEnum = {
  unlock: {
    icon: '/icons/gallery/album/unlock.svg',
    activeIcons: '/icons/gallery/album/active_status/unlock.svg',
    black: '/icons/gallery/album/black_statuses/open.svg',
    name: 'Відкритий всім',
  },
  like: {
    icon: '/icons/gallery/album/like.svg',
    activeIcons: '/icons/gallery/album/active_status/like.svg',
    black: '/icons/gallery/album/black_statuses/heart.svg',
    name: 'Для шанувальників',
  },
  locked: {
    icon: '/icons/gallery/album/locked.svg',
    activeIcons: '/icons/gallery/album/active_status/locked.svg',
    black: '/icons/gallery/album/black_statuses/locked.svg',
    name: 'Секретний',
  },
  groupe: {
    icon: '/icons/gallery/album/group.svg',
    activeIcons: '/icons/gallery/album/active_status/group.svg',
    black: '/icons/gallery/album/black_statuses/club.svg',
    name: 'Для мого клубу',
  },
};

const dancersList = [
  {
    id: 1,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист'],
    place: 'Golden club (Біла Церква)',
    avatar: '/image/avatar.png',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
    liked: true,
  },
  {
    id: 2,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист'],
    place: 'VIVA Dance Sport Studio (Олександрія)',
    avatar: '',
    ageCategory: 'Juveniles 1',
    region: 'Назва області-2',
  },
  {
    id: 3,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист'],
    place: 'Імперія танцю (Київ)',
    avatar: '',
    ageCategory: 'Juveniles 2',
    region: 'Назва області-3',
  },
  {
    id: 4,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    ageCategory: 'Juniors 1',
    region: 'Назва області-4',
    liked: true,
  },
  {
    id: 5,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    ageCategory: 'Juniors 2',
    region: 'Назва області-5',
  },
  {
    id: 6,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    ageCategory: 'Youth 1',
    region: 'Назва області-6',
  },
  {
    id: 7,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    ageCategory: 'Adults',
    region: 'Назва області-7',
  },
  {
    id: 8,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    ageCategory: 'Seniors',
    region: 'Назва області-8',
  },
  {
    id: 9,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    ageCategory: 'Baby 1',
    region: 'Назва області-9',
  },
  {
    id: 10,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    ageCategory: 'Baby 1',
    region: 'Назва області-10',
  },
  {
    id: 11,
    userName: 'Ім\'я Прізвище',
    staus: ['Тренер'],
    place: 'Golden club (Біла Церква)',
    avatar: '/image/avatar.png',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
    liked: true,
  },
  {
    id: 12,
    userName: 'Ім\'я Прізвище',
    staus: ['Тренер', 'Керівник клубу'],
    place: 'VIVA Dance Sport Studio (Олександрія)',
    avatar: '',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 21,
    userName: 'Ім\'я Прізвище',
    staus: ['Суддя'],
    place: 'Golden club (Біла Церква)',
    avatar: '/image/avatar.png',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
    liked: true,
  },
  {
    id: 13,
    userName: 'Ім\'я Прізвище',
    staus: ['Тренер', 'Суддя'],
    place: 'Імперія танцю (Київ)',
    avatar: '',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
    liked: true,
  },
  {
    id: 14,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист', 'Тренер', 'Суддя'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 15,
    userName: 'Ім\'я Прізвище',
    staus: ['Тренер'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 16,
    userName: 'Ім\'я Прізвище',
    staus: ['Тренер', 'Керівник клубу'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 17,
    userName: 'Ім\'я Прізвище',
    staus: ['Тренер', 'Суддя'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
    liked: true,
  },
  {
    id: 18,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист', 'Тренер', 'Суддя'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 19,
    userName: 'Ім\'я Прізвище',
    staus: ['Тренер'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 20,
    userName: 'Ім\'я Прізвище',
    staus: ['Тренер'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 22,
    userName: 'Ім\'я Прізвище',
    staus: ['Суддя'],
    place: 'Назва клубу (Місто)',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 23,
    userName: 'Ім\'я Прізвище',
    staus: ['Суддя'],
    place: 'Назва клубу (Місто)',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 24,
    userName: 'Ім\'я Прізвище',
    staus: ['Суддя'],
    place: 'Назва клубу (Місто)',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 25,
    userName: 'Ім\'я Прізвище',
    staus: ['Суддя'],
    place: 'Назва клубу (Місто)',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
    liked: true,
  },
  {
    id: 26,
    userName: 'Ім\'я Прізвище',
    staus: ['Суддя'],
    place: 'Назва клубу (Місто)',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 27,
    userName: 'Ім\'я Прізвище',
    staus: ['Керівник клубу'],
    place: 'Назва клубу (Місто)',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 28,
    userName: 'Ім\'я Прізвище',
    staus: ['Керівник клубу'],
    place: 'Назва клубу (Місто)',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 29,
    userName: 'Ім\'я Прізвище',
    staus: ['Керівник клубу'],
    place: 'Назва клубу (Місто)',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 30,
    userName: 'Ім\'я Прізвище',
    staus: ['Керівник клубу'],
    place: 'Назва клубу (Місто)',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 31,
    userName: 'Ім\'я Прізвище',
    staus: ['Керівник клубу'],
    place: 'Назва клубу (Місто)',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
  {
    id: 32,
    userName: 'Ім\'я Прізвище',
    staus: ['Керівник клубу'],
    place: 'Назва клубу (Місто)',
    ageCategory: 'Baby 1',
    region: 'Назва області-1',
  },
];

const clubsList = [
  {
    id: 1,
    name: 'Назва Клубу-1 (Місто)',
    userName: 'Ім’я Прізвище',
    country: 'Україна',
    liked: true,
  },
  { id: 2, name: 'Назва Клубу-2 (Місто)', userName: 'Ім’я Прізвище', country: 'Україна' },
  { id: 3, name: 'Назва Клубу-3 (Місто)', userName: 'Ім’я Прізвище', country: 'Україна' },
  {
    id: 4,
    name: 'Назва Клубу-4 (Місто)',
    userName: 'Ім’я Прізвище',
    country: 'Україна',
    liked: true,
  },
  { id: 5, name: 'Назва Клубу-5 (Місто)', userName: 'Ім’я Прізвище', country: 'Україна' },
  { id: 6, name: 'Назва Клубу-6 (Місто)', userName: 'Ім’я Прізвище', country: 'Україна' },
  { id: 7, name: 'Назва Клубу-7 (Місто)', userName: 'Ім’я Прізвище', country: 'Україна' },
  { id: 8, name: 'Назва Клубу-8 (Місто)', userName: 'Ім’я Прізвище', country: 'Україна' },
  { id: 9, name: 'Назва Клубу-9 (Місто)', userName: 'Ім’я Прізвище', country: 'Україна' },
  { id: 10, name: 'Назва Клубу-10 (Місто)', userName: 'Ім’я Прізвище', country: 'Україна' },
];

const searchList = [
  {
    id: 1,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист'],
    place: 'Golden club (Біла Церква)',
    avatar: '/image/avatar.png',
    liked: true,
  },
  {
    id: 2,
    userName: 'Ім\'я Прізвище',
    staus: ['Тренер', 'Керівник клубу'],
    place: 'VIVA Dance Sport Studio (Олександрія)',
    avatar: '',
    liked: false,
  },
  {
    id: 3,
    userName: 'Ім\'я Прізвище',
    staus: ['Тренер', 'Суддя'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    liked: true,
  },
  {
    id: 4,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист', 'Тренер', 'Суддя'],
    place: 'Назва клубу (Місто)',
    avatar: '',
  },
  {
    id: 5,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист'],
    place: 'Назва клубу (Місто)',
    avatar: '',
  },
  {
    id: 6,
    userName: 'Ім\'я Прізвище',
    staus: ['Тренер', 'Керівник Клубу'],
    place: 'Назва клубу (Місто)',
    avatar: '',
  },
  {
    id: 7,
    userName: 'Ім\'я Прізвище',
    staus: ['Тренер', 'Суддя'],
    place: 'Назва клубу (Місто)',
    avatar: '',
  },
  {
    id: 8,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист', 'Тренер', 'Суддя'],
    place: 'Назва клубу (Місто)',
    avatar: '',
  },
  {
    id: 9,
    userName: 'Ім\'я Прізвище',
    staus: ['Танцюрист'],
    place: 'Назва клубу (Місто)',
    avatar: '',
    liked: true,
  },
  {
    id: 10,
    userName: 'Ім\'я Прізвище',
    staus: ['Тренер', 'Керівник клубу'],
    place: 'Назва клубу (Місто)',
    avatar: '',
  },
];

const organizationsList = [
  {
    id: 1,
    name: 'Назва Організації-1',
    userName: 'Ім’я Прізвище',
    country: 'Україна',
    liked: true,
  },
  { id: 2, name: 'Назва Організації-2', userName: 'Ім’я Прізвище', country: 'назва' },
  { id: 3, name: 'Назва Організації-3', userName: 'Ім’я Прізвище', country: 'назва' },
];

const trainerList = [
  'Ім’я Прізвище-1',
  'Ім’я Прізвище-2',
  'Ім’я Прізвище-3',
  'Ім’я Прізвище-4',
  'Ім’я Прізвище-5',
  'Ім’я Прізвище-6',
  'Ім’я Прізвище-7',
  'Ім’я Прізвище-8',
  'Ім’я Прізвище-9',
  'Ім’я Прізвище-10',
];

const judgesList = [
  'Назва-1',
  'Назва-2',
  'Назва-3',
  'Назва-4',
  'Назва-5',
  'Назва-6',
  'Назва-7',
  'Назва-8',
  'Назва-9',
  'Назва-10',
];

const organizationList = ['Назва організації-1', 'Назва організації-2'];

const userList = [
  'Ім’я Прізвище-1',
  'Ім’я Прізвище-2',
  'Ім’я Прізвище-3',
  'Ім’я Прізвище-4',
  'Ім’я Прізвище-5',
];

const sportCategoryList = [
  'Майстер спорту України міжнародного класу',
  'Майстер спорту України',
  'Кандидат у майстри спорту України',
  'Перший розряд',
  'Другий розряд',
  'Третій розряд',
  'Перший юнацький розряд',
  'Другий юнацький розряд',
  'Третій юнацький розряд',
];

const userData = {
  email: 'Назва',
  facebook: 'Назва',
  instagram: 'Назва',
  telegram: 'Назва',
  tiktok: 'Назва',
  about: 'Розповідь про себе, якщо вона є...',
};


const listAlertsForAvatar = [
  'Фото за прикладом як на паспорт.',
  'Обличчя на фотографії має бути відкритим (волосся не повинні закривати очі).',
  'На фотографії крупним планом повинні бути зображені голова і верхня частина плечей.',
  'Особа має займати 70-80% вертикального розміру знімка (визначається візуально).',
  'Фотографія повинна бути на білому фоні.',
  'Файл формату JPEG або PNG, до 8 МБ',
];

const listAlertsForWrapper = [
  'Забороняється розміщати інформацію рекламного характеру, пропаганди насилля, війни чи расової дискримінації.',
  'Розміщення невідповідного контенту призведе до блокування акаунту.',
];

const allUsers = [
  {
    id: 1,
    firstName: 'Ім’я',
    lastName: 'Прізвище',
  },
];

export {
  rewards,
  chronology,
  userInformation,
  dancerInformation,
  judgeInformation,
  trainerInformation,
  trainerDancersList,
  searchResult,
  accounts,
  sportCategory,
  paymentsData,
  paidData,
  myInfo,
  albums,
  statusesEnum,
  dancersList,
  clubsList,
  searchList,
  trainerList,
  organizationList,
  judgesList,
  organizationsList,
  userList,
  sportCategoryList,
  userData,
  listAlertsForAvatar,
  listAlertsForWrapper,
  allUsers,
  managerInformation,
  clubInformation,
  sportCategoryOptions,
  dancerClassOptions,
  managerTrainersList,
};
