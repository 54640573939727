import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BookmarksTabsStyled, TabsTitle } from '../../bookmarks/bookmarksTabs/BookmarksTabs';
import OptionsGroupe from '../searchFilter/searchFilterItem/OptionsGroupe';
import { dancersList, clubsList } from '../../../mockData/mockData';
import ButtonBlock from '../../settings/ButtonBlock';

const SearchFilterStyled = styled(BookmarksTabsStyled)`
  margin-top: 20px;
  width: 100%;
  ul {
    padding: 12px;
  }

  @media (max-width: 576px) {
    margin-top: 0;
    margin-bottom: 12px;
  }
`;

const FilterTitle = styled(TabsTitle)`
  letter-spacing: 0.07em;

  @media (max-width: 576px) {
    box-shadow: 0px -1px 2px #ffffff;
    border-radius: 2px;
    img {
      display: block;
    }
  }
`;

const OptionsForm = styled.form`
  padding: 0 15px 20px 15px;
  img {
    margin-right: 10px;
  }

  @media (max-width: 576px) {
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

const ButtonWrapper = styled.div`
  display: none;

  @media (max-width: 576px) {
    display: block;
  }
`;

const SearchClubsFilter = ({ showInMobile }) => {
  const [showTabs, setShowTabs] = useState(false);
  const [submitActive, setSubmitActive] = useState(false);
  const [isActiveFilter, setActiveFilter] = useState(false);

  const [filterRegion, setFilterRegion] = useState('');
  const [filterClub, setFilterClub] = useState('');

  const regionList = [...new Set(dancersList.map((item) => item.region))];
  const clubNames = [...new Set(clubsList.map((item) => item.name))];

  useEffect(() => {
    if (filterRegion || filterClub) {
      setSubmitActive(true);
    } else {
      setSubmitActive(false);
      setActiveFilter(false);
    }
  }, [filterRegion, filterClub]);

  const onSubmit = (e) => {
    e.preventDefault();
    setActiveFilter(true);
    setShowTabs(false);
  };

  const onReset = () => {
    setFilterRegion('');
    setFilterClub('');
    setActiveFilter(false);
  };

  return (
    <SearchFilterStyled showInMobile={showInMobile}>
      <FilterTitle showTabs={showTabs} isActiveFilter={isActiveFilter} onClick={() => setShowTabs((state) => !state)}>
        Фільтр{' '}
        <img src={isActiveFilter ? '/icons/filter_active.svg' : '/icons/filter.svg'} alt="filter" />
      </FilterTitle>
      <OptionsForm onSubmit={onSubmit} onReset={onReset} show={showTabs}>
        <OptionsGroupe
          show
          optionArr={[
            {
              title: 'Область',
              focusPlaceholder: 'Виберіть область...',
              imgUrl: '/icons/search/filter_options/region.svg',
              subOptions: regionList,
              value: filterRegion,
              setValue: setFilterRegion,
            },
            {
              title: 'Назва клубу',
              focusPlaceholder: 'Виберіть клуб...',
              imgUrl: '/icons/links/my_club.svg',
              subOptions: clubNames,
              value: filterClub,
              setValue: setFilterClub,
            },
          ]}
        />
        <ButtonWrapper>
          <ButtonBlock
            submitTitle={
              <span>
                <img src="/icons/filter_white.svg" alt="filter" /> Відфільтрувати
              </span>
            }
            secondButton={
              <span>
                <img src="/icons/filter_active.svg" alt="filter" /> Скинути фільтр
              </span>
            }
            submitActive={submitActive}
            type="reset"
          />
        </ButtonWrapper>
      </OptionsForm>
    </SearchFilterStyled>
  );
};

export default SearchClubsFilter;
