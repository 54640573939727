import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BlockDivider from '../../BlockDivider';
import EyeBlock from '../../settings/settingsPassword/EyeBlock';
import PopupLayout from '../PopupLayout';

const PopupBody = styled.div`
  position: relative;
  padding: 0 25px 15px 25px;
`;

const TextBlock = styled.div`
  margin-top: 15px;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #223344;

  @media (max-width: 576px) {
    font-size: 15px;
    line-height: 20px;
  }
`;

const InputBlock = styled.div`
  position: relative;
  margin-top: 20px;
  width: 200px;
  height: 40px;
  input {
    display: block;
    width: 100%;
    height: 100%;
    padding: 5px 50px 5px 10px;
    border: ${({ isActive }) => (isActive ? '1px solid #1E87A9' : '1px solid #778899')};
    border-radius: 5px;
    outline: none;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    color: #445566;
    ::placeholder {
      font-weight: normal;
      font-size: 15px;
      line-height: 30px;
      color: #778899;
    }
  }

  @media (max-width: 576px) {
    margin: 0 auto;
    margin-top: 20px;
  }
`;

const CodeNone = styled.div`
  margin-top: 40px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #1e87a9;
  cursor: pointer;
  padding-left: 10px;
`;

const PopupPhoneChange = ({
  show,
  setShow,
  phoneNumber,
  curentCode = '1234',
  setShowCodeNone,
  setAccess,
  setShowPopupResult,
}) => {
  const [submitActive, setSubmitActive] = useState(false);
  const [visible, setVisible] = useState(false);
  const [code, setCode] = useState('');

  useEffect(() => {
    if (code) {
      setSubmitActive(true);
    } else {
      setSubmitActive(false);
    }
  }, [code]);

  const onEdit = () => {
    setShow(false);
  };

  const onCodeNone = () => {
    setShowCodeNone(true);
    setShow(false);
  };

  const onSubmit = () => {
    if (code === curentCode) {
      setShow(false);
      setAccess(true);
      setShowPopupResult(true);
    } else {
      setShow(false);
      setAccess(false);
      setShowPopupResult(true);
    }
  };

  return (
    <PopupLayout
      onAccess={onSubmit}
      submitActive={submitActive}
      submitTitle="Продовжити"
      onEdit={onEdit}
      show={show}
      setShow={setShow}
      title="Введіть код"
    >
      <PopupBody>
        <TextBlock>Ми надіслали вам код в SMS на ваш номер телефону:</TextBlock>
        <TextBlock>{`+***********${phoneNumber.slice(phoneNumber.length - 2)}`}</TextBlock>
        <TextBlock>Ваш код має складатися з 4 символів.</TextBlock>
        <InputBlock isActive={submitActive}>
          <input
            placeholder="Введіть код"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            type={visible ? 'text' : 'password'}
          />
          <EyeBlock visible={visible} setVisible={setVisible} />
        </InputBlock>
        <CodeNone onClick={onCodeNone}>Не отримали код?</CodeNone>
        <BlockDivider height="2px" />
      </PopupBody>
    </PopupLayout>
  );
};

export default PopupPhoneChange;
