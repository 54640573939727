import React from 'react';
import styled from 'styled-components';
import AddSettingsBlock from '../../AddSettingsBlock';

const StatusCheckStyled = styled.div`
  position: relative;
  padding: 20px 20px 20px 60px;

	@media (max-width: 576px) {
    padding: 20px;
  }
`;

const StatusState = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  color: #445566;
  span {
    margin-right: 10px;
    font-size: 15px;
    color: #1e87a9;
  }
	@media (max-width: 576px) {
		font-size: 14px;
		line-height: 20px;
		span {
			font-size: 13px;
		}
	}
`;

const SportCategory = styled.div`
  width: 535px;
  margin-top: 20px;
  padding: 10px;
  min-height: 40px;
  box-shadow: 0px 0px 2px #d4d4d4;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.07em;
  color: #445566;
	img {
		display: none;
	}

  @media (max-width: 576px) {
		position: relative;
		padding: 10px 10px 10px 36px;
    width: 100%;
		margin-top: 16px;
		font-size: 14px;
		img {
			display: inline;
			position: absolute;
			top: 11px;
			left: 11px;
		}
  }
`;

const StatusCheckItem = styled.div`
  width: 535px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: #445566;
  span {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
  }

  @media (max-width: 576px) {
    width: 100%;
		margin-top: 16px;
		font-size: 14px;
  	line-height: 20px;
		span {
			font-size: 13px;
			margin-bottom: 4px;
		}
  }
`;

const SportCategoryStausCheck = ({ organization, doc, curentOption, setStatusCheck }) => {
  return (
    <>
      <StatusCheckStyled>
        <StatusState>
          <span>Статус: </span> Підтверджено
        </StatusState>
        <SportCategory><img src='/icons/settings_page/sport_category.svg' alt='category' />{curentOption}</SportCategory>
        <StatusCheckItem>
          <span>Ким присвоїно:</span>
          {organization}
        </StatusCheckItem>
        <StatusCheckItem>
          <span>Документ:</span>
          {doc}
        </StatusCheckItem>
      </StatusCheckStyled>
      <AddSettingsBlock title="Нова заявка" onClick={() => setStatusCheck(false)} />
    </>
  );
};

export default SportCategoryStausCheck;
