import React from 'react';
import styled from 'styled-components';
import PopupLayout from '../PopupLayout';

const PopupBody = styled.div`
	padding: ${({access}) => access ? '15px 25px 106px 25px' : '15px 25px 47px 25px'}
`;

const TextBlock = styled.div`
  margin-top: 15px;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #223344;

	@media(max-width: 576px) {
		font-size: 15px;
  	line-height: 20px;
	}
`;


const PopupResult = ({ show, setShow, access }) => {

	const onEdit = () => {
		setShow(false)
	}

  return (
    <PopupLayout
      show={show}
      onEdit={onEdit}
      title={access ? 'Вітаємо!' : 'Увага!'}
			button={false}
    >
			<PopupBody access={access}>
				<TextBlock>
					{
						access ? 'Ви успішно змінили номер телефону.' : (
							<>
							Ви ввели невірний код.<br></br><br></br>
							Перевірте надіслане нами SMS та повторіть спробу.
							</>
						)
					}
				</TextBlock>
			</PopupBody>
		</PopupLayout>
  );
};

export default PopupResult;
