import React from 'react';
import styled from 'styled-components';

const InputHintStyled = styled.div`
  display: ${(p) => (p.show ? 'block' : 'none')};
  padding: 4px 0 0 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #556677;
  @media (max-width: 480px) {
    max-width: ${({w480}) => w480};
  }@media (max-width: 360px) {
    padding: 4px 24px 0 14px;
    padding: ${(p) => p.p0cd};
  }
  @media (max-width: 320px) {
    padding: 4px 0 0 24px;
    padding: ${(p) => p.p0cd};
  }
`;

const InpupHint = ({ title, show = true, p0cd , w480}) => {
  return (
    <InputHintStyled show={show} p0cd={p0cd} w480={w480}>
      {title}
    </InputHintStyled>
  );
};

export default InpupHint;
