import React, { useEffect, useState } from 'react';
import styled from "styled-components";

const ContactsBlockStyled = styled.ul`
  li {
    display: flex;
    align-items: center;
    height: 40px;
  }
  img {
    margin-right: 5px;
  }
  div {
    font-weight: 500;
    font-size: 16px;
    color: #445566; 
    line-height: 18px;
  }

  @media(max-width: 1200px) {
    span {
      display: none;
    }
  }

  @media(max-width: 576px) {
    div {
      font-size: 13.5px;
      padding-top: 2px;
    }
    img {
      max-width: 22px;
    }
    li {
      height: 30px;
      align-items: flex-start;
    }
  }
`;

const ShowAddressStyled = styled.div`
margin-top: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 25px;
`
const ShowAddressText = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #778899;
  margin-right: 11px;
`

const ShowAddressImg = styled.img``


const ContactsClubBlock = ({ value }) => {
  const [length, setLength] = useState(true);

useEffect(() => {}, [value,length]
)

  return (
    <ContactsBlockStyled >
      {value.map((item,i)=> {
       return length ? i < 2 &&  <li key={i}><div>{item}</div></li> : <li key={i}><div>{item}</div></li>
      })}
      <ShowAddressStyled>
        <ShowAddressText onClick={() => setLength(!length)}>
          {length ? 'Показати всі адреси' : 'Показати менше'}
        </ShowAddressText>
        <ShowAddressImg src={length ? '/icons/clubs/arrow-down.svg' : '/icons/clubs/arrow-up.svg'}/>
      </ShowAddressStyled>
    </ContactsBlockStyled>
  );
};

export default ContactsClubBlock;
