import React, {useState} from "react";
import Pagination from "../../pagination/Pagination";
import PaidBody from "./paidBody/PaidBody";
import PaidHeader from "./paidHeader/PaidHeader";
import PaymentsSubheader from '../paymentsSubheader/PaymentsSubheader';
import { paidData } from "../../../mockData/mockData";

const Paid = () => {

  const [searchTerm, setSearchTerm] = useState('');

  const filteredArr = paidData.filter(
    (item) => item.accountDescr.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
  );

  return (
    <>
      <PaymentsSubheader searchTerm={searchTerm} onSearch={setSearchTerm} />
      <PaidHeader />
      <PaidBody filteredArr={filteredArr}/>
      <Pagination />
    </>
  );
};

export default Paid;
