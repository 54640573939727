import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputLabel from './InputLabel';
import ValidMessage from './ValidMessage';
import InputHint from './InputHint';

export const AuthInputStyled = styled.div`
  position: relative;
  margin-top: ${(p) => p.mt || 0};
  min-height: 52px;
  padding: 16px 12px;
  box-shadow: ${(p) =>
    p.focus
      ? '1px 1px 1px #FFFFFF, -1px -1px 1px #E7EAED'
      : '-1px -1px 1px #FFFFFF, 1px 1px 1px #E7EAED'};
  border: ${(p) => (!p.isValid && !p.focus ? '1px solid #C70000' : '1px solid transparent')};
  border-radius: 10px;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    margin-top: ${({mt1000}) => mt1000 || '0'};
    max-width: 432px;
    //margin: ${({m1000}) => m1000};
  }
  @media (max-width: 480px) {
    max-width: 312px;
  }
  @media (max-width: 360px) {
    max-width: 296px;
  }
`;

export const InputStyled = styled.input`
  width: 100%;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  background: #f6f6f6;
  outline: none;
  border: none;
  color: #223344;
  ::placeholder {
    font-weight: normal;
    color: #778899;
  }
`;

const AuthInputText = ({
  lang,
  mt1000,
  mt,
  hint,
  value,
  setValue,
  placeholder,
  validate,
  message,
  realizeDateField,
  m1000,
}) => {
  const [focus, setFocus] = useState(false);
  const [validMessage, setvalidMessage] = useState('');

  useEffect(() => {
    if (validate && !value) {
      setvalidMessage('Обов’язкове поле');
    } else {
      setvalidMessage('');
    }
  }, [validate, value]);
  return (
    <div>
      <AuthInputStyled mt1000={mt1000} mt={mt} focus={focus} isValid={message ? !message : !validMessage} m1000={m1000}>
        <InputStyled
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={value}
          onChange={(e) =>
            // !lang
            //   ? setValue(e.target.value)
            //   : lang && lang === 'UA' && e.target.value.match(/[а-яієїґ\']+/ig)
            //   ? setValue(e.target.value)
            //   : lang && lang === 'ENG' && e.target.value.match(/^[A-Za-z][A-Za-z]*$/)
            //   ? setValue(e.target.value)
            //   : lang && lang === 'ENG_NUM' && e.target.value.match(/^[A-Za-z][A-Za-z\W]*$/)
            //   ? setValue(e.target.value)
            //   : lang && lang === 'NUM' && e.target.value.match(/^[s0-9\W]*$/)
            //   ? setValue(e.target.value)
            //   : lang && lang === 'UA_Num' && e.target.value.match(/^[а-яієїґ\sА-я0-9.№]+$/)
            //   ? setValue(e.target.value)
            //   : lang && lang === 'WEB' && e.target.value.match(/^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/)
            //   ? setValue(e.target.value)
            //   : setValue(e.target.value)
            setValue(e.target.value)

          }
          type="text"
          placeholder={focus ? '' : placeholder}
        />
        <InputLabel
          text
          valid={message ? !message : !validMessage}
          focus={focus}
          title={placeholder}
          show={value}
          m1000='center'
        />
      </AuthInputStyled>
      <ValidMessage message={message ? message : validMessage} ml0rd="10px" />
      {hint ? <InputHint title={hint} p0cd="4px 24px 0 14px" w480='312px'/> : 'padding: 4px 24px 0 36px'}
    </div>
  );
};

export default AuthInputText;