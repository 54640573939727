import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import users from '../reducers/usersReducer';
import usersProfiles from '../reducers/usersReducer';
import login from '../reducers/login.reducer';
import registerUsers from '../reducers/register.reduser';
import {userCountry, userRegion} from '../reducers/country.reducer';
import popup from '../reducers/popups.reducer'
import {userProfiles, setUserProfiles} from '../reducers/profile.reducer';
import userGallery from '../reducers/gallery.reducer';
import positionsReducer from "../reducers/positions.reducer";

const rootReducer = combineReducers({
  users,
  usersProfiles,
  login,
  registerUsers,
  userCountry,
  userRegion,
  popup,
  userProfiles,
  setUserProfiles,
  userGallery,
  positionsReducer
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;