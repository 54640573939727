import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';


const InputRoleSelect = styled.div`
  display: ${({ d1440 }) => d1440 ? d1440 : 'none'};
  cursor: pointer;
  position: relative;
  margin-left: 25px;
  @media (max-width: 425px) {
    display: ${({ d425 }) => d425 ? d425 : 'none'};
    padding: 24px 0;
    margin-left: 0;
  }
`;

const Options = styled.div`
  display: ${(show) => (show ? 'block' : 'none')};
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: #F6F6F6;
  @media (max-width: 425px) {
    //width: 100%;
    //height: 100%;
    //top: 0;
    //left: 0;

  }
`;

const DefaultText = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 215px;
  height: 40px;
  padding: 8px 10px 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #1e87a9;
  border: 2px solid rgba(253, 253, 253, 0.75);
  filter: drop-shadow(0px 1px 2px rgba(174, 174, 192, 0.2)) drop-shadow(-1px -1px 1px #FFFFFF) drop-shadow(1px 1px 1px #E5E5E5);
  border-radius: 12px;
  text-align: center;
  margin-right: 12px;
`;
const RoleOptionBackground = styled.div`
  position: absolute;
  top: 50px;
  width: 255px;
  left: 50%;
  transform: translate(-50%);
  background-color: #F6F6F6;
  box-shadow: 0px 0px 1px #D4D4D4;
  border-radius: 0px 0px 10px 10px;
  @media (max-width: 425px) {
    position: fixed;
    width: ${({ show }) => show ? '100%' : '0'};
    height: ${({ show }) => show ? '100%' : '0'};
    transform: translate(0);
    left: 0;
    top: 0;
    z-index: 20;
  }
`;
const OptionsItem = styled.div`
  padding: 5px 20px;
  max-width: 215px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #778899;
  border: 2px solid rgba(253, 253, 253, 0.75);
  filter: drop-shadow(0px -1px 2px #FFFFFF) drop-shadow(0px 1px 2px #E5E5E5);
  border-radius: 12px;
  text-align: center;
  background: #F6F6F6;
  margin: 20px auto;

  :hover {
    color: #0A87AA;
  }

  @media (max-width: 425px) {
    &:first-child {
      margin-top: 150px;
    }

  }
`;
const ArrowDown = styled.div`
  position: relative;
  display: inline-block;
  top: 13px;
  transform: rotate(180deg);
  //right: 15px;
  //padding-right: 12px;
  margin-left: 12px;
  ${(p) => (p.show ? 'transform: rotate(0deg);top: 0 !important' : '')} // 

`;

const ClosePopup = styled.div`
  display: none;
          @media (max-width: 425px) {
            display: ${({show}) => show ? 'flex' : 'none'};
            position: absolute;
            z-index: 2;
            top: 20px;
            right: 20px;
          }
  `
;
const RoleSelect = ({ d425, d1440 }) => {

  const RoleOptions = [
    { title: 'Танцюрист', id: 1 },
    { title: 'Тренер', id: 2 },
    { title: 'Суддя', id: 3 },
    { title: 'Керівник клубу', id: 4 },
  ];
  const [roleValue, setRoleValue] = useState('Керівник клубу');
  const [roleId, setRoleId] = useState(4);
  const [showOptions, setShowOptions] = useState(false);
  const listRef = useRef();
  const handleCloseList = (e) => {
    return !e.composedPath().includes(listRef?.current) ? setShowOptions(false) : null;
  };
  useEffect(() => {
    document.body.addEventListener('click', handleCloseList);
  }, [showOptions, roleId, roleValue, listRef]);


  const handleOption = (item) => {
    setRoleValue(item.title);
    setRoleId(item.id);
    setShowOptions(false);
  };
  return (


    <InputRoleSelect d425={d425} d1440={d1440} onClick={() => setShowOptions(!showOptions)}
                     ref={listRef}>

      <DefaultText onClick={() => setShowOptions((showOptions) => !showOptions)}>
        {roleValue}
        <ArrowDown show={showOptions} onClick={() => setShowOptions(!showOptions)}>
          <img src='icons/arrow-blue-card.svg' alt='arrow down' />
        </ArrowDown>
      </DefaultText>
      <RoleOptionBackground show={showOptions}>
        <ClosePopup show={showOptions}>
          <img src='/icons/popups/popup-close.svg' alt='close-popup' />
        </ClosePopup>
        <Options show={showOptions}>
          {showOptions && RoleOptions.map((item, i) => {
            return (
              <OptionsItem onClick={() => {
                handleOption(item, i);
              }} key={i}>
                {item.title}
              </OptionsItem>
            );
          })
          }

        </Options>
      </RoleOptionBackground>
    </InputRoleSelect>
  );
};

export default RoleSelect;