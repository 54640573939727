import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LogInPage from './LogInPage';
import LogInHeader from '../../components/auth/logIn/LogInHeader';
import ForgotPasswordPage from './ForgotPasswordPage';
import EnterCodePage from './EnterCodePage';
import ChangePasswordPage from './ChangePasswordPage';
import RegisterPage from './RegisterPage';
import RegisterDataPage from './RgisterDataPage';
import RegisterEnterCodePage from './RegisterEnterCodePage';
import AlreadyRegPage from './AlreadyRegPage';
import CongratulationPage from './CongratulationPage';
import AddRolePage from './AddRolePage';
import QuestionaryDancerPage from './QuestionaryDancerPage';
import QuestionaryParentPage from './QuestionaryParentPage';
import QuestionaryTrainerPage from './QuestionaryTrainerPage';
import QuestionaryManagerPage from './QuestionaryManagerPage';
import QuestionaryPersonalPage from './QuustionaryPersonalPage';
import QuestionarySponsorPage from './QuestionarySponsorPage';
import RegisterSuccessPage from './RegisterSuccessPage';
import AddDocumentsPage from './AddDocumentsPage';

const AuthPage = ({ auth, setAuth }) => {
  return (
    <>
      <LogInHeader />
      <Switch>
        <Route path="/auth/login">
          <LogInPage auth={auth} setAuth={setAuth} />
        </Route>
        <Route path="/auth/forgot_password">
          <ForgotPasswordPage />
        </Route>
        <Route path="/auth/enter_code">
          <EnterCodePage />
        </Route>
        <Route path="/auth/change_password">
          <ChangePasswordPage />
        </Route>
        <Route path="/auth/register">
          <RegisterPage pd="0 24px" />
        </Route>
        <Route path="/auth/reg_data">
          <RegisterDataPage />
        </Route>
        <Route path="/auth/reg_code">
          <RegisterEnterCodePage />
        </Route>
        <Route path="/auth/already_reg">
          <AlreadyRegPage />
        </Route>
        <Route path="/auth/reg_congratulation">
          <CongratulationPage />
        </Route>
        <Route path="/auth/reg_add_role">
          <AddRolePage />
        </Route>
        <Route path="/auth/questionary_dancer">
          <QuestionaryDancerPage />
        </Route>
        <Route path="/auth/questionary_parents">
          <QuestionaryParentPage />
        </Route>
        <Route path="/auth/questionary_trainer">
          <QuestionaryTrainerPage />
        </Route>
        <Route path="/auth/questionary_manager">
          <QuestionaryManagerPage />
        </Route>
        <Route path="/auth/questionary_personal">
          <QuestionaryPersonalPage />
        </Route>
        <Route path="/auth/questionary_sponsor">
          <QuestionarySponsorPage />
        </Route>
        <Route path="/auth/reg_success">
          <RegisterSuccessPage />
        </Route>
        <Route path="/auth/reg_add_doc">
          <AddDocumentsPage />
        </Route>
      </Switch>
    </>
  );
};

export default AuthPage;
