import React from 'react';
import styled from 'styled-components';

const AlertMessageStyled = styled.div`
  display: ${({show}) => show ? 'block' : 'none'};
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #C70000;

  @media(max-width: 576px) {
    font-size: 13px;
  }
`;

const SuccessMessageStyled = styled(AlertMessageStyled)`
  color: #1E87A9;
`;

const AlertMessage = ({show=false, message='', successMessage='', showSuccess=false}) => {
	return (
    <>
    <AlertMessageStyled show={show}>{message}</AlertMessageStyled>
    <SuccessMessageStyled show={showSuccess} >{successMessage}</SuccessMessageStyled>
    </>
  )
}

export default AlertMessage;