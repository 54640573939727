import React, { useState, useCallback } from 'react';
import AccountsHeader from './accountsHeader/AccountsHeader';
import AccountsBody from './accountsBody/AccountsBody';
import AccountsFooter from './accountsFooter/AccountsFooter';

import { paymentsData } from '../../../mockData/mockData';
import PaymentsSubheader from '../paymentsSubheader/PaymentsSubheader';

const Accounts = () => {
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedAccounts, setCheckedAccounts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const onSearch = (value) => {
    setSearchTerm(value);
  };

  const onCheckedAll = (checked) => {
    setCheckedAll(checked);
  };

  const onCheckedAccounts = useCallback(
    (id) => {
      if (!checkedAccounts.includes(id)) {
        if (paymentsData.length-1 === checkedAccounts.length) {
          setCheckedAll(true)          
        }
        setCheckedAccounts((state) => [...state, id]);
      }
    },
    [checkedAccounts]
  );

  const onUnCheckedAccounts = (id) => {
    if (checkedAccounts.length) {
      setCheckedAll(false);
      setCheckedAccounts((state) => state.filter((item) => item !== id));
    }
  };

  const onAddALLtoChecked = () => {
    setCheckedAccounts(paymentsData.map((item) => item.id));
  };

  const onRemoveAllFromChecked = () => {
    setCheckedAccounts([]);
  };

  const filteredArr = paymentsData.filter(
    (item) => item.accountDescr.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
  );
  
  const amount = filteredArr
    .filter((item) => checkedAccounts.includes(item.id))
    .map((item) => +item.accountDescr.amount)
    .reduce((a, b) => a + b, 0);

  return (
    <>
      <PaymentsSubheader searchTerm={searchTerm} onSearch={onSearch} />
      <AccountsHeader
        onCheckedAll={onCheckedAll}
        checkedAll={checkedAll}
        checkedItems={checkedAccounts}
        onAddALLtoChecked={onAddALLtoChecked}
        onRemoveAllFromChecked={onRemoveAllFromChecked}
        onSearch={onSearch}
        searchTerm={searchTerm}
      />
      <AccountsBody
        checkedAll={checkedAll}
        onCheckedAll={onCheckedAll}
        checkedItems={checkedAccounts}
        onCheckedItems={onCheckedAccounts}
        onUnCheckedItems={onUnCheckedAccounts}
        filteredArr={filteredArr}
      />
      <AccountsFooter amount={amount} />
    </>
  );
};

export default Accounts;
