import React, { useState } from "react";
import styled from "styled-components";

import LanguageHover from './LanguageHover';

const LanguageWrapper = styled.div`
  position: relative;
  width: 68px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;

  @media(max-width: 768px) {
    display: none;
  }
`;

const LanguageIdentificate = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #223344;
  text-transform: uppercase;
`;

const LanguageBlock = () => {

  const [show, setShow] = useState(false);

  return (
    <LanguageWrapper onMouseEnter={()=>setShow(true)} onMouseLeave={()=>setShow(false)}>
      <img src="/icons/language.svg" alt="language" />
      <LanguageIdentificate>ua</LanguageIdentificate>
      <div>
        <img src="/icons/arrow-down.svg" alt="stroke" />
      </div>
      <LanguageHover show={show} setVisible={()=>setShow(false)}/>
    </LanguageWrapper >
  );
};

export default LanguageBlock;
