import React from 'react';
import styled from 'styled-components';

const ItemStyled = styled.li`
	margin-right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 35px;
	height: 35px;
	font-weight: 600;
	font-size: 15px;
	line-height: 18px;
	color: ${({isActive}) => isActive ? '#FFF;' : '#1E87A9;'} 
	background: ${({isActive}) => isActive ? '#1E87A9;' : '#F6F6F6;'} 
	box-shadow: 1px 1px 2px #FFFFFF, -1px -1px 2px rgba(174, 174, 192, 0.3);
	border-radius: 5px;
	cursor: pointer;
`;

const PaginationItem = ({count, isActive=false}) => {
	return (
		<ItemStyled isActive={isActive}>{count}</ItemStyled>
	)
}

export default PaginationItem;