import React, { useEffect } from 'react';
import styled from 'styled-components';

const PopupLayoutStyled = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
	justify-content: center;
	align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
	z-index: 10;
`;

const PopupWrapper = styled.div`
	width: ${({width}) => width};
  display: flex;
	height: 94%;
	margin-top: 10px;
  border-radius: 5px;
	box-shadow: 0px 0px 2px #D4D4D4;
	background: #FFFFFF;
`;

const PopupLayout = ({ show, children, wrapperWidth='67%' }) => {


  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    }
  }, [show])

  return (
    <PopupLayoutStyled show={show}>
      <PopupWrapper width={wrapperWidth}>{children}</PopupWrapper>
    </PopupLayoutStyled>
  );
};

export default PopupLayout;
