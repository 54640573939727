import React from "react";
import styled from "styled-components";

import UserInformationtTabs from "./UserInformationTabs";
import UserInformation from "./UserInformation";

const UserInformationStyled = styled.section`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 992px) {
    margin-top: 0;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const UserInformationBlock = ({tabsArr}) => {
  return (
    <UserInformationStyled id='dancer'>
      <UserInformationtTabs tabsArr={tabsArr} />
      <UserInformation />
    </UserInformationStyled>
  );
};

export default UserInformationBlock;
