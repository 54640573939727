import React from 'react';
import styled from 'styled-components';

const ButtonsContainerStyled = styled.div`
  margin-top: ${(p) => p.mt || '40px'};
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: 1000px){
    justify-content: ${({jc1000}) => jc1000};
    margin-bottom: 40px;
  }
  
  @media (max-width: 480px) {
    flex-direction: ${({fd480}) => fd480};
    height: ${({h480}) => h480 || '120px'};
    width: ${({w480}) => w480};
    margin: ${({m480}) => m480};
  }
  @media (max-width: 360px) {
    flex-direction: column;
    justify-content: start;
  }
`;

const ButtonsContainer = ({ mt, children,jc1000, fd480, h480 , w480, m480}) => {
  return <ButtonsContainerStyled m480={m480} w480={w480} fd480={fd480} mt={mt} jc1000={jc1000} h480={h480}>{children}</ButtonsContainerStyled>;
};

export default ButtonsContainer;
