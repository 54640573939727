import React, { useEffect, useState } from 'react';
import UserInformationItem from "./UserInformationItem";
import TrainerDancerList from './TrainerDancerList';
import { useSelector } from 'react-redux';

const InformationListTrainer = ({mockData}) => {

	const trainerInfo = useSelector(state => state.userProfiles?.currentProfile?.trainer)

	const [trainerClubs, setTrainerClubs] = useState([]);
	const [trainerClubsList, setTrainerClubList] = useState('');

	useEffect(() => {
		setTrainerClubs(() => trainerInfo?.clubs.length > 0 ? [...trainerInfo?.clubs] : [])
	},[trainerInfo])

	useEffect(() => {
	setTrainerClubList(() => (trainerClubs.join('')))
	}, [trainerClubs])

	return (
		<ul>
			 <UserInformationItem
        image="/icons/dancer_inform/status.svg"
        title="Статус"
        value={mockData.status}
      />
			<UserInformationItem
        image="/icons/dancer_inform/organization.svg"
        title="Організація"
        value={mockData.organization}
      />
			<UserInformationItem
        image="/icons/dancer_inform/club.svg"
        title="Клуб"
        value={trainerClubsList}
      />
			<UserInformationItem
        image="/icons/dancer_inform/club.svg"
        title="Клуб"
        value={mockData.club1}
      />
			<UserInformationItem
        image="/icons/search/active_icons/dancers.svg"
        title="Танцюристи клубу"
        value={<TrainerDancerList value='45'/>}

      />
		</ul>
	)
}

export default InformationListTrainer;