import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AuthDivider from '../AuthDivider';
import AuthTitle from '../AuthTitle';
import ValidMessage from '../ValidMessage';
import RegisterFileInputBlock from './RegisterFileInputBlock';

const DownloadPhotoBlockStyled = styled.div`
  position: relative;
  padding: 40px 0;
  @media (max-width: 1000px) {
    max-width: ${({mw1000}) => mw1000};
    margin: ${({m1000}) => m1000};
  } @media (max-width: 480px) {
    padding: 0 0 32px;
  }
  /* @media (max-width: 360px) {
    padding: 40px 12px;
  } */
`;

const AttentionList = styled.ul`
  padding: 20px;
  list-style: disc;
  margin-top: 16px;
  background: #e7eaed;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #223344;

  li {
    margin-left: 20px;
    margin-top: 12px;

    :first-child {
      margin-top: 0;
    }
  }
`;

const DownloadPhotoBlock = ({ files, onChange, onRemoveFile, validate, title ,mw1000 ,m1000}) => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (validate) {
      if (!files.length){
        setMessage('Файл не вибрано')
      }
      // else if (!files[0]?.name?.match((?i).*\.(png|jpeg|PNG|JPEG)$/i)){
      else if (!files[0]?.name?.match(/.*\.(jpeg|jpg|png)$/igm)){
        setMessage('Невірний формат файлу')
      }
       else {
        setMessage('')
      }
    }
  }, [validate, files]);
  return (
    <DownloadPhotoBlockStyled mw1000={mw1000} m1000={m1000}>
      <AuthTitle small title={title} fs='24px' fs480='20px' p0rd='0' />
      <AttentionList>
        <li>Фото за прикладом як на паспорт.</li>
        <li>Обличчя на фотографії має бути відкритим (волосся не повинно закривати очі).</li>
        <li>На фотографії великим планом повинні бути зображені голова і верхня частина плечей.</li>
        <li>Особа має займати 70-80% вертикального розміру знімка (визначається візуально).</li>
        <li>Фотографія повинна бути на білому фоні.</li>
        <li>Файл формату JPEG або PNG, до 8 МБ.</li>
      </AttentionList>
      <RegisterFileInputBlock files={files} onChange={onChange} onRemoveFile={onRemoveFile} />
      <ValidMessage mt='16px' ml='-1px' message={message} ml0rd='0px' />
      <AuthDivider mlcd='0' />
    </DownloadPhotoBlockStyled>
  );
};

export default DownloadPhotoBlock;
