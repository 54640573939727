import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

const LinkStyled = styled(Link)`
  display: block;
`;

const MenuItemStyled = styled.li`
  margin: 0 auto;
  width: 90%;
  margin-bottom: 10px;
  padding-right: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  border: 2.5px solid transparent;
  :hover {
    ${({ isActive }) =>
      !isActive
        ? `
      border: 2.5px solid rgba(253, 253, 253, 0.75);
      filter: drop-shadow(0px 1px 2px rgba(174, 174, 192, 0.2)) drop-shadow(-1px -1px 1px #FFFFFF) drop-shadow(1px 1px 1px #E5E5E5);
      border-radius: 16px;
    ` : ''}
  }

  @media(max-width: 992px) {
    margin-bottom: 5px;
  }
`;

const IconBlock = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  ${({ isSubMenu, isActive }) =>
    isSubMenu
      ? `
background: #F6F6F6;
border-radius: 100%;
box-shadow: ${
  isActive
    ? '1px 1px 2px #FFFFFF, -1px -1px 2px rgba(174, 174, 192, 0.3)'
    : '-1px -1px 3px #FFFFFF, 1px 1px 3px #E5E5E5'
};
` : ''}

@media(max-width: 992px) {
  min-width: 30px;
  height: 30px;
}
`;

const LinkTitle = styled.div`
  font-weight: ${({ isActive }) => (isActive ? '500' : '500')};
  font-size: 16px;
  letter-spacing: ${({ isActive }) => (isActive ? '0.09em' : '0.1em')};
  color: ${({ isActive }) => (isActive ? '#1E87A9' : '#445566')};

  @media(max-width: 992px) {
    font-size: 15px;
  }
`;

const MenuItem = ({ image, title, path, isSubMenu }) => {
  const location = useLocation();
  const isActive = location.pathname.indexOf(path.split('/')[1]) > -1;

  return (
    <LinkStyled to={path}>
      <MenuItemStyled isActive={isActive}>
        <IconBlock isActive={isActive} isSubMenu={isSubMenu}>
          <img
            src={isActive ? `/icons/active_links${image}` : `/icons/links${image}`}
            alt={title}
          />
        </IconBlock>
        <LinkTitle isActive={isActive}>{title}</LinkTitle>
      </MenuItemStyled>
    </LinkStyled>
  );
};

export default MenuItem;
