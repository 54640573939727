import React from 'react';
import styled from 'styled-components';

const GalleryContainerStyled = styled.div`
  position: relative;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 0px 2px #d4d4d4;
  border-radius: 5px;
  background: #fff;

  @media (max-width: 576px) {
    padding-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: ${({showAddItem = true}) => showAddItem ? '43px' : 'auto'};
    grid-auto-rows: auto;
    row-gap: 16px;
    column-gap: 9px;
  }
`;

const GalleryContainer = ({ children, showAddItem }) => {

  return (
    <GalleryContainerStyled showAddItem={showAddItem}>
      {children}
    </GalleryContainerStyled>
  );
};

export default GalleryContainer;
