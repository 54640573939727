import React, { useEffect, useMemo, useState } from 'react';
import GalleryContainer from '../GalleryContainer';
import GalleryImgItem from '../GalleryImgItem';
import GalleryAddItem from '../GalleryAddItem';
import AlbumTitle from './AlbumTitle';
import RemoveAlbum from '../../popups/removeAlbum/RemoveAlbum';
import { useDispatch, useSelector } from 'react-redux';
// import { getUserAlbums } from '../../../actions/galleryAction';
import { getUserAlbums } from '../../../actions/galleryAction';
import styled from "styled-components";

const PaginationList = styled.ul`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const PaginationPages = styled.li`
  padding: 15px;

  span {
    cursor: pointer;
    color: ${({ number, activePage }) => number === activePage ? "#1E87A9" : "#445566"};
    border: ${({ number, activePage }) => number === activePage ? "1px solid #1E87A9" : "none"};
    padding: 5px 10px;
    border-radius: 10px;
    background: ${({ number, activePage }) => number === activePage ? "#f1f2f3" : "transparent"};

    :hover {
      color: #1E87A9
    }
  }
`;


const Album = ({showAddItem, withOption=true, isMove, albums, albumLink, itsAlbum, setItsAlbum}) => {

  const userId = useSelector(state => state.login?.currentUser?.id)
  const userAlbums = useSelector(state => state.userGallery?.dataAlbum?.results?.albums);
  const userAlbumsCount = useSelector(state => state.userGallery?.dataAlbum?.count);

  const [photos, setPhotos] = useState();
  const [showPopupRemoveAlbum, setShowPopupRemoveAlbum] = useState(false);
  const [albumToRemoveId, setAlbumToRemoveId] = useState(null);
  const [albumToMoveId, setAlbumToMoveId] = useState(null);
  const [userAlbumsPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(1);
  const userTotalPage = [];
  const [currentOffset, setCurrentOffset] = useState(0);
  const [activePage, setActivePage] = useState(1)

  const dispatch = useDispatch();

  const paginate = (pageNumber) => {
    setCurrentOffset(() => pageNumber === 1 ? 0 : pageNumber * 7 - 7);
    setActivePage(pageNumber)
  };

  useEffect(() => {
    if (userId !== ''){
    dispatch(getUserAlbums(userId, currentOffset))
    }
  }, [userId,currentPage,currentOffset])

  for (let i = 1; i <= Math.ceil(userAlbumsCount / userAlbumsPerPage); i++) {
    userTotalPage.push(i);
  }

  let firstIndex = 0;
  const onDragStart = (e) => {
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.dropEffect = 'move';
    firstIndex = photos?.findIndex((item) => +item.id === +e.target.getAttribute('data-id'));
  };

  const onDragEnter = (e) => {
    e.preventDefault();
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };
  const onDrop = (e) => {
    if (e.target.hasAttribute('data-id')) {
      setPhotos((state) => {
        let lastIndex = albums?.findIndex((item) => +item.id === +e.target.getAttribute('data-id'));
        const sortedArr = state.slice();
        sortedArr[firstIndex] = state[lastIndex];
        sortedArr[lastIndex] = state[firstIndex];
        return sortedArr;
      });
    }
  }; 

  const onShowRemoveAlbum = (id) => {
    setAlbumToRemoveId(id);
    setShowPopupRemoveAlbum(true);
  }

  const onCheckedAlbumToMove = (id) => {
    setAlbumToMoveId(id);
  }
  return (
    <>
    <GalleryContainer showAddItem={showAddItem}>
      <GalleryAddItem show={showAddItem} userId={userId}/>
      {userAlbums?.map((item, i) => (
        <div key={item?.id + i}>
          <GalleryImgItem
            userAlbumsCount={userAlbumsCount}
            currentOffset={currentOffset}
          setCurrentOffset={setCurrentOffset}
          activePage={activePage}
          setActivePage={setActivePage}
            userTotalPage={userTotalPage}
            currentPage={currentPage}
            userAlbumsPerPage={userAlbumsPerPage}
            itsAlbum={itsAlbum}
            userId={userId}
            imgUrl={item?.image}
            id={item?.id}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            isAlbum
            withOption={item.name !== 'Фото' && withOption}
            onShowRemoveAlbum={onShowRemoveAlbum}
            isMove={isMove}
            onCheckedAlbumToMove={onCheckedAlbumToMove}
            showOverlay={item.id === albumToMoveId}
            albumLink={albumLink}
          />
          <AlbumTitle albumLink={albumLink} title={item?.title} status={item?.status} count={item.photos?.length} id={item?.id} isMove={isMove} />
        </div>
      ))}
      <RemoveAlbum show={showPopupRemoveAlbum} setShow={setShowPopupRemoveAlbum} albumId={albumToRemoveId} />
      <PaginationList>
        {userTotalPage?.map((number,i) => (
          <PaginationPages key={number + i} number={number} activePage={activePage}>
            <span onClick={() => paginate(number)} >
              {number}
            </span>

          </PaginationPages>
        ))}
      </PaginationList>
    </GalleryContainer>


    </>
  );
};

export default Album;
