import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const AvatarBlockStyled = styled.div`
  position: relative;
  height: 347px;
	border-radius: 5px 5px 0 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
  }

  @media(max-width: 1200px) {
    height: 270px;
  }

  @media(max-width: 992px) {
    height: 200px;
  }

  @media(max-width: 768px) {
    border-radius: 0;
    img {
      border-radius: 0;
    }
  }

  @media(max-width: 576px) {
    height: 150px;
  }
`;

const Avatar = styled.div`
  position: absolute;
  left: 30px;
  bottom: 30px;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  img {
    border-radius: 100%;
    // border: 3px solid #fff;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media(max-width: 992px) {
    width: 150px;
    height: 150px;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media(max-width: 768px) {
    width: 110px;
    height: 110px;
  }
`;

// const wrapperBacgound = styled.

const AvatarBlock = () => {
	const userInfo = useSelector(state => state.userProfiles?.currentProfile)

	const [userCover,setUserCover] = useState('');
	const [userPhoto,setUserPhoto] = useState('');

	useEffect(() => {
		setUserCover(() => userInfo?.user?.cover);
		setUserPhoto(() => userInfo?.user?.photo);
	},[userInfo])
	return (
	<AvatarBlockStyled>
			<img src={userCover !== null ? `${userCover}`: "/image/promo_bg.jpg"} alt="wrapper" />
		<Avatar>
			<img src={`${userPhoto}`} alt="avatar" />
		</Avatar>
	</AvatarBlockStyled>
	)
}

export default AvatarBlock;